import { useCallback, useEffect } from 'react';
import { WidgetProps } from '@rjsf/utils';

import { MenuItem, Select, InputLabel } from '@mui/material';

import { ITableStructureListModel } from "../../../../services/model/dataManagement/ITableStructureListModel";
import { usePageContext } from "../../pages/pagesContext";

const DbTableWidget = ({ onChange, id, value, label }: WidgetProps) => {
    const { setSelectedTableId, dbData } = usePageContext();

    useEffect(() => {
        setSelectedTableId(value);
    }, [value, setSelectedTableId]);

    const handleChange = useCallback((event: any) => {
        const newValue = event.target.value;
        onChange(newValue);
    }, [onChange]);

    return (
        <>
            <InputLabel id={`${id}-label`}>{label}</InputLabel>
            <Select
                id={id}
                value={value || (dbData?.tables && dbData?.tables[0]?.id)}
                labelId={`${id}-label`}
                label={label}
                onChange={handleChange}
                variant="outlined"
                defaultValue={dbData?.tables && dbData?.tables[0]?.id}
            >
                {dbData?.tables?.map((table: ITableStructureListModel) =>
                    <MenuItem key={table.id} value={table.id}>{table.displayName}</MenuItem>)
                }
            </Select>
        </>
    );
};

export { DbTableWidget };
