import * as React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

interface StagingWarningModalProps {
    open: boolean;
    description: string;
    onConfirm: () => void;
    onCancel: () => void;
}

export const StagingWarningModal: React.FC<StagingWarningModalProps> = ({ open, description, onConfirm, onCancel }) => {
    return (
        <Modal open={open} onClose={onCancel} aria-labelledby="warning-modal-title" aria-describedby="warning-modal-description" className="bb-delete-modal">
            <Box className="bb-delete-modal" sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4
            }}>
                <Typography id="warning-modal-title" variant="h3" component="h2">
                    Warning!
                </Typography>
                <Typography id="warning-modal-description" sx={{ mt: 2 }}>
                    {description} This action will reset your <b>Staging Environment</b> with the latest version of your <b>Live Environment</b>!
                </Typography>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }} className="bb-mt-4">
                    <Button variant="outlined" color="warning" onClick={onCancel}>
                        Cancel & Close
                    </Button>
                    <Button variant="contained" color="primary" onClick={onConfirm}>
                        Confirm & Reset
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export {};
