import { Link } from "react-router-dom";

import Typography from "@mui/material/Typography";

import {
    Container,
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    TablePagination,
    TableHead,
    CircularProgress,
    Alert
} from "@mui/material";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';

import { useState, useEffect, useCallback } from "react";

import { SearchField } from "../applications/customWidget/Search/SearchField";

import useDataClient from "../../axios/dataClient";

import { stringSort } from "../../services/helper/sort";

import { ResultListModel } from "../../schemas/eventManagement/resultSchema";

import { getPartners } from "../../services/api/PartnerManagementService";

import { Partner } from "../../schemas/partnerManagement/schema";

import { errors } from "../ErrorDisplay";

import { newPartner } from "../newModuleNames";

export const PartnersList = () => {
    const { get } = useDataClient();

    const [partners, setPartners] = useState<Partner[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [isLoading, setLoading] = useState(false);
    const [searchString, setSearchString] = useState<string>();
    const [filteredPartners, setFilteredPartners] = useState<Partner[]>([]);

    const callSearch = useCallback((search?: string) => {
        setSearchString(search);
    }, [setSearchString]);

    useEffect(() => {
        if (!partners) {
            return;
        }

        const searchStringUpper = searchString?.toUpperCase();
        setCurrentPage(0);
        const filtered = partners.filter((partner) =>
            (!searchStringUpper || (partner?.partnerName && partner?.partnerName.toUpperCase().includes(searchStringUpper))));
        setFilteredPartners(filtered);
    }, [searchString, partners]);

    const fetchData = useCallback(async () => {

        getPartners<ResultListModel<Partner>>(get)().then((partnersData) => {
            if (partnersData?.isSuccess && partnersData?.data) {
                setPartners(partnersData.data);
                setFilteredPartners(partnersData.data);
            } else if (partnersData?.messages) {
                errors(partnersData.messages);
            }

            setLoading(false);
            return;
        });
    }, [get]);

    useEffect(() => {
        setSearchString("");
    }, []);

    const refreshList = useCallback(() => {
        setLoading(true);
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [fetchData]);

    const handleChangePage = (event: any, newPage: any) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0); // Reset to first page
    };

    return (
        <Container>
            <Box className="bb-title-bar bb-mt-3">
                <h2 className="bb-m0 bb-p0">Manage Partners</h2>
            </Box>
            <Breadcrumbs className="bb-breadcrumb bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
                <Link to="/partnerManagement">Partner Management</Link>
                <Typography color="text.primary" className="bb-m0 bb-p0">Manage Partners</Typography>
            </Breadcrumbs>
            <Alert className="bb-title-info bb-mb-2 bb-align-items-center bb-flex" severity="info" sx={{ marginTop: '0px!important' }}
            >
            Welcome to your list of <b>Partners.</b> Here, you can view and manager all of your partners.
            </Alert>
            <Box className="bb-flex bb-ui-box bb-flex-column bb-tac">
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box>
                        <h3 className="bb-m0 bb-p0">Parnter Manager</h3>
                    </Box>
                    <Box className="bb-tal" sx={{ display: "flex", justifyContent: "flex-end", alignItems: 'center' }}>
                        <Box>
                            <Button
                                variant="contained"
                                className="bb-ml-auto bb-primary-button"
                                onClick={refreshList}
                                startIcon={<RefreshOutlinedIcon />}
                                disabled={isLoading}
                                sx={{ marginRight: '10px', color: '#000000!important', backgroundColor: '#ffffff!important', border: '1px solid #11a681' }}
                            >
                        Refresh
                            </Button>
                        </Box>
                        <Box>
                            <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon" to={`/partnerManagement/partners/${newPartner}`}>
                                <Button variant="contained" startIcon={<PersonAddAlt1OutlinedIcon />} disabled={isLoading}>
                            New Partner
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                </Box>
                {isLoading
                    ? (<Box className="bb-tac"><CircularProgress /></Box>)
                    : (
                        <TableContainer component={Paper} sx={{ boxShadow: "none", marginTop: "1rem" }} >
                            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: 'center', marginBottom: "20px" }}>
                                <Typography variant="h5" className="bb-m0 bb-p0">Search by Partner Name</Typography>
                                <Box className="bb-med-search">
                                    <SearchField
                                        searchString={searchString}
                                        callSearch={callSearch}
                                        timeout={500}
                                    />
                                </Box>
                            </Box>
                            <Table aria-label="table" sx={{ padding: "0", margin: "0" }} className="bb-page-list-table bb-global-table">
                                <TableHead sx={{ borderRadius: "5px 5px 0 0" }}>
                                    <TableRow >
                                        <TableCell>Partner Name</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>{
                                    filteredPartners
                                        .sort((a, b) => stringSort(a.partnerName, b.partnerName))
                                        .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                                        .map((partner, index) => {
                                            return (
                                                <TableRow key={`partnerUser_${index}`} sx={{ padding: "0", margin: "0" }}>
                                                    <TableCell sx={{ padding: "0", margin: "0" }}>
                                                        <Box className="bb-ml-2">
                                                            <Link
                                                                className="bb-pages-list bb-align-items-center"
                                                                to={`/partnerManagement/partners/${partner.rowKey}`}
                                                            >
                                                                {partner.partnerName}
                                                            </Link>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                }
                                </TableBody>
                            </Table>
                            <TablePagination
                                className="bb-global-table-footer"
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={filteredPartners.length}
                                rowsPerPage={rowsPerPage}
                                page={currentPage}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    )
                }
            </Box>
        </Container>
    );
};
