import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

export const BotPageLayout = () => {
    return (
        <Box sx={{ marginTop: "1rem" }}>
            <Outlet />
        </Box>
    );
};
