import { JSONSchema7 } from 'json-schema';

import { Form } from '@rjsf/mui';

import validatorAjv8 from '@rjsf/validator-ajv8';

import React, { useMemo } from "react";

import { UiSchema } from "@rjsf/utils";

import { MediaLibraryWidget } from "../customWidget/MediaLibrary/MediaLibraryWidget";

import { Settings } from "../../../schemas/settings";
import schema from "../../../schemas/settings/settings.json";

export interface SettingsFormProps {
    settings: Settings;
    submitting: boolean;
    onChange: (e: any) => void;
    onError: (e: any) => void;
    onSubmit: (e: any) => void;
    uiSchema: UiSchema;
}

export const SettingsForm: React.FC<SettingsFormProps> = ({ onChange, submitting, onError, onSubmit, settings, uiSchema }) => {
    const customWidgets = useMemo(() => ({
        MediaLibraryWidget
    }), []);
    return (
        <Form
            id="settings_pagesubmit"
            schema={schema as JSONSchema7}
            formData={settings}
            validator={validatorAjv8}
            onChange={onChange}
            onSubmit={onSubmit}
            onError={onError}
            uiSchema={uiSchema}
            liveValidate={true}
            showErrorList={false}
            widgets={customWidgets}
            disabled={submitting}
        />
    );
};
