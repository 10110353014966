import React, { FC, useMemo, useState, useCallback } from "react";

import { Form } from '@rjsf/mui';

import validatorAjv8 from "@rjsf/validator-ajv8";

import { RJSFSchema, UiSchema } from "@rjsf/utils";

import { Grid, Box, Button, styled, Modal, Typography, useTheme } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { GridRowId } from '@mui/x-data-grid';

export interface DataApprovalJsonFormModalProps {
    id: GridRowId;
    onUpdate: (e: any) => void;
    onApprove: (e: any) => void;
    onReject: (e: any) => void;
    onCancel: (e: any) => void;
    onClose: (e: any) => void;
    onError?: (e: any) => void;
    schema: RJSFSchema;
    data: any;
    open: boolean;
    isPartnerUser?: boolean;
}

const ModalBox = styled(Box)(() => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    padding: 15,
    p: 4,
    overflow: "auto",
    maxHeight: "60%",
}));

export const DataApprovalJsonFormModal: FC<DataApprovalJsonFormModalProps> = ({
    id,
    onUpdate,
    onApprove,
    onReject,
    onCancel,
    onError,
    onClose,
    schema,
    data,
    open,
    isPartnerUser }) => {
    const theme = useTheme();
    const [formData, setFormData] = useState(data);

    const uiSchema: UiSchema = useMemo(() => ({
        "PartitionKey": {
            "ui:readonly": true
        },
        "RowKey": {
            "ui:readonly": true
        },
        "RowStatus": {
            "ui:readonly": true,
            "ui:widget": "hidden"
        },
        "Timestamp": {
            "ui:readonly": true
        },
        "ui:title": "",
        'ui:submitButtonOptions': {
            norender: true
        }
    }), []);

    const handleApproveClick = useCallback(() => {
        onApprove(id);
    }, [id, onApprove]);

    const handleRejectClick = useCallback(() => {
        onReject(id);
    }, [id, onReject]);

    const handleCancelClick = useCallback(() => {
        onCancel(id);
    }, [id, onCancel]);

    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ "& .MuiBox-root": { backgroundColor: theme.palette.background.default } }}
        >
            <ModalBox className={"bb-edit-page-modal"}>
                <Box className="bb-flex bb-p-3 bb-align-items-center bb-edit-page-modal-header">
                    <Typography variant='h2' className="bb-mb-0" sx={{ marginLeft: ".5rem" }}>
                        {schema?.title}
                    </Typography>
                    <Button className="bb-ml-auto" onClick={onClose} variant="text">
                        <CloseIcon></CloseIcon>
                    </Button>
                </Box>
                <Box className="bb-pe-modal-form-media">
                    <Form
                        id={schema?.title}
                        schema={{ ...schema }}
                        onChange={(e) => setFormData(e.formData)}
                        formData={{ ...formData }}
                        validator={validatorAjv8}
                        onError={onError}
                        onSubmit={onUpdate}
                        uiSchema={uiSchema}
                        className={"bb-pe-modal-form"}
                        disabled={!isPartnerUser}
                        formContext={{ formData }}
                    >
                        <Box className="bb-pe-modal-footer">
                            <Grid container spacing={1} className="bb-flex bb-fixed">
                                <Grid item>
                                    <Button onClick={onClose} variant="text">
                                Close
                                    </Button>
                                </Grid>
                                {!isPartnerUser &&
                                    <>
                                        <Grid item className="bb-ml-auto">
                                            <Button onClick={handleApproveClick} variant="contained">
                                        Approve
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={handleRejectClick} variant="contained" color="error">
                                        Reject
                                            </Button>
                                        </Grid>
                                    </>
                                }
                                {isPartnerUser &&
                                    <>
                                        <Grid item className="bb-ml-auto">
                                            <Button variant="contained" type="submit">
                                        Update Data
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={handleCancelClick} variant="contained"color="error">
                                        Cancel Data Update Request
                                            </Button>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </Box>
                    </Form>
                </Box>
            </ModalBox>
        </Modal>
    );
};
