import React, { useState, useEffect } from "react";
import "./event.css";
import { Modal, Box, Button, Typography, Grid, IconButton } from "@mui/material";
import { TextEditor } from "../../applications/customWidget/HtmlEditor/TextEditor";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";

interface EventDescriptionModalProps {
    value: string;
    onChange: (newValue: string) => void;
}

export const EventDescriptionModal: React.FC<EventDescriptionModalProps> = ({ value, onChange }) => {
    const [open, setOpen] = useState(false);
    const [editorContent, setEditorContent] = useState(value);

    useEffect(() => {
        if (open) {
            setEditorContent(value);
        }
    }, [open, value]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSave = () => {
        onChange(editorContent);
        handleClose();
    };

    return (
        <div className="textEditor-width-100">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '26px',
                    width: '100%',
                    position: 'relative',
                    minWidth: '100%!important'
                }}>
                <Box
                    sx={{ display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        backgroundColor: '#ebebeb',
                        padding: '8px',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                        border: '1.2px solid #bdbdbd',
                        borderBottom: 'none'
                    }}>
                    <Typography
                        variant="body1"
                        sx={{ paddingLeft: '10px', paddingTop: '8px' }}>
                        Event Description
                    </Typography>
                    <IconButton
                        onClick={handleOpen}
                        sx={{
                            padding: '5px',
                            backgroundColor: '#11a681',
                            '&:hover': {
                                backgroundColor: '#0e8a6b',
                            },
                        }}
                        size="small">
                        <EditIcon sx={{ color: '#ffffff', padding: '3px' }} />
                    </IconButton>
                </Box>
                <Box
                    onClick={handleOpen}
                    sx={{
                        backgroundColor: '#ffffff',
                        border: '1.2px solid #bdbdbd',
                        borderTop: 'none',
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                        minHeight: '150px',
                        overflow: 'auto',
                        padding: '20px',
                        minWidth: '100%',
                        '&:hover': {
                            border: '1px solid #000000',
                        },
                    }}>
                    <Typography
                        variant="body1"
                        sx={{ textAlign: 'left', whiteSpace: 'pre-wrap', minWidth: '100%' }}>
                        {value ? (
                            <span dangerouslySetInnerHTML={{ __html: value }} />
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center!important'
                                }}>
                                <Box>
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            marginBottom: '0px!important',
                                            marginTop: '30px'
                                        }}>
                                        Please add an Events Description...
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                    </Typography>
                </Box>
            </Box>
            <Modal open={open} onClose={handleClose}>
                <Box
                    className="bb-edit-page-modal"
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        bgcolor: 'background.paper',
                        boxShadow: 24, p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        height: '80vh'
                    }}>
                    <Box
                        className="bb-pe-modal-header"
                        sx={{ display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center', mb: 2
                        }}>
                        <Typography
                            variant="h2"
                            component="h2"
                        >
                            Edit Description
                        </Typography>
                        <Box>
                            <Button
                                className="bb-ml-auto"
                                onClick={handleClose}
                                variant="text"
                            >
                                <CloseIcon />
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        className="bb-pe-modal-form"
                        sx={{
                            flexGrow: 1,
                            overflow: 'auto',
                            mb: 5
                        }}>
                        <TextEditor
                            value={editorContent}
                            onChange={setEditorContent}
                        />
                    </Box>
                    <Box
                        className="bb-pe-modal-footer"
                    >
                        <Grid
                            container spacing={1}
                            className="bb-flex bb-fixed"
                        >
                            <Grid item>
                                <Button onClick={handleClose} variant="text">
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item className="bb-ml-auto">
                                <Button onClick={handleSave} variant="contained">
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};
