import { Grid } from "@mui/material";

import { StatCard } from "./StatCard";
import { bigNumber } from "./StatHelpers";

interface Props {
    data: any | undefined;
    loading: boolean;
}

export const NotificationsSummaryGrid = ({ loading, data }: Props) => {

    let values = [0, 0, 0];

    if (data) {
        const values2 = data.data as any;
        values = [values2.broadcastN[0], values2.sentN[0], values2.openedN[0]];
    }

    return (
        <Grid container gap="1rem" className="bb-flex bb-width-100 bb-analytics-big-stat">
            <div className="bb-flex-1 animated animatedFadeInUp fadeInUp"><StatCard loading={loading} title="Notifications Created"
                stat={
                    bigNumber(values[0])
                } />
            </div>

            <div className="bb-flex-1 animated animatedFadeInUp fadeInUp1"><StatCard loading={loading} title="Messages Sent"
                stat={
                    bigNumber(values[1])
                } />
            </div>
            <div className="bb-flex-1 animated animatedFadeInUp fadeInUp2"><StatCard loading={loading} title="Messages Opened"
                stat={
                    bigNumber(values[2])
                } />
            </div>
        </Grid >
    );
};
