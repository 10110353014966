import { Checkbox, Divider, FormControl, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";

import { MemberAppContext } from "../../../MemberAppContext";
import { Team } from "../../../services/model/users/customerUser";

const myTeamKey = "MYTEAM";
const allTeamKey = "ALLTEAM";

interface TeamSelectProps {
    teams: Team[];
    selectedTeams: string[];
    setSelectedTeams: React.Dispatch<React.SetStateAction<string[]>>;
}

export const TeamsSelect = ({ teams: allTeams, selectedTeams, setSelectedTeams }: TeamSelectProps) => {
    // This actually initializes to true thanks to the useEffects at the bottom
    const [myTeamsSelected, setMyTeamsSelected] = useState<boolean>(false);
    const [myTeamsCheckboxSelected, setMyTeamsCheckboxSelected] = useState<boolean>(false);
    const [myTeamsIndeterminate, setMyTeamsIndeterminate] = useState<boolean>(false);

    const [allTeamsSelected, setAllTeamsSelected] = useState<boolean>(false);
    const [allTeamsCheckboxSelected, setAllTeamsCheckboxSelected] = useState<boolean>(false);
    const [allTeamsIndeterminate, setAllTeamsIndeterminate] = useState<boolean>(false);

    const { user: { liveAgentTeams, isLiveAgentAdmin } } = useContext(MemberAppContext);
    const [teams, setTeams] = useState<Team[]>(allTeams.filter(team => liveAgentTeams.some(myTeam => team.id.startsWith(myTeam))));

    useEffect(() => {
        if (isLiveAgentAdmin) {
            setTeams(allTeams);
        }
    }, [isLiveAgentAdmin, allTeams]);
    // Initialize the select with live agent teams in it
    useEffect(() => {
        if (liveAgentTeams.length !== 0) {
            const teamsIncludingLiveAgent = [...liveAgentTeams];
            setSelectedTeams(teamsIncludingLiveAgent);
        }
    }, [liveAgentTeams, setSelectedTeams]);

    const selectChange = useCallback((event: SelectChangeEvent<typeof selectedTeams>) => {
        const {
            target: { value },
        } = event;
        let selected = typeof value === "string" ? value.split(",") : value;

        const myTeamsIsSet = selected.includes(myTeamKey);
        const myTeamsWasSet = myTeamsSelected;

        if (myTeamsIsSet && !myTeamsWasSet) {
            selected = [...new Set([...selected, ...liveAgentTeams])];
        }

        if (myTeamsWasSet && !myTeamsIsSet) {
            selected = selected.filter(selectedTeam => !liveAgentTeams.includes(selectedTeam));
        }

        const allTeamsIsSet = selected.includes(allTeamKey);
        const allTeamsWasSet = allTeamsSelected;

        if (allTeamsIsSet && !allTeamsWasSet) {
            selected = [...new Set([...selected, ...teams.map(team => team.id)])];
        }

        if (allTeamsWasSet && !allTeamsIsSet) {
            selected = [];
        }

        setMyTeamsSelected(myTeamsIsSet);
        setAllTeamsSelected(allTeamsIsSet);
        setSelectedTeams(selected);
    }, [myTeamsSelected, allTeamsSelected, liveAgentTeams, teams, setSelectedTeams]);

    const renderSelect = useCallback((selected: string[]) => {
        return selected.map(teamId => allTeams.find(team => team.id === teamId))
            .filter(team => team !== undefined)
            .map(team => team?.displayName).join(", ");
    }, [allTeams]);

    useEffect(() => {
        const mySelectedTeams = selectedTeams.filter(selectedTeam => liveAgentTeams.includes(selectedTeam));
        let newSelectedTeams = [...selectedTeams];

        if (mySelectedTeams.length === liveAgentTeams.length && liveAgentTeams.length !== 0) {
            setMyTeamsSelected(true);
            setMyTeamsCheckboxSelected(true);
            setMyTeamsIndeterminate(false);
            if (!selectedTeams.includes(myTeamKey)) {
                newSelectedTeams = [...newSelectedTeams, myTeamKey];
            }
        }
        else if (mySelectedTeams.length > 0) {
            setMyTeamsCheckboxSelected(false);
            setMyTeamsIndeterminate(true);
        }
        else {
            setMyTeamsIndeterminate(false);
            setMyTeamsCheckboxSelected(false);
            if (selectedTeams.includes(myTeamKey)) {
                setMyTeamsSelected(false);
                newSelectedTeams = newSelectedTeams.filter(selectedTeam => selectedTeam !== myTeamKey);
            }
        }

        const selectedTeamsWithoutKeys = selectedTeams.filter(team => team !== myTeamKey && team !== allTeamKey);
        if (selectedTeamsWithoutKeys.length === teams.length && teams.length !== 0) {
            setAllTeamsSelected(true);
            setAllTeamsCheckboxSelected(true);
            setAllTeamsIndeterminate(false);
            if (!selectedTeams.includes(allTeamKey)) {
                newSelectedTeams = [...newSelectedTeams, allTeamKey];
            }
        }
        else if (selectedTeamsWithoutKeys.length !== 0) {
            setAllTeamsIndeterminate(true);
            setAllTeamsCheckboxSelected(false);
        }

        else {
            setAllTeamsCheckboxSelected(false);
            setAllTeamsIndeterminate(false);
            if (selectedTeams.includes(allTeamKey)) {
                setAllTeamsSelected(false);
                newSelectedTeams = newSelectedTeams.filter(selectedTeam => selectedTeam !== allTeamKey);
            }
        }

        if (newSelectedTeams.length !== selectedTeams.length) {
            setSelectedTeams(newSelectedTeams);
        }
    }, [allTeams, selectedTeams, teams, liveAgentTeams, setSelectedTeams]);

    return (
        <FormControl>
            <InputLabel>Teams</InputLabel>
            <Select
                multiple
                label="teams"
                value={selectedTeams}
                onChange={selectChange}
                renderValue={renderSelect}
                placeholder="Teams">
                <MenuItem value={myTeamKey}>
                    <Checkbox indeterminate={myTeamsIndeterminate} checked={myTeamsCheckboxSelected} />
                    <ListItemText primary="My Teams" />
                </MenuItem>
                <MenuItem value={allTeamKey}>
                    <Checkbox indeterminate={allTeamsIndeterminate} checked={allTeamsCheckboxSelected} />
                    <ListItemText primary="All Teams" />
                </MenuItem>
                <Divider />
                {teams.map((team) => (
                    <MenuItem key={team.id} value={team.id}>
                        <Checkbox checked={selectedTeams.includes(team.id)} />
                        <ListItemText primary={team.displayName} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>);
};
