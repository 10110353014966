import {
    Button,
    CircularProgress,
    Grid,
    Container,
    Box,
    Breadcrumbs,
    MenuItem,
    ListItemText,
    Typography, Checkbox,
    Alert,
    Tooltip
} from "@mui/material";

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

import { Field, FieldProps, Form, Formik } from "formik";

import { useState, useCallback, useEffect } from "react";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import { useParams, Link, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import dayjs, { Dayjs } from 'dayjs';

import useDataClient from "../../../axios/dataClient";

import { TextField } from "../../../material/TextField";

import { Select } from "../../../material/Select";

import { Schedule, ScheduleSchema } from "../../../schemas/eventManagement/scheduleSchema";

import { Venue } from "../../../schemas/eventManagement/venueSchema";

import { ResultModel, ResultEntityModel, ResultListModel } from "../../../schemas/eventManagement/resultSchema";

import { stringSort } from "../../../services/helper/sort";

import { getSchedule, createSchedule, updateSchedule, deleteSchedule } from "../../../services/api/ScheduleManagementService";

import { getVenues } from "../../../services/api/VenueManagementService";

import { UndoButton } from "../../applications/UndoButton";

import { WarningModal } from "../../shared/WarningModal";

import { errors } from "../../ErrorDisplay";
import FormControlLabel from "@mui/material/FormControlLabel";

import { EventDetails } from '../schedule/EventDetails';

export const ScheduleForm = () => {

    const { put, post, get, deleteRequest } = useDataClient();
    const { eventId, scheduleId } = useParams();
    const navigate = useNavigate();
    const isDelete = location?.pathname?.endsWith("/delete");

    const [selectedStartDate, setSelectedStartDate] = useState<Dayjs | null>();
    const [selectedEndDate, setSelectedEndDate] = useState<Dayjs | null>();
    const [selectedRepeatUntilDate, setSelectedRepeatUntilDate] = useState<Dayjs | null>();

    const [schedule, setSchedule] = useState<Schedule>();
    const [isLoading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [warningModalOpen, setWarningModalOpen] = useState(false);
    const [venues, setVenues] = useState<Venue[]>([]);

    const handleSelectedStartDateChange = useCallback((date: Dayjs | null) => {
        setSelectedStartDate(date);
    }, []);

    const handleSelectedEndDateChange = useCallback((date: Dayjs | null) => {
        setSelectedEndDate(date);
    }, []);

    const handleSelectedRepeatUntilDateChange = useCallback((date: Dayjs | null) => {
        setSelectedRepeatUntilDate(date);
    }, []);

    const fetchData = useCallback(async () => {
        if (!eventId) {
            return;
        }

        if (scheduleId) {
            getSchedule<ResultEntityModel<Schedule>>(get)(eventId, scheduleId).then((scheduleData) => {
                if (scheduleData?.isSuccess) {
                    setSchedule(scheduleData.entity);
                    setSelectedStartDate(dayjs(scheduleData.entity?.startDate));
                    setSelectedEndDate(dayjs(scheduleData.entity?.endDate));
                } else if (scheduleData?.messages) {
                    errors(scheduleData.messages);
                }

                return;
            }).finally(() => {
                setLoading(false);
            });
        }
        else {
            setSchedule({
                scheduleName: "",
                capacity: 0,
                price: 0,
                daysOfWeek: [],
                repeatUntilDate: undefined,
                isRecurring: false,
            });
            setLoading(false);
        }
    }, [get, scheduleId, eventId]);

    const fetchVenueData = useCallback(async () => {

        getVenues<ResultListModel<Venue>>(get)().then((venuesData) => {
            if (venuesData?.isSuccess && venuesData?.data) {
                setVenues(venuesData.data.sort((a, b) => stringSort(a.venueName, b.venueName)));
            }

            return;
        });
    }, [get]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await fetchData();
            await fetchVenueData();
        })();
    }, [fetchData, fetchVenueData]);

    const callSave = useCallback(async (scheduleData: Schedule) => {
        if (!eventId) {
            return;
        }

        setSubmitting(true);

        if (!schedule) {
            setSubmitting(false);
            return;
        }

        const scheduleDataToSave: Schedule = { ...scheduleData, eventId };

        if (selectedStartDate) {
            scheduleDataToSave.startDate = new Date(selectedStartDate.toISOString());
        }

        if (selectedEndDate) {
            scheduleDataToSave.endDate = new Date(selectedEndDate.toISOString());
        }

        if (selectedRepeatUntilDate) {
            scheduleDataToSave.repeatUntilDate = new Date(selectedRepeatUntilDate.toISOString());
        }

        console.log("scheduleDataToSave", scheduleDataToSave);
        if (scheduleId) {
            updateSchedule<ResultModel>(put)(eventId, scheduleId, scheduleDataToSave).then((result) => {
                if (result.isSuccess) {
                    toast.success("Schedule updated");
                } else if (result?.messages) {
                    errors(result.messages);
                }
                return;
            }).finally(() => {
                setSubmitting(false);
            });
        }
        else {
            createSchedule<ResultEntityModel<Schedule>>(post)(eventId, scheduleDataToSave).then((result) => {
                if (result.isSuccess) {
                    toast.success("Schedule created");
                    navigate(`/eventManagement/events/${eventId}/schedules/${result.entity?.rowKey}`);
                } else if (result?.messages) {
                    errors(result.messages);
                }
                return;
            }).finally(() => {
                setSubmitting(false);
            });
        }
    }, [eventId, schedule, selectedStartDate, selectedEndDate, selectedRepeatUntilDate, scheduleId, put, post, navigate]);

    const navigateToDelete = () => {
        navigate(`/eventManagement/events/${eventId}/schedules/${scheduleId}/delete`);
    };

    const handleUndo = useCallback(async (): Promise<void> => {
        setSubmitting(true);

        fetchData().then(() => {
            toast.warning("Schedule data has been reset");
            return;
        }).finally(() => {
            setSubmitting(false);
        });
    }, [fetchData]);

    const handleDelete = useCallback(async (): Promise<boolean> => {
        if (scheduleId && eventId) {
            setSubmitting(true);
            if (schedule) {
                return deleteSchedule<ResultModel>(deleteRequest)(eventId, scheduleId).then((result) => {
                    if (result?.isSuccess) {
                        setSchedule({ ...schedule, deleted: true });
                        toast.warning(`Schedule has been marked as deleted`);
                        setLoading(true);
                        fetchData();
                        return true;
                    } else if (result?.messages) {
                        errors(result.messages);
                    }
                    return false;
                }).finally(() => {
                    setSubmitting(false);
                });
            }
        }

        return false;
    }, [scheduleId, eventId, deleteRequest, schedule, fetchData]);

    const handleConfirmDelete = useCallback(async (): Promise<void> => {
        await handleDelete();
        setWarningModalOpen(false);
        navigate(`/eventManagement/events/${eventId}/schedules/${scheduleId}`);
    }, [navigate, handleDelete, eventId, scheduleId]);

    const handleWarningModalClose = () => {
        setWarningModalOpen(false);
        navigate(`/eventManagement/events/${eventId}/schedules/${scheduleId}`);
    };

    const handleWarningModalOpen = () => setWarningModalOpen(true);

    useEffect(() => {
        if (isDelete) {
            handleWarningModalOpen();
        }
    }, [isDelete]);

    const navigateToBookings = () => {
        navigate(`/eventManagement/events/${eventId}/schedules/${scheduleId}/bookings`);
    };

    if (isLoading || !schedule) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <>
            <Container>
                <Box sx={{ marginTop: '1.2rem!important', display: 'flex', justifyContent: 'space-between' }}>
                    <Box className="bb-title-bar">
                        <h2 className="bb-m0 bb-p0">Schedules:</h2>{eventId && <EventDetails eventId={eventId} />}
                    </Box>
                    <Box>
                        <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon"
                            to={`/eventManagement/events/${eventId}/schedules`}>
                            <Button variant="outlined" size="small" sx={{ padding: '6px 12px', fontSize: '0.95rem', minWidth: 'auto' }}
                                startIcon={<KeyboardArrowLeftIcon/>} disabled={isLoading}>
                            Back to Schedules
                            </Button>
                        </Link>
                    </Box>
                </Box>
                <Breadcrumbs className="bb-breadcrumb bb-mb-3 bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
                    <Link to="/eventManagement">Event Management</Link>
                    <Link to="/eventManagement/events">Events</Link>
                    <Link to={`/eventManagement/events/${eventId}/schedules`}>Schedules</Link>
                </Breadcrumbs>
                <Alert className="bb-title-info bb-mb-2 bb-align-items-center bb-flex" severity="info" sx={{ marginTop: '20px!important' }}
                >
                                Welcome to the <b>Schedules Page.</b> Here, you can configure your
                                    Schedule Information and set what dates & venues your event will take place.
                                Once you have created your event, please navigate to Schedules to add the dates your events will take place.
                </Alert>
                <Box className="bb-flex bb-ui-box bb-flex-column bb-tac">
                    <Box className="bb-page-edit-cont">
                        {submitting && <Box className="bb-tac"><CircularProgress /></Box>}
                        <Box className="bb-flex-column bb-justify-content-center bb-tac" sx={{ minHeight: "60vh" }}>
                            <Box className="bb-flex page-edit-buttons bb-mb-4"
                                sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                <Box>
                                    <Typography sx={{ fontSize: 18, fontWeight: "bold", mb: 0 }} alignContent="left"
                                        className="bb-mb-0 bb-pb-0">
                                    Schedule Editor
                                    </Typography>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                    {scheduleId &&
                                <>
                                    <Grid item xs={1}>
                                        <Button
                                            onClick={navigateToBookings}
                                            variant="outlined"
                                            startIcon={<PersonOutlineOutlinedIcon />}
                                            disabled={submitting}>
                                            Bookings
                                        </Button>
                                    </Grid>
                                    {!schedule.deleted &&
                                        <Grid item xs={1}>
                                            <Button
                                                onClick={navigateToDelete}
                                                variant="outlined"
                                                startIcon={<DeleteOutlineIcon />}
                                                color="error"
                                                disabled={submitting}>
                                                Delete
                                            </Button>
                                        </Grid>
                                    }
                                    <Grid item xs={1}>
                                        <UndoButton onClick={handleUndo} disable={submitting}></UndoButton>
                                    </Grid>
                                </>
                                    }
                                    <Button variant="contained" startIcon={<SaveOutlinedIcon />} disabled={submitting} type="submit" form="schedule_submit">
                                    Save
                                    </Button>
                                </Box>
                            </Box>
                            <Box sx={{ width: "100%" }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="bb-sp-cont">
                                    <Formik<Schedule>
                                        initialValues={schedule}
                                        onSubmit={callSave}
                                        enableReinitialize={true}
                                        validationSchema={ScheduleSchema}
                                    >
                                        {({ values, setFieldValue, validateForm }) => (
                                            <Form
                                                id={"schedule_submit"}
                                                className="bb-pathways-initial-form"
                                            >
                                                <Select
                                                    name="venueId"
                                                    label="Venue"
                                                    variant="outlined"
                                                    required
                                                >
                                                    {venues?.map((venue) => (
                                                        <MenuItem key={venue.rowKey} value={venue.rowKey} sx={{ marginTop: '30px' }}>
                                                            <ListItemText primary={venue.venueName} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <Box>
                                                    <TextField
                                                        name="scheduleName"
                                                        label="Schedule Name"
                                                        variant="outlined"
                                                        sx={{ marginTop: '30px', marginRight: '8px' }}
                                                    />
                                                </Box>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <TextField
                                                        name="capacity"
                                                        label="Capacity"
                                                        type="number"
                                                        variant="outlined"
                                                        sx={{ marginTop: '30px', marginRight: '8px' }}
                                                        InputProps={{ inputProps: { min: 0 } }}
                                                        required
                                                    />
                                                    <TextField
                                                        name="price"
                                                        label="Price"
                                                        type="number"
                                                        variant="outlined"
                                                        sx={{ marginTop: '30px', marginLeft: '8px' }}
                                                        InputProps={{ inputProps: { min: 0 } }}
                                                        required
                                                    />
                                                </Box>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Tooltip
                                                            title="Please select a start date & time for your event."
                                                            arrow
                                                            placement="top"
                                                            PopperProps={{
                                                                sx: {
                                                                    '& .MuiTooltip-tooltip': {
                                                                        backgroundColor: 'black',
                                                                        color: 'white',
                                                                        fontSize: '.8rem',
                                                                        padding: '0.5rem',
                                                                    },
                                                                    '& .MuiTooltip-arrow': {
                                                                        color: 'black',
                                                                    },
                                                                },
                                                            }}>
                                                            <Box sx={{ width: "50%", marginTop: '30px', marginRight: '8px' }}>
                                                                <DateTimePicker
                                                                    name="startDate"
                                                                    label="Start Date"
                                                                    value={selectedStartDate}
                                                                    onChange={(newValue) => handleSelectedStartDateChange(newValue)}
                                                                    slotProps={{ textField: { fullWidth: true, required: true } }}
                                                                    format="DD/MM/YYYY HH:mm"
                                                                    closeOnSelect={false}
                                                                />
                                                            </Box>
                                                        </Tooltip>
                                                        <Tooltip
                                                            title="Please select a end date & time for your event. If this is a recurring event,
                                                            please select recurring event below."
                                                            arrow
                                                            placement="top"
                                                            PopperProps={{
                                                                sx: {
                                                                    '& .MuiTooltip-tooltip': {
                                                                        backgroundColor: 'black',
                                                                        color: 'white',
                                                                        fontSize: '.8rem',
                                                                        padding: '0.5rem',
                                                                    },
                                                                    '& .MuiTooltip-arrow': {
                                                                        color: 'black',
                                                                    },
                                                                },
                                                            }}>
                                                            <Box sx={{ width: "50%", marginTop: '30px', marginLeft: '8px' }}>
                                                                <DateTimePicker
                                                                    name="endDate"
                                                                    label="End Date"
                                                                    value={selectedEndDate}
                                                                    onChange={(newValue) => handleSelectedEndDateChange(newValue)}
                                                                    slotProps={{ textField: { fullWidth: true, required: true } }}
                                                                    format="DD/MM/YYYY HH:mm"
                                                                    closeOnSelect={false}
                                                                />
                                                            </Box>
                                                        </Tooltip>
                                                    </Box>
                                                    { !scheduleId &&
                                                        <>
                                                            <Field name="isRecurring">
                                                                {({ field }: FieldProps) => (
                                                                    <FormControlLabel sx={{ marginTop: '30px', marginBottom: '30px' }}
                                                                        control={
                                                                            <Checkbox
                                                                                {...field}
                                                                                checked={field.value}
                                                                                onChange={() => {
                                                                                    setFieldValue("isRecurring", !field.value);
                                                                                    validateForm();
                                                                                }}
                                                                            />
                                                                        }
                                                                        label={
                                                                            <Typography variant="h3" component="label" >
                                                                                Recurring event
                                                                            </Typography>
                                                                        }
                                                                    />
                                                                )}
                                                            </Field>
                                                            {values.isRecurring && (
                                                                <>
                                                                    <TextField
                                                                        name="repeatEvery"
                                                                        label="Repeat Every (weeks)"
                                                                        type="number"
                                                                        variant="outlined"
                                                                        sx={{ marginBottom: '30px' }}
                                                                        InputProps={{ inputProps: { min: 1 } }}
                                                                        required
                                                                    />
                                                                    <Field name="daysOfWeek">
                                                                        {({ field }: FieldProps) => (
                                                                            <Select
                                                                                {...field}
                                                                                multiple
                                                                                value={field.value || []}
                                                                                onChange={(event) => {
                                                                                    setFieldValue("daysOfWeek", event.target.value);
                                                                                    validateForm();
                                                                                }}
                                                                                label="Repeat Days"
                                                                                variant="outlined"
                                                                                required
                                                                                MenuProps={{
                                                                                    PaperProps: {
                                                                                        sx: {
                                                                                            '& .MuiMenuItem-root.Mui-selected': {
                                                                                                backgroundColor: '#11a681',
                                                                                                color: 'white',
                                                                                            },
                                                                                            '& .MuiMenuItem-root.Mui-selected:hover': {
                                                                                                backgroundColor: '#0e8a6b',
                                                                                            },
                                                                                        },
                                                                                    },
                                                                                }}
                                                                            >
                                                                                <MenuItem value="Monday">Monday</MenuItem>
                                                                                <MenuItem value="Tuesday">Tuesday</MenuItem>
                                                                                <MenuItem value="Wednesday">Wednesday</MenuItem>
                                                                                <MenuItem value="Thursday">Thursday</MenuItem>
                                                                                <MenuItem value="Friday">Friday</MenuItem>
                                                                                <MenuItem value="Saturday">Saturday</MenuItem>
                                                                                <MenuItem value="Sunday">Sunday</MenuItem>
                                                                            </Select>
                                                                        )}
                                                                    </Field>
                                                                    <DateTimePicker
                                                                        name="repeatUntil"
                                                                        label="Repeat Until"
                                                                        value={selectedRepeatUntilDate}
                                                                        onChange={(newValue) => {
                                                                            handleSelectedRepeatUntilDateChange(newValue);
                                                                            validateForm();
                                                                        }}
                                                                        slotProps={{ textField: { fullWidth: true, required: true } }}
                                                                        format="DD/MM/YYYY"
                                                                        sx={{ marginTop: '30px', marginBottom: '30px' }}
                                                                    />
                                                                </>
                                                            )}
                                                        </>
                                                    }
                                                </LocalizationProvider>
                                                {scheduleId &&
                                                <Box sx={{ textAlign: "left", marginTop: "30px" }}>
                                                    <label>Deleted: {schedule.deleted ? "Yes" : "No"}</label>
                                                </Box>
                                                }
                                            </Form>)}
                                    </Formik>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
            <WarningModal
                open={warningModalOpen}
                description={"Are you sure you want to perform this action?"}
                onConfirm={handleConfirmDelete}
                onCancel={handleWarningModalClose}
            />
        </>);
};
