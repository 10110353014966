const getThemeContent = <schema>(get: <T>(url: string) => Promise<T>) => async (appId: string) => {
    const result = await get<schema>(`/api/frontendTheme/${appId}`);
    return result;
};

const upsertThemeContent = <schema>(post: <T>(url: string, body: any) => Promise<T>) => async (appId: string, body: any) => {
    const result = await post<schema>(`/api/frontendTheme/${appId}`, body);
    return result;
};

const convertDocumentToCss = <schema>(post: <T>(url: string, body: any) => Promise<T>) => async (appId: string, body: any) => {
    const result = await post<schema>(`/api/frontendTheme/${appId}/css`, body);
    return result;
};

export { getThemeContent, upsertThemeContent, convertDocumentToCss };
