import * as yup from "yup";

export type QuestionAnswer = {
    rowKey?: string;
    questionId?: string;
    answer?: string;
    questionText?: string;
    userId?: string;
    userEmail?: string;
    timestamp?: Date;
    deleted?: boolean;
};

export const QuestionAnswerSchema: yup.SchemaOf<QuestionAnswer> = yup.object({
    rowKey: yup.string().notRequired(),
    questionId: yup.string().notRequired(),
    answer: yup.string().required(),
    questionText: yup.string().required(),
    userId: yup.string().required(),
    userEmail: yup.string().required(),
    timestamp: yup.date().notRequired(),
    deleted: yup.boolean().notRequired()
});

