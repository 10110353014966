import { useCallback, useContext, useState } from "react";
import { Box, Container, Alert, Button, Typography, Grid } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { ChatSignInDialog } from "./../../livechat";
import { MemberAppContext } from "../../../MemberAppContext";
import { useNavigate, Link } from "react-router-dom";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import BarChartIcon from '@mui/icons-material/BarChart';
import { LiveChatDashboard } from "./LiveChatDashboard";
export const LiveChatHome = () => {

    const [liveChatDialogOpen, setLiveChatDialogOpen] = useState(false);

    const appContext = useContext(MemberAppContext);

    const startSignIn = useCallback(() => {
        setLiveChatDialogOpen(true);
    }, []);

    const cancelDialog = useCallback(() => {
        setLiveChatDialogOpen(false);
    }, []);

    const isMobile = useMediaQuery({ query: "(max-width: 760px)" });

    const navigate = useNavigate();

    const onSignIn = useCallback(() => {
        setLiveChatDialogOpen(false);
        appContext.setSidebarOpen(!isMobile);
        navigate("/livechat/chat");
    }, [navigate, appContext, isMobile]);

    const onFailedSignin = useCallback(() => {
        setLiveChatDialogOpen(false);
    }, []);

    return (<>
        <Container>
            <Box className="bb-title-bar"><h2>Live Agent</h2></Box>
            <Alert className="bb-title-info" severity="info"
            >
                This dashboard provides a range of tools for managing your Live Agent and viewing statisitcs.
            </Alert>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box sx={{ backgroundColor: '#25454d', marginBottom: '1rem', borderRadius: '6px', display: 'flex',
                        flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '.5rem' }}>
                        <Box sx={{ color: '#ffffff', marginTop: '1rem', fontSize: '4rem' }}>
                            <SupportAgentIcon sx={{ fontSize: 'inherit' }} />
                        </Box>
                        <Typography sx={{ color: '#ffffff', textAlign: 'center', marginBottom: '.3rem' }}>
                            <h2>Sign into Live Agent</h2>
                        </Typography>
                        <Button
                            sx={{ margin: '.5rem', width: '95%', padding: '.9rem' }}
                            onClick={startSignIn}
                            variant="contained"
                            color="primary"
                        >
                            Live Agent Login
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{ backgroundColor: '#25454d', marginBottom: '1rem', borderRadius: '6px', display: 'flex',
                        flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '.5rem' }}>
                        <Box sx={{ color: '#ffffff', marginTop: '1rem', fontSize: '4rem' }}>
                            <BarChartIcon sx={{ fontSize: 'inherit' }} />
                        </Box>
                        <Typography sx={{ color: '#ffffff', textAlign: 'center', marginBottom: '.3rem' }}>
                            <h2>Live Agent Analytics</h2>
                        </Typography>
                        <Button
                            sx={{ margin: '.5rem', width: '95%', padding: '.9rem' }}
                            component={Link} to="/livechat/analytics"
                            variant="contained"
                            color="primary"
                        >
                            View Analytics
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Container>
        <LiveChatDashboard />
        <ChatSignInDialog
            dialogOpen={liveChatDialogOpen}
            onCancel={cancelDialog}
            onSuccessfulSignin={onSignIn}
            onFailedSignin={onFailedSignin}
        />
    </>);
};
