import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

import { LiveAgentQueueItem } from "./liveAgentQueueItem";

interface QueueItemDeletingDialogProps {
    open: boolean;
    item: LiveAgentQueueItem | null;
    onCancel: () => Promise<void>;
    onConfirm: () => Promise<void>;
}

export const QueueItemDeletingDialog = ({ open, item, onCancel, onConfirm }: QueueItemDeletingDialogProps) => {
    return (<Dialog open={open}>
        <DialogTitle className="bbot-dialog-title">
            <Typography>Deleting</Typography>
        </DialogTitle>
        <DialogContent className="bbot-dialog-content">
            <Typography>You're about to close this chat with {item?.userName}. The end users details will be forwarded.</Typography>
            <Typography>Do you wish to continue?</Typography>
        </DialogContent>
        <DialogActions className="bbot-dialog-actions">
            <Button onClick={onCancel}>Cancel</Button>
            <Button onClick={onConfirm}>Continue</Button>
        </DialogActions>
    </Dialog>);
};
