import { Typography } from "@mui/material"; import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartOptions,
    ChartData,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "./Graph.css";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

interface Params {
    x?: number[];
    y?: number[];
    data?: ChartData<"line", number[] | undefined, number> | undefined;
    options?: ChartOptions<"line"> | undefined;
    xRange?: number[];
    yRange?: number[];
    title?: string;
}

export const Graph = ({ x, y, title, xRange, yRange, data: d, options: o }: Params) => {
    if (y && !d) {
        if (!x) {
            x = Array.from(Array(y.length).keys());
        } else if (x.length !== y.length) {
            return (<Typography>
                Graph input data length mismatch! (x:{x.length}, y:{y.length})
            </Typography>);
        }
    }

    const data: ChartData<"line", number[] | undefined, number> = d || {
        labels: x,
        datasets: [{ data: y }],
    };

    const options: ChartOptions<"line"> = o || {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: title ? true : false,
                text: title,
            },
            legend: { display: false, },
            tooltip: { enabled: false, },
        },
        scales: {
            y: {
                ticks: { display: false, },
                grid: { display: false, },
                min: yRange && yRange[0],
                max: yRange && yRange[1],
            },
            x: {
                ticks: { display: false, },
                grid: { display: false, },
                min: xRange && xRange[0],
                max: xRange && xRange[1],
            }
        },
        elements: {
            point: {
                radius: 0
            }
        }
    };

    return <Line className="full-width-canvas" data={data} options={options} />;
};
