import {
    Button,
    Box,
    Grid,
    styled
} from "@mui/material";

import { Form } from '@rjsf/mui';

import CloseIcon from "@mui/icons-material/Close";

import React from "react";

import { JSONSchema7 }  from "json-schema";

import validatorAjv8 from "@rjsf/validator-ajv8";

import { UiSchema } from "@rjsf/utils";

import { Profile } from "../../../schemas/profile/schema";

import schemaOptionsModel from "../../../schemas/profile/profileOptionsSchema.json";

import { AccordionFieldTemplate } from "../../applications/customWidget/FieldTemplate/AccordionFieldTemplate";

export interface AppProfileOptionFormProps {
    currentProfile: Profile;
    onUpdate: (e: any) => void;
    onError: (e: any) => void;
    onCancel: (e: any) => void;
}

export const AppProfileOptionForm: React.FC<AppProfileOptionFormProps> = ({ currentProfile, onUpdate, onError, onCancel }) => {
    const uiSchema: UiSchema = {
        'ui:submitButtonOptions': {
            norender: true
        },
        "options": {
            "ui:options": {
                "orderable": false
            },
            "items": {
                "subOptions": {
                    "ui:options": {
                        "orderable": false
                    }
                },
                "ui:ObjectFieldTemplate": AccordionFieldTemplate,
            }
        },
        "ui:title": "",
    };

    const ModalBox = styled(Box)(() => ({
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "50%",
        padding: 15,
        p: 4,
        overflow: "auto",
        maxHeight: "60%",
    }));

    return (
        <ModalBox className="bb-edit-page-modal">
            <Box className="bb-flex bb-pe-modal-header">
                {/*title is disabled in schema so can style it here */}
                <h2>Profile: {currentProfile.type}</h2>
                <Button className="bb-ml-auto" onClick={onCancel} variant="text">
                    <CloseIcon></CloseIcon>
                </Button>
            </Box>
            <>
                <Form
                    schema={schemaOptionsModel as JSONSchema7}
                    formData={currentProfile}
                    validator={validatorAjv8}
                    onError={onError}
                    onSubmit={onUpdate}
                    className={"bb-pe-modal-form"}
                    uiSchema={uiSchema}
                >
                    <Box className="bb-pe-modal-footer">
                        <Grid container spacing={1} className="bb-flex bb-fixed">
                            <Grid item>
                                <Button onClick={onCancel} variant="text">
                                Cancel
                                </Button>
                            </Grid>
                            <Grid item className="bb-ml-auto">
                                <Button variant="contained" type="submit">
                                Update
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Form>
            </>
        </ModalBox>
    );
};
