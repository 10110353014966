import React, { useCallback, useEffect } from 'react';

import { TextField, Box, Button, styled, Modal, useTheme, CircularProgress, Typography, Tooltip } from '@mui/material';

import CloseIcon from "@mui/icons-material/Close";

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import { WidgetProps } from '@rjsf/utils';

import { FileFolderView, FileFolderInfo } from "./FileFolderView";

import { SearchField } from "../Search/SearchField";

type AltMediaLibraryWidgetProps = {
    onChange: (value: string) => void;
    id: string;
    value: string;
    propLabel: string;
    schema: {
        height: number;
        width: number;
    };
};

const AltMediaLibraryWidget = (props: WidgetProps | AltMediaLibraryWidgetProps) => {
    const theme = useTheme();

    const [openEditModal, setOpenEditModal] = React.useState(false);
    const [fileFolderInfo, setFileFolderInfo] = React.useState<FileFolderInfo>();
    const [mediaSearchString, setMediaSearchString] = React.useState<string>();
    const [requiredHeight, setRequiredHeight] = React.useState<number>();
    const [requiredWidth, setRequiredWidth] = React.useState<number>();

    useEffect(() => {
        document.title = 'Beebot AI - Media Library';
    },[]);

    const ModalBox = styled(Box)(() => ({
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "50%",
        padding: 15,
        p: 4,
        overflow: "auto",
        maxHeight: "60%",
    }));

    const callSearch = useCallback((searchString?: string) => {
        setMediaSearchString(searchString);
    }, [setMediaSearchString]);

    const handleChange = useCallback((event: any) => {
        const newValue = event.target.value;
        props.onChange(newValue);
    }, [props]);

    const handleModalClose = React.useCallback(() => {
        return;
    }, []);

    const handleModalCancel = React.useCallback((): void => {
        setOpenEditModal(false);
    }, []);

    const handleFileSelect = React.useCallback((fileUrl: string): void => {
        props.onChange(encodeURI(fileUrl));
        setOpenEditModal(false);
    }, [props]);

    const handleModalOpen = React.useCallback(() => {
        setOpenEditModal(true);
    }, []);

    const handleFolderSelection = React.useCallback((fullPath?: string): void => {
        setFileFolderInfo(getInfoFromPath(fullPath));
    }, []);

    useEffect(() => {
        setMediaSearchString("");
        setFileFolderInfo(getInfoFromPath());
    }, []);

    useEffect(() => {
        if (!isNaN(props.schema?.height) &&
            !isNaN(props.schema?.width)) {

            setRequiredHeight(Number(props.schema?.height));
            setRequiredWidth(Number(props.schema?.width));
        }
    }, [props.schema?.height, props.schema?.width]);

    const getInfoFromPath = (fullPath?: string): FileFolderInfo => {
        if (fullPath === undefined) {
            return { fullPath, currentPath: "", parentPath: "", name: "", isPublic: true };
        }

        const segments = fullPath.split("/");
        if (segments.length === 0) {
            return { fullPath, currentPath: "", parentPath: "", name: "", isPublic: true };
        }

        return {
            fullPath,
            currentPath: fullPath,
            parentPath: segments.map(segment => decodeURI(segment)).slice(0, segments.length - 1).join("/"),
            name: decodeURI(segments[segments.length - 1]),
            isPublic: true
        };
    };

    const getTooltipText = () => {
        return `Required Image Size: Height - ${requiredHeight}px, Width - ${requiredWidth}px`;
    };

    if (!fileFolderInfo) {
        return (<div className="bb-tac"><CircularProgress /></div>);
    }

    return (
        <>
            <Modal
                open={openEditModal}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ "& .MuiBox-root": { backgroundColor: theme.palette.background.default } }}
            >
                <ModalBox className="bb-edit-page-modal">
                    <>
                        <Box className="bb-flex bb-p-3 bb-align-items-center bb-edit-page-modal-header">
                            <Typography variant='h2' className="bb-mb-0" sx={{ marginLeft: ".5rem" }}>
                                Media Library: {fileFolderInfo.name || "Public"}
                            </Typography>
                            {requiredHeight && requiredWidth &&
                                <>
                                    <Tooltip
                                        title={getTooltipText()}
                                        arrow
                                        placement="top"
                                        PopperProps={{
                                            sx: {
                                                '& .MuiTooltip-tooltip': {
                                                    backgroundColor: 'black',
                                                    color: 'white',
                                                    fontSize: '.8rem',
                                                    padding: '0.5rem',
                                                },
                                                '& .MuiTooltip-arrow': {
                                                    color: 'black',
                                                },
                                            },
                                        }}>
                                        <Typography variant='h5' className="bb-mb-0" sx={{ marginLeft: "1rem" }}>
                                         Required Image Size?!....
                                        </Typography>
                                    </Tooltip>
                                </>
                            }
                            <Box className="bb-med-search">
                                <SearchField
                                    searchString={mediaSearchString}
                                    callSearch={callSearch}
                                />
                            </Box>
                            <Button className="bb-ml-auto" onClick={handleModalCancel} variant="text">
                                <CloseIcon></CloseIcon>
                            </Button>
                        </Box>
                        <Box className="bb-pe-modal-form-media">
                            <FileFolderView
                                fileFolderInfo={fileFolderInfo}
                                mediaSearchString={mediaSearchString}
                                handleFolderSelection={handleFolderSelection}
                                handleFileSelect={handleFileSelect}
                                handleModalCancel={handleModalCancel}
                                requiredHeight={requiredHeight}
                                requiredWidth={requiredWidth}
                            />
                        </Box>
                    </>
                </ModalBox>
            </Modal>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '100%'
                }}>
                <Tooltip
                    title="If you already have a URL for your Image, then please paste it here.
                    If you need to upload your image to the Media Library please click the plus icon on the right hand side of this field."
                    arrow
                    placement="top"
                    PopperProps={{
                        sx: {
                            '& .MuiTooltip-tooltip': {
                                backgroundColor: 'black',
                                color: 'white',
                                fontSize: '.8rem',
                                padding: '0.5rem',
                            },
                            '& .MuiTooltip-arrow': {
                                color: 'black',
                            },
                        },
                    }}>
                    <TextField
                        id={props.id}
                        label={props.propLabel ?? "Image"}
                        variant="outlined"
                        value={props.value}
                        onChange={handleChange}
                        sx={{ width: '90%' }} // Set a fixed width
                    />
                </Tooltip>
                <Tooltip
                    title="Click here to add your Image"
                    arrow
                    placement="top"
                    PopperProps={{
                        sx: {
                            '& .MuiTooltip-tooltip': {
                                backgroundColor: 'black',
                                color: 'white',
                                fontSize: '.8rem',
                                padding: '0.5rem',
                            },
                            '& .MuiTooltip-arrow': {
                                color: 'black',
                            },
                        },
                    }}>
                    <Box
                        onClick={handleModalOpen}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            cursor: 'pointer',
                            border: '1px dashed #ccc',
                            borderRadius: '4px',
                            width: '100px',
                            marginLeft: '16px',
                        }}
                    >
                        {props.value ? (
                            <img src={props.value} className="bb-media-thumb-picker bb-width-100 bb-border-radius" alt="Your Image" />
                        ) : (
                            <AddCircleOutlineOutlinedIcon sx={{ fontSize: '40px', color: '#11a681' }} />
                        )}
                    </Box>
                </Tooltip>
            </Box>
        </>
    );
};

export { AltMediaLibraryWidget };
