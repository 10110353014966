import {
    Box,
    Grid,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TablePagination,
    Button
} from "@mui/material";

import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

import RefreshIcon from "@mui/icons-material/Refresh";

import { getDeployments, postDeployment } from "../../../services/api/FrontendDeploymentService";
import useDataClient from "../../../axios/dataClient";
import {
    DeploymentModel, DeploymentProgress, DeploymentType,
} from "../../../schemas/deployment";

import { DeploymentTableHeaders } from "./DeploymentTableHeaders";
import { DeploymentRow } from "./DeploymentRow";

export interface DeploymentTableProps {
    deploymentType: DeploymentType;
}

export const DeploymentTable: React.FC<DeploymentTableProps> = ({ deploymentType }) => {
    const [submittingDeployment, setSubmittingDeployment] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [deployments, setDeployments] = useState<DeploymentModel[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page
    };

    const { post, get } = useDataClient();

    const getDeploymentsCallback = useCallback(async () => {
        setRefresh(true);
        const data = await getDeployments<DeploymentModel[]>(get)(deploymentType);
        setDeployments(data);
        setRefresh(false);
    },[deploymentType, get]);

    const onSubmitDeployment = useCallback(async (rowKey: string) => {
        setSubmittingDeployment(true);
        await postDeployment(post)(deploymentType, rowKey, {});
        await getDeploymentsCallback();
        toast.success("Deployment Started");
        setSubmittingDeployment(false);
    }, [post, deploymentType, getDeploymentsCallback]);

    useEffect(() => {
        const fetchData = async () => {
            await getDeploymentsCallback();
        };

        fetchData();
    },
    [get, getDeploymentsCallback]);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sx={{ textAlign: 'right' }}>
                    <Button
                        sx={{ background: "#fff" }}
                        aria-label="refresh"
                        variant="outlined"
                        onClick={ () => getDeploymentsCallback()}
                        className="bb-mb-1"
                        startIcon={<RefreshIcon/>}
                    >
                        Refresh
                    </Button>
                </Grid>
            </Grid>
            <Box sx={{ flexGrow: 1 }} className="bb-m0 bb-p0">
                <TableContainer component={Paper} sx={{ boxShadow: "none" }} className="bb-m0 bb-p0 bb-global-table">
                    <Table aria-label="collapsible table" sx={{ boxShadow: "none" }}>
                        { refresh ? <div>Loading...</div> :
                            <>
                                <DeploymentTableHeaders></DeploymentTableHeaders>
                                <TableBody>{
                                    deployments &&
                                    deployments
                                        .sort((a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime())
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((deployment: DeploymentModel) => {
                                            const deploymentChildrenAreProcessing = deployment.deploymentItemModels.some((item) =>
                                                item.deploymentProgress === DeploymentProgress.InProgress ||
                                                    item.deploymentProgress === DeploymentProgress.Waiting) ||
                                                submittingDeployment;

                                            return (
                                                <DeploymentRow
                                                    onDelete={getDeploymentsCallback}
                                                    deploymentType={deploymentType}
                                                    key={deployment.rowKey}
                                                    rowItem={deployment}
                                                    canDeploy={!deploymentChildrenAreProcessing}
                                                    submitDeployment={onSubmitDeployment} />
                                            );
                                        })
                                }
                                </TableBody>
                            </>
                        }
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={deployments.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Box>
        </>
    );
};
