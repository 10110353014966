import { Link } from "react-router-dom";

import {
    Container,
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    TablePagination,
    TableHead,
    CircularProgress,
    Select,
    MenuItem,
    Typography,
    Breadcrumbs,
    Alert,
    Tooltip
} from "@mui/material";

import { SelectChangeEvent } from '@mui/material/Select';

import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';

import { useState, useEffect, useCallback } from "react";

import { useParams } from "react-router-dom";

import useDataClient from "../../../axios/dataClient";

import { stringSort } from "../../../services/helper/sort";

import { Question } from "../../../schemas/eventManagement/questionSchema";

import { getQuestions } from "../../../services/api/QuestionManagementService";

import { ResultListModel } from "../../../schemas/eventManagement/resultSchema";

import { newQuestion } from "../../newModuleNames";

import { errors } from "../../ErrorDisplay";

import { SearchField } from "../../applications/customWidget/Search/SearchField";

import { EventDetails } from '../schedule/EventDetails';

export const QuestionList: React.FC = () => {
    const { get } = useDataClient();
    const { eventId } = useParams();

    const [questions, setQuestions] = useState<Question[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [isLoading, setLoading] = useState(false);
    const [searchString, setSearchString] = useState<string>();
    const [filteredQuestions, setFilteredQuestions] = useState<Question[]>([]);

    const filterShowAll = "ShowAll";
    const filterShowNonDeletedOnly = "filterShowNonDeletedOnly";
    const [listFilter, setListFilter] = useState(filterShowAll);

    const callSearch = useCallback((search?: string) => {
        setSearchString(search);
    }, [setSearchString]);

    const handleFilterChange = (event: SelectChangeEvent) => {
        setListFilter(event.target.value as string);
    };

    useEffect(() => {
        if (!questions) {
            return;
        }

        setCurrentPage(0);
        const filtered = questions.filter((question) =>
            (listFilter === filterShowAll || question?.deleted !== true) &&
            (!searchString ||
                question.text?.toLowerCase().includes(searchString.toLowerCase())
            )
        );
        setFilteredQuestions(filtered);
    }, [listFilter, questions, searchString]);

    const fetchData = useCallback(async () => {
        if (eventId) {
            getQuestions<ResultListModel<Question>>(get)(eventId).then((questionsData) => {
                if (questionsData?.isSuccess && questionsData?.data) {
                    setQuestions(questionsData.data);
                } else if (questionsData?.messages) {
                    errors(questionsData.messages);
                }

                return;
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [get, eventId]);

    const refreshList = useCallback(() => {
        setLoading(true);
        fetchData();
    }, [fetchData]);

    const handleChangePage = (event: any, newPage: any) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0); // Reset to first page
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            await fetchData();
        })();
    }, [fetchData]);

    if (!eventId) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <Container>
            <Box sx={{ marginTop: '1.2rem!important', display: 'flex', justifyContent: 'space-between' }}>
                <Box className="bb-title-bar">
                    <h2 className="bb-m0 bb-p0">Event Questions:</h2><EventDetails eventId={eventId} />
                </Box>
                <Box>
                    <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon"
                        to={`/eventManagement/events/${eventId}`}>
                        <Button variant="outlined" size="small" sx={{ padding: '6px 12px', fontSize: '0.95rem', minWidth: 'auto' }}
                            startIcon={<KeyboardArrowLeftIcon/>} disabled={isLoading}>
                            Back to Event
                        </Button>
                    </Link>
                </Box>
            </Box>
            <Breadcrumbs className="bb-breadcrumb bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
                <Link to="/eventManagement">Event Management</Link>
                <Link to="/eventManagement/events">Events</Link>
                <Link to={`/eventManagement/events/${eventId}`}>Event</Link>
                <Typography color="text.primary" className="bb-m0 bb-p0">Questions</Typography>
            </Breadcrumbs>
            <Alert className="bb-title-info bb-mb-2 bb-align-items-center bb-flex" severity="info" sx={{ marginTop: '20px!important' }}
            >
            Welcome to the <b>Event Questions Page.</b> Here, you can view and edit all of your event Questions.
            We set up a few default questions for you, but you can add your own custom questions as well.
            Click the <b>New Custom Question</b> button to get started.
            </Alert>
            <Box className="bb-flex bb-ui-box bb-flex-column bb-tac">
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box>
                        <Typography variant="h3" className="bb-m0 bb-p0">Question Manager</Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: "1rem" }}>
                        <Box>
                            <Button
                                variant="contained"
                                className="bb-ml-auto bb-primary-button"
                                onClick={refreshList}
                                startIcon={<RefreshOutlinedIcon />}
                                disabled={isLoading}
                                sx={{ marginRight: '10px', color: '#000000!important', backgroundColor: '#ffffff!important', border: '1px solid #11a681' }}
                            >
                        Refresh
                            </Button>
                        </Box>
                        <Box>
                            <Link className="bb-text-decoration-none bb-app-icon" to={`/eventManagement/events/${eventId}/questions/${newQuestion}`}>
                                <Button variant="contained" startIcon={<QuestionMarkOutlinedIcon />} disabled={isLoading}>
                            New Custom Question
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                </Box>
                {isLoading
                    ? (<Box className="bb-tac"><CircularProgress /></Box>)
                    : (
                        <TableContainer component={Paper} sx={{ boxShadow: "none", marginTop: "1rem" }} >
                            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginBottom: "1rem" }}>
                                <Box className="bb-med-search" sx={{ minWidth: 200 }}>
                                    <Select
                                        id="filter-select"
                                        value={listFilter}
                                        onChange={handleFilterChange}
                                        variant="outlined"
                                        fullWidth
                                        sx={{ height: 40 }}
                                    >
                                        <MenuItem value={filterShowAll}>Show All</MenuItem>
                                        <MenuItem value={filterShowNonDeletedOnly}>Hide Deleted Questions</MenuItem>
                                    </Select>
                                </Box>
                                <Tooltip
                                    title="You can search by Question Text."
                                    arrow
                                    placement="top"
                                    PopperProps={{
                                        sx: {
                                            '& .MuiTooltip-tooltip': {
                                                backgroundColor: 'black',
                                                color: 'white',
                                                fontSize: '.8rem',
                                                padding: '0.5rem',
                                            },
                                            '& .MuiTooltip-arrow': {
                                                color: 'black',
                                            },
                                        },
                                    }}>
                                    <Box className="bb-med-search">
                                        <SearchField
                                            searchString={searchString}
                                            callSearch={callSearch}
                                            timeout={500}
                                        />
                                    </Box>
                                </Tooltip>
                            </Box>
                            <Table aria-label="table" sx={{ padding: "0", margin: "0" }} className="bb-page-list-table bb-global-table">
                                <TableHead sx={{ borderRadius: "5px 5px 0 0" }}>
                                    <TableRow >
                                        <TableCell>Question Text</TableCell>
                                        <TableCell>Question Type</TableCell>
                                        <TableCell>Deleted</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>{
                                    filteredQuestions
                                        .sort((a, b) => stringSort(a.text, b.text))
                                        .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                                        .map((question, index) => {
                                            return (
                                                <TableRow key={`partnerUser_${index}`} sx={{ padding: "0", margin: "0" }}>
                                                    <TableCell sx={{ padding: "0", margin: "0" }}>
                                                        <Box className="bb-ml-2">
                                                            <Link
                                                                className="bb-pages-list bb-align-items-center"
                                                                to={`/eventManagement/events/${eventId}/questions/${question.rowKey}`}
                                                            >
                                                                {question.text}
                                                            </Link>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell sx={{ padding: "0", margin: "0" }}>
                                                        <Box className="bb-ml-2">{question.questionType}</Box>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Box className="bb-ml-2">{question.deleted ? "Yes" : "No"}</Box>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                }
                                </TableBody>
                            </Table>
                            <TablePagination
                                className="bb-global-table-footer"
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={filteredQuestions.length}
                                rowsPerPage={rowsPerPage}
                                page={currentPage}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    )
                }
            </Box>
        </Container>
    );
};
