import { useCallback, useEffect, useState } from 'react';
import { WidgetProps } from '@rjsf/utils';

import { MenuItem, Select, InputLabel, OutlinedInput, Checkbox, ListItemText } from '@mui/material';

import { ITableStructureListModel } from "../../../../services/model/dataManagement/ITableStructureListModel";

import { usePartnerUserContext } from "../../../partnerManagement/partnerUserContext";

const DbMultiTableWidget = ({ onChange, id, value, label }: WidgetProps) => {
    const { dbData } = usePartnerUserContext();
    const [tableNames, setTableNames] = useState<string[]>([]);

    useEffect(() => {
        const initialTableNames = value ? (typeof value === 'string' ? value.split(',') : value) : [];
        setTableNames(initialTableNames);
    }, [value, setTableNames]);

    const handleChange = useCallback((event: any) => {
        let newValue = event.target.value;
        if (newValue.includes('none') && newValue.length > 1) {
            newValue = newValue.filter((s: any) => s !== 'none');
        }
        const filteredValue = newValue.filter((s: any) => !!s);
        onChange(filteredValue.length > 0 ? filteredValue.join(',') : 'none');
    }, [onChange]);

    const getDisplayName = (tableId: string) => {
        const selectedTable = dbData?.tables?.find((table: ITableStructureListModel) => table.id === tableId);
        return selectedTable ? selectedTable.displayName : tableId;
    };

    const getDisplayNames = (selectedValues: string[]) => {
        return selectedValues.filter(s => !!s).map(getDisplayName).join(', ');
    };

    return (
        <>
            <InputLabel id={`${id}-label`}>{label}</InputLabel>
            <Select
                id={id}
                value={tableNames}
                labelId={`${id}-label`}
                label={label}
                multiple
                onChange={handleChange}
                variant="outlined"
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => getDisplayNames(selected)}
            >
                {dbData?.tables?.map((table: ITableStructureListModel) => (
                    <MenuItem key={table.id} value={table.id}>
                        <Checkbox checked={tableNames.indexOf(table.id) > -1} />
                        <ListItemText primary={table.displayName} />
                    </MenuItem>
                ))}
            </Select>
        </>
    );
};

export { DbMultiTableWidget };
