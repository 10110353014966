import * as React from "react";
import { useState } from "react";
import { Button } from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { EventPreviewModal } from "./EventPreviewModal";
import { Event } from "../../../schemas/eventManagement/eventSchema";

interface EventListPreviewProps {
    event: Event;
}

export const EventListPreview: React.FC<EventListPreviewProps> = ({ event }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button
                variant="contained"
                startIcon={<RemoveRedEyeOutlinedIcon sx={{ color: '#11a681' }} />}
                color="primary" onClick={handleOpen}
                sx={{ padding: '4px 8px', margin: '15px 35px 15px 15px', fontSize: '0.75rem', background: 'transparent', color: '#000000', '&:hover': {
                    background: 'transparent',
                    color: '#000000',
                }, }}>
                Preview
            </Button>
            <EventPreviewModal event={event} open={open} onClose={handleClose} />
        </div>
    );
};
