import { InferType, array, object, string } from "yup";

export const BotSecretSchema = object({
    secrets: array().of(object({
        key: string().required("Name required"),
        value: string().required("Value required"),
        id: string().required()
    })).required()
});

export type BotSecretType = InferType<typeof BotSecretSchema>;
