import {
    Button,
    CircularProgress,
    Grid,
    Container,
    Box,
    Typography,
    Modal,
    useTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import { useParams, Link, useNavigate, useLocation } from "react-router-dom";

import React, { useState, useEffect, useCallback } from "react";

import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { toast } from "react-toastify";

import { Form } from '@rjsf/mui';

import validatorAjv8 from "@rjsf/validator-ajv8";

import { JSONSchema7 } from "json-schema";

import { UiSchema } from "@rjsf/utils";

import { v7 as uuidv7 } from "uuid";

import useDataClient from "../../../axios/dataClient";

import { getApp } from "../../../services/api/FrontendService";

import { getAppConfigContent, postAppConfigContent  } from "../../../services/api/FrontendAppConfigService";

import { AppViewModel } from "../../../services/model/apps/AppViewModel";

import { SchemaType } from "../../../schemas/schemaTypes";

import { Schema, Profile } from "../../../schemas/profile/schema";

import profileSchemaModel from "../../../schemas/profile/profileSchemaData.json";

import { WarningModal } from "../../shared/WarningModal";

import { newProfile as newProfileType } from "../../newModuleNames";

import { AppProfileOptionForm } from "./AppProfileOptionForm";

import { UndoButton } from "../UndoButton";

import { AppLinks } from "../AppLinksComponent";

export const AppProfileForm: React.FC = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const location = useLocation();

    const { appId, profileName } = useParams();
    const [appData, setAppData] = useState<AppViewModel | null>(null);
    const [schema, setSchema] = useState<Schema>();
    const [profile, setProfile] = useState<Profile>();
    const { get, post } = useDataClient();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [currentProfileName, setCurrentProfileName] = useState<string | undefined>(profileName);
    const [openEditOptionsModal, setOpenEditOptionsModal] = React.useState(false);
    const [showProfileReloadModal, setShowProfileReloadModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const isDelete = location?.pathname?.endsWith("/delete");

    useEffect(() => {
        document.title = 'Beebot AI - New Profile';
    },[]);

    const uiSchema: UiSchema = {
        'ui:submitButtonOptions': {
            norender: true
        },
        "ui:options": {
            classNames: "custom-class-title1"
        },
        "type": {
            "ui:readonly": !!profileName
        }
    };

    const navigateToDelete = () => {
        navigate(`/applications/${appId}/profiles/${currentProfileName}/delete`);
    };

    const handleOpenDelete = () => {
        setOpenDeleteModal(true);
    };

    const handleCloseDelete = () => {
        setOpenDeleteModal(false);
        navigate(`/applications/${appId}/profiles/${currentProfileName}`);
    };

    useEffect(() => {
        if (isDelete) {
            handleOpenDelete();
        }
    }, [isDelete]);

    useEffect(() => {
        const fetchAppData = async () => {
            if (appId) {
                const data = await getApp(get)(appId);
                setAppData(data);
            }
        };

        fetchAppData();
    }, [appId, get]);

    const fetchData = React.useCallback(async () => {
        if (appId) {
            const schemaData = await getAppConfigContent<Schema>(get)(appId, SchemaType.Profile);
            setSchema(schemaData);

            if (currentProfileName) {
                const profileData = schemaData?.profile?.find(p => p.type === currentProfileName);
                setProfile(profileData);
            }
            else {
                const newProfile: Profile = { options: [] };
                setProfile(newProfile);
            }
        }
    }, [appId, get, currentProfileName]);

    useEffect(() => {
        if (profileName && !currentProfileName) {
            setCurrentProfileName(profileName);
        }
    }, [profileName, currentProfileName]);

    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, [appId, get, fetchData]);

    const handleSubmit = useCallback(async ({ formData }: any) => {
        if (appId) {
            setSubmitting(true);

            const isNewProfile = !profileName;

            const schemaData: Schema = !schema ? { profile: [] } : { ...schema };

            const newProfile = formData as Profile;

            if (newProfile.type?.toUpperCase() === newProfileType.toUpperCase()) {
                toast.error(`Profile type cannot be ${newProfileType}`);
                setSubmitting(false);
                return;
            }

            const profileDataIndex = schemaData.profile.findIndex(p => p.type?.toUpperCase() === newProfile.type?.toUpperCase());
            if (profileDataIndex >= 0) {

                let latestProfileHash: string | undefined;

                if (!isNewProfile) {
                    const latestConfigData = await getAppConfigContent<Schema>(get)(appId, SchemaType.Profile);
                    const lastestProfileData = latestConfigData.profile.find(p => p.type?.toUpperCase() === newProfile.type?.toUpperCase());

                    if (lastestProfileData) {
                        latestProfileHash = lastestProfileData.savedHash;

                        if (latestProfileHash !== newProfile.savedHash) {
                            setShowProfileReloadModal(true);
                            setSubmitting(false);
                            return;
                        }
                    }
                }
                else {
                    toast.error("Profile with the same type already exists");
                    setSubmitting(false);
                    return;
                }

                newProfile.savedHash = uuidv7();
                schemaData.profile.splice(profileDataIndex, 1, newProfile);
            }
            else {
                newProfile.savedHash = uuidv7();
                schemaData.profile.push(newProfile);
            }

            postAppConfigContent<Schema>(post)(appId, SchemaType.Profile, {
                configDocument: schemaData,
                message: "Updated profile"
            }).then(() => {
                toast.success("Profile updated");
                if (isNewProfile) {
                    navigate(`/applications/${appId}/profiles/${newProfile.type}`);
                }

                setSchema(schemaData);
                setProfile(newProfile);

                return;
            }).finally(() => {
                setSubmitting(false);
            });
        }
    }, [post, appId, navigate, profileName, schema, get]);

    const handleError = useCallback((e: any): void => {
        console.log("Data changed: ", e.formData);
    }, []);

    const handleUndo = useCallback(async (): Promise<void> => {
        setSubmitting(true);
        fetchData().then(() => {
            toast.warning("Profile data has been reset");
            return;
        }).finally(() => {
            setSubmitting(false);
        });
    }, [fetchData]);

    const handleDelete = useCallback(async (): Promise<boolean> => {
        if (appId && currentProfileName) {
            setSubmitting(true);
            const schemaData: Schema = !schema ? { profile: [] } : { ...schema };
            const profileDataIndex = schemaData.profile.findIndex(p => p.type === currentProfileName);
            if (profileDataIndex >= 0) {
                schemaData.profile.splice(profileDataIndex, 1);
            }

            return postAppConfigContent<Schema>(post)(appId, SchemaType.Profile, {
                configDocument: schemaData,
                message: "Removed profile data from profiles"
            }).then(() => {
                toast.success("Profile has been deleted");
                navigate(`/applications/${appId}/profiles`);

                return true;
            }).finally(() => {
                setSubmitting(false);
            });
        }

        return false;
    }, [appId, currentProfileName, post, navigate, schema]);

    const handleConfirmDelete = useCallback(async (): Promise<void> => {
        const isDeleted = await handleDelete();
        setOpenDeleteModal(false);
        if (!isDeleted) {
            navigate(`/applications/${appId}/profiles/${currentProfileName}`);
        }
    }, [navigate, handleDelete, appId, currentProfileName]);

    const handleModalClose = React.useCallback(() => {
        return;
    }, []);

    const handleModalCancel = React.useCallback((): void => {
        setOpenEditOptionsModal(false);
    }, []);

    const handleModalOpen = React.useCallback(() => {
        setOpenEditOptionsModal(true);
    }, []);

    const handleModalUpdate = React.useCallback((e: any): void => {
        const newProfileOptions = e.formData as Profile;
        const newProfile = { ...profile };
        if (newProfileOptions && newProfile) {
            newProfile.options = newProfileOptions.options;
            setProfile(newProfile);
        }
        setOpenEditOptionsModal(false);
    }, [profile]);

    const handleShowProfileReloadModalClose = () => {
        setShowProfileReloadModal(false);
    };

    const handleShowProfileReloadModalProceed = () => {
        setShowProfileReloadModal(false);
        handleUndo();
    };

    if (!appData || !profile) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <>
            <Modal
                open={openEditOptionsModal}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ "& .MuiBox-root": { backgroundColor: theme.palette.background.default } }}
            >
                {/*This is the form that is rendered for rows & columns & panels*/}
                <AppProfileOptionForm
                    currentProfile={profile}
                    onUpdate={handleModalUpdate}
                    onError={handleError}
                    onCancel={handleModalCancel}
                />
            </Modal>
            <Dialog
                open={showProfileReloadModal}
            >
                <DialogTitle id="alert-dialog-title" className="bb-error-dialog-header bb-flex bb-align-items-center">
                    <WarningAmberIcon className="bb-mr-1"></WarningAmberIcon> <h2 className="bb-m0 bb-p0">{"WARNING!"}</h2>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className="bb-tac">
                        <h3>
                            Another user has recently updated Profile. Please reload the page to get the most recent profile data and try again.
                        </h3>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleShowProfileReloadModalClose}>
                        Close
                    </Button>
                    <Button onClick={handleShowProfileReloadModalProceed} className="bb-ml-auto">
                        Reload Profile Data
                    </Button>
                </DialogActions>
            </Dialog>
            <Container>
                <Box className="bb-title-bar bb-mt-3">
                    <h2 className="bb-m0 bb-p0">Profiles: {appData.displayName}</h2>
                    <AppLinks />
                </Box>
                <Breadcrumbs className="bb-breadcrumb bb-mb-3 bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
                    <Link to="/applications">Applications</Link>
                    <Link to={`/applications/${appId}`}>Modules</Link>
                    <Link to={`/applications/${appId}/profiles`}>Profiles</Link>
                    <Typography color="text.primary" className="bb-m0 bb-p0 bb-crumb-text">{profileName}</Typography>
                </Breadcrumbs>
                <Box className="bb-flex bb-ui-box bb-flex-column bb-tac" sx={{ minHeight: "60vh" }}>
                    <Box className="bb-page-edit-cont">
                        {submitting && <Box className="bb-tac"><CircularProgress /></Box>}
                        <Box className="bb-flex bb-flex-column bb-justify-content-center bb-tac" sx={{ minHeight: "60vh" }}>
                            <Box className="bb-flex page-edit-buttons bb-align-items-center">
                                <Typography sx={{ fontSize: 18, fontWeight: "bold", mb: 0 }} alignContent="left"
                                    className="bb-mb-0 bb-pb-0">
                                    Profile Editor
                                </Typography>
                                <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon" to={`/applications/${appId}/profiles`}>
                                    <Button variant="outlined" startIcon={<KeyboardArrowLeftIcon />} disabled={submitting}>
                                        Profiles
                                    </Button>
                                </Link>
                                {currentProfileName &&
                                    <>
                                        <Grid item xs={1}>
                                            <Button
                                                onClick={navigateToDelete}
                                                variant="outlined"
                                                startIcon={<DeleteOutlineIcon />}
                                                color="error"
                                                disabled={submitting}>
                                                    Delete
                                            </Button>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <UndoButton onClick={handleUndo} disable={submitting}></UndoButton>
                                        </Grid>
                                    </>
                                }
                                <Button variant="contained" startIcon={<SaveOutlinedIcon />} disabled={submitting} type="submit" form="profile_pagesubmit">
                                    Save
                                </Button>
                            </Box>
                            <Box sx={{ width: "100%" }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="bb-sp-cont">
                                    <Form
                                        id={"profile_pagesubmit"}
                                        schema={profileSchemaModel as JSONSchema7}
                                        formData={profile}
                                        validator={validatorAjv8}
                                        onSubmit={handleSubmit}
                                        onError={handleError}
                                        uiSchema={uiSchema}
                                        liveValidate={true}
                                        showErrorList={false}
                                        disabled={submitting}
                                    />
                                    <Typography
                                        sx={{ fontSize: 14, fontWeight: "bold" }}
                                        color="text.secondary"
                                        gutterBottom alignContent="left"
                                        className="bb-ml-auto bb-flex bb-flex-end bb-align-items-center bb-mt-1"
                                    >
                                        {profile.options?.length || 0} options
                                        <Button onClick={handleModalOpen} variant="outlined" disabled={submitting} size="small" sx={{ marginLeft: 2 } }>
                                            Edit Options
                                        </Button>
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <WarningModal
                    open={openDeleteModal}
                    description={"Are you sure you want to perform this action?"}
                    onConfirm={handleConfirmDelete}
                    onCancel={handleCloseDelete}
                />
            </Container>
        </>);
};
