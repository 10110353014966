import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import { Form, Formik } from "formik";

import { Operator } from "../../../services/model/operator";

import useDataClient from "../../../axios/dataClient";

import { Select } from "../../../material/Select";

import { LiveAgentQueueItem } from "./liveAgentQueueItem";

interface QueueItemForwardDialogProps {
    open: boolean;
    onCancel: () => void;
    onSuccessfullForward: () => void;
    item: LiveAgentQueueItem | null;
}

export const QueueItemForwardDialog = ({ open, item, onCancel, onSuccessfullForward }: QueueItemForwardDialogProps) => {
    const { get, post } = useDataClient();
    const [operatorsList, setOperatorsList] = useState<Operator[]>([]);
    useEffect(() => {
        const effect = async () => {
            if (open) {
                const operators = await get<Operator[]>("api/chat/operators/active");
                setOperatorsList(operators);
            }
        };

        effect();
    }, [get, open, setOperatorsList]);

    const onForward = useCallback(({ toOperator }: { toOperator: string }) => {
        post("api/queue/assignChat", { chatId: item?.chatId, botId: item?.botName, agentId: toOperator });
        onSuccessfullForward();
    }, [onSuccessfullForward, item, post]);

    return (<Dialog open={open}>
        <DialogTitle>
            Forward
        </DialogTitle>
        <Formik initialValues={{ toOperator: "" }} onSubmit={onForward}>
            <Form>
                <DialogContent>
                    <Select name="toOperator">
                        {operatorsList.map(operator => <MenuItem key={operator.operatorId} value={operator.operatorId}>{operator.operatorName}</MenuItem>)}
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button type="submit">Forward</Button>
                </DialogActions>
            </Form>
        </Formik>
    </Dialog>);
};
