import { useState, useEffect, useCallback } from "react";

const CACHE_DURATION = 60 * 60 * 1000; // 1 hour in milliseconds

interface IdTokenClaims {
    extension_CustomerId: string;
}

const getCustomerIdFromSessionStorage = (): string | null => {
    console.log("Checking session storage for customer ID...");
    for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i);
        if (key && key.includes("signin")) {
            const tokenData = sessionStorage.getItem(key);
            if (tokenData) {
                try {
                    const token = JSON.parse(tokenData);
                    if (token.idTokenClaims) {
                        const claims: IdTokenClaims = token.idTokenClaims;
                        console.log("Customer ID found:", claims.extension_CustomerId);
                        return claims.extension_CustomerId;
                    }
                } catch (error) {
                    console.error("Failed to parse token", error);
                }
            }
        }
    }
    console.log("Customer ID not found in session storage.");
    return null;
};

export const useCustomerName = () => {
    const [customerName, setCustomerName] = useState<string | null>(null);
    const customerId = getCustomerIdFromSessionStorage();

    const fetchCustomerName = useCallback(async () => {
        if (!customerId) {
            console.error("Customer ID not found in token");
            return;
        }

        console.log("Fetching customer name from backend...");
        const response = await fetch(`/api/version/customer/${customerId}`);
        if (response.ok) {
            const data = await response.json();
            const cacheData = {
                customerName: data.customerName,
                timestamp: Date.now()
            };
            const cacheKey = `customer_name_for_${data.customerId}`;
            localStorage.setItem(cacheKey, JSON.stringify(cacheData));
            setCustomerName(data.customerName);
            console.log("Customer name fetched and cached:", data.customerName);
        } else {
            console.error("Failed to fetch customer name from backend.");
        }
    }, [customerId]);

    useEffect(() => {
        if (!customerId) {
            console.error("Customer ID not found in token");
            return;
        }

        const cacheKey = `customer_name_for_${customerId}`;
        const cachedData = localStorage.getItem(cacheKey);

        if (cachedData) {
            const { customerName: cachedCustomerName, timestamp } = JSON.parse(cachedData);
            const isCacheValid = Date.now() - timestamp < CACHE_DURATION;

            if (isCacheValid) {
                console.log("Using cached customer name:", cachedCustomerName);
                setCustomerName(cachedCustomerName);
            } else {
                console.log("Cache expired. Fetching new customer name...");
                fetchCustomerName();
            }
        } else {
            console.log("No cached customer name found. Fetching new customer name...");
            fetchCustomerName();
        }
    }, [customerId, fetchCustomerName]);

    return customerName;
};
