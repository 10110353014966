import { useCallback, useEffect, useState } from 'react';
import { WidgetProps } from '@rjsf/utils';

import { MenuItem, Select, InputLabel, OutlinedInput, Checkbox, ListItemText } from '@mui/material';

import { Partner } from "../../../../schemas/partnerManagement/schema";

import { usePartnerUserContext } from "../../../partnerManagement/partnerUserContext";

const DbMultiPartnerWidget = ({ onChange, id, value, label, readonly }: WidgetProps) => {
    const { partners } = usePartnerUserContext();
    const [partnerIds, setPartnerIds] = useState<string[]>([]);

    useEffect(() => {
        if (value) {
            setPartnerIds(
                typeof value === 'string' ? value.split(',') : value,
            );
        }
    }, [value, setPartnerIds]);

    const handleChange = useCallback((event: any) => {
        const newValue = event.target.value;
        onChange((newValue as string[]).filter(s => !!s).join(','));
    }, [onChange]);

    const getDisplayName = (partnerId: string) => {
        const selectedPartner = partners?.find((partner: Partner) => partner.rowKey === partnerId);
        return selectedPartner?.partnerName;
    };

    const getDisplayNames = (selectedValues: string[]) => {
        return selectedValues.filter(s => !!s).map(getDisplayName).join(', ');
    };

    return (
        <>
            <InputLabel id={`${id}-label`}>{label}</InputLabel>
            <Select
                id={id}
                value={partnerIds}
                labelId={`${id}-label`}
                label={label}
                multiple
                disabled={readonly}
                onChange={handleChange}
                variant="outlined"
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => getDisplayNames(selected)}
            >
                {partners.filter((partner: Partner) => !!partner.rowKey).map((partner: Partner) => (
                    <MenuItem key={partner.rowKey} value={partner.rowKey}>
                        <Checkbox checked={!!partner?.rowKey && partnerIds.indexOf(partner.rowKey) > -1} />
                        <ListItemText primary={partner.partnerName} />
                    </MenuItem>
                ))}
            </Select>
        </>
    );
};

export { DbMultiPartnerWidget };
