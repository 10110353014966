import { useParams, Link, useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import {
    Container,
    Box,
    Alert,
    CircularProgress
} from "@mui/material";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import { useState, useEffect } from "react";

import useDataClient from "../../../axios/dataClient";

import { getApp } from "../../../services/api/FrontendService";

import { AppViewModel } from "../../../services/model/apps/AppViewModel";

import { PageEditBaseForm } from "./PageEditBaseForm";
import { AppLinks } from "../AppLinksComponent";

import { FavoriteProvider } from '../../favorites/FavoriteContext';
import { FavoriteButton } from '../../favorites/FavoriteButton';

export const PageEdit: React.FC = () => {
    const { appId, pageName } = useParams();
    const [appData, setAppData] = useState<AppViewModel | null>(null);
    const { get } = useDataClient();
    const location = useLocation();
    const isDelete = location?.pathname?.endsWith("/delete");

    useEffect(() => {
        const fetchData = async () => {
            if (appId) {
                const data = await getApp(get)(appId);
                setAppData(data);
            }
        };

        fetchData();
    }, [appId, get]);

    if (!appData) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <FavoriteProvider>
            <Container className="bb-page-editor-container">
                <Box className="bb-title-bar bb-mt-3">
                    <h2 className="bb-m0 bb-p0">Pages: {appData.displayName}</h2>
                    {pageName && (
                        <Box>
                            <FavoriteButton page={`applications/${appId}/pages/${pageName}`} displayName={`App: ${appData.displayName}`}
                                displayModule={`Page: ${pageName}`} />
                        </Box>
                    )}
                    <AppLinks pageName={pageName} />
                </Box>
                <Breadcrumbs className="bb-breadcrumb bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
                    <Link to="/applications">Applications</Link>
                    <Link to={`/applications/${appId}`}>Modules</Link>
                    <Link to={`/applications/${appId}/pages`}>Pages</Link>
                    <Typography color="text.primary" className="bb-m0 bb-p0 bb-crumb-text">{pageName}</Typography>
                </Breadcrumbs>
                <Box>
                    <Alert
                        className="bb-title-info bb-mb-2"
                        severity="info"
                    >
                Create and edit your page with our page editor, then click the "Preview" button to view your changes.
                    </Alert>
                </Box>
                <Box className="bb-flex bb-ui-box bb-flex-column bb-tac" sx={{ minHeight: "60vh" }}>
                    <PageEditBaseForm appId={appId} pageName={pageName} isDelete={isDelete}></PageEditBaseForm>
                </Box>
            </Container>
        </FavoriteProvider>
    );
};
