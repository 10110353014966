const stringSort = (a: string | undefined, b: string | undefined) => {
    const string1 = (a || "").toUpperCase();
    const string2 = (b || "").toUpperCase();

    if (string1 < string2) {
        return -1;
    }

    if (string1 > string2) {
        return 1;
    }

    return 0;
};

const dateSort = (a: Date | undefined, b: Date | undefined) => {
    if (!a || !b) {
        return 0;
    }

    if (a < b) {
        return -1;
    }

    if (b > a) {
        return 1;
    }

    return 0;
};

export { stringSort, dateSort };
