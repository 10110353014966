export enum SchemaType {
    None = 0,
    App = 10,
    ClientStyle = 20,
    Modal = 30,
    ModalData = 40,
    Nav = 50,
    Profile = 60,
    Search = 70,
    SearchData = 80,
    SessionIntent = 90,
    Page = 100,
    Pathways = 120,
}
