import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";

import { Form, Formik } from "formik";

import { BotSecret, BotSecrets } from "../../../services/model/botSecret";

import useDataClient from "../../../axios/dataClient";

import { BotSecretSchema, BotSecretType } from "../../../yup-schemas/BotSecretsSchema";

import { BotSecretsForm } from "./BotSecretsForm";

interface BotSecretsDialogProps {
    open: boolean;
    secrets: BotSecrets;
    botId: string;
    onCancel: () => void;
    onSave: (newSecrets: BotSecrets) => void;
    onError: () => void;
}

export const BotSecretsDialog = ({ open, secrets, botId, onCancel, onSave, onError }: BotSecretsDialogProps) => {

    const { post } = useDataClient();
    const [processing, setProcessing] = useState(false);
    const cancel = useCallback(() => {
        onCancel();
    }, [onCancel]);

    const transformedSecrets = useMemo<{ secrets: BotSecret[] }>(() => {
        return {
            secrets: Object.keys(secrets).map(secretKey => {
                return {
                    key: secretKey,
                    value: secrets[secretKey],
                    id: Math.random().toString(10)
                };
            })
        };
    }, [secrets]);

    const reformSecrets = useCallback((formSecrets: BotSecret[]): BotSecrets => {
        const obj: BotSecrets = {};
        formSecrets.forEach(secret => obj[secret.key] = secret.value);
        return obj;
    }, []);

    const save = useCallback(async (formSecrets: BotSecretType) => {
        try {
            setProcessing(true);
            const newSecrets = await post<BotSecrets>("api/bot/secrets/" + botId, reformSecrets(formSecrets.secrets));
            setProcessing(false);
            onSave(newSecrets);
        }
        catch {
            setProcessing(false);
            onError();
        }
    }, [onSave, onError, reformSecrets, botId, post]);

    return (<Dialog open={open}>
        <Formik<BotSecretType>
            initialValues={transformedSecrets}
            validationSchema={BotSecretSchema}
            onSubmit={save}>
            {(props) => (
                <Form>
                    <DialogTitle className="bbot-dialog-title">
                        <Typography>Secrets</Typography></DialogTitle>
                    <DialogContent className="bbot-dialog-content bb-global-modal">
                        <BotSecretsForm />
                    </DialogContent>
                    <DialogActions className="bbot-dialog-actions">
                        <Button onClick={cancel}>Cancel</Button>
                        <Button type="submit" variant="contained" disabled={!props.isValid}>{ processing ? <CircularProgress /> : "Save" }</Button>
                    </DialogActions>
                </Form>
            )}
        </Formik>
    </Dialog>);
};
