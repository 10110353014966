import { createContext, useContext } from "react";

import { Schema } from "../../../schemas/profile/schema";

export interface SmartPagesContextSchemas {
    profileData: Schema;
}

export const SmartPageContext = createContext<SmartPagesContextSchemas>(
    {
        profileData: { profile: [] }
    }
);
export const useSmartPageContext = () => useContext(SmartPageContext);
