import { useCallback, useState, useEffect, FC } from "react";

import { Box, CircularProgress, Grid, Container, Alert, Typography } from "@mui/material";

import { useAsync } from "../../useAsync";

import { getTables } from "../../services/api/DataManagementService";

import { ITableListModel } from "../../services/model/dataManagement/ITableListModel";

import useDataClient from "../../axios/dataClient";

import { TableCard, TableCardProps } from "./TableCard";

import { FavoriteProvider } from '../favorites/FavoriteContext';
import { FavoriteButton } from '../favorites/FavoriteButton';

export interface TableListProps {
    tableRoute: string;
}

export const TableList: FC<TableListProps> = ({ tableRoute }) => {
    const { get } = useDataClient();
    const [tables, setTables] = useState<ITableListModel[]>([]);
    const [tablesWithData, setTablesWithData] = useState<ITableListModel[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        document.title = 'Beebot AI - Data Management';
    }, []);

    useEffect(() => {
        if (tables) {
            setTablesWithData(tables.filter(t => t.hasPendingData === true));
        }
    }, [tables]);

    const loadTables = useCallback(async () => {
        setIsLoading(true);
        const data = await getTables(get);
        setIsLoading(false);
        return data;
    }, [get]);

    useAsync(loadTables, setTables, [get]);

    const getTableCardProps =
        (table: ITableListModel, showPendingDataOnly: boolean): TableCardProps => {
            return {
                id: table.id,
                route: tableRoute,
                displayName: table.displayName,
                isReadOnly: table.isReadOnly,
                description: table.description,
                showPendingDataOnly
            };
        };

    const renderCard = useCallback((tableId: string, props: TableCardProps) => {
        return (
            <Grid item xs={12} sm={6} md={4} key={tableId}>
                <TableCard {...props} />
            </Grid>);
    }, []);

    return (
        <FavoriteProvider>
            <Container>
                <Box className="bb-title-bar">
                    <h2>Data Tables</h2>
                    <FavoriteButton page={`data`} displayName={`Data Management`} displayModule={`Manage Your Data`} />
                </Box>
                <Alert className="bb-title-info" severity="info">Select one of your tables to edit and view.</Alert>
                <Grid className="bb-apps"
                    container
                    spacing={2}
                    alignItems="stretch"
                    alignContent="center">
                    {isLoading
                        ? <CircularProgress />
                        : tables.length > 0
                            ? tables.map(table => renderCard(table.id, getTableCardProps(table, false)))
                            : <Box className="bb-flex bb-ui-box bb-flex-column bb-justify-content-center bb-tac bb-misc-ui-helper" sx={{ minHeight: "60vh" }}>
                                <Box className="bb-app-loader"><i></i></Box>
                                <Typography variant="h4">No Data to view or manage.</Typography>
                            </Box>}
                </Grid>
                {tablesWithData?.length > 0 &&
                    <>
                        <Alert className="bb-title-info bb-mt-4" severity="info">Select one of your tables with pending data to approve or reject.</Alert>
                        <Grid className="bb-apps"
                            container
                            spacing={2}
                            alignItems="stretch"
                            alignContent="center">
                            {isLoading
                                ? <CircularProgress />
                                : tablesWithData.map(table => renderCard(table.id, getTableCardProps(table, true)))
                            }
                        </Grid>
                    </>
                }
            </Container>
        </FavoriteProvider>
    );
};
