import {
    Button,
    CircularProgress,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography
} from "@mui/material";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import { Form } from '@rjsf/mui';

import React, { useState, useEffect, useCallback } from "react";

import { toast } from "react-toastify";

import { JSONSchema7 }  from "json-schema";

import validatorAjv8 from "@rjsf/validator-ajv8";

import { UiSchema } from "@rjsf/utils";

import { v7 as uuidv7 } from "uuid";

import useDataClient from "../../../axios/dataClient";

import { getAppConfigContent, postAppConfigContent } from "../../../services/api/FrontendAppConfigService";

import { SchemaType } from "../../../schemas/schemaTypes";

import { Schema } from "../../../schemas/navigation/schema";

import schemaModel from "../../../schemas/navigation/navigationSchema.json";

import { UndoButton } from "../UndoButton";

export interface AppNavigationFormProps {
    appId: string | undefined;
}

export const AppNavigationForm: React.FC<AppNavigationFormProps> = ({ appId }) => {
    const [navigation, setNavigation] = useState<Schema | null>(null);
    const { get, post } = useDataClient();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [showNavigationReloadModal, setShowNavigationReloadModal] = useState(false);

    const uiSchema: UiSchema = {
        'ui:submitButtonOptions': {
            norender: true
        },
        "nav": {
            "items": {
                "ui:options": {
                    "addButton": {
                        "label": "Add page"
                    }
                },
                "subNav": {
                    "items": {
                        "ui:options": {
                            "addButton": {
                                "label": "Add sub page"
                            }
                        }
                    }
                }
            }
        }
    };

    const templates = {
        ButtonTemplates: {
            AddButton: (props: any) => {
                return (
                    <Button {...props} variant="contained" color="primary">
                        {props.uiSchema.items["ui:options"].addButton.label}
                    </Button>
                );}
        }
    };

    const fetchData = React.useCallback(async () => {
        if (appId) {
            const navigationData = await getAppConfigContent<Schema>(get)(appId, SchemaType.Nav);
            setNavigation(navigationData);
        }
    }, [appId, get]);

    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, [appId, get, fetchData]);

    const handleSubmit = useCallback(async ({ formData }: any ) => {
        if (appId) {
            setSubmitting(true);

            const latestNavigationData = await getAppConfigContent<Schema>(get)(appId, SchemaType.Nav);
            const latestNavigationHash = latestNavigationData.savedHash;

            if (latestNavigationHash !== formData.savedHash) {
                setShowNavigationReloadModal(true);
                setSubmitting(false);
                return;
            }

            const newNavigationData: Schema = { ...formData, savedHash: uuidv7() };

            setNavigation(newNavigationData);
            postAppConfigContent<Schema>(post)(appId, SchemaType.Nav, {
                configDocument: newNavigationData,
                message: "Updated navigation settings"
            }).then(() => {
                toast.success("Navigation settings updated");
                return;
            }).finally(() => {
                setSubmitting(false);
            });
        }
    }, [post, appId, get]);

    const handleError = useCallback((e: any): void => {
        console.log("Data changed: ", e.formData);
    }, []);

    const handleUndo = useCallback(async (): Promise<void> => {
        setSubmitting(true);
        fetchData().then(() => {
            toast.warning("Navigation data has been reset");
            return;
        }).finally(() => {
            setSubmitting(false);
        });
    }, [fetchData]);

    const handleShowNavigationReloadModalClose = () => {
        setShowNavigationReloadModal(false);
    };

    const handleShowNavigationReloadModalProceed = () => {
        setShowNavigationReloadModal(false);
        handleUndo();
    };

    if (!navigation) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <Box>
            <Dialog
                open={showNavigationReloadModal}
            >
                <DialogTitle id="alert-dialog-title" className="bb-error-dialog-header bb-flex bb-align-items-center">
                    <WarningAmberIcon className="bb-mr-1"></WarningAmberIcon> <h2 className="bb-m0 bb-p0">{"WARNING!"}</h2>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className="bb-tac">
                        <h3>
                            Another user has recently updated Navigation. Please reload the page to get the most recent navigation data and try again.
                        </h3>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleShowNavigationReloadModalClose}>
                        Close
                    </Button>
                    <Button onClick={handleShowNavigationReloadModalProceed} className="bb-ml-auto">
                        Reload Navigation Data
                    </Button>
                </DialogActions>
            </Dialog>
            {submitting && <Box className="bb-tac"><CircularProgress /></Box>}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h4">Navigation Items</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                    <UndoButton onClick={handleUndo} disable={submitting}></UndoButton>
                    <Button className="bb-flex" variant="contained" disabled={submitting} type="submit" form="navigation_pagesubmit">
                     Save
                    </Button>
                </Box>
            </Box>
            <Form
                id="navigation_pagesubmit"
                className="bb-nav-form"
                schema={schemaModel as JSONSchema7}
                formData={navigation}
                validator={validatorAjv8}
                onSubmit={handleSubmit}
                onError={handleError}
                uiSchema={uiSchema}
                liveValidate={true}
                showErrorList={false}
                disabled={submitting}
                templates={templates}
            />
        </Box>
    );
};
