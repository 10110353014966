import { useParams, Link } from "react-router-dom";
import {
    Container,
    Box,
    Alert,
    CircularProgress,
    Button,
    Paper,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    TablePagination
} from "@mui/material";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { useState, useEffect, useCallback } from "react";

import useDataClient from "../../../axios/dataClient";

import { getApp } from "../../../services/api/FrontendService";

import { getAppConfigContent } from "../../../services/api/FrontendAppConfigService";

import { AppViewModel } from "../../../services/model/apps/AppViewModel";

import { SchemaType } from "../../../schemas/schemaTypes";

import { Schema } from "../../../schemas/smartPages/schema";

import { DateDisplay } from "../../shared/DateDisplay";

import { stringSort } from "../../../services/helper/sort";

import { newSmartPage } from "../../newModuleNames";

import { SearchField } from "../customWidget/Search/SearchField";
import { AppLinks } from "../AppLinksComponent";
import { Breadcrumb } from "../BreadCrumbComponent";

export const AppSmartPages = () => {
    useEffect(() => {
        document.title = 'Beebot AI - Smart Pages';
    },[]);

    const { appId } = useParams();
    const { get } = useDataClient();

    const [appData, setAppData] = useState<AppViewModel | null>(null);
    const [smartPageData, setSmartPageData] = useState<Schema>();
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [searchString, setSearchString] = useState<string>();
    const [filteredSmartPageData, setFilteredSmartPageData] = useState<Schema>();

    const callSearch = useCallback((search?: string) => {
        setSearchString(search);
    }, [setSearchString]);

    useEffect(() => {
        setSearchString("");
    }, []);

    useEffect(() => {
        if (!smartPageData?.smartPages) {
            return;
        }

        const searchStringUpper = searchString?.toUpperCase();
        setCurrentPage(0);
        const filtered = smartPageData.smartPages.filter((p) =>
            !searchStringUpper || (p?.name && p.name.toUpperCase().includes(searchStringUpper)));
        setFilteredSmartPageData({ ...smartPageData, smartPages: filtered });
    }, [searchString, smartPageData]);

    useEffect(() => {
        const fetchData = async () => {
            if (appId) {
                const data = await getApp(get)(appId);
                setAppData(data);
                const smartPages = await getAppConfigContent<Schema>(get)(appId, SchemaType.Modal);
                setSmartPageData(smartPages);
                setFilteredSmartPageData(smartPages);
            }
        };

        fetchData();
    },
    [appId, get]);

    const handleChangePage = (event: any, newPage: any) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0); // Reset to first page
    };

    if (!appData || !filteredSmartPageData) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <Container>
            <Box className="bb-title-bar bb-mt-0">
                <h2 className="bb-m0 bb-p0">Smart Pages: {appData.displayName}</h2>
                <AppLinks />
            </Box>
            <Breadcrumb />
            <Alert className="bb-title-info bb-mb-2" severity="info">Manage the Smart Pages for
                your <b>{appData.displayName}</b> application.&nbsp;
            <a rel="noreferrer" className="bb-mr-1" href={`https://product.bbotapp.com/?page=smartpages`}
                target="_blank">Learn More</a></Alert>
            <Box className="bb-flex bb-ui-box bb-flex-column">
                <Box className="bb-flex bb-tal bb-align-items-center bb-mb-2">
                    <h3 className="bb-m0 bb-p0">Your Smart Pages</h3>
                    <Box className="bb-med-search">
                        <SearchField
                            searchString={searchString}
                            callSearch={callSearch}
                            timeout={500}
                        />
                    </Box>
                    <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon" to={`/applications/${appId}/smartPages/${newSmartPage}`}>
                        <Button variant="contained">
                            New Smart Page
                        </Button>
                    </Link>
                </Box>
                <TableContainer component={Paper} sx={{ boxShadow: "none" }} className="bb-m0 bb-p0 bb-global-table">
                    <Table aria-label="table">
                        <TableHead>
                            <TableRow >
                                <TableCell>Name</TableCell>
                                <TableCell>Active</TableCell>
                                <TableCell>Valid From</TableCell>
                                <TableCell>Valid To</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{
                            filteredSmartPageData.smartPages
                                .sort((a, b) => stringSort(a.name, b.name))
                                .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                                .map((smartPage, index) => {
                                    return (
                                        <TableRow key={`tablerow_${index}`}>
                                            <TableCell>
                                                <Link
                                                    className="bb-pages-list bb-align-items-center"
                                                    to={`/applications/${appId}/smartPages/${smartPage.name}`}
                                                >
                                                    <ArrowRightIcon className="bb-page-list-icon"></ArrowRightIcon> {smartPage.name}
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                {smartPage.target?.active ? "Yes" : "No"}
                                            </TableCell>
                                            {smartPage.target?.validFrom ?
                                                (<TableCell>
                                                    <DateDisplay value={new Date(smartPage.target?.validFrom)} dateStyle="short" timeStyle="short" />
                                                </TableCell>)
                                                : (<TableCell></TableCell>)
                                            }
                                            {smartPage.target?.validTo ?
                                                (<TableCell>
                                                    <DateDisplay value={new Date(smartPage.target?.validTo)} dateStyle="short" timeStyle="short" />
                                                </TableCell>)
                                                : (<TableCell></TableCell>)
                                            }
                                        </TableRow>
                                    );
                                })
                        }
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[15, 20, 30, 40]}
                        component="div"
                        count={filteredSmartPageData.smartPages.length}
                        rowsPerPage={rowsPerPage}
                        page={currentPage}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className={"bb-global-table-footer"}
                    />
                </TableContainer>
            </Box>
        </Container>
    );
};
