const suffixes = "KMGTPEZY";
export function bigNumber(a: number | undefined): string {
    if (a === undefined) {
        return "";
    }
    let i = -1;
    while (a >= 1000) {
        a /= 1000;
        i++;
    }

    return (i < 0)
        ? a.toString()
        : a.toPrecision(3) + " " + suffixes[i];
}

export function round2sf(a: number | undefined): string {
    if (a === undefined) {
        return "";
    }

    return (Math.round(a * 100) / 100).toString();
}

export function daysSinceEpoch(date: Date) {
    return Math.floor(+date / 8.64e7);
}

export function fromDaysSinceEpoch(date: number) {
    return new Date(+date * 8.64e7);
}

export function pcNumber(a: number | undefined, raw: boolean): string {
    if (a === undefined) {
        return "";
    }
    if (raw) {
        if (a > 0) {
            return "+ " + (Math.round(a * 10) / 10).toString() + " %";
        } else {
            return (Math.round(a * 10) / 10).toString() + " %";
        }
    } else {
        return (Math.round(a * 100.0 * 10) / 10).toString() + " %";
    }
}
