import { Venue } from "../../schemas/eventManagement/venueSchema";

const getVenues = <schema>(get: <T>(url: string) => Promise<T>) => async () => {
    const result = await get<schema>(`/api/venue`);
    return result;
};

const getVenue = <schema>(get: <T>(url: string) => Promise<T>) => async (venueId: string) => {
    const result = await get<schema>(`/api/venue/${venueId}`);
    return result;
};

const createVenue = <schema>(post: <T>(url: string, body: any) => Promise<T>) => async (form: Venue) => {
    const result = await post<schema>(`/api/venue`, form);
    return result;
};

const updateVenue = <schema>(put: <T>(url: string, body: any) => Promise<T>) => async (venueId: string, form: Venue) => {
    const result = await put<schema>(`/api/venue/${venueId}`, form);
    return result;
};

const deleteVenue = <schema>(deleteRequest: <T>(url: string, body: any) => Promise<T>) => async (venueId: string) => {
    const result = await deleteRequest<schema>(`/api/venue/${venueId}`, {});
    return result;
};

export { getVenues, getVenue, createVenue, updateVenue, deleteVenue };
