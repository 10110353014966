/*eslint no-loop-func: "error"*/

import * as React from "react";
import { Box } from "@mui/material";

export interface BBHtmlProps {
    panel: any;
}

export const BBHtml: React.FC<BBHtmlProps> = ({ panel }) => {
    return (
        <Box>
            {panel?.panelHeading ? (<h3 className="bb-tal">{panel?.panelHeading}</h3>) : (<span></span>)}
            {panel?.panelPara ? (<p className="bb-tal">{panel?.panelPara}</p>) : (<span></span>)}
            <Box sx={{ width: '100%' }}>
                <div className="bbHTML" dangerouslySetInnerHTML={{ __html: panel?.html }}></div>
            </Box>
        </Box>
    );
};
