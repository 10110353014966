export const secondLastEditorPaths = [
    "profiles",
    "smartPages",
    "pages",
    "pathways"
];

export const lastEditorPaths = [
    "settings",
    "navigation",
    "theme"
];

export const allowedLastEditorPaths = [
    "delete"
];
