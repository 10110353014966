import * as React from "react";
import { useState, useEffect } from "react";
import { Box, Tooltip, Button, IconButton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export interface BBFadingBannerProps {
    panel: any;
}

export const BBFadingBanner: React.FC<BBFadingBannerProps> = ({ panel }) => {
    const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
    const [fade, setFade] = useState(true);
    const zoom = panel.bannerZoomEffect === true ? " Yes" : " No";
    const bannerData = React.useMemo(() => panel.bannerData || [], [panel.bannerData]);
    const bannerCount = bannerData.length;

    useEffect(() => {
        // Preload all images
        bannerData.forEach((banner: any) => {
            const img = new Image();
            img.src = banner.bannerImgUrl;
        });
    }, [bannerData]);

    const handlePrevClick = () => {
        setFade(false);
        setTimeout(() => {
            setCurrentBannerIndex((prevIndex) => (prevIndex === 0 ? bannerData.length - 1 : prevIndex - 1));
            setFade(true);
        }, 500);
    };

    const handleNextClick = () => {
        setFade(false);
        setTimeout(() => {
            setCurrentBannerIndex((prevIndex) => (prevIndex === bannerData.length - 1 ? 0 : prevIndex + 1));
            setFade(true);
        }, 500);
    };

    if (bannerCount === 0) {
        return <Box>No Banners have been created yet.</Box>;
    }

    const currentBanner = bannerData[currentBannerIndex];

    return (
        <Box>
            <Box className="bb-mb-2">
                <Box className={`bb-pe-fading-banner ${fade ? 'fade-in' : 'fade-out'}`}
                    sx={{ backgroundColor: currentBanner.bannerBgColor,
                        color: "#fff",
                        overflow: "hidden",
                        borderRadius: "5px",
                        width: "100%",
                    }}
                >
                    <Box
                        component="img"
                        src={currentBanner.bannerImgUrl}
                        sx={{}}
                    />
                    <Box className="bb-tal bb-p-2 bb-flex bb-flex-column bb-pe-fb-display">
                        <h2>{currentBanner.bannerHeading}</h2>
                        <h4>{currentBanner.bannerSubHeading}</h4>
                        <p>{currentBanner.bannerPara}</p>
                        {currentBanner.bannerButtonVisible && (
                            <Button sx={{
                                margin: "0.5rem 0",
                                textDecoration: "none",
                                fontWeight: "bold",
                                padding: "0.6rem",
                                borderRadius: "5px",
                                backgroundColor: currentBanner.bannerButtonBgColor,
                                color: currentBanner.bannerButtonTextColor,
                                width: "fit-content",
                                '&:hover': {
                                    backgroundColor: currentBanner.bannerButtonBgColor,
                                    color: currentBanner.bannerButtonTextColor
                                }
                            }}>
                                {currentBanner.bannerLinkText}
                            </Button>
                        )}
                    </Box>
                </Box>
                <Box className="dots-arrows-container" sx={{ justifyContent: bannerData.length > 1 ? 'space-between' : 'center' }}>
                    {bannerData.length > 1 && (
                        <IconButton onClick={handlePrevClick} className="arrow-button">
                            <ArrowBackIosIcon className="arrow-icon" />
                        </IconButton>
                    )}
                    <Box className="dots-container">
                        {bannerData.map((_: any, index: number) => (
                            <Box
                                key={index}
                                className={`dot ${index === currentBannerIndex ? 'active' : ''}`}
                            />
                        ))}
                    </Box>
                    {bannerData.length > 1 && (
                        <IconButton onClick={handleNextClick} className="arrow-button">
                            <ArrowForwardIosIcon className="arrow-icon" />
                        </IconButton>
                    )}
                </Box>
            </Box>
            <Box sx={{ borderTop: "1px solid #eee" }}>
                <Box className="bb-flex bb-align-items-center bb-justify-content-center bb-mt-2" sx={{ alignItems: "bottom" }}>
                    <h3 className="bb-header-with-icon">Fading Banner </h3>
                    <Tooltip title="Fading banners can display across the full width of your page.
                    Text will display on the left, and your image on the right. If you have multiple banners on one row,
                    banners will stack so the image sits on top." placement="top">
                        <InfoOutlinedIcon className="bb-header-icon"></InfoOutlinedIcon>
                    </Tooltip>
                </Box>
                <ul className="bb-pe-list bb-justify-content-center">
                    <li className="bb-flex bb-align-items-center">
                        <Tooltip title="Images zoom in as the banners fade between one another." placement="top">
                            <InfoOutlinedIcon className="bb-tip-icon"></InfoOutlinedIcon>
                        </Tooltip>
                        <span className="bb-pe-setting">Zoom</span>: <b>&nbsp;{zoom}</b>
                    </li>
                    <li className="bb-flex bb-align-items-center">
                        <Tooltip title="Number of banner items." placement="top">
                            <InfoOutlinedIcon className="bb-tip-icon"></InfoOutlinedIcon>
                        </Tooltip>
                        <span className="bb-pe-setting">Items</span>: <b>&nbsp;{bannerCount}</b>
                    </li>
                </ul>
            </Box>
        </Box>
    );
};
