/* eslint-disable max-len */
import * as React from "react";
import { Grid, Box } from "@mui/material";
export interface BBSocialProps {
    panel: any;
}
export const BBSocial: React.FC<BBSocialProps> = ({ panel }) => {
    return (
        <Grid item xs className="bb-social-box">
            {panel?.imgUrl ? (<img src={panel?.imgUrl} className="bb-pe-img, bb-width-100" />) : (<span></span>)}
            {panel?.panelHeading ? (<h3 className="bb-tal">{panel?.panelHeading}</h3>) : (<span></span>)}
            {panel?.panelHeading ? (<p className="bb-tal">{panel?.panelPara}</p>) : (<span></span>)}
            <Box className="bb-flex bb-justify-content-center">
                {panel?.Facebook ? (
                    <Box>
                        <Box className='bb-op-share bb-share-facebook'>
                            <a href={panel?.Facebook} target='_blank' title='Facebook' rel="noreferrer">
                                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
                                    <path d='M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z'></path>
                                </svg>
                            </a>
                        </Box>
                    </Box>
                ) : (<span></span>)}
                {panel?.Twitter ? (
                    <Box>
                        <Box className='bb-op-share bb-share-twitter'>
                            <a href={panel?.Twitter} target='_blank' title='X Twitter' rel="noreferrer">
                                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                                    <path d='M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z'></path>
                                </svg>
                            </a>
                        </Box>
                    </Box>
                ) : (<span></span>)}
                {panel?.Instagram ? (
                    <Box>
                        <Box className='bb-op-share bb-share-insta'>
                            <a href={panel?.Instagram} target='_blank' title='Instagram' rel="noreferrer">
                                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                                    <path d='M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334'/>
                                </svg>
                            </a>
                        </Box>
                    </Box>
                ) : (<span></span>)}
                {panel?.YouTube ? (
                    <Box>
                        <Box className='bb-op-share bb-share-youtube'>
                            <a href={panel?.YouTube} target='_blank' title='Youtube' rel="noreferrer">
                                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                                    <path d='M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z'/>
                                </svg>
                            </a>
                        </Box>
                    </Box>
                ) : (<span></span>)}
                {panel?.LinkedIn ? (
                    <Box>
                        <Box className='bb-op-share bb-share-linkedin'>
                            <a href={panel?.LinkedIn} target='_blank' title='Linked In' rel="noreferrer">
                                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 310 310'>
                                    <path id='XMLID_802_' d='M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73C77.16,101.969,74.922,99.73,72.16,99.73z'/>
                                    <path id='XMLID_803_' d='M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z'/>
                                    <path id='XMLID_804_' d='M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995C310,145.43,300.549,94.761,230.454,94.761z'/>
                                </svg>
                            </a>
                        </Box>
                    </Box>
                ) : (<span></span>)}
            </Box>
        </Grid>
    );
};
