import { toast } from "react-toastify";

export const error = (message: string) => {
    toast.error(message);
};

export const errors = (messages: string[]) => {
    messages?.forEach(message => {
        toast.error(message);
    });
};
