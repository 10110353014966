import { useCallback, useEffect, useState } from 'react';
import { WidgetProps } from '@rjsf/utils';

import { TextField, FormLabel } from '@mui/material';

const MultiTextWidget = ({ onChange, id, value, label }: WidgetProps) => {
    const [rowIdentifiers, setRowIdentifiers] = useState<string[]>([]);

    useEffect(() => {
        setRowIdentifiers(
            typeof value === 'string' ? value.split(',') : value,
        );
    }, [value, setRowIdentifiers]);

    useEffect(() => {
        if (rowIdentifiers[rowIdentifiers.length - 1]) {
            setRowIdentifiers([...rowIdentifiers, ""]);
        }
        else if (!rowIdentifiers[rowIdentifiers.length - 1] &&
            rowIdentifiers.length > 1 &&
            !rowIdentifiers[rowIdentifiers.length - 2]) {
            setRowIdentifiers([...rowIdentifiers.slice(0, rowIdentifiers.length - 1)]);
        }
    }, [rowIdentifiers, setRowIdentifiers]);

    const handleChange = useCallback((event: any) => {
        const name = event.target.name;
        const fieldIndex = Number(name);

        if (!isNaN(fieldIndex) && rowIdentifiers.length > fieldIndex) {

            const newRowIdentifiers = [ ...rowIdentifiers ];
            const newValue = event.target.value;

            newRowIdentifiers[fieldIndex] = newValue;

            onChange(newRowIdentifiers.join(','));
        }
    }, [rowIdentifiers, onChange]);

    return (
        <>
            <FormLabel id={`${id}-label`} sx={{ textAlign: "left" }} >{label}</FormLabel>
            {rowIdentifiers.map((input, index) => (
                <TextField
                    id={`${id}-${index}`}
                    name={`${index}`}
                    key={index}
                    value={input}
                    onChange={handleChange}
                    variant="outlined"
                />
            ))}
        </>
    );
};

export { MultiTextWidget };
