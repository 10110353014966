/* eslint-disable @typescript-eslint/no-non-null-assertion */

import {
    Alert,
    CircularProgress
} from "@mui/material";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
    GridRowModesModel,
    GridRowModes,
    DataGrid,
    GridColDef,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowId,
    GridRowModel,
    GridSlots,
    GridValidRowModel,
    useGridApiRef,
    GridToolbarQuickFilter
} from '@mui/x-data-grid';

import { useParams, Link } from "react-router-dom";

import React, { useState, useEffect, useCallback, FC, useContext } from "react";

import { IRowKeyPair } from "../../services/model/dataManagement/IRowKeyPair";

import useDataClient from "../../axios/dataClient";

import { getDataApprovals, approveRows, rejectRows, cancelRows, savepPendingRow } from "../../services/api/DataManagementService";

import { ITableDataEditModel } from "../../services/model/dataManagement/ITableEditModel";
import { createJsonObject, createObjectFromRow } from "./rjsfSchemaGenerator";
import { DataApprovalJsonFormModal } from "./DataApprovalJsonFormModal";
import { toast } from "react-toastify";
import { MemberAppContext } from "../../MemberAppContext";

import { FavoriteProvider } from '../favorites/FavoriteContext';
import { FavoriteButton } from '../favorites/FavoriteButton';

interface EditToolbarProps {
    handleApproveSelectedRows: any;
    handleRejectSelectedRows: any;
    handleCancelSelectedRows: any;
    data: ITableDataEditModel | null;
    isPartnerUser: boolean;
}

function EditToolbar(props: EditToolbarProps) {
    const { handleApproveSelectedRows, handleRejectSelectedRows, handleCancelSelectedRows, data, isPartnerUser } = props;

    const handleApprove = () => {
        handleApproveSelectedRows();
    };

    const handleReject = () => {
        handleRejectSelectedRows();
    };

    const handleCancel = () => {
        handleCancelSelectedRows();
    };

    return (
        <GridToolbarContainer>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <GridToolbarQuickFilter />
                </Box>
                {!data?.isReadOnly && (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        {!isPartnerUser &&
                            <Button startIcon={<DoneOutlinedIcon />} onClick={handleApprove} style={{ color: 'var(--bb-primary)' }}>
                                Approve selected
                            </Button>
                        }
                        {!isPartnerUser &&
                            <Button startIcon={<CloseOutlinedIcon />} onClick={handleReject} style={{ color: 'red' }}>
                                Reject selected
                            </Button>
                        }
                        {isPartnerUser &&
                            <Button startIcon={<CloseOutlinedIcon />} onClick={handleCancel} style={{ color: 'red' }}>
                                Cancel selected
                            </Button>
                        }
                    </Box>
                )}
            </Box>
        </GridToolbarContainer>
    );
}

enum DataApprovalActionState {
    NONE = 0,
    APPROVE = 1,
    REJECT = 2,
    CANCEL = 3
}

const DataApprovalActionStateText = {
    [DataApprovalActionState.NONE]: "None",
    [DataApprovalActionState.APPROVE]: "Approve",
    [DataApprovalActionState.REJECT]: "Reject",
    [DataApprovalActionState.CANCEL]: "Cancel"
};

export interface ViewDataApprovalsTableProps {}
export const ViewDataApprovalsTable: FC<ViewDataApprovalsTableProps> = () => {
    const { get, put } = useDataClient();
    const appContext = useContext(MemberAppContext);

    const gridRef = React.useRef<any>(null);
    const apiRef = useGridApiRef();

    const { tableId } = useParams<{ tableId: string }>();

    const [data, setData] = React.useState<ITableDataEditModel | null>(null);
    const [dataSchema, setDataSchema] = React.useState<any>(null);
    const [dataObject, setDataObject] = React.useState<any>(null);
    const [showRowForm, setShowRowForm] = React.useState<boolean>(false);
    const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);
    const [approveCandidate, setApproveCandidate] = React.useState<GridRowId[]>([]);
    const [rejectCandidate, setRejectCandidate] = React.useState<GridRowId[]>([]);
    const [cancelCandidate, setCancelCandidate] = React.useState<GridRowId[]>([]);
    const [dataApprovalActionState, setDataApprovalActionState] = React.useState<DataApprovalActionState>(DataApprovalActionState.NONE);
    const [viewRowId, setViewRowId] = React.useState<GridRowId>();

    const getActionCandidate = () => {
        if (dataApprovalActionState === DataApprovalActionState.APPROVE) {
            return approveCandidate;
        } else if (dataApprovalActionState === DataApprovalActionState.REJECT) {
            return rejectCandidate;
        } else if (dataApprovalActionState === DataApprovalActionState.CANCEL) {
            return cancelCandidate;
        }

        return [];
    };

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setDataApprovalActionState(DataApprovalActionState.NONE);
    };

    const [columns, setColumns] = React.useState<GridColDef[]>([]);
    const [rows, setRows] = React.useState<GridValidRowModel[]>([]);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
        PartitionKey: false,
        RowKey: false,
        Data: false,
        TableName: false,
        UpdatedBy: !appContext.user.isPartnerUser
    });

    const handleColumnVisibilityModelChange = (newModel: { [key: string]: boolean }) => {
        const updatedModel = {
            ...newModel,
            PartitionKey: false,
            RowKey: false,
            Data: false,
            TableName: false,
            UpdatedBy: !appContext.user.isPartnerUser
        };
        setColumnVisibilityModel(updatedModel);
    };

    useEffect(() => {
        if (tableId) {
            const fetchData = async () => {
                const result = await getDataApprovals(get, tableId!);
                setData(result);
            };
            fetchData();
        }
    }, [get, tableId]);

    const handleApproveSelectedRows = async () => {
        setDataApprovalActionState(DataApprovalActionState.APPROVE);
        setApproveCandidate(selectionModel);
        setOpen(true);
    };

    const handleRejectSelectedRows = async () => {
        setDataApprovalActionState(DataApprovalActionState.REJECT);
        setRejectCandidate(selectionModel);
        setOpen(true);
    };

    const handleCancelSelectedRows = async () => {
        setDataApprovalActionState(DataApprovalActionState.CANCEL);
        setCancelCandidate(selectionModel);
        setOpen(true);
    };

    const handleActualAction = async () => {
        const actionCandidate = getActionCandidate();

        if (Array.isArray(actionCandidate) && actionCandidate.length > 0) {
            const rowKeyPairs: IRowKeyPair[] = actionCandidate.map(id => {
                const row = rows.find(row1 => row1.id === id);
                return { PartitionKey: row!.PartitionKey, RowKey: row!.RowKey };
            });

            try {
                if (dataApprovalActionState === DataApprovalActionState.APPROVE) {
                    await approveRows(put, tableId!, rowKeyPairs);
                } else if (dataApprovalActionState === DataApprovalActionState.REJECT) {
                    await rejectRows(put, tableId!, rowKeyPairs);
                } else if (dataApprovalActionState === DataApprovalActionState.CANCEL) {
                    await cancelRows(put, tableId!, rowKeyPairs);
                }
                // Filter out the deleted rows and update the state
                const newRows = rows.filter(row1 => !actionCandidate.includes(row1.id));
                setRows(newRows);
                setData({ ...data!, rows: newRows });

                setSelectionModel([]);
                setApproveCandidate([]);
                setRejectCandidate([]);
                setCancelCandidate([]);
            } catch (error) {
                console.error("Failed to action rows:", error);
            } finally {
                handleClose();
                handleFormClose();
            }
        } else {
            handleClose();
            handleFormClose();
        }
    };

    const handleViewClick = useCallback((id: GridRowId) => {
        const schema = createJsonObject({ ...data, columns: data!.dataColumns });
        const rowDataObject = JSON.parse(data?.rows.find(row1 => row1.id === id)?.Data);
        const row = createObjectFromRow(rowDataObject, data!.dataColumns);
        setDataSchema(schema);
        setDataObject(row);
        setViewRowId(id);
        setShowRowForm(true);
    }, [data]);

    const handleFormSaveClick = useCallback(async (rowData: any) => {
        const { ...updatedValues } = rowData.formData;
        const pendingRow = rows.find(row1 => row1.id === viewRowId!);

        await savepPendingRow(put, tableId!, pendingRow!.PartitionKey, pendingRow!.RowKey, updatedValues);

        const newRows = rows.map((row) => {
            if (row.id === viewRowId!) {
                return { ...row, Data: JSON.stringify(updatedValues) };
            }
            return row;
        });

        setRows(newRows);
        setData({ ...data!, rows: newRows });

        setRowModesModel((prevModel) => ({ ...prevModel, [pendingRow!.id]: { mode: GridRowModes.View } }));
        setViewRowId(undefined);
        setShowRowForm(false);
        toast.success("Table row has been updated...");
    }, [put, setRowModesModel, setShowRowForm, setRows, tableId, rows, data, viewRowId]);

    const handleFormClose = useCallback(() => {
        setViewRowId(undefined);
        setShowRowForm(false);
    }, []);

    const handleApproveClick = useCallback((id: GridRowId) => {
        setDataApprovalActionState(DataApprovalActionState.APPROVE);
        setApproveCandidate([id]);
        setOpen(true);
    }, []);

    const handleRejectClick = useCallback((id: GridRowId) => {
        setDataApprovalActionState(DataApprovalActionState.REJECT);
        setRejectCandidate([id]);
        setOpen(true);
    }, []);

    const handleCancelClick = useCallback((id: GridRowId) => {
        setDataApprovalActionState(DataApprovalActionState.CANCEL);
        setCancelCandidate([id]);
        setOpen(true);
    }, []);

    const isCellEditable = () => {
        return true;
    };

    const actionColumn = React.useMemo(() => ({
        field: 'actions',
        type: 'actions' as const,
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id }: { id: GridRowId }) => {
            const gridActions: any[] = [];

            gridActions.push(
                <GridActionsCellItem
                    key={`${id}-view`}
                    icon={<VisibilityOutlinedIcon style={{ color: 'cornflowerblue' }} />}
                    label="View"
                    title="View"
                    onClick={() => handleViewClick(id)}
                />
            );

            if (appContext.user.isPartnerUser) {
                gridActions.push(
                    <GridActionsCellItem
                        key={`${id}-cancel`}
                        icon={<CloseOutlinedIcon style={{ color: 'red' }} />}
                        label="Cancel"
                        title="Cancel"
                        onClick={() => handleCancelClick(id)}
                    />
                );
            } else {
                gridActions.push(
                    <GridActionsCellItem
                        key={`${id}-approve`}
                        icon={<DoneOutlinedIcon style={{ color: 'green' }} />}
                        label="Approve"
                        title="Approve"
                        onClick={() => handleApproveClick(id)}
                    />
                );
                gridActions.push(
                    <GridActionsCellItem
                        key={`${id}-reject`}
                        icon={<CloseOutlinedIcon style={{ color: 'red' }} />}
                        label="Reject"
                        title="Reject"
                        onClick={() => handleRejectClick(id)}
                    />
                );
            }

            return gridActions;
        },
    }), [handleApproveClick, handleRejectClick, handleCancelClick, handleViewClick, appContext.user.isPartnerUser]);

    useEffect(() => {
        if (data && data.rows) {
            setRows(data.rows);
        }
    }, [data]);

    useEffect(() => {
        if (data && data.columns) {
            const newColumns: GridColDef[] = [
                ...(data.isReadOnly ? [] : [actionColumn]),
                ...data.columns.map(column => ({
                    ...column,
                    editable: !data.isReadOnly && column.field !== "Timestamp",
                    hideable: !(column.field === 'PartitionKey' || column.field === 'RowKey'),
                    ...(column.type === "dateTime" ? {
                        valueGetter: (value: string) => new Date(value),
                    } : {})
                }))
            ];
            setColumns(newColumns);
        }
    }, [data, actionColumn]);

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };
        const newRows = rows.map((row) => (row.id === newRow.id ? updatedRow : row));
        setRows(newRows);
        setData({ ...data!, rows: newRows });
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const isRowSelectable = () => {
        return true;
    };

    if (!data) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <FavoriteProvider>
            <Box>
                <Box className="bb-title-bar bb-mt-3">
                    <Typography variant="h2" className="bb-m0 bb-p0">Pending Data Approvals - {data?.displayName ?? ""}</Typography>
                    <FavoriteButton page={`data/${tableId}`} displayName={`Data Management`} displayModule={`${data?.displayName}`} />
                </Box>
                <Breadcrumbs className="bb-breadcrumb bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
                    <Link to="/data">Tables</Link>
                    <Typography color="text.primary" className="bb-m0 bb-p0 bb-crumb-text">Directory: {data?.displayName ?? ""}</Typography>
                </Breadcrumbs>
                {!data?.isReadOnly && (
                    <Alert className="bb-title-info bb-mb-2" severity="info">Please note, you are managing <b>live data</b>.</Alert>
                )}
                <Box className="bb-width-100 bb-flex">
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle id="alert-dialog-title" className="bb-error-dialog-header bb-flex bb-align-items-center">
                            <WarningAmberIcon className="bb-mr-1"></WarningAmberIcon> <h2 className="bb-m0 bb-p0">{"WARNING!"}</h2>
                        </DialogTitle>
                        <DialogContent className="bb-global-modal">
                            <DialogContentText className="bb-tac">
                                <h2>Are you really sure?</h2>
                            This will {DataApprovalActionStateText[dataApprovalActionState]} the selected content.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>
                            Close
                            </Button>
                            <Button variant="contained" onClick={handleActualAction} className="bb-ml-auto">
                            Yes, {DataApprovalActionStateText[dataApprovalActionState]}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
                <Box style={{ height: "70vh", width: "100%" }}>
                    <DataGrid
                        apiRef={apiRef}
                        ref={gridRef}
                        style={{ flexGrow: 1 }}
                        rows={rows}
                        rowHeight={30}
                        columns={columns}
                        checkboxSelection={!data.isReadOnly}
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        processRowUpdate={processRowUpdate}
                        editMode="row"
                        isRowSelectable={isRowSelectable}
                        slots={{
                            toolbar: EditToolbar as GridSlots['toolbar'],
                        }}
                        slotProps={{
                            toolbar: {
                                setRows,
                                setRowModesModel,
                                handleApproveSelectedRows,
                                handleRejectSelectedRows,
                                handleCancelSelectedRows,
                                data,
                                showQuickFilter: true,
                                isPartnerUser: appContext.user.isPartnerUser
                            },
                        }}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    PartitionKey: false,
                                    RowKey: false,
                                    Data: false,
                                    TableName: false,
                                    UpdatedBy: !appContext.user.isPartnerUser
                                },
                            },
                        }}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
                        onRowSelectionModelChange={(newSelectionModel: any) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        isCellEditable={isCellEditable}
                    />
                </Box>
                {showRowForm &&
                    <DataApprovalJsonFormModal
                        id={viewRowId!}
                        onApprove={handleApproveClick}
                        onReject={handleRejectClick}
                        onCancel={handleCancelClick}
                        open={showRowForm}
                        onUpdate={handleFormSaveClick}
                        onClose={handleFormClose}
                        schema={dataSchema}
                        data={dataObject}
                        isPartnerUser={appContext.user.isPartnerUser}
                    ></DataApprovalJsonFormModal>
                }
            </Box>
        </FavoriteProvider>);
};
