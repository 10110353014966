import { InferType, mixed, object, string, array } from "yup";

import { BlobFile } from "../components/assets/folder";

export const AssetUploadSchema = (existingFiles: BlobFile[]) => {
    return object({
        fileNames: array().of(string()
            .min(1)
            .required()
            .test("slash", "You are unable to add a title with a / in it.", (val) => {
                if (val === undefined) {
                    return true;
                }

                return val.indexOf("/") === -1 && val.indexOf("\\") === -1;
            })
            .test("duplicate", "Name must be unique!", (val, context) => {
                const positionInArray = context.path.slice(10, -1);
                const positionInArrayInt = parseInt(positionInArray, 10);
                const extensions = (context.options as any).from[0].value.extensions;
                return !existingFiles.some(existing => existing.name === val + "." + extensions[positionInArrayInt]);
            })
            .test("duplicateWithinUpload", "Name must be unique!", (val, context) => {
                const extensions = (context.options as any).from[0].value.extensions;
                const positionInArray = context.path.slice(10, -1);
                const positionInArrayInt = parseInt(positionInArray, 10);
                const fullFileName = val + "." + extensions[positionInArrayInt];
                const allFiles = context.parent as string[];
                return !allFiles.some((file, index) => file + "." + extensions[index] === fullFileName && index !== positionInArrayInt);
            }))
            .min(1).required("No files selected"),
        files: array().of(mixed())
            .required(),
        extensions: array().of(string().min(1).required("Extension not specified")).min(1).required("No files selected"),
    });
};

const AssetUploadSchemaInstance = AssetUploadSchema([]);

export type AssetUploadType = InferType<typeof AssetUploadSchemaInstance>;
