import {
    Button,
    Card,
    CardActions,
    CardHeader,
    Grid
} from "@mui/material";

import { Link } from "react-router-dom";

export interface TableCardProps {
    id: string;
    displayName: string;
    isReadOnly: boolean;
    description: string;
    route: string;
    showPendingDataOnly: boolean;
}

export const TableCard = ({
    id,
    displayName,
    isReadOnly,
    description,
    route,
    showPendingDataOnly
}: TableCardProps) => {
    return (
        <Card className="bb-app-icons animated animatedFadeInUp fadeInUp">
            <CardHeader title={displayName} />
            <Grid container spacing={1} className="bb-app-icon-stats">
                <Grid item xs={12} className="animated animatedFadeInUp fadeInUp2">
                    <div className="bb-app-icon-description">
                        <p className="fontSize1 bb-m0 bb-p0">{description}</p>
                        <p className="bb-m0">&nbsp;</p>
                        <h2 className="fontSize1 bb-m0 bb-p0">Is read only</h2>
                        <p className="fontSize1 bb-m0 bb-p0">{isReadOnly ? "Yes" : "No"}</p>
                    </div>
                </Grid>
            </Grid>
            <CardActions>
                <Button variant="contained"
                    component={Link}
                    to={`/${route}${showPendingDataOnly ? "/pending" : ""}/${id}`}
                    sx={{ marginLeft: "auto" }}>
                    View
                </Button>
            </CardActions>
        </Card>);
};
