import { Box, Button, Typography } from "@mui/material";
import { useCallback, useState } from "react";

import useDataClient from "../../axios/dataClient";

interface ChatFileBoxProps {
    fileName: string;
    chatId: string;
}

export const ChatFileBox = ({ fileName, chatId }: ChatFileBoxProps) => {

    const { getBlob } = useDataClient();
    const [file, setFile] = useState<Blob | undefined>(undefined);

    const downloadFileAction = useCallback((targetFile: Blob) => {
        const url = window.URL.createObjectURL(targetFile);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    }, [fileName]);

    const downloadFile = useCallback(async () => {
        if (file) {
            downloadFileAction(file);
            return;
        }

        const params = new URLSearchParams({
            chatId,
            fileName
        });
        const result = await getBlob<any>("api/chat/getFile?" + params.toString());
        setFile(result);
        downloadFileAction(result);
    }, [chatId, fileName, getBlob, file, downloadFileAction]);

    const openFile = useCallback(async () => {
        if (file) {
            const url = window.URL.createObjectURL(file);
            window.open(url);
        }
        else {
            const params = new URLSearchParams({
                chatId,
                fileName
            });
            const result = await getBlob<any>("api/chat/getFile?" + params.toString());
            setFile(result);
            const url = window.URL.createObjectURL(result);
            window.open(url);
        }
    }, [file, getBlob, chatId, fileName]);
    return (
        <Box>
            <Typography>{fileName}</Typography>
            <Button onClick={downloadFile}>Download</Button>
            <Button onClick={openFile}>Open</Button>
        </Box>
    );
};
