import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Typography,
    Tooltip
} from "@mui/material";

import './app-preview.css';
import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import CloseIcon from "@mui/icons-material/Close";

import useDataClient from "../../../axios/dataClient";
import { getPreviewContent, storePreviewModelInLocalStorage } from "../../../services/api/FrontendAppConfigService";

import './app-preview.css';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: '98%',
    width: '75%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export interface AppPreviewProps {
    page: any;
    pageName: string;
    theme?: string;
    queryParamName?: "page" | "modal";
}

export const AppPreview: React.FC<AppPreviewProps> = ({ page, pageName, theme, queryParamName = "page" }) => {
    const { appId } = useParams();
    const editorPath = process.env.PUBLIC_URL + `/editor/index.html?${queryParamName}=${pageName}`;

    const { get } = useDataClient();
    const [previewLoaded, setPreviewLoaded] = useState<boolean>(false);
    const [previewType, setPreviewType] = React.useState('bb-p-desktop');
    const [changePreviewType, setChangePreviewType] = React.useState(true);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handlePreviewChange = (event: any) => {
        const deviceFrame = document.getElementById("bui-editor-preview");
        if (deviceFrame) {
            setChangePreviewType(true);
            const val = event.target.value;
            updateDeviceFrameClass(val);
            setPreviewType(val);
            setChangePreviewType(false);
        }
    };

    const updateDeviceFrameClass = (val: string) => {
        const previewFrame = document.getElementById("bui-editor-preview");
        if (previewFrame) {
            const classesToRemove = [
                'bb-p-desktop',
                'bb-p-tablet-landscape',
                'bb-p-tablet-portrait',
                'bb-p-mobile-landscape',
                'bb-p-mobile-portrait'
            ];
            previewFrame.classList.remove(...classesToRemove);
            previewFrame.classList.add(val);
        }
    };

    const fetchData = React.useCallback(async () => {
        if (appId) {
            setPreviewLoaded(false);
            const contents = await getPreviewContent(get)(appId);
            storePreviewModelInLocalStorage(contents, pageName, page, queryParamName, theme);
            setPreviewLoaded(true);
        }
    }, [appId, get, pageName, page, theme, queryParamName]);

    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, [appId, get, fetchData]);

    useEffect(() => {
        setChangePreviewType(true);
        updateDeviceFrameClass(previewType);
        setChangePreviewType(false);
    }, [previewType, changePreviewType]);

    if (!previewLoaded || changePreviewType) {
        return <Box className="bb-tac bb-button-loader"><CircularProgress className="bb-prev-load"/></Box>;
    }
    return (
        <>
            <Tooltip title="Preview your app" placement="top">
                <Button variant="outlined" startIcon={<RemoveRedEyeOutlinedIcon/>} onClick={handleOpen}></Button>
            </Tooltip>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div>
                    <Box sx={style} className="bb-preview-modal">
                        <Box className="bb-flex bb-mb-3">
                            <Typography id="modal-modal-title" variant="h4" component="h2">
                                App Preview
                            </Typography>
                            <Button className="bb-ml-auto bb-dark-icon bb-m0 bb-p0" onClick={handleClose}><CloseIcon></CloseIcon></Button>
                        </Box>
                        <FormControl fullWidth className="bb-mb-3">
                            <InputLabel id="preview-type">Preview Type</InputLabel>
                            <Select
                                labelId="preview-type"
                                value={previewType}
                                defaultValue={"bb-p-desktop"}
                                label="Preview Type"
                                onChange={handlePreviewChange}
                            >
                                <MenuItem value={"bb-p-desktop"}>Desktop</MenuItem>
                                <MenuItem value={"bb-p-mobile-portrait"}>Mobile Portrait</MenuItem>
                                <MenuItem value={"bb-p-tablet-portrait"}>Tablet Portrait</MenuItem>
                                <MenuItem value={"bb-p-tablet-landscape"}>Tablet Landscape</MenuItem>
                                <MenuItem value={"bb-p-mobile-landscape"}>Mobile Landscape</MenuItem>
                            </Select>
                        </FormControl>
                        <iframe
                            title={"Preview"}
                            id="bui-editor-preview"
                            src={editorPath}
                            className="builder-iframe bb-preview-frame bb-m-auto">
                        </iframe>
                    </Box>
                </div>
            </Modal>
        </>
    );
};
