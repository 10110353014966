import React, { useCallback } from "react";

import { Box, IconButton, Collapse, TableCell, TableRow, Typography, Button, Grid } from "@mui/material";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import {
    DeploymentModel, DeploymentProgress,
    DeploymentProgressText,
    DeploymentState,
    DeploymentStateText, DeploymentType
} from "../../../schemas/deployment";

import { DateDisplay } from "../../shared/DateDisplay";

import { DeploymentItemTable } from "./DeploymentItemTable";
import { StagingWarningModal } from "./StagingWarningModal";
import { PublishWarningModal } from "./PublishWarningModal";
import { toast } from "react-toastify";
import useDataClient from "../../../axios/dataClient";
import { resetStaging } from "../../../services/api/FrontendDeploymentService";

export interface DeploymentRowProps {
    rowItem: DeploymentModel;
    canDeploy: boolean;
    submitDeployment: (rowKey: string) => Promise<void>;
    deploymentType: DeploymentType;
    onDelete: () => void;
}

export const DeploymentRow: React.FC<DeploymentRowProps> = ({ rowItem, canDeploy, submitDeployment, deploymentType, onDelete }) => {
    const { post } = useDataClient();
    const [open, setOpen] = React.useState(false);
    const [StagingWarningModalOpen, setStagingWarningModalOpen] = React.useState(false);
    const [PublishWarningModalOpen, setPublishWarningModalOpen] = React.useState(false);
    const handleStagingWarningModalOpen = () => setStagingWarningModalOpen(true);
    const handleStagingWarningModalClose = () => setStagingWarningModalOpen(false);
    const handlePublishWarningModalOpen = () => setPublishWarningModalOpen(true);
    const handlePublishWarningModalClose = () => setPublishWarningModalOpen(false);

    const onResetConfigStaging = useCallback(async () => {
        handleStagingWarningModalClose();
        await resetStaging(post)(deploymentType, rowItem.appId, rowItem.rowKey, {});
        toast.success("Staging has been reset");
        onDelete();
    }, [deploymentType, onDelete, post, rowItem.appId, rowItem.rowKey]);

    const onConfirmDeployment = useCallback(async () => {
        handlePublishWarningModalClose();
        await submitDeployment(rowItem.rowKey);
    }, [rowItem.rowKey, submitDeployment]);

    const row = (deployment: DeploymentModel) => {
        return (
            <React.Fragment>
                <StagingWarningModal
                    open={StagingWarningModalOpen}
                    description={""}
                    onConfirm={onResetConfigStaging}
                    onCancel={handleStagingWarningModalClose}
                />
                <PublishWarningModal
                    open={PublishWarningModalOpen}
                    description={""}
                    onConfirm={onConfirmDeployment}
                    onCancel={handlePublishWarningModalClose}
                />
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                        </IconButton>
                    </TableCell>
                    <TableCell>
                        {deployment.appId}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {DeploymentStateText[deployment.deploymentState]}
                    </TableCell>
                    <TableCell align="left"><DateDisplay value={new Date(deployment.dateCreated)} dateStyle="short" timeStyle="short"/></TableCell>
                    <TableCell align="left">{DeploymentProgressText[deployment.deploymentProgress]}</TableCell>
                    <TableCell align="left">
                        <Button
                            aria-label="deploy"
                            variant="contained"
                            disabled={!canDeploy || deployment.deploymentState === DeploymentState.Live}
                            onClick={handlePublishWarningModalOpen}>
                            PUBLISH
                        </Button>
                    </TableCell>
                    <TableCell align="left">
                        <Grid item xs={1}>
                            <Button
                                size="small"
                                onClick={handleStagingWarningModalOpen}
                                disabled={(deployment.deploymentState === DeploymentState.Live)
                                    && (deployment.deploymentProgress === DeploymentProgress.Completed ||
                                        deployment.deploymentProgress === DeploymentProgress.InProgress)
                                }>Reset Staging</Button>
                        </Grid>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box className="bb-p-3">
                                <Typography variant="h4" gutterBottom component="div" className="bb-p-1">
                                    Deployment Details
                                </Typography>
                                <DeploymentItemTable deploymentItems={deployment.deploymentItemModels}></DeploymentItemTable>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    };

    return (row(rowItem));
};
