import React from "react";

import { TableCell, TableHead, TableRow } from "@mui/material";

export const DeploymentTableHeaders = () => {
    return (
        <TableHead>
            <TableRow >
                <TableCell />
                <TableCell>App</TableCell>
                <TableCell>Deployment State</TableCell>
                <TableCell>Date Created</TableCell>
                <TableCell>Deployment Progress</TableCell>
                <TableCell>Deploy</TableCell>
                <TableCell>Reset</TableCell>
            </TableRow>
        </TableHead>
    );
};
