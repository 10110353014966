import { Schedule } from "../../schemas/eventManagement/scheduleSchema";

const getSchedules = <schema>(get: <T>(url: string) => Promise<T>) => async (eventId: string) => {
    const result = await get<schema>(`/api/event/${eventId}/schedule`);
    return result;
};

const getSchedule = <schema>(get: <T>(url: string) => Promise<T>) => async (eventId: string, scheduleId: string) => {
    const result = await get<schema>(`/api/event/${eventId}/schedule/${scheduleId}`);
    return result;
};

const createSchedule = <schema>(post: <T>(url: string, body: any) => Promise<T>) => async (eventId: string, form: Schedule) => {
    const result = await post<schema>(`/api/event/${eventId}/schedule`, form);
    return result;
};

const updateSchedule = <schema>(put: <T>(url: string, body: any) => Promise<T>) => async (eventId: string, scheduleId: string, form: Schedule) => {
    const result = await put<schema>(`/api/event/${eventId}/schedule/${scheduleId}`, form);
    return result;
};

const deleteSchedule = <schema>(deleteRequest: <T>(url: string, body: any) => Promise<T>) => async (eventId: string, scheduleId: string) => {
    const result = await deleteRequest<schema>(`/api/event/${eventId}/schedule/${scheduleId}`, {});
    return result;
};

export { getSchedules, getSchedule, createSchedule, updateSchedule, deleteSchedule };
