/* eslint-disable max-len */

import * as React from "react";
import "./css/bb-panels-preview.css";
import { Box, CardContent, Card, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
export interface BBSliderProps {
    panel: any;
}
export const BBSlider: React.FC<BBSliderProps> = ({ panel }) => {
    let responsive = "";
    if (panel?.slideResponsive === true) {
        responsive = "Yes";
    }
    else {
        responsive = "No";
    }
    let card = "";
    if (panel?.bbCard === true) {
        card = "Yes";
    }
    else {
        card = "No";
    }
    let Arrows = "";
    if (panel?.directionArrows === "arrows-on") {
        Arrows = "Yes";
    }
    else {
        Arrows = "No";
    }
    let slideCount ="";
    if (panel?.slideCol === "bb-one") {
        slideCount = "1";
    }
    if (panel?.slideCol === "bb-two") {
        slideCount = "2";
    }
    if (panel?.slideCol === "bb-three") {
        slideCount = "3";
    }
    if (panel?.slideCol === "bb-four") {
        slideCount = "4";
    }
    if (panel?.slideCol === "bb-five") {
        slideCount = "5";
    }
    if (panel?.slideCol === "bb-six") {
        slideCount = "6";
    }
    if (panel?.slideCol === "bb-seven") {
        slideCount = "7";
    }
    return (<Box>
        {panel?.imgUrl ? (<img src={panel?.imgUrl} className="bb-pe-img, bb-width-100" />) : (<span></span>)}
        {panel?.panelHeading ? (<h3 className="bb-tal">{panel?.panelHeading}</h3>) : (<span></span>)}
        {panel?.panelHeading ? (<p className="bb-tal">{panel?.panelPara}</p>) : (<span></span>)}
        <Box sx={{ marginBottom: "1rem" }}>
            <Box className="slider">
                <Box className="slides">
                    {panel?.links?.map((link: any, index: number) => (
                        <Box key={index} className="slide-cont">
                            <Box id={`slide-${index +1}`}>
                                <Card key={index} sx={{ width: "210px", margin: "0.5rem" }} className="bb-slider-card">
                                    <CardContent sx={{ borderBottom: "1px solid #eee", background: "#eee", padding: "0", margin: "0" }}>
                                        <img src={link.linkThumbUrl} alt={link.linkText} className="bb-slider-img bb-width-100" />
                                        <span className="slide-number">{index +1}</span>
                                    </CardContent>
                                    <CardContent sx={{ padding: ".25rem 1rem", textAlign: "center" }}>
                                        <h5><a href={link.linkText}>{link.linkText}</a></h5>
                                        <p>{panel?.id}</p>
                                    </CardContent>
                                </Card>
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box className="slider-links-container">
                    {panel?.links?.map((link: any, index: number) => (
                        <p key={index} className="slide-link">{index +1}</p>
                    ))}
                </Box>
            </Box>
        </Box>
        <Box sx={{ borderTop: "1px solid #eee" }}>
            <Box className="bb-flex bb-align-items-center bb-justify-content-center bb-mt-2" sx={{ alignItems: "bottom" }}>
                <h3 className="bb-header-with-icon">Slider </h3><Tooltip title="Slider will stretch to the size of its container, and there are many settings available."placement="top"><InfoOutlinedIcon className="bb-header-icon"></InfoOutlinedIcon></Tooltip>
            </Box>
            <ul className="bb-pe-list bb-justify-content-center">
                <li className="bb-flex bb-align-items-center">
                    <Tooltip title="Displays each slide as a ui card with a white background." placement="top">
                        <InfoOutlinedIcon className="bb-tip-icon"></InfoOutlinedIcon>
                    </Tooltip>
                    <span className="bb-pe-setting">Card</span>: <b>&nbsp;{card}</b>
                </li>
                <li className="bb-flex bb-align-items-center">
                    <Tooltip title="Directional navigational arrows." placement="top">
                        <InfoOutlinedIcon className="bb-tip-icon"></InfoOutlinedIcon>
                    </Tooltip>
                    <span className="bb-pe-setting">Arrows</span>: <b>&nbsp;{Arrows}</b>
                </li>
                <li className="bb-flex bb-align-items-center">
                    <Tooltip title="Number of slides on screen at one time." placement="top">
                        <InfoOutlinedIcon className="bb-tip-icon"></InfoOutlinedIcon>
                    </Tooltip>
                    <span className="bb-pe-setting">Slides Visible</span>: <b>&nbsp;{slideCount}</b>
                </li>
                <li className="bb-flex bb-align-items-center">
                    <Tooltip title="Visible slides reduce inline with screen size." placement="top">
                        <InfoOutlinedIcon className="bb-tip-icon"></InfoOutlinedIcon>
                    </Tooltip>
                    <span className="bb-pe-setting">Responsive</span>: <b>&nbsp;{responsive}</b>
                </li>
            </ul>
        </Box>
    </Box>
    );
};
