import axios from "axios";

import { FrontendListModel } from "../model/frontend";
import { AppViewModel } from "../model/apps/AppViewModel";
import { FrontendCreateModel, FrontendEditModel } from "../../yup-schemas/frontend-schema";

const getFrontends = async (getFunction: <T>(url: string) => Promise<T>) => {
    const result = await getFunction<FrontendListModel[]>("/api/frontend");
    return result;
};

const getApp = (get: <T>(url: string) => Promise<T>) => async (appId: string) => {
    const result = await get<AppViewModel>(`/api/frontend/apps/${appId}`);
    return result;
};

const getName = (get: <T>(url: string) => Promise<T>) => async (appId: string) => {
    const result = await get<string>(`/api/frontend/apps/${appId}/getname`);
    return result;
};

const getFrontend = async (customerId: string, frontendId: string) => {
    const result = await axios.get<FrontendEditModel>(`/api/frontend/${customerId}/${frontendId}`);
    return result.data;
};

const createFrontend = async (customerId: string, frontend: FrontendCreateModel) => {
    await axios.post<FrontendListModel>(`/api/frontend/${customerId}/${frontend.id}`, frontend);
};

const editFrontend = async (customerId: string, frontendId: string, frontend: FrontendEditModel) => {
    await axios.put<FrontendListModel>(`/api/frontend/${customerId}/${frontendId}`, frontend);
};

const deleteFrontend = async (customerId: string, frontendId: string) => {
    await axios.delete(`/api/frontend/${customerId}/${frontendId}`);
};

const loadTemplates = async () => {
    const result = await axios.get("/api/frontend/templates");
    return result.data;
};

export {
    getFrontends,
    getFrontend,
    getApp,
    getName,
    createFrontend,
    deleteFrontend,
    editFrontend,
    loadTemplates
};
