import * as yup from "yup";

export type Venue = {
    rowKey?: string;
    venueName?: string;
    eventType?: EventType | string;
    venueLocation?: string;
    onlineLocation?: string;
    timestamp?: Date;
    deleted?: boolean;
};

export enum EventType {
    Virtual = 0,
    InPerson = 1,
    InPersonAndVirtual = 2
};

export const EventTypeText = {
    [EventType.Virtual]: "Virtual",
    [EventType.InPerson]: "In Person",
    [EventType.InPersonAndVirtual]: "In Person and Virtual"
};

export const VenueSchema: yup.SchemaOf<Venue> = yup.object({
    rowKey: yup.string().notRequired(),
    venueName: yup.string().required(),
    eventType: yup.mixed<EventType | string>().oneOf([...Object.values(EventType), ...Object.keys(EventTypeText)]).notRequired(),
    venueLocation: yup.string().notRequired(),
    onlineLocation: yup.string().notRequired(),
    timestamp: yup.date().notRequired(),
    deleted: yup.boolean().notRequired()
});

