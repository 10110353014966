/* eslint-disable @typescript-eslint/no-non-null-assertion */

import {
    Alert,
    CircularProgress
} from "@mui/material";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
    GridRowsProp,
    GridRowModesModel,
    GridRowModes,
    DataGrid,
    GridColDef,
    GridToolbarContainer,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    GridRowEditStopReasons,
    GridSlots,
    GridValidRowModel,
    useGridApiRef,
    GridToolbarQuickFilter,
    GridCellParams,
    GridToolbarExport
} from '@mui/x-data-grid';

import { useParams, Link } from "react-router-dom";

import React, { useState, useEffect, useCallback, FC, useContext } from "react";

import {
    randomInt,
    randomId
} from '@mui/x-data-grid-generator';

import { IRowKeyPair } from "../../services/model/dataManagement/IRowKeyPair";

import useDataClient from "../../axios/dataClient";

import { getTable, deleteRows, saveRow } from "../../services/api/DataManagementService";

import { ITableEditModel } from "../../services/model/dataManagement/ITableEditModel";
import { createJsonObject, createObjectFromRow } from "./rjsfSchemaGenerator";
import { JsonFormModal } from "./JsonFormModal";
import { toast } from "react-toastify";
import { MemberAppContext } from "../../MemberAppContext";

import { FavoriteProvider } from '../favorites/FavoriteContext';
import { FavoriteButton } from '../favorites/FavoriteButton';

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
    handleDeleteSelectedRows: any;
    data: ITableEditModel | null;
    disableDelete?: boolean;
    disableCreate?: boolean;
}

function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel, handleDeleteSelectedRows, data } = props;

    const handleClick = () => {
        const id = randomInt(100000, 1000000);
        const rowKey = randomId();
        setRows((oldRows) => [{ id, Timestamp: new Date(), PartitionKey: "DataManagement", RowKey: rowKey }, ...oldRows]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'Address' },
        }));
    };

    const handleDelete = () => {
        handleDeleteSelectedRows();
    };

    return (
        <GridToolbarContainer>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <GridToolbarQuickFilter />
                </Box>
                <Box>
                </Box>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    {!data?.isReadOnly && (
                        <Box>
                            {  !props.disableCreate &&
                            <Button startIcon={<AddIcon />} onClick={handleClick} style={{ color: 'var(--bb-primary)', marginRight: '5px' }}>
                                Create a new record
                            </Button>
                            }
                            {  !props.disableDelete &&
                            <Button startIcon={<DeleteIcon />} onClick={handleDelete} style={{ color: 'red' }}>
                                Delete selected
                            </Button>
                            }
                        </Box>
                    )}
                    <GridToolbarExport csvOptions={{ fileName: data?.displayName ?? "export" }} />
                </Box>
            </Box>
        </GridToolbarContainer>
    );
}

export interface ViewTableProps {}
export const ViewTable: FC<ViewTableProps> = () => {
    const { get, put, deleteRequest } = useDataClient();
    const appContext = useContext(MemberAppContext);

    const gridRef = React.useRef<any>(null);
    const apiRef = useGridApiRef();

    const { tableId } = useParams<{ tableId: string }>();

    const [data, setData] = React.useState<ITableEditModel | null>(null);
    const [dataSchema, setDataSchema] = React.useState<any>(null);
    const [dataObject, setDataObject] = React.useState<any>(null);
    const [showRowForm, setShowRowForm] = React.useState<boolean>(false);
    const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);
    const [deleteCandidate, setDeleteCandidate] = React.useState<GridRowId | GridRowId[]>([]);

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const [columns, setColumns] = React.useState<GridColDef[]>([]);
    const [rows, setRows] = React.useState<GridValidRowModel[]>([]);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
        PartitionKey: false,
        RowKey: false,
    });

    const handleColumnVisibilityModelChange = (newModel: { [key: string]: boolean }) => {
        const updatedModel = {
            ...newModel,
            PartitionKey: false,
            RowKey: false,
        };
        setColumnVisibilityModel(updatedModel);
    };

    useEffect(() => {
        if (tableId) {
            const fetchData = async () => {
                const result = await getTable(get, tableId!);
                setData(result);
            };
            fetchData();
        }
    }, [get, tableId]);

    const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleDeleteSelectedRows = async () => {
        setDeleteCandidate(selectionModel);
        setOpen(true);
    };

    const handleActualDelete = async () => {
        if (Array.isArray(deleteCandidate) && deleteCandidate.length > 0) {
            const rowKeyPairs: IRowKeyPair[] = deleteCandidate.map(id => {
                const row = rows.find(row1 => row1.id === id);
                return { PartitionKey: row!.PartitionKey, RowKey: row!.RowKey };
            });

            try {
                await deleteRows(deleteRequest, tableId!, rowKeyPairs);
                // Filter out the deleted rows and update the state
                setRows(rows.filter(row1 => !deleteCandidate.includes(row1.id)));
                setSelectionModel([]);
                setDeleteCandidate([]);
            } catch (error) {
                console.error("Failed to delete rows:", error);
            } finally {
                handleClose();
            }
        } else {
            handleClose();
        }
    };

    const handleEditClick = useCallback((id: GridRowId, enableForm?: boolean) => () => {
        if (!enableForm) {
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
        } else {
            const schema = createJsonObject(data!);
            const row = createObjectFromRow(data?.rows.find(row1 => row1.id === id), data!.columns);
            setDataSchema(schema);
            setDataObject(row);
            setShowRowForm(true);
        }
    }, [rowModesModel, setRowModesModel, data]);

    const handleSaveClick = useCallback((id: GridRowId) => async () => {
        const updatedRow = apiRef.current.getRowWithUpdatedValues(id, "");

        const rowKeyPair: IRowKeyPair = await saveRow(put, tableId!, updatedRow);

        setRows((prevRows) => prevRows.map((row) => {
            if (row.id === id) {
                return { ...row, PartitionKey: rowKeyPair.PartitionKey, RowKey: rowKeyPair.RowKey };
            }
            return row;
        }));

        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    }, [rowModesModel, setRowModesModel, put, tableId, apiRef]);

    const getRowByProperty = useCallback((property: string, value: any) => {
        const allRows = apiRef.current.getRowModels();
        for (const [id, row] of allRows) {
            if (row[property] === value) {
                return apiRef.current.getRow(id);
            }
        }
        return null;
    }, [apiRef]);

    const handleFormSaveClick = useCallback(async (rowData: any) => {
        const { ...updatedValues } = rowData.formData;
        const existingRow = getRowByProperty("RowKey", rowData.formData.RowKey);
        const updatedRow = { ...existingRow, ...updatedValues };
        const rowKeyPair: IRowKeyPair = await saveRow(put, tableId!, updatedRow);

        setRows((prevRows) => prevRows.map((row) => {
            if (row.id === existingRow.id) {
                return { ...row, ...updatedValues, PartitionKey: rowKeyPair.PartitionKey, RowKey: rowKeyPair.RowKey, RowStatus: "Pending" };
            }
            return row;
        }));

        setRowModesModel((prevModel) => ({ ...prevModel, [existingRow.id]: { mode: GridRowModes.View } }));
        setShowRowForm(false);
        toast.success("Table row has been updated...");
    }, [put, setRowModesModel, setShowRowForm, setRows, tableId, getRowByProperty]);

    const handleDeleteClick = useCallback((id: GridRowId) => async () => {
        setDeleteCandidate([id]);
        setOpen(true);
    }, []);

    const handleCancelClick = useCallback((id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow!.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    }, [rowModesModel, setRowModesModel, rows]);

    const isRowPendingApproval = useCallback((rowId: GridRowId) => {
        const row = rows.find(r => r.id === rowId);
        return row?.RowStatus === "Pending";
    }, [rows]);

    const isCellEditable = (params: GridCellParams) => {
        return params.row.RowStatus !== 'Pending';
    };

    const actionColumn = React.useMemo(() => ({
        field: 'actions',
        type: 'actions' as const,
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id }: { id: GridRowId }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
            const isPendingApproval = isRowPendingApproval(id);

            let gridActions: any[] = [];
            if (!isPendingApproval) {
                gridActions = [
                    <GridActionsCellItem
                        key={`${id}-edit`}
                        icon={<EditIcon style={{ color: 'cornflowerblue' }}/>}
                        label="Edit"
                        onClick={handleEditClick(id, appContext.user.isPartnerUser)}
                    />
                ];
            }

            if (!appContext.user.isPartnerUser && !isPendingApproval) {
                gridActions.push(
                    <GridActionsCellItem
                        key={`${id}-delete`}
                        icon={<DeleteIcon style={{ color: 'red' }} />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                    />
                );
            }

            if (isInEditMode && !isPendingApproval) {
                return [
                    <GridActionsCellItem
                        key={`${id}-save`}
                        icon={<SaveIcon style={{ color: 'var(--bb-primary)' }} />}
                        label="Save"
                        onClick={handleSaveClick(id)} />,
                    <GridActionsCellItem
                        key={`${id}-cancel`}
                        icon={<CancelIcon style={{ color: 'orange' }} />}
                        label="Cancel"
                        onClick={handleCancelClick(id)} />
                ];
            }

            return gridActions;
        },
    }), [rowModesModel, handleSaveClick, handleCancelClick, handleEditClick, handleDeleteClick, appContext.user.isPartnerUser, isRowPendingApproval]);

    useEffect(() => {
        if (data && data.rows) {
            setRows(data.rows);
        }
    }, [data]);

    useEffect(() => {
        if (data && data.columns) {
            const newColumns: GridColDef[] = [
                ...(data.isReadOnly ? [] : [actionColumn]),
                ...data.columns.map(column => ({
                    ...column,
                    editable: !data.isReadOnly && column.field !== "Timestamp",
                    hideable: !(column.field === 'PartitionKey' || column.field === 'RowKey'),
                    ...(column.type === "dateTime" ? {
                        valueGetter: (value: string) => new Date(value),
                    } : {})
                }))
            ];
            setColumns(newColumns);
        }
    }, [data, actionColumn]);

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleCellKeyDown: GridEventListener<'cellKeyDown'> = (
        params: GridCellParams,
        event: React.KeyboardEvent
    ) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();

            const columnFields = apiRef.current.getAllColumns().map(col => col.field);
            const currentColumnIndex = columnFields.indexOf(params.field);

            if (currentColumnIndex < columnFields.length - 1) {
                const nextField = columnFields[currentColumnIndex + 1];
                apiRef.current.setCellFocus(params.id, nextField);
            }
        }
    };

    const isRowSelectable = (params: GridRowModel) => {
        return params.row.RowStatus !== 'Pending';
    };

    if (!data) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <FavoriteProvider>
            <Box>
                <Box className="bb-title-bar bb-mt-3">
                    <Typography variant="h2" className="bb-m0 bb-p0">{data?.displayName ?? ""}</Typography>
                    <FavoriteButton page={`data/${tableId}`} displayName={`Data Management`} displayModule={`${data?.displayName}`} />
                </Box>
                <Breadcrumbs className="bb-breadcrumb bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
                    <Link to="/data">Tables</Link>
                    <Typography color="text.primary" className="bb-m0 bb-p0 bb-crumb-text">Directory: {data?.displayName ?? ""}</Typography>
                </Breadcrumbs>
                {!data?.isReadOnly && (
                    <Alert className="bb-title-info bb-mb-2" severity="info">Please note, you are managing <b>live data</b>.</Alert>
                )}
                <Box className="bb-width-100 bb-flex">
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle id="alert-dialog-title" className="bb-error-dialog-header bb-flex bb-align-items-center">
                            <WarningAmberIcon className="bb-mr-1"></WarningAmberIcon> <h2 className="bb-m0 bb-p0">{"WARNING!"}</h2>
                        </DialogTitle>
                        <DialogContent className="bb-global-modal">
                            <DialogContentText className="bb-tac">
                                <h2>Are you really sure?</h2>
                            This will permenantly delete your content.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>
                            Cancel
                            </Button>
                            <Button variant="contained" onClick={handleActualDelete} className="bb-ml-auto">
                            Yes, Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
                <Box style={{ height: "70vh", width: "100%" }}>
                    <DataGrid
                        apiRef={apiRef}
                        ref={gridRef}
                        style={{ flexGrow: 1 }}
                        rows={rows}
                        rowHeight={30}
                        columns={columns}
                        checkboxSelection={!data.isReadOnly && !appContext.user.isPartnerUser}
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        onRowEditStop={handleRowEditStop}
                        processRowUpdate={processRowUpdate}
                        editMode="row"
                        onCellKeyDown={handleCellKeyDown}
                        isRowSelectable={isRowSelectable}
                        slots={{
                            toolbar: EditToolbar as GridSlots['toolbar'],
                        }}
                        slotProps={{
                            toolbar: {
                                setRows,
                                setRowModesModel,
                                handleDeleteSelectedRows,
                                data,
                                showQuickFilter: true,
                                disableDelete: appContext.user.isPartnerUser ,
                                disableCreate: appContext.user.isPartnerUser
                            },
                        }}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    PartitionKey: false,
                                    RowKey: false,
                                },
                            },
                        }}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
                        onRowSelectionModelChange={(newSelectionModel: any) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        isCellEditable={isCellEditable}
                    />
                </Box>
                { showRowForm &&
                    <JsonFormModal
                        open={showRowForm}
                        onUpdate={handleFormSaveClick}
                        onCancel={() => { setShowRowForm(false); }}
                        schema={dataSchema}
                        data={dataObject}
                    ></JsonFormModal>
                }
            </Box>
        </FavoriteProvider>);
};
