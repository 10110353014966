import * as React from "react";
import { Container, Box, Button } from "@mui/material";
import './BBRte.css';

export interface BBRteProps {
    panel: any;
}

const BBRte: React.FC<BBRteProps> = ({ panel }) => {
    let buttonBG = "";
    if (panel?.ButtonBgColor === "") {
        buttonBG = "#000000";
    } else {
        buttonBG = panel?.buttonBgColor;
    }

    let buttonTextColor = "";
    if (panel?.buttonTextColor === "") {
        buttonTextColor = "#ffffff";
    } else {
        buttonTextColor = panel?.buttonTextColor;
    }

    let buttonText = "";
    if (panel?.buttonText === "") {
        buttonText = "Button";
    } else {
        buttonText = panel?.buttonText;
    }

    const buttonVisible = panel?.buttonVisible === true;

    return (
        <>
            <Container>
                <Box>
                    {panel?.imgUrl ? (<img src={panel?.imgUrl} className="bb-rte-img" />) : (<span></span>)}
                </Box>
                <Box>
                    {panel?.panelHeading ? (<h3 className="bb-tal">{panel?.panelHeading}</h3>) : (<span></span>)}
                    {panel?.panelPara ? (<p className="bb-tal">{panel?.panelPara}</p>) : (<span></span>)}
                    <div className="bbHTML" dangerouslySetInnerHTML={{ __html: panel?.rteBody }}></div>
                </Box>
                <Box sx={{ marginTop: '10px' }}>
                    {buttonVisible && (
                        <Button style={{ backgroundColor: buttonBG, color: buttonTextColor }}>
                            {buttonText}
                        </Button>
                    )}
                </Box>
            </Container>
        </>
    );
};

export { BBRte };
