import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

interface GoOfflineDialogProps {
    open: boolean;
    onCancel: () => void;
    onGoOffline: () => void;
}

export const GoOfflineDialog = ({ open, onCancel, onGoOffline }: GoOfflineDialogProps) => {
    return (
        <Dialog open={open} maxWidth="sm">
            <DialogTitle className="bbot-dialog-title">
                <Typography>Go Offline
                </Typography>
            </DialogTitle>
            <DialogContent dividers className="bbot-dialog-content">
                <Typography>
                You are about to go offline, which will mean that no
                more chats will be directed to you. However you will
                have to end all active conversations, before you can
                leave the Live Agent. Do you wish to continue?
                </Typography>
            </DialogContent>
            <DialogActions className="bbot-dialog-actions">
                <Button color="warning" onClick={onCancel}>
                Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onGoOffline}
                >
                Submit
                </Button>
            </DialogActions>
        </Dialog>);
};
