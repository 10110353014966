export interface FileImport {
    fileName: string;
    dateCreated: Date;
    importState: ImportState;
    totalRecords?: number;
    importedRecords?: number;
    errorRecords?: number;
    errorFileLocation?: string;
};

export enum ImportState {
    Uploaded = 0,
    Started = 10,
    Completed = 20,
    CompletedWithErrors = 30
}

export const ImportStateText = {
    [ImportState.Uploaded]: "File Uploaded",
    [ImportState.Started]: "In-Progress",
    [ImportState.Completed]: "Completed",
    [ImportState.CompletedWithErrors]: "Completed with errors"
};

export interface PartnerUser {
    id?: string;
    firstName: string;
    lastName: string;
    partnerId: string;
    email: string;
    roles?: any[];
    teamIds?: string[];
    isActive: boolean;
    inviteEmailSent: boolean;
}

export interface PartnerUserDetails extends PartnerUser {
    tableNames: string;
    rowIdentifiers: string;
    displayName: string;
    lastLoginDate?: Date;
}

export interface Partner {
    rowKey?: string;
    partnerName: string;
    registeredAddress?: string;
    contactNumber?: string;
    contactEmail?: string;
    website?: string;
    partnerLogo?: string;
    serviceDescription?: string;
}

export interface Schema {
    users: PartnerUserDetails[];
}
