import * as React from "react";
import { Box, Modal, Card, CardContent, CardMedia, Typography, IconButton } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CurrencyPoundOutlinedIcon from '@mui/icons-material/CurrencyPoundOutlined';
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CloseIcon from "@mui/icons-material/Close";

export interface EventPreviewModalProps {
    event: any;
    open: boolean;
    onClose: () => void;
}

export const EventPreviewModal: React.FC<EventPreviewModalProps> = ({ event, open, onClose }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <Box sx={{ position: 'relative', backgroundColor: '#F8F7FF', padding: '20px', borderRadius: '4px', boxShadow: 24 }}>
                    <Typography variant="h3" component="h3" sx={{ marginBottom: '20px' }}>
                        Event Preview
                    </Typography>
                    <IconButton
                        sx={{ position: 'absolute', top: '10px', right: '10px',
                            backgroundColor: '#11a681', color: 'white', '&:hover': { backgroundColor: '#0e8e6f' } }}
                        onClick={onClose}
                    >
                        <CloseIcon sx={{ fontSize: 24 }} />
                    </IconButton>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Card sx={{ maxWidth: 600, margin: '2rem', borderRadius: '4px!important', width: '50%', minWidth: '50%' }}>
                            <CardMedia
                                component="img"
                                height="140"
                                image={event.eventLogo}
                                alt={event.eventName}
                            />
                            <CardContent>
                                <Typography variant="h4" component="div" sx={{ marginBottom: '10px' }}>
                                    {event.eventName}
                                </Typography>
                                <Typography
                                    color="text.secondary"
                                    sx={{ marginBottom: '15px!important' }}
                                    dangerouslySetInnerHTML={{ __html: event.eventDescription }} // Use dangerouslySetInnerHTML
                                />
                                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                    <CalendarTodayIcon />
                                    <Typography variant="body2" color="text.secondary" sx={{ marginLeft: '8px' }}>
                                    Event Date
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                                    <CurrencyPoundOutlinedIcon />
                                    <Typography variant="body2" color="text.secondary" sx={{ marginLeft: '8px' }}>
                                    Event Price
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                                    <LocationOnIcon />
                                    <Typography variant="body2" color="text.secondary" sx={{ marginLeft: '8px' }}>
                                    Event Location
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};
