import React, { useState, useCallback } from 'react';
import { Alert } from "@mui/material";
import { VideoComponent } from '../../shared/VideoComponent';

const TrainingVideoWidget = (props: any) => {
    const { description, linkText, linkUrl } = props.schema;
    const [isVideoOpen, setIsVideoOpen] = useState(false);

    const handleOpenVideo = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        setIsVideoOpen(true);
    }, []);

    const handleCloseVideo = useCallback(() => {
        setIsVideoOpen(false);
    }, []);

    return (
        <>
            <Alert className="bb-title-info bb-mb-2" severity="info">
                {description} <a href="#" onClick={handleOpenVideo}>{linkText}</a>
            </Alert>
            <VideoComponent
                videoUrl={linkUrl}
                isOpen={isVideoOpen}
                onClose={handleCloseVideo}
            />
        </>
    );
};

export { TrainingVideoWidget };
