import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

interface SetAwayDialogProps {
    open: boolean;
    onCancel: () => void;
    onNotifications: () => void;
    onNoNotifications: () => void;
}

export const SetAwayDialog = ({ open, onNotifications, onNoNotifications, onCancel }: SetAwayDialogProps) => {
    return (<Dialog open={open}>
        <DialogTitle className="bbot-dialog-title">
            <Typography>Away</Typography>
        </DialogTitle>
        <DialogContent className="bbot-dialog-content bb-global-modal">
            <Typography>Would you like to recieve notifications?</Typography>
        </DialogContent>
        <DialogActions className="bbot-dialog-actions">
            <Button onClick={onCancel}>Cancel</Button>
            <Button onClick={onNotifications}>Notifications</Button>
            <Button onClick={onNoNotifications}>Silent</Button>
        </DialogActions>
    </Dialog>);
};
