import { Link } from "react-router-dom";

import {
    Container,
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    TablePagination,
    TableHead,
    CircularProgress,
    Select,
    MenuItem,
    Typography,
    Breadcrumbs,
    Alert,
    Tooltip
} from "@mui/material";

import { SelectChangeEvent } from '@mui/material/Select';

import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

import { useState, useEffect, useCallback } from "react";

import { SearchField } from "../../applications/customWidget/Search/SearchField";

import useDataClient from "../../../axios/dataClient";

import { stringSort } from "../../../services/helper/sort";

import { Event, EventBookerTypeText } from "../../../schemas/eventManagement/eventSchema";

import { getEvents } from "../../../services/api/EventManagementService";

import { ResultListModel } from "../../../schemas/eventManagement/resultSchema";

import { newEvent } from "../../newModuleNames";

import { errors } from "../../ErrorDisplay";

import { EventListPreview } from "./EventListPreview";

import { FavoriteProvider } from '../../../components/favorites/FavoriteContext';
import { FavoriteButton } from '../../../components/favorites/FavoriteButton';

export const EventList: React.FC = () => {
    const { get } = useDataClient();

    const [events, setEvents] = useState<Event[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [isLoading, setLoading] = useState(false);
    const [searchString, setSearchString] = useState<string>();
    const [filteredEvents, setFilteredEvents] = useState<Event[]>([]);

    const filterShowAll = "ShowAll";
    const filterShowNonDeletedOnly = "filterShowNonDeletedOnly";
    const [listFilter, setListFilter] = useState(filterShowNonDeletedOnly);

    const callSearch = useCallback((search?: string) => {
        setSearchString(search);
    }, [setSearchString]);

    const handleFilterChange = (event: SelectChangeEvent) => {
        setListFilter(event.target.value as string);
    };

    useEffect(() => {
        if (!events) {
            return;
        }

        const searchStringUpper = searchString?.toUpperCase();
        setCurrentPage(0);
        const filtered = events.filter((event) =>
            (!searchStringUpper || (event?.eventName && event?.eventName.toUpperCase().includes(searchStringUpper)))
            && (listFilter === filterShowAll || event?.deleted !== true));
        setFilteredEvents(filtered);
    }, [searchString, listFilter, events]);

    const fetchData = useCallback(async () => {

        getEvents<ResultListModel<Event>>(get)().then((eventsData) => {
            if (eventsData?.isSuccess && eventsData?.data) {
                setEvents(eventsData.data);
                setFilteredEvents(eventsData.data);
            } else if (eventsData?.messages) {
                errors(eventsData.messages);
            }

            return;
        }).finally(() => {
            setLoading(false);
        });;
    }, [get]);

    useEffect(() => {
        setSearchString("");
    }, []);

    const refreshList = useCallback(() => {
        setLoading(true);
        fetchData();
    }, [fetchData]);

    const handleChangePage = (event: any, newPage: any) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0); // Reset to first page
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            await fetchData();
        })();
    }, [fetchData]);

    return (
        <FavoriteProvider>
            <Container>
                <Box className="bb-title-bar bb-mt-3">
                    <h2 className="bb-m0 bb-p0">Events</h2>
                    <FavoriteButton page={`eventManagement/events/`} displayName={`Your Events`} displayModule={`View Your Events List`} />
                </Box>
                <Breadcrumbs className="bb-breadcrumb bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
                    <Link to="/eventManagement">Event Management</Link>
                    <Typography color="text.primary" className="bb-m0 bb-p0">Events</Typography>
                </Breadcrumbs>
                <Alert className="bb-title-info bb-mb-2 bb-align-items-center bb-flex" severity="info" sx={{ marginTop: '20px!important' }}
                >
                            Welcome to the <b>Events Management Page.</b> Here, you can view and edit all of your events.
                </Alert>
                <Box className="bb-flex bb-ui-box bb-flex-column bb-tac">
                    <Box sx={{ display: "flex", justifyContent: "space-between" }} className="bb-mb-2">
                        <Typography variant="h3" className="bb-m0 bb-p0">Event Manager</Typography>
                        <Box>
                            <Button
                                variant="contained"
                                className="bb-ml-auto bb-primary-button"
                                onClick={refreshList}
                                startIcon={<RefreshOutlinedIcon />}
                                disabled={isLoading}
                                sx={{ marginRight: '10px', color: '#000000!important', backgroundColor: '#ffffff!important', border: '1px solid #11a681' }}
                            >
                        Refresh
                            </Button>
                            <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon" to={`/eventManagement/events/${newEvent}`}>
                                <Button variant="contained">
                            New Event
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                    {isLoading
                        ? (<Box className="bb-tac"><CircularProgress /></Box>)
                        : (
                            <TableContainer component={Paper} sx={{ boxShadow: "none", marginTop: "1rem" }} >
                                <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginBottom: "1rem" }}>
                                    <Box className="bb-med-search bb-ml-2" sx={{ minWidth: 200, marginLeft: '0px!important' }}>
                                        <Select
                                            id="filter-select"
                                            value={listFilter}
                                            onChange={handleFilterChange}
                                            variant="outlined"
                                            fullWidth
                                            sx={{ height: 40 }}
                                        >
                                            <MenuItem value={filterShowAll}>Show All</MenuItem>
                                            <MenuItem value={filterShowNonDeletedOnly}>Hide Deleted Events</MenuItem>
                                        </Select>
                                    </Box>
                                    <Tooltip
                                        title="Search for a Event by Event Name."
                                        arrow
                                        placement="top"
                                        PopperProps={{
                                            sx: {
                                                '& .MuiTooltip-tooltip': {
                                                    backgroundColor: 'black',
                                                    color: 'white',
                                                    fontSize: '.8rem',
                                                    padding: '0.5rem',
                                                },
                                                '& .MuiTooltip-arrow': {
                                                    color: 'black',
                                                },
                                            },
                                        }}>
                                        <Box className="bb-med-search">
                                            <SearchField
                                                searchString={searchString}
                                                callSearch={callSearch}
                                                timeout={500}
                                            />
                                        </Box>
                                    </Tooltip>
                                </Box>
                                <Table aria-label="table" sx={{ padding: "0", margin: "0" }} className="bb-page-list-table bb-global-table">
                                    <TableHead sx={{ borderRadius: "5px 5px 0 0" }}>
                                        <TableRow >
                                            <TableCell>Event Name</TableCell>
                                            <TableCell>Owner</TableCell>
                                            <TableCell>Event Category</TableCell>
                                            <TableCell>Deleted</TableCell>
                                            <TableCell>Preview</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>{
                                        filteredEvents
                                            .sort((a, b) => stringSort(a.eventName, b.eventName))
                                            .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                                            .map((event, index) => {
                                                return (
                                                    <TableRow key={`partnerUser_${index}`} sx={{ padding: "0", margin: "0" }}>
                                                        <Tooltip
                                                            title="View & Manage Event"
                                                            arrow
                                                            placement="top"
                                                            PopperProps={{
                                                                sx: {
                                                                    '& .MuiTooltip-tooltip': {
                                                                        backgroundColor: 'black',
                                                                        color: 'white',
                                                                        fontSize: '.8rem',
                                                                        padding: '0.5rem',
                                                                    },
                                                                    '& .MuiTooltip-arrow': {
                                                                        color: 'black',
                                                                    },
                                                                },
                                                            }}>
                                                            <TableCell sx={{ padding: "0", margin: "0" }}>
                                                                <Box className="bb-ml-2">
                                                                    <Link
                                                                        className="bb-pages-list bb-align-items-center"
                                                                        to={`/eventManagement/events/${event.rowKey}`}
                                                                    >
                                                                        {event.eventName}
                                                                    </Link>
                                                                </Box>
                                                            </TableCell>
                                                        </Tooltip>
                                                        <TableCell sx={{ padding: "0", margin: "0" }}>
                                                            <Box className="bb-ml-2">{EventBookerTypeText[event.eventBookerType]}</Box>
                                                        </TableCell>
                                                        <TableCell sx={{ padding: "0", margin: "0" }}>
                                                            <Box className="bb-ml-2">{event.eventCategory}</Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box className="bb-ml-2">{event.deleted ? "Yes" : "No"}</Box>
                                                        </TableCell>
                                                        <Tooltip
                                                            title="Preview Your Event"
                                                            arrow
                                                            placement="top"
                                                            PopperProps={{
                                                                sx: {
                                                                    '& .MuiTooltip-tooltip': {
                                                                        backgroundColor: 'black',
                                                                        color: 'white',
                                                                        fontSize: '.8rem',
                                                                        padding: '0.5rem',
                                                                    },
                                                                    '& .MuiTooltip-arrow': {
                                                                        color: 'black',
                                                                    },
                                                                },
                                                            }}>
                                                            <TableCell>
                                                                <EventListPreview event={event} />
                                                            </TableCell>
                                                        </Tooltip>
                                                    </TableRow>
                                                );
                                            })
                                    }
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    className="bb-global-table-footer"
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={filteredEvents.length}
                                    rowsPerPage={rowsPerPage}
                                    page={currentPage}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                        )
                    }
                </Box>
            </Container>
        </FavoriteProvider>
    );
};
