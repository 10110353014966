import { Link } from "react-router-dom";

import Typography from "@mui/material/Typography";

import { toast } from "react-toastify";

import {
    Container,
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    TablePagination,
    TableHead,
    styled,
    CircularProgress,
    Alert
} from "@mui/material";

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

import Breadcrumbs from "@mui/material/Breadcrumbs";

import { useState, useEffect, useCallback } from "react";

import useDataClient from "../../axios/dataClient";

import { dateSort } from "../../services/helper/sort";

import { getImports, uploadImportUsers } from "../../services/api/PartnerManagementService";

import { DateDisplay } from "../shared/DateDisplay";

import { error } from "../ErrorDisplay";

import { FileImport, ImportStateText } from "../../schemas/partnerManagement/schema";

import { FavoriteProvider } from '../favorites/FavoriteContext';
import { FavoriteButton } from '../favorites/FavoriteButton';

export const PartnerUserImport = () => {
    const { get, post } = useDataClient();

    const handleDownloadTemplate = () => {
        const csvUrl = "https://bbotliveeunmedia.blob.core.windows.net/pub/CutomerPortalFiles/importPartnerUsers.csv";
        const link = document.createElement('a');
        link.href = csvUrl;
        link.setAttribute('download', 'template.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const [fileImports, setFileImports] = useState<FileImport[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [isLoading, setLoading] = useState(false);

    const HiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const fetchData = useCallback(async () => {

        getImports<FileImport[]>(get)().then((imports) => {
            setFileImports(imports);
            setLoading(false);
            return;
        });
    }, [get]);

    const refreshList = useCallback(() => {
        setLoading(true);
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [fetchData]);

    const handleChangePage = (event: any, newPage: any) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0); // Reset to first page
    };

    const handleFileUpload = useCallback(async (event: any) => {

        if (event.target.files?.length === 1) {
            setLoading(true);

            const form = new FormData();
            form.append("file", event.target.files[0]);
            uploadImportUsers(post)(form).then((result: { errors: any[] }) => {
                if (result?.errors && result.errors.length > 0) {
                    for (const fileError of result.errors) {
                        error(fileError);
                    }

                    return;
                }

                toast.success("File has been queued for import successfully");
                fetchData();
                return;
            }).catch(() => {
                toast.error("File could not be queued for import. Please try again after some time.");
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [fetchData, post]);

    return (
        <FavoriteProvider>
            <Container>
                <Box className="bb-title-bar bb-mt-3">
                    <h2 className="bb-m0 bb-p0">Import Users</h2>
                    <FavoriteButton page={`partnerUserImport/`} displayName={`Partner User Import`} displayModule={`Bulk Import Your Partner Users`} />
                </Box>
                <Breadcrumbs className="bb-breadcrumb bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
                    <Link to="/partnerManagement">Partner Management</Link>
                    <Typography color="text.primary" className="bb-m0 bb-p0">Import Users</Typography>
                </Breadcrumbs>
                <Alert className="bb-title-info bb-mb-2 bb-align-items-center bb-flex" severity="info"
                    sx={{ marginBottom: '2px!important', marginTop: '15px!important' }}
                >
                 Bulk upload your users by downloading the <b>CSV Template</b>, once you have comleted the template, click the <b>Upload Button</b>.
                </Alert>
                <Box className="bb-flex bb-ui-box bb-flex-column bb-tac">
                    <Box className="bb-flex bb-tal bb-align-items-center bb-mb-2">
                        <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon sx={{ color: 'gutter' }} />}
                            sx={{ marginRight: "0.9rem", backgroundColor: 'white', border: '1.1px solid #11a681', color: '#000000',     '&:hover': {
                                backgroundColor: '#11a681',
                                color: 'white'
                            } }}
                            onClick={handleDownloadTemplate}
                        >
                            Download Template
                        </Button>
                        <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon />}
                        >
                        Upload file and import
                            <HiddenInput
                                type="file"
                                onChange={handleFileUpload}
                            />
                        </Button>
                        <Button
                            variant="contained"
                            className="bb-ml-auto bb-primary-button"
                            onClick={refreshList}
                            startIcon={<RefreshOutlinedIcon />}
                            disabled={isLoading}
                        >
                        Refresh
                        </Button>
                    </Box>
                    {isLoading
                        ? (<Box className="bb-tac"><CircularProgress /></Box>)
                        : (
                            <TableContainer component={Paper} sx={{ boxShadow: "none", marginTop: "1rem" }} >
                                <Table aria-label="table" sx={{ padding: "0", margin: "0" }} className="bb-page-list-table bb-global-table">
                                    <TableHead sx={{ borderRadius: "5px 5px 0 0" }}>
                                        <TableRow >
                                            <TableCell>File Name</TableCell>
                                            <TableCell>Import Date</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Records</TableCell>
                                            <TableCell>Completed</TableCell>
                                            <TableCell>Failed</TableCell>
                                            <TableCell>Errors</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>{
                                        fileImports
                                            .sort((a, b) => dateSort(b.dateCreated, a.dateCreated))
                                            .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                                            .map((fileImport, index) => {
                                                return (
                                                    <TableRow key={`fileImport_${index}`} sx={{ padding: "0", margin: "0", height: 60 }}>
                                                        <TableCell sx={{ padding: "0", margin: "0" }}>
                                                            <Box className="bb-ml-2">{fileImport.fileName}</Box>
                                                        </TableCell>
                                                        {fileImport?.dateCreated ?
                                                            (<TableCell>
                                                                <DateDisplay value={new Date(fileImport?.dateCreated)} dateStyle="short" timeStyle="short" />
                                                            </TableCell>)
                                                            : <TableCell></TableCell>
                                                        }
                                                        <TableCell>
                                                            <Box className="bb-ml-2">{ImportStateText[fileImport.importState]}</Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box className="bb-ml-2">{fileImport.totalRecords}</Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box className="bb-ml-2">{fileImport.importedRecords}</Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box className="bb-ml-2">{fileImport.errorRecords}</Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box className="bb-ml-2">
                                                                {fileImport.errorFileLocation &&
                                                                <a href={fileImport.errorFileLocation} download>
                                                                    Download
                                                                </a>
                                                                }
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                    }
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    className="bb-global-table-footer"
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={fileImports.length}
                                    rowsPerPage={rowsPerPage}
                                    page={currentPage}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                        )
                    }
                </Box>
            </Container>
        </FavoriteProvider>
    );
};
