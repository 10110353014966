import MuiTextField, { TextFieldProps } from "@mui/material/TextField";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";

import { useField, useFormikContext } from "formik";
import { useCallback, useMemo } from "react";

interface MuiDateFieldProps {
    name: string;
    label: string;
    placeholder?: string;
    required?: boolean;
}

const DateField = (props: MuiDateFieldProps) => {
    const [field, meta] = useField(props.name);
    const { setFieldValue } = useFormikContext();

    const error: boolean = meta.touched && !!meta.error;
    const errorMessage: string | undefined = error ? meta.error : undefined;

    const onChange = useCallback((value: any) => {
        setFieldValue(field.name, value);
    }, [field, setFieldValue]);

    const renderDatePickInput = useMemo(() => {
        const textFieldProps: TextFieldProps = {
            error: meta.touched && !!meta.error,
            name: field.name,
            value: field.value,
            onBlur: field.onBlur,
            fullWidth: true,
            label: props.label,
            helperText: errorMessage,
            variant: "standard",
            margin: "dense",
            required: props.required,
            placeholder: props.placeholder
        };
        return { textField: textFieldProps };
    }
    , [field, meta, props.required, props.placeholder, props.label, errorMessage]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={props.placeholder}
                value={field.value}
                onChange={onChange}
                format="DD/MM/YYYY"
                slots={{ textField: MuiTextField }}
                slotProps={renderDatePickInput}
            />
        </LocalizationProvider>
    );
};

export { DateField };
