import { Link } from "react-router-dom";
import {
    Container,
    Box,
    Button,
    Select,
    MenuItem,
    Typography,
    Breadcrumbs,
    CircularProgress,
    Alert,
    Tooltip
} from "@mui/material";
import { SelectChangeEvent } from '@mui/material/Select';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useState, useEffect, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import useDataClient from "../../../axios/dataClient";
import { Schedule } from "../../../schemas/eventManagement/scheduleSchema";
import { deleteSchedule, getSchedules } from "../../../services/api/ScheduleManagementService";
import { ResultListModel, ResultModel } from "../../../schemas/eventManagement/resultSchema";
import { newSchedule } from "../../newModuleNames";
import { errors } from "../../ErrorDisplay";
import { DateDisplay } from "../../shared/DateDisplay";
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { SearchField } from "../../applications/customWidget/Search/SearchField";
import { EventDetails } from '../schedule/EventDetails';

import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from "react-toastify";

export const ScheduleList: React.FC = () => {
    const { get, deleteRequest } = useDataClient();
    const { eventId } = useParams();

    const [schedules, setSchedules] = useState<Schedule[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [listFilter, setListFilter] = useState("ShowAllWithoutDeleted");
    const [searchString, setSearchString] = useState<string>();
    const [selectedRows, setSelectedRows] = useState<Schedule[]>([]);

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Box className="bb-med-search bb-ml-2" sx={{ minWidth: 200, marginLeft: '0px!important' }}>
                        <Select
                            id="filter-select"
                            value={listFilter}
                            onChange={handleFilterChange}
                            variant="outlined"
                            fullWidth
                            sx={{ height: 40 }}
                        >
                            <MenuItem value="ShowAll">Show All</MenuItem>
                            <MenuItem value="ShowAllWithoutDeleted">Hide Deleted Schedules</MenuItem>
                        </Select>
                    </Box>
                    <Tooltip
                        title="Search for a schedule by venue name or start date."
                        arrow
                        placement="top"
                        PopperProps={{
                            sx: {
                                '& .MuiTooltip-tooltip': {
                                    backgroundColor: 'black',
                                    color: 'white',
                                    fontSize: '.8rem',
                                    padding: '0.5rem',
                                },
                                '& .MuiTooltip-arrow': {
                                    color: 'black',
                                },
                            },
                        }}>
                        <Box className="bb-med-search" sx={{ paddingLeft: '0px!important', marginLeft: '0px!important' }}>
                            <SearchField
                                searchString={searchString}
                                callSearch={callSearch}
                                timeout={500}
                            />
                        </Box>
                    </Tooltip>
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        color="warning"
                        startIcon={<DeleteIcon />}
                        onClick={handleDelete}
                        disabled={selectedRows.length === 0}
                        sx={{ marginRight: '10px' }}
                    >
                    Delete
                    </Button>
                    <GridToolbarExport />
                </Box>
            </GridToolbarContainer>
        );
    };

    const handleFilterChange = (event: SelectChangeEvent) => {
        setListFilter(event.target.value as string);
    };

    const handleDelete = async () => {
        const selectedIDs = new Set(selectedRows.map((row) => row.rowKey));
        const deletePromises = Array.from(selectedIDs).map((scheduleId) =>
            deleteSchedule<ResultModel>(deleteRequest)(eventId as string, scheduleId as string).then((result) => {
                if (result?.isSuccess) {
                    toast.warning(`Schedule ${scheduleId} has been marked as deleted`);
                    return true;
                } else if (result?.messages) {
                    errors(result.messages);
                    return false;
                }
                return false;
            })
        );

        await Promise.all(deletePromises);
        const newSchedules = schedules.filter((row) => !selectedIDs.has(row.rowKey));
        setSchedules(newSchedules);
    };

    const callSearch = useCallback((search?: string) => {
        setSearchString(search);
    }, [setSearchString]);

    const filteredSchedules = useMemo(() => {
        const searchStringUpper = searchString?.toUpperCase();

        const dateTimeFormatter = new Intl.DateTimeFormat("en-GB", {
            dateStyle: "short",
            timeStyle: "short",
        });

        return schedules.filter((schedule) => {
            const startDateStr = schedule.startDate ? dateTimeFormatter.format(new Date(schedule.startDate)).toUpperCase() : '';
            const endDateStr = schedule.endDate ? dateTimeFormatter.format(new Date(schedule.endDate)).toUpperCase() : '';
            return (
                (!searchStringUpper ||
                    (schedule?.venueName && schedule?.venueName.toUpperCase().includes(searchStringUpper)) ||
                    startDateStr.includes(searchStringUpper) ||
                    endDateStr.includes(searchStringUpper)
                ) &&
                (listFilter === "ShowAll" || schedule?.deleted !== true)
            );
        });
    }, [listFilter, schedules, searchString]);

    const fetchData = useCallback(async () => {
        if (eventId) {
            setLoading(true);
            getSchedules<ResultListModel<Schedule>>(get)(eventId).then((schedulesData) => {
                if (schedulesData?.isSuccess && schedulesData?.data) {
                    setSchedules(schedulesData.data);
                } else if (schedulesData?.messages) {
                    errors(schedulesData.messages);
                }
                return null;
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [get, eventId]);

    const refreshList = useCallback(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    if (!eventId) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    const columns: GridColDef[] = [
        { field: 'startDate', headerName: 'Start Date', flex: 1, renderCell: (params) =>
            <DateDisplay value={new Date(params.value)} dateStyle="short" timeStyle="short" /> },
        { field: 'endDate', headerName: 'End Date', flex: 1, renderCell: (params) =>
            <DateDisplay value={new Date(params.value)} dateStyle="short" timeStyle="short" /> },
        { field: 'venueName', headerName: 'Venue', flex: 1, minWidth: 320 },
        { field: 'capacity', headerName: 'Capacity', flex: 1 },
        { field: 'actions', headerName: 'View Schedules', flex: 1, renderCell: (params) => (
            <Button
                variant="contained"
                size="small"
                startIcon={<RemoveRedEyeOutlinedIcon />}
                component={Link}
                to={`/eventManagement/events/${eventId}/schedules/${params.row.rowKey}`}
            >
                View
            </Button>
        ) },
    ];

    return (
        <Container>
            <Box sx={{ marginTop: '1.2rem!important', display: 'flex', justifyContent: 'space-between' }}>
                <Box className="bb-title-bar">
                    <h2 className="bb-m0 bb-p0">Schedules:</h2><EventDetails eventId={eventId} />
                </Box>
                <Box>
                    <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon"
                        to={`/eventManagement/events/${eventId}`}>
                        <Button variant="outlined" size="small" sx={{ padding: '6px 12px', fontSize: '0.95rem', minWidth: 'auto' }}
                            startIcon={<KeyboardArrowLeftIcon/>} disabled={isLoading}>
                            Back to Event
                        </Button>
                    </Link>
                </Box>
            </Box>
            <Breadcrumbs className="bb-breadcrumb bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
                <Link to="/eventManagement">Event Management</Link>
                <Link to="/eventManagement/events">Events</Link>
                <Link to={`/eventManagement/events/${eventId}`}>Event</Link>
                <Typography color="text.primary" className="bb-m0 bb-p0">Schedules</Typography>
            </Breadcrumbs>
            <Alert className="bb-title-info bb-mb-2 bb-align-items-center bb-flex" severity="info" sx={{ marginTop: '18px!important' }}
            >
            Welcome to the <b>Schedules Management Page.</b> Here, you can manage all of the schedules for your event.
            </Alert>
            <Box className="bb-flex bb-ui-box bb-flex-column bb-tac" sx={{ marginTop: '5px!important' }}>
                <Box className="bb-tal bb-mb-2" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        <Typography variant="h3" className="bb-m0 bb-p0">Schedule Manager</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Box>
                            <Button
                                variant="contained"
                                className="bb-ml-auto bb-primary-button"
                                onClick={refreshList}
                                startIcon={<RefreshOutlinedIcon sx={{ color: '#11a681' }}/>}
                                disabled={isLoading}
                                sx={{ marginRight: '10px', color: '#000000!important', backgroundColor: '#ffffff!important', border: '1px solid #11a681' }}
                            >
                        Refresh
                            </Button>
                            <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon"
                                to={`/eventManagement/events/${eventId}/schedules/${newSchedule}`}>
                                <Button variant="contained" disabled={isLoading}>
                            New Schedule
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                </Box>
                {isLoading
                    ? (<Box className="bb-tac"><CircularProgress/></Box>)
                    : (
                        <Box sx={{ height: 'auto', width: '100%', border: 'transparent!important', borderRadius: '4px', padding: '0px!important' }}>
                            <DataGrid
                                sx={{ border: 'transparent!important' }}
                                rows={filteredSchedules}
                                columns={columns}
                                pagination
                                pageSizeOptions={[5, 10, 25]}
                                checkboxSelection
                                autoHeight
                                getRowId={(row) => row.rowKey}
                                isRowSelectable={(params) => !params.row.deleted}
                                onRowSelectionModelChange={(newSelection) => {
                                    const selectedIDs = new Set(newSelection);
                                    const selectedRowsData = schedules.filter((row) => selectedIDs.has(row.rowKey as string));
                                    setSelectedRows(selectedRowsData);
                                }}
                                slots={{
                                    toolbar: CustomToolbar,
                                }}
                            />
                        </Box>
                    )
                }
            </Box>
        </Container>
    );
};
