import { useParams, Link, useLocation } from "react-router-dom";

import {
    Breadcrumbs, Typography
} from "@mui/material";

export const Breadcrumb = () => {
    const { appId } = useParams();
    const location = useLocation();
    const { pathname } = location;
    const lastPath = pathname.replace(/\/$/, "").split('/').splice(-1, 1);
    return (
        <Breadcrumbs className="bb-breadcrumb bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
            <Link to="/applications">Applications</Link>
            <Link to={`/applications/${appId}`}>Modules</Link>
            <Typography color="text.primary" className="bb-m0 bb-p0 bb-crumb-text" sx={{ textTransform: "capitalize" }}>{lastPath}</Typography>
        </Breadcrumbs>
    );
};
