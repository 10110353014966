import * as React from "react";

import { Link } from "react-router-dom";

import {
    Container,
    Box,
    Grid,
    Typography,
    Card,
    CardContent,
    Alert,
    Tooltip
} from "@mui/material";

import EventOutlinedIcon from '@mui/icons-material/EventOutlined';

import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import { FavoriteProvider } from '../favorites/FavoriteContext';
import { FavoriteButton } from '../favorites/FavoriteButton';

import { MemberAppContext } from "../../MemberAppContext";

import { useContext, useEffect } from "react";

export const EventManagement = () => {
    useEffect(() => {
        document.title = 'Beebot AI - Event Management';
    },[]);

    const { user } = useContext(MemberAppContext);

    return (
        <FavoriteProvider>
            <Container>
                <Box className="bb-title-bar" sx={{ marginBottom: '4px' }}>
                    <h2 className="bb-m0 bb-p0">Event Management</h2>
                    <FavoriteButton page={`eventManagement/`} displayName={`Event Management`} displayModule={`Manage Your Events`} />
                </Box>
                <Alert className="bb-title-info bb-mb-2 bb-align-items-center bb-flex" severity="info" sx={{ marginTop: '0px!important' }}
                >
                Welcome to the <b>Event Management Page.</b> Here, you can efficiently organise and manage all aspects of your events and venues.
                </Alert>
                <Grid
                    container
                    spacing={3}
                    alignItems="stretch"
                    alignContent="center">

                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className="animated animatedFadeInUp fadeInUp3">
                        <Tooltip
                            title="The Events Management section enables you to easily create and manage all of your events,
                            schedules, and bookings in one simple place."
                            arrow
                            placement="top"
                            PopperProps={{
                                sx: {
                                    '& .MuiTooltip-tooltip': {
                                        backgroundColor: 'black',
                                        color: 'white',
                                        fontSize: '.8rem',
                                        padding: '0.5rem',
                                    },
                                    '& .MuiTooltip-arrow': {
                                        color: 'black',
                                    },
                                },
                            }}>
                            <Card className="bb-app-icon">
                                <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon" to={`/eventManagement/events` }>
                                    <Box className="bb-flex bb-justify-content-center bb-card-header bb-header-icons">
                                        <EventOutlinedIcon></EventOutlinedIcon>
                                    </Box>
                                    <CardContent className="bb-align-items-center bb-flex bb-flex-column bb-module-card">
                                        <Typography gutterBottom variant="h2" fontWeight="bold" component="div">
                                        Events
                                        </Typography>
                                        <Typography variant="body2" fontSize={"0.9rem"
                                        } className="bb-text-center bb-min-height-3">
                                        Manage your events, schedules, and bookings.
                                        </Typography>
                                    </CardContent>
                                </Link>
                            </Card>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className="animated animatedFadeInUp fadeInUp3">
                        <Tooltip
                            title="The Venues Management section allows you to manage all of
                            your venues and event locations, giving you the ability to add multiple physical &
                            online locations that can be used across all your events."
                            arrow
                            placement="top"
                            PopperProps={{
                                sx: {
                                    '& .MuiTooltip-tooltip': {
                                        backgroundColor: 'black',
                                        color: 'white',
                                        fontSize: '.8rem',
                                        padding: '0.5rem',
                                    },
                                    '& .MuiTooltip-arrow': {
                                        color: 'black',
                                    },
                                },
                            }}>
                            <Card className="bb-app-icon">
                                <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon" to={`/eventManagement/venues`}>
                                    <Box className="bb-flex bb-justify-content-center bb-card-header bb-header-icons">
                                        <LocationOnOutlinedIcon></LocationOnOutlinedIcon>
                                    </Box>
                                    <CardContent className="bb-align-items-center bb-flex bb-flex-column bb-module-card">
                                        <Typography gutterBottom variant="h2" fontWeight="bold" component="div">
                                        Venues
                                        </Typography>
                                        <Typography variant="body2" fontSize={"0.9rem"
                                        } className="bb-text-center bb-min-height-3">
                                        Manage all of your venues and event locations.
                                        </Typography>
                                    </CardContent>
                                </Link>
                            </Card>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className="animated animatedFadeInUp fadeInUp3">
                        {!user.isPartnerUser && (
                            <Tooltip
                                title="Coming Soon!, Event Settings will enable you to Create & manage
                            various configuration options suchs as Event Categories and Questions."
                                arrow
                                placement="top"
                                PopperProps={{
                                    sx: {
                                        '& .MuiTooltip-tooltip': {
                                            backgroundColor: 'black',
                                            color: 'white',
                                            fontSize: '.8rem',
                                            padding: '0.5rem',
                                        },
                                        '& .MuiTooltip-arrow': {
                                            color: 'black',
                                        },
                                    },
                                }}>
                                <Card className="bb-app-icon">
                                    <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon" to={`/eventManagement`}>
                                        <Box className="bb-flex bb-justify-content-center bb-card-header bb-header-icons">
                                            <SettingsOutlinedIcon></SettingsOutlinedIcon>
                                        </Box>
                                        <CardContent className="bb-align-items-center bb-flex bb-flex-column bb-module-card">
                                            <Typography gutterBottom variant="h2" fontWeight="bold" component="div">
                                        Event Settings
                                            </Typography>
                                            <Typography variant="body2" fontSize={"0.9rem"
                                            } className="bb-text-center bb-min-height-3">
                                        Create & manage various configuration options for events.
                                            </Typography>
                                        </CardContent>
                                    </Link>
                                </Card>
                            </Tooltip>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </FavoriteProvider>
    );
};
