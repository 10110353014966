import { createContext, Dispatch, useContext, SetStateAction } from "react";

import { JSONSchema7 } from "json-schema";

import { Schema, DbData } from "../../../schemas/pages/schema";

export interface PagesContextSchemas {
    schema: Schema | undefined;
    updateSchema: (newSchema: Schema | undefined) => void;
    handleSubmit: (args: { formData: any }) => Promise<void>;
    handleUpdate: (newSchema: Schema) => void;
    currentFormData: any;
    setCurrentFormData: Dispatch<SetStateAction<any>>;
    currentSchema: JSONSchema7 | undefined;
    setCurrentSchema: Dispatch<SetStateAction<JSONSchema7 | undefined>>;
    dbData: DbData;
    selectedTableId: string | undefined;
    setSelectedTableId: Dispatch<SetStateAction<string | undefined>>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const defaultSetCurrentData = <T,>(): Dispatch<SetStateAction<T>> => () => { };
export const PageContext = createContext<PagesContextSchemas>(
    {
        schema: {
            bbRow: [],
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        updateSchema: () => {
        }, // Provide a noop function as default,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        handleSubmit: async () => {
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        handleUpdate: () => {
        },
        currentFormData: undefined,
        setCurrentFormData: defaultSetCurrentData<any>,
        currentSchema: {},
        setCurrentSchema: defaultSetCurrentData<JSONSchema7 | undefined>,
        dbData: {},
        selectedTableId: undefined,
        setSelectedTableId: defaultSetCurrentData<string | undefined>
    }
);
export const usePageContext = () => useContext(PageContext);
