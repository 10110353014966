import { createContext, ReactNode } from "react";
import { useQuery } from "react-query";

import useDataClient from "./axios/dataClient";

import { getCustomerConfiguration } from "./services/api/CustomerConfigurationService";

import { CustomerConfiguration } from "./services/model/CustomerConfiguration";

// campaigns: false,
export const CustomerConfigurationContext =
    createContext<CustomerConfiguration>({
        home: false,
        conversationAI: false,
        automation: false,
        liveAgent: false,
        connections: false,
        assets: false,
        insights: false,
        dataManagement: false,
        applications: false,
        advancedEditor: false,
        maximumChats: 0,
        logoUrl: undefined
    });

export const CustomerConfigurationContextProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const { get } = useDataClient();

    const { data } = useQuery("CustomerConfigurationContext", () =>
        getCustomerConfiguration(get)
    );

    const customerConfiguration = data ?? {
        home: false,
        conversationAI: false,
        automation: false,
        liveAgent: false,
        connections: false,
        assets: false,
        insights: false,
        dataManagement: false,
        applications: false,
        advancedEditor: false,
        maximumChats: 0
    };

    return (
        <CustomerConfigurationContext.Provider value={customerConfiguration}>
            {children}
        </CustomerConfigurationContext.Provider>
    );
};
