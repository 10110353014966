import { Box, Button, Card, CardActions, CardContent, CardHeader, Typography, Tooltip } from "@mui/material";

import { useCallback, useMemo, useState } from "react";

import { CustomerUser, Team } from "../../services/model/users/customerUser";

import { UserEditDialog } from "./UserEditDialog";

interface UserBoxProps {
    user: CustomerUser;
    teams: Team[];
    editSuccess: (user: CustomerUser) => void;
}

export const UserBox = ({ user, teams, editSuccess }: UserBoxProps) => {
    const [editOpen, setEditOpen] = useState(false);
    const fullName = useMemo(() => {
        return user.firstName + " " + user.lastName;
    }, [user]);

    const teamsString = useMemo(() => {
        const userTeams = user.teamIds.map(teamId => teams.find(team => team.id === teamId));
        return  userTeams.map(t => t?.displayName).join(", ");
    }, [user, teams]);

    const rolesString = useMemo(() => {
        const userRoles = user.roles.map(role => role.name);
        return userRoles.join(", ");
    }, [user]);

    const editUser = useCallback(() => {
        setEditOpen(true);
    }, []);

    const onEditSuccess = useCallback((editedUser: CustomerUser) => {
        editSuccess(editedUser);
        setEditOpen(false);
    }, [editSuccess]);

    const editCancel = useCallback(() => {
        setEditOpen(false);
    }, []);

    return (<><Card>
        <CardHeader title={fullName} className="bot-card-header" />
        <CardContent  sx={{ verticalAlign: "middle" }} className="bb-user-card-body">
            <Typography><Box component="span" sx={{ fontWeight: "bold" }}>Roles: </Box>{rolesString}</Typography>
            <Typography><Box component="span" sx={{ fontWeight: "bold" }}>Teams: </Box>{teamsString}</Typography>
            <Typography><Box component="span" sx={{ fontWeight: "bold" }}>Maximum Chats: </Box>{user.maximumChats}</Typography>
        </CardContent>
        <CardActions>
            <Tooltip className="bb-ml-auto" title="Manage User"><Button variant="contained" className="bb-ml-auto" onClick={editUser}>Edit</Button></Tooltip>
        </CardActions>
    </Card>

    <UserEditDialog dialogOpen={editOpen} teams={teams} user={user} onSuccess={onEditSuccess} onCancel={editCancel} />
    </>);
};
