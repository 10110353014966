import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";

import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";

import useDataClient from "../../axios/dataClient";

import { DateField } from "../../material/DateField";
import { SwitchField } from "../../material/SwitchField";
import { TextField } from "../../material/TextField";
import { editBot } from "../../services/api/BotService";
import { BotDetailsModel } from "../../services/model/bot";
import { BotDetailsEdit, botDetailsEditSchema, EditToModel, ModelToEdit } from "../../yup-schemas/BotDetailsEditSchema";

interface Props {
    open: boolean;
    onClose: () => void;
    onBotUpdated: () => void;
    originalBotInformation: BotDetailsModel;
}

export const BotEditInformationDialog = ({ open, onClose, onBotUpdated, originalBotInformation }: Props) => {

    const params = useParams();
    const { put } = useDataClient();
    const botId = params.botId as string;

    const onSubmitEdit = useCallback(
        async (values: BotDetailsEdit) => {
            const model = EditToModel(values, originalBotInformation);
            await editBot(put, botId, model);
            if (onBotUpdated) { onBotUpdated(); }
            onClose();
        },
        [botId, onBotUpdated, onClose, put, originalBotInformation]
    );

    const initialValues = useMemo(() => ModelToEdit(originalBotInformation), [originalBotInformation]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <Formik<BotDetailsEdit>
                initialValues={initialValues}
                validationSchema={botDetailsEditSchema}
                onSubmit={onSubmitEdit}>
                {(props) => (
                    <Form>
                        <DialogTitle className="bb-modal-header bb-mb-3">
                            <Typography variant="h3" className="bb-mb-0">Edit {initialValues.displayName}</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Stack spacing={2}>
                                <TextField label="Bot Name" name="displayName" required />
                                <TextField label="Production Domains" name="productionDomains" required />
                                <DateField label="Live Date" name="liveDate" />
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography>Active (this bot is active an available for all the users)</Typography>
                                    <SwitchField name="isActive" label="" />
                                </Stack>
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose}>
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                className="bb-ml-auto"
                                type="submit"
                                disabled={props.isSubmitting}>
                                {!props.isSubmitting ? (
                                    "Submit"
                                ) : (
                                    <CircularProgress />
                                )}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};
