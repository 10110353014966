import { string, object, date, boolean, InferType } from "yup";

import dayjs from "dayjs";

import { BotDetailsModel } from "../services/model/bot";

export const botDetailsEditSchema = object({
    displayName: string().required(),
    productionDomains: string().required(),
    liveDate: date().required(),
    isActive: boolean().required(),
});

type BotSchema = InferType<typeof botDetailsEditSchema>;

// We need this to be of type dayjs, because our date picker expects it
export type BotDetailsEdit = Omit<BotSchema, "liveDate"> & {
    liveDate: dayjs.Dayjs;
}

export function EditToModel(edit: BotDetailsEdit, originalModel: BotDetailsModel): BotDetailsModel {
    return {
        displayName: edit.displayName,
        productionDomains: edit.productionDomains.split(",").map(x => x.trim()),
        liveDate: edit.liveDate.toDate(),
        lastUpdatedDate: new Date(),
        isActive: edit.isActive,
        logo: originalModel.logo,
        beeBotFile: originalModel.beeBotFile,
        botType: originalModel.botType
    };
}

export function ModelToEdit(edit: BotDetailsModel): BotDetailsEdit {
    return {
        displayName: edit.displayName,
        productionDomains: edit.productionDomains.join(", "),
        liveDate: dayjs(edit.liveDate),
        isActive: edit.isActive,
    };
}
