import * as React from "react";

import { Grid, Box } from "@mui/material";

export interface BBImgProps {
    panel: any;
}
export const BBImg: React.FC<BBImgProps> = ({ panel }) => {
    return (
        <Box>
            <Grid item xs>
                <Box>
                    {panel?.imgSrc ? (
                        <img src={panel?.imgSrc} className='bb-pe-img, bb-width-100 bb-border-radius'/>
                    ) : (
                        <span>Add your image.</span>
                    )
                    }
                </Box>
            </Grid>
        </Box>
    );
};
