import { Booking } from "../../schemas/eventManagement/bookingSchema";

const getBookings = <schema>(get: <T>(url: string) => Promise<T>) => async (scheduleId: string) => {
    const result = await get<schema>(`/api/schedule/${scheduleId}/booking`);
    return result;
};

const getBookingsQueue = <schema>(get: <T>(url: string) => Promise<T>) => async (scheduleId: string) => {
    const result = await get<schema>(`/api/schedule/${scheduleId}/booking/queue`);
    return result;
};

const getBooking = <schema>(get: <T>(url: string) => Promise<T>) => async (scheduleId: string, bookingId: string) => {
    const result = await get<schema>(`/api/schedule/${scheduleId}/booking/${bookingId}`);
    return result;
};

const createBooking = <schema>(post: <T>(url: string, body: any) => Promise<T>) => async (scheduleId: string, form: Booking) => {
    const result = await post<schema>(`/api/schedule/${scheduleId}/booking`, form);
    return result;
};

const updateBooking = <schema>(put: <T>(url: string, body: any) => Promise<T>) => async (scheduleId: string, bookingId: string, form: Booking) => {
    const result = await put<schema>(`/api/schedule/${scheduleId}/booking/${bookingId}`, form);
    return result;
};

const deleteBooking = <schema>(deleteRequest: <T>(url: string, body: any) => Promise<T>) => async (scheduleId: string, bookingId: string) => {
    const result = await deleteRequest<schema>(`/api/schedule/${scheduleId}/booking/${bookingId}`, {});
    return result;
};

export { getBookings, getBooking, createBooking, updateBooking, deleteBooking, getBookingsQueue };
