import { BotDetailsModel, BotListModel } from "../model/bot";
import { BotSecrets } from "../model/botSecret";

const getBots = async (getFunction: <T>(url: string) => Promise<T>) => {
    const result = await getFunction<BotListModel[]>("/api/customer/bots");
    return result;
};

const getBot = async (
    getFunction: <T>(url: string) => Promise<T>,
    botId: string
) => {
    const result = await getFunction<BotDetailsModel>(
        `/api/customer/bots/${botId}`
    );
    return result;
};

const editBot = async (
    putFunction: <T>(url: string, body: T) => Promise<T>,
    botId: string,
    body: BotDetailsModel
) => {
    const result = await putFunction<BotDetailsModel>(
        `/api/customer/bots/${botId}`,
        body,
    );
    return result;
};

const getSecrets = async (
    getFunction: <T>(url: string) => Promise<T>,
    botId: string
) => {
    const result = await getFunction<BotSecrets>(
        `/api/bot/secrets/${botId}`
    );
    return result;
};

export { getBots, getBot, editBot, getSecrets };
