import { Accordion, AccordionDetails, AccordionSummary, AppBar, Box, Button, Divider, Typography, useTheme } from "@mui/material";

import { AccountCircle, ExpandMore } from "@mui/icons-material";

import { useCallback } from "react";
import { InteractionType } from "@azure/msal-browser";

import { TopBarHeight } from "../theme";

export const ErrorComponent = (o: any) => {
    // eslint-disable-next-line no-console
    console.error(o);
    const theme = useTheme();

    const login = useCallback(() => {
        o.login(InteractionType.Redirect);
    }, [o]);
    return (
        <Box>
            <AppBar
                position="relative"
                color="default"
                sx={{ zIndex: theme.zIndex.drawer + 1, justifyContent: "center", height: TopBarHeight }}
            >
                <Box className="user-profile" sx={{ display: "inline-flex", flexDirection: "column" }}>
                    <Button startIcon={<AccountCircle />} onClick={login} sx={{ color: theme.palette.primary.contrastText, alignSelf: "end" }}>
                        <span className="user-name">LOGIN</span>
                    </Button>
                </Box>
            </AppBar>
            <Box sx={{ padding: "10px", textAlign: "center" }}>
                <Typography variant="h1">Organisational Account is not configured for Azure Single Sign On</Typography>
                <Typography>If you are interested in configuring Azure SSO please contact the BeebotAI
                    <a href="https://beebotai.atlassian.net/servicedesk/customer/portals">service desk</a></Typography>
            </Box>
            <Accordion elevation={0} className="background-expansion">
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography>Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>{o?.error?.errorMessage}</Typography>
                </AccordionDetails>
            </Accordion>
            <Divider />
        </Box>);
};
