export const newPage = "newPage";
export const newSmartPage = "newSmartPage";
export const newProfile = "newProfile";
export const newPathway = "newPathway";
export const newVenue = "newVenue";
export const newEvent = "newEvent";
export const newSchedule = "newSchedule";
export const newBooking = "newBooking";
export const newQuestion = "newQuestion";
export const newQuestionAnswer = "newQuestionAnswer";
export const newPartner = "newPartner";
export const newPartnerUser = "newUser";
