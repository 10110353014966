import "./css/bb-app-settings.css";
import { useParams } from "react-router-dom";
import {
    Container,
    Box,
    Alert,
    CircularProgress
} from "@mui/material";

import { useState, useEffect } from "react";

import useDataClient from "../../../axios/dataClient";

import { getApp } from "../../../services/api/FrontendService";

import { AppViewModel } from "../../../services/model/apps/AppViewModel";

import { AppSettingsBaseForm } from "./AppSettingsBaseForm";

import { AppLinks } from "../AppLinksComponent";
import { Breadcrumb } from "../BreadCrumbComponent";

export const AppSettings = () => {
    const { appId } = useParams();
    const [appData, setAppData] = useState<AppViewModel | null>(null);
    const { get } = useDataClient();

    useEffect(() => {
        const fetchData = async () => {
            if (appId) {
                const data = await getApp(get)(appId);
                setAppData(data);
            }
        };

        fetchData();
    },
    [appId, get]);

    useEffect(() => {
        document.title = 'Beebot AI - Settings';
    },[]);

    if (!appData) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <Container>
            <Box className="bb-title-bar">
                <h2 className="bb-m0 bb-p0">Settings: {appData.displayName}</h2>
                <AppLinks />
            </Box>
            <Breadcrumb />
            <Alert className="bb-title-info bb-mb-2" severity="info">Manage the settings of
                your <b>{appData.displayName}</b> application.&nbsp;
            <a rel="noreferrer" className="bb-mr-1" href={`https://delightful-bush-088acfb03.5.azurestaticapps.net/?page=theme`}
                target="_blank">Learn More</a></Alert>
            <Box className="bb-flex bb-ui-box bb-flex-column bb-tac">
                <AppSettingsBaseForm appId={appId}></AppSettingsBaseForm>
            </Box>
        </Container>
    );
};
