import * as React from "react";

import { Grid, Box } from "@mui/material";

export interface BBBotLearnProps {
    panel: any;
}

export const BBBotLearn: React.FC<BBBotLearnProps> = ({ panel }) => {
    return (
        <Box sx={{ background: "aliceblue", padding: "1rem", border: "1px solid #eee", borderRadius: "5px" }}>
            <Grid item xs>
                {panel?.imgUrl ? (<img src={panel?.imgUrl} className="bb-pe-img, bb-width-100" />) : (<span></span>)}
                {panel?.panelHeading ? (<h3 className="bb-tal">{panel?.panelHeading}</h3>) : (<span></span>)}
                {panel?.panelHeading ? (<p className="bb-tal">{panel?.panelPara}</p>) : (<span></span>)}
                <Box className="bb-tal bb-flex">
                    <input type="text" placeholder="Enter your text here" className="bb-learn-input bb-flex-fill"></input>
                    <input type="submit" className="bb-learn-submit bb-ml-1"></input>
                </Box>
            </Grid>
        </Box>
    );
};
