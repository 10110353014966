import { ImageNotSupported } from "@mui/icons-material";
import {
    Card,
    CardActionArea,
    CardMedia,
    Checkbox,
    Grid,
    Box,
    Icon,
    Typography
} from "@mui/material";

import { useCallback, useMemo, useState } from "react";

import { FileInfo } from "./FileInfo";

import { BlobFile } from "./FileFolderView";

export interface FileCardProps {
    file: BlobFile;
    isPublic: boolean;
    isSelected: boolean;
    handleFileSelection: (file: BlobFile) => void;
    handleFileSelectFromFileInfo: (file: BlobFile) => void;
    isImageSizeMismatch?: boolean;
}

export const FileCard = ({
    file,
    isPublic,
    isSelected,
    handleFileSelection,
    handleFileSelectFromFileInfo,
    isImageSizeMismatch }: FileCardProps) => {
    const [infoOpen, setInfoOpen] = useState(false);

    const openInfo = useCallback(() => {
        setInfoOpen(true);
    }, []);

    const closeInfo = useCallback(() => {
        setInfoOpen(false);
    }, []);

    const selectFile = useCallback(() => {
        handleFileSelection(file);
    }, [handleFileSelection, file]);

    const selectFileFromFileInfo = useCallback(() => {
        setInfoOpen(false);
        handleFileSelectFromFileInfo(file);
    }, [file, handleFileSelectFromFileInfo]);

    const shouldShowThumbnail = useMemo(() => {
        const upper = file.name.toLocaleUpperCase();
        return isPublic && (upper.endsWith("PNG") || upper.endsWith("JPEG") || upper.endsWith("JPG") || upper.endsWith("GIF"));
    }, [file, isPublic]);

    const renderLastUpdated = useCallback((lastUpdated?: Date | string) => {
        if (typeof lastUpdated === "string") {
            return new Date(lastUpdated).toLocaleString();
        }
        else {
            return lastUpdated?.toLocaleString();
        }
    }, []);

    return (
        <Grid item xs={6} sm={4} md={3} lg={3} xl={2} className="">
            <Card className="bb-media-card">
                <Box onClick={openInfo}>
                    {shouldShowThumbnail ? <CardMedia component="img" image={file.url} /> : <CardMedia><Icon><ImageNotSupported /></Icon></CardMedia>}
                </Box>
                <CardActionArea className="bb-media-item-info" onClick={selectFile}>
                    <Typography sx={{ fontSize: ".6", fontWeight: "normal", padding: ".5rem .5rem 0 .5rem" }}>
                        <Checkbox sx={{ padding: "0" }} checked={isSelected} /> {file.name}
                    </Typography>
                    <Typography sx={{ fontSize: ".7rem", padding: "0 .5rem" }}>{renderLastUpdated(file.lastUpdated)}</Typography>
                </CardActionArea>
            </Card>
            <FileInfo
                open={infoOpen}
                onCancel={closeInfo}
                onSelect={selectFileFromFileInfo}
                file={file}
                isImageSizeMismatch={isImageSizeMismatch}
            />
        </Grid>);
};
