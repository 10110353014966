import { Link } from "react-router-dom";

import {
    Container,
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    TablePagination,
    TableHead,
    CircularProgress,
    Select,
    MenuItem,
    Typography,
    Breadcrumbs,
    Tooltip
} from "@mui/material";

import { SelectChangeEvent } from '@mui/material/Select';

import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

import { useState, useEffect, useCallback } from "react";

import { SearchField } from "../../applications/customWidget/Search/SearchField";

import useDataClient from "../../../axios/dataClient";

import { stringSort } from "../../../services/helper/sort";

import { Venue, EventTypeText } from "../../../schemas/eventManagement/venueSchema";

import { getVenues } from "../../../services/api/VenueManagementService";

import { ResultListModel } from "../../../schemas/eventManagement/resultSchema";

import { newVenue } from "../../newModuleNames";

import { errors } from "../../ErrorDisplay";

export const VenueList: React.FC = () => {
    const { get } = useDataClient();

    const [venues, setVenues] = useState<Venue[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [isLoading, setLoading] = useState(false);
    const [searchString, setSearchString] = useState<string>();
    const [filteredVenues, setFilteredVenues] = useState<Venue[]>([]);

    const filterShowAll = "ShowAll";
    const filterShowNonDeletedOnly = "filterShowNonDeletedOnly";
    const [listFilter, setListFilter] = useState(filterShowAll);

    const callSearch = useCallback((search?: string) => {
        setSearchString(search);
    }, [setSearchString]);

    const handleFilterChange = (event: SelectChangeEvent) => {
        setListFilter(event.target.value as string);
    };

    useEffect(() => {
        if (!venues) {
            return;
        }

        const searchStringUpper = searchString?.toUpperCase();
        setCurrentPage(0);
        const filtered = venues.filter((venue) => {
            const venueNameMatches = !searchStringUpper || (venue?.venueName && venue?.venueName.toUpperCase().includes(searchStringUpper));
            const eventTypeMatches = !searchStringUpper || (typeof venue?.eventType === 'number' &&
                EventTypeText[venue.eventType].toUpperCase().includes(searchStringUpper));
            const filterMatches = listFilter === filterShowAll || venue?.deleted !== true;
            return (venueNameMatches || eventTypeMatches) && filterMatches;
        });
        setFilteredVenues(filtered);
    }, [searchString, listFilter, venues]);

    const fetchData = useCallback(async () => {

        getVenues<ResultListModel<Venue>>(get)().then((venuesData) => {
            if (venuesData?.isSuccess && venuesData?.data) {
                setVenues(venuesData.data);
                setFilteredVenues(venuesData.data);
            } else if (venuesData?.messages) {
                errors(venuesData.messages);
            }

            return;
        }).finally(() => {
            setLoading(false);
        });;
    }, [get]);

    useEffect(() => {
        setSearchString("");
    }, []);

    const refreshList = useCallback(() => {
        setLoading(true);
        fetchData();
    }, [fetchData]);

    const handleChangePage = (event: any, newPage: any) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0); // Reset to first page
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            await fetchData();
        })();
    }, [fetchData]);

    return (
        <Container>
            <Box className="bb-title-bar bb-mt-3">
                <h2 className="bb-m0 bb-p0">Venues</h2>
            </Box>
            <Breadcrumbs className="bb-breadcrumb bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
                <Link to="/eventManagement">Event Management</Link>
                <Typography color="text.primary" className="bb-m0 bb-p0">Venues</Typography>
            </Breadcrumbs>
            <Box className="bb-flex bb-ui-box bb-flex-column bb-tac">
                <Box className="bb-tal bb-mb-2" sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box>
                        <h3 className="bb-m0 bb-p0">Search by Venue Name</h3>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        <Button
                            variant="contained"
                            className="bb-ml-auto bb-primary-button"
                            onClick={refreshList}
                            startIcon={<RefreshOutlinedIcon />}
                            disabled={isLoading}
                            sx={{ marginRight: "10px" }}
                        >
                        Refresh
                        </Button>
                        <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon" to={`/eventManagement/venues/${newVenue}`}>
                            <Button variant="contained">
                            New Venue
                            </Button>
                        </Link>
                    </Box>
                </Box>
                {isLoading
                    ? (<Box className="bb-tac"><CircularProgress /></Box>)
                    : (
                        <TableContainer component={Paper} sx={{ boxShadow: "none", marginTop: "1rem" }} >
                            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginBottom: "1rem" }}>
                                <Box className="bb-med-search bb-ml-2" sx={{ minWidth: 200, marginLeft: '0px!important' }}>
                                    <Select
                                        id="filter-select"
                                        value={listFilter}
                                        onChange={handleFilterChange}
                                        variant="outlined"
                                        fullWidth
                                        sx={{ height: 40 }}
                                    >
                                        <MenuItem value={filterShowAll}>Show All</MenuItem>
                                        <MenuItem value={filterShowNonDeletedOnly}>Hide Deleted Venues</MenuItem>
                                    </Select>
                                </Box>
                                <Tooltip
                                    title="Search for a Event by Venue Name."
                                    arrow
                                    placement="top"
                                    PopperProps={{
                                        sx: {
                                            '& .MuiTooltip-tooltip': {
                                                backgroundColor: 'black',
                                                color: 'white',
                                                fontSize: '.8rem',
                                                padding: '0.5rem',
                                            },
                                            '& .MuiTooltip-arrow': {
                                                color: 'black',
                                            },
                                        },
                                    }}>
                                    <Box className="bb-med-search">
                                        <SearchField
                                            searchString={searchString}
                                            callSearch={callSearch}
                                            timeout={500}
                                        />
                                    </Box>
                                </Tooltip>
                            </Box>
                            <Table aria-label="table" sx={{ padding: "0", margin: "0" }} className="bb-page-list-table bb-global-table">
                                <TableHead sx={{ borderRadius: "5px 5px 0 0" }}>
                                    <TableRow >
                                        <TableCell>Venue Name</TableCell>
                                        <TableCell>Venue Type</TableCell>
                                        <TableCell>Deleted</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>{
                                    filteredVenues
                                        .sort((a, b) => stringSort(a.venueName, b.venueName))
                                        .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                                        .map((venue, index) => {
                                            return (
                                                <TableRow key={`venue_${index}`} sx={{ padding: "0", margin: "0" }}>
                                                    <TableCell sx={{ padding: "0", margin: "0" }}>
                                                        <Box className="bb-ml-2">
                                                            <Link
                                                                className="bb-pages-list bb-align-items-center"
                                                                to={`/eventManagement/venues/${venue.rowKey}`}
                                                            >
                                                                {venue.venueName}
                                                            </Link>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Box className="bb-ml-2">
                                                            {typeof venue.eventType === 'number' ? EventTypeText[venue.eventType] : "Unknown"}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Box className="bb-ml-2">{venue.deleted ? "Yes" : "No"}</Box>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                }
                                </TableBody>
                            </Table>
                            <TablePagination
                                className="bb-global-table-footer"
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={filteredVenues.length}
                                rowsPerPage={rowsPerPage}
                                page={currentPage}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    )
                }
            </Box>
        </Container>
    );
};
