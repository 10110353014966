import * as React from "react";

import { Grid } from "@mui/material";

export interface BBIconBoxProps {
    panel: any;
}

export const BBIconBox: React.FC<BBIconBoxProps> = ({ panel }) => {
    return (
        <Grid item xs className="bb-icon-box">
            {panel?.imgUrl ? (<img src={panel?.imgUrl} className="bb-pe-img, bb-width-100" />) : (<span></span>)}
            {panel?.panelHeading ? (<h3 className="bb-tal">{panel?.panelHeading}</h3>) : (<span></span>)}
            {panel?.panelHeading ? (<p className="bb-tal">{panel?.panelPara}</p>) : (<span></span>)}
            <p><span className="material-icons">{panel?.iconName}</span></p>
            {panel?.links?.map((link: any, index: number) => (
                <a key={index} href={link?.linkUrl}>{link?.linkText}</a>
            ))}
            <p>Color: {panel?.iconColor}</p>
        </Grid>
    );
};
