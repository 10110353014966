import { Card, CardActions, CardHeader, CardContent, Button } from "@mui/material";
import { Divider, Switch, Grid } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useState } from "react";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";

interface RpaCardProps {
    id: string;
    displayName: string;
    isEnabled: boolean;
    successfulRuns: number;
    failedRuns: number;
    handleIsEnabledChange: (logicAppId: string, isEnabled: boolean) => Promise<void>;
    isEnableToggleDisabled: boolean;
}

export const RpaCard = (props: RpaCardProps) => {
    const { id, displayName, isEnabled, successfulRuns, failedRuns, handleIsEnabledChange, isEnableToggleDisabled } = props;

    const [open, setOpen] = useState(false);
    const [desiredState, setDesiredState] = useState(isEnabled);

    const handleOpen = (newState: boolean) => () => {
        setDesiredState(newState);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleConfirm = async () => {
        await handleIsEnabledChange(id, desiredState);
        handleClose();
    };
    const handleSwitchChange = handleOpen(!isEnabled);
    const handleButtonClick = handleOpen(!isEnabled);
    const bbhandleClose = handleClose;
    const bbhandleConfirm = handleConfirm;

    return (
        <Card sx={{ flexGrow: 1 }}>
            <CardHeader title={displayName} className="rpa-card-header" />
            <Divider />
            <CardContent className="bb-p0">
                <Grid container spacing={1} className="bb-app-icon-stats">
                    <Grid item xs={6}>
                        <div className="bb-app-icon-description">
                            <h2 className="fontSize2 bb-m0 bb-p0">{successfulRuns}{" "}</h2>
                            <p className="bb-small-text bb-m0 bb-p0">(Successful runs)</p>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="bb-app-icon-description">
                            <h2 className="fontSize2 bb-m0 bb-p0">{failedRuns}{" "}</h2>
                            <p className="bb-small-text bb-m0 bb-p0">(Failed runs)</p>
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions disableSpacing className="rpa-card-actions">
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                onClick={handleButtonClick}
                                checked={isEnabled}
                                disabled={isEnableToggleDisabled}
                                onChange={handleSwitchChange}
                                size="small" />
                        }
                        label="Enabled" />
                    <Dialog open={open} onClose={bbhandleClose}>
                        <DialogTitle id="alert-dialog-title" className="bb-error-dialog-header bb-flex bb-align-items-center">
                            <WarningAmberIcon className="bb-mr-1"></WarningAmberIcon> <h2 className="bb-m0 bb-p0">{"WARNING!"}</h2>
                        </DialogTitle>
                        <DialogContent className="bb-global-modal">
                            <DialogContentText className="bb-tac">
                                <h2>Are you really sure?</h2>
                                {isEnabled ? "This will disable your automation" : "This will enable your automation"}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={bbhandleClose}>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={bbhandleConfirm} className="bb-ml-auto">
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>
                </FormGroup>
            </CardActions>
        </Card>
    );
};
