import React, { useCallback } from 'react';
import { MenuItem, Select } from '@mui/material';

const PageOptionsWidget = (props: any) => {
    const pageOptions = props.formContext.pageOptions || [];

    const handleChange = useCallback((event: any) => {
        const newValue = event.target.value;
        props.onChange(newValue);
    }, [props]);

    return (
        <Select
            id={props.id}
            value={props.value}
            onChange={handleChange}
            variant="outlined"
            defaultValue={pageOptions[0] || ''}
        >
            {pageOptions.map((option: any, i: number) => (
                <MenuItem key={i} value={option}>
                    {option}
                </MenuItem>
            ))}
        </Select>
    );
};

export { PageOptionsWidget };

export {};
