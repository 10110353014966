import { AgentState } from "../components/livechat/agentState";

interface ReducerAction {
    isLiveOperator?: boolean;
    isAdmin?: boolean;
    isCreator?: boolean;
    displayName?: string;
    isChatting?: AgentState;
    liveAgentTeams?: string[];
    isLiveAgentAdmin?: boolean;
    isApplicationContributor?: boolean;
    isApplicationAdmin?: boolean;
    isCaiContributor?: boolean;
    isCaiAdmin?: boolean;
    isRpaContributor?: boolean;
    isRpaAdmin?: boolean;
    isMediaContributor?: boolean;
    isDataContributor?: boolean;
    isDataAdmin?: boolean;
    isPartnerUser?: boolean;
    sub?: string;
    isPartnerManager?: boolean;
    isEventManager?: boolean;
}

export interface MemberState {
    isAuthenticated: boolean;
    isAdmin: boolean;
    displayName: string;
    isLiveOperator: boolean;
    isChatting: AgentState;
    liveAgentTeams: string[];
    isLiveAgentAdmin: boolean;
    isApplicationContributor: boolean;
    isApplicationAdmin: boolean;
    isCaiContributor: boolean;
    isCaiAdmin: boolean;
    isRpaContributor: boolean;
    isRpaAdmin: boolean;
    isMediaContributor: boolean;
    isDataContributor: boolean;
    isDataAdmin: boolean;
    isPartnerUser: boolean;
    sub: string;
    isPartnerManager: boolean;
    isEventManager: boolean;
}

export const authReducer = (state: MemberState, action: ReducerAction): MemberState => {
    return {
        ...state,
        isAuthenticated: true,
        ...action
    };

};
