import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useCallback } from "react";

import { TextField } from "../../material/TextField";
import { FolderUploadSchema, FolderUploadType } from "../../yup-schemas/folderSchema";

interface CreateFolderDialogProps {
    open: boolean;
    onCancel: () => void;
    onConfirm: (newFolderName: string) => void;
}

export const CreateFolderDialog = ({ open, onCancel, onConfirm }: CreateFolderDialogProps) => {
    const sanitizeFolderName = (folderName: string) => {
        return folderName.replace(/\s+/g, '_'); // Replace spaces with underscores
    };

    const onConfirmCallback = useCallback((form: FolderUploadType) => {
        const sanitizedFolderName = sanitizeFolderName(form.folderName);
        onConfirm(sanitizedFolderName);
    }, [onConfirm]);

    return (<Dialog open={open}>
        <DialogTitle className="bbot-dialog-title">
            <Typography>Create folder</Typography>
        </DialogTitle>
        <Formik<FolderUploadType> initialValues={{ folderName: "" }} validationSchema={FolderUploadSchema} onSubmit={onConfirmCallback}>
            {props => (
                <Form>
                    <DialogContent className="bbot-dialog-content bb-global-modal">
                        <TextField inputProps={{ maxLength: 20 }} name="folderName" label="Name" />
                    </DialogContent>
                    <DialogActions className="bbot-dialog-actions">
                        <Button onClick={onCancel}>Cancel</Button>
                        <Button variant="contained" type="submit" disabled={!props.isValid}>Create</Button>
                    </DialogActions>
                </Form>
            )}
        </Formik>
    </Dialog>);
};
