import {
    Button,
    CircularProgress,
    Grid,
    Container,
    Box,
    Alert,
    Typography
} from "@mui/material";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import { useParams, Link, useNavigate } from "react-router-dom";

import React, { useState, useEffect, useCallback, useMemo } from "react";

import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { toast } from "react-toastify";

import { Form } from '@rjsf/mui';

import validatorAjv8 from "@rjsf/validator-ajv8";

import { JSONSchema7 } from "json-schema";

import { UiSchema } from "@rjsf/utils";

import useDataClient from "../../axios/dataClient";

import { WarningModal } from "../shared/WarningModal";

import { getPartnerUser, updatePartnerUser, createPartnerUser } from "../../services/api/PartnerManagementService";

import { PartnerUserDetails } from "../../schemas/partnerManagement/schema";

import { getTableStructures } from "../../services/api/DataManagementService";

import userSchemaModel from "../../schemas/partnerManagement/userSchemaData.json";

import { DbData } from "../../schemas/pages/schema";

import { getPartners } from "../../services/api/PartnerManagementService";

import { Partner } from "../../schemas/partnerManagement/schema";

import { ResultListModel } from "../../schemas/eventManagement/resultSchema";

import { DateDisplay } from "../shared/DateDisplay";

import { DbMultiTableWidget } from "../applications/customWidget/DbWidget/DbMultiTableWidget";

import { DbMultiPartnerWidget } from "../applications/customWidget/DbWidget/DbMultiPartnerWidget";

import { MultiTextWidget } from "../applications/customWidget/MultiText/MultiTextWidget";

import { UndoButton } from "../applications/UndoButton";

import { PartnerUserContext } from "./partnerUserContext";

export const PartnerUserForm: React.FC = () => {
    const navigate = useNavigate();

    const { userId, partnerId } = useParams();
    const [user, setUser] = useState<PartnerUserDetails>();
    const { get, post, put } = useDataClient();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [warningModalOpen, setWarningModalOpen] = React.useState(false);
    const isDelete = location?.pathname?.endsWith("/delete");
    const [dbData, setDbData] = React.useState<DbData | undefined>();
    const [partners, setPartners] = React.useState<Partner[]>([]);

    const uiSchema: UiSchema = {
        'ui:submitButtonOptions': {
            norender: true
        },
        "ui:options": {
            classNames: "custom-class-title1"
        },
        "partnerId": {
            "ui:widget": "DbMultiPartnerWidget",
            "ui:readonly": true
        },
        "email": {
            "ui:readonly": !!userId
        },
        "isActive": {
            "ui:readonly": true
        },
        "id": {
            "ui:widget": "hidden"
        },
        "displayName": {
            "ui:widget": "hidden"
        },
        "tableNames": {
            "ui:widget": "DbMultiTableWidget"
        },
        "rowIdentifiers": {
            "ui:widget": "DbMultiPartnerWidget",
            "ui:readonly": true
        },
        "sendEmail": userId ? { "ui:widget": "hidden" } : {}
    };

    const fetchDbData = React.useCallback(async () => {
        const data = await getTableStructures(get);
        data.push({ id: "none", displayName: "None", columns: [], isReadOnly: true, description: "" });

        setDbData({
            tables: data
        });

        const partnersData = await getPartners<ResultListModel<Partner>>(get)();
        if (partnersData?.isSuccess && partnersData?.data) {
            setPartners(partnersData.data);
        }

    }, [get]);

    const fetchData = React.useCallback(async () => {
        if (userId) {
            const userData = await getPartnerUser<PartnerUserDetails>(get)(userId);
            userData.rowIdentifiers = userData.rowIdentifiers || "";
            setUser(userData);
        }
        else {
            setUser({
                displayName: "",
                email: "",
                firstName: "",
                id: "",
                lastName: "",
                partnerId: partnerId || "",
                rowIdentifiers: `${partnerId}`,
                tableNames: "none",
                isActive: true,
                inviteEmailSent: false
            });
        }
    }, [get, userId, partnerId]);

    useEffect(() => {
        (async () => {
            await fetchData();
            await fetchDbData();
        })();
    }, [get, fetchData, fetchDbData]);

    const customWidgets = useMemo(() => ({
        DbMultiTableWidget,
        MultiTextWidget,
        DbMultiPartnerWidget
    }), []);

    const handleSubmit = useCallback(async ({ formData }: any) => {
        setSubmitting(true);
        const newUser = formData as PartnerUserDetails;
        newUser.displayName = `${newUser.firstName} ${newUser.lastName}`;

        if (newUser.rowIdentifiers) {
            const rowIdentifiers = newUser.rowIdentifiers?.split(',');

            if (rowIdentifiers?.length > 0) {
                const rowIdentifiersFiltered = rowIdentifiers.filter(r => r);
                newUser.rowIdentifiers = rowIdentifiersFiltered.join(',');
            }
        }

        if (userId) {
            updatePartnerUser(put)(userId, newUser).then(() => {
                toast.success("User details updated");

                setUser(newUser);
                return;
            }).finally(() => {
                setSubmitting(false);
            });
        }
        else {
            newUser.roles = [];
            newUser.teamIds = [];
            createPartnerUser<PartnerUserDetails>(post)(newUser).then((result) => {
                toast.success("User created");
                setUser(newUser);
                navigate(`/partnerManagement/partners/${partnerId}/users/${result?.id}`);
                return;
            }).finally(() => {
                setSubmitting(false);
            });
        }
    }, [put, post, userId, navigate, partnerId]);

    const handleError = useCallback((e: any): void => {
        console.log("Data changed: ", e.formData);
    }, []);

    const handleUndo = useCallback(async (): Promise<void> => {
        setSubmitting(true);
        fetchData().then(() => {
            toast.warning("User data has been reset");
            return;
        }).finally(() => {
            setSubmitting(false);
        });
    }, [fetchData]);

    const handleDisable = useCallback(async (): Promise<boolean> => {
        if (userId) {
            setSubmitting(true);
            if (user) {
                user.isActive = !user.isActive;
                return updatePartnerUser(put)(userId, user).then(() => {
                    toast.warning(`User has been ${user.isActive ? 'enabled' : 'disabled'}`);
                    navigate(`/partnerManagement/partners/${partnerId}/users/${userId}`);

                    return true;
                }).finally(() => {
                    setSubmitting(false);
                });
            }
        }

        return false;
    }, [userId, put, user, navigate, partnerId]);

    const navigateToDisable = () => {
        navigate(`/partnerManagement/partners/${partnerId}/users/${userId}/delete`);
    };

    const handleWarningModalOpen = () => setWarningModalOpen(true);

    const handleWarningModalClose = () => {
        setWarningModalOpen(false);
        navigate(`/partnerManagement/partners/${partnerId}/users/${userId}`);
    };

    useEffect(() => {
        if (isDelete) {
            handleWarningModalOpen();
        }
    }, [isDelete]);

    const handleConfirmDisable = useCallback(async (): Promise<void> => {
        const isDeleted = await handleDisable();
        setWarningModalOpen(false);
        if (!isDeleted) {
            navigate(`/partnerManagement/partners/${partnerId}/users/${userId}`);
        }
    }, [navigate, handleDisable, userId, partnerId]);

    if (!user || !dbData) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <PartnerUserContext.Provider
            value={{
                dbData,
                partners
            }}>
            <Container>
                <Box sx={{ marginTop: '1.2rem!important', display: 'flex', justifyContent: 'space-between' }}>
                    <Box className="bb-title-bar">
                        <h2 className="bb-m0 bb-p0">User Manager</h2>
                    </Box>
                    <Box>
                        <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon"
                            to={`/partnerManagement/partners/${partnerId}/users`}>
                            <Button variant="outlined" size="small" sx={{ padding: '6px 12px', fontSize: '0.95rem', minWidth: 'auto' }}
                                startIcon={<KeyboardArrowLeftIcon/>}>
                            Back to Users
                            </Button>
                        </Link>
                    </Box>
                </Box>
                <Breadcrumbs className="bb-breadcrumb bb-mb-3 bb-flex bb-align-items-center"
                    separator="›" aria-label="breadcrumb">
                    <Link to="/partnerManagement">Partner Management</Link>
                    <Link to="/partnerManagement/partners">Partners</Link>
                    <Link to={`/partnerManagement/partners/${partnerId}/users`}>Manage Users</Link>
                    <Typography color="text.primary" className="bb-m0 bb-p0 bb-crumb-text">{user?.firstName + " " + user?.lastName}</Typography>
                </Breadcrumbs>
                <Alert className="bb-title-info bb-mb-2" severity="info" style={{ width: "100%" }}>Manage your <b>Partner User Details</b> here.
                </Alert>
                <Box className="bb-flex bb-ui-box bb-flex-column bb-tac">
                    <Box className="bb-page-edit-cont">
                        {submitting && <Box className="bb-tac"><CircularProgress /></Box>}
                        <Box className="bb-flex bb-flex-column bb-justify-content-center bb-tac" sx={{ minHeight: "60vh" }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box>
                                    {user.lastLoginDate &&
                                    <Typography
                                        sx={{ fontSize: 18, fontWeight: "bold" }}
                                        color="text.secondary"
                                        gutterBottom
                                        alignContent="left"
                                    >
                                        Last Login: <DateDisplay value={new Date(user.lastLoginDate)} dateStyle="short" timeStyle="short" />
                                    </Typography>
                                    }
                                </Box>
                                <Box className="page-edit-buttons" sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    {userId &&
                                    <>
                                        <Grid item xs={1}>
                                            <Button
                                                onClick={navigateToDisable}
                                                variant="outlined"
                                                startIcon={<DeleteOutlineIcon />}
                                                color="error"
                                                sx={{ marginRight: "10px" }}
                                                disabled={submitting}>
                                                <>{user.isActive ? "Disable" : "Enable"}</>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={1} sx={{ marginRight: "10px" }}>
                                            <UndoButton onClick={handleUndo} disable={submitting}></UndoButton>
                                        </Grid>
                                    </>
                                    }
                                    <Button variant="contained" startIcon={<SaveOutlinedIcon />} disabled={submitting} type="submit" form="partner_user_submit">
                                    Save
                                    </Button>
                                </Box>
                            </Box>
                            <Box sx={{ width: "100%" }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="bb-sp-cont">
                                    <Form
                                        id={"partner_user_submit"}
                                        schema={userSchemaModel as JSONSchema7}
                                        formData={user}
                                        validator={validatorAjv8}
                                        onSubmit={handleSubmit}
                                        onError={handleError}
                                        widgets={customWidgets}
                                        uiSchema={uiSchema}
                                        liveValidate={true}
                                        showErrorList={false}
                                        disabled={submitting}
                                        className="bb-pathways-initial-form"
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
            <WarningModal
                open={warningModalOpen}
                description={"Are you sure you want to perform this action?"}
                onConfirm={handleConfirmDisable}
                onCancel={handleWarningModalClose}
            />
        </PartnerUserContext.Provider>);
};
