import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { useFavorites } from './FavoriteContext';
import { FavoriteCard } from './FavoriteCard';
import PushPinIcon from '@mui/icons-material/PushPin';

export const FavoritesList = () => {
    const { favorites } = useFavorites();

    return (
        <Grid sx={{ width: '100%', backgroundColor: '#25454d', borderRadius: '5px', marginTop: '2rem', padding: '1rem' }}
            className="animated animatedFadeInUp fadeInUp2">
            <Box>
                <Typography sx={{ textAlign: 'center', color: '#ffffff' }}>
                    <h2> Your Favourites </h2>
                </Typography>
            </Box>
            <Grid container spacing={2}>
                {favorites.length === 0 ? (
                    <Grid item xs={12}>
                        <PushPinIcon sx={{ fontSize: 40, color: '#ffffff', display: 'block', margin: 'auto' }} />
                        <Typography sx={{ textAlign: 'center', color: '#ffffff', paddingBottom: '10px', paddingTop: '10px' }}>
                        Your dashboard is currently empty. To add a favourite, click the pin icon at the top of any page.
                        </Typography>
                    </Grid>
                ) : (
                    favorites.map((favorite: { url: string; title: string; subtitle: string }, index: number) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                            <FavoriteCard page={favorite.url} title={favorite.title} subtitle={favorite.subtitle} />
                        </Grid>
                    ))
                )}
            </Grid>
        </Grid>
    );
};
