import * as React from "react";

import { Box } from "@mui/material";

export interface BBMediaPanelProps {
    panel: any;
}

export const BBMediaPanel: React.FC<BBMediaPanelProps> = ({ panel }) => {
    return (<Box sx={{ borderRadius: "5px" }} className="bb-tal">
        {panel?.imgUrl ? (<img src={panel?.imgUrl} className="bb-pe-img, bb-width-100 bb-med-panel-img" />) : (<span></span>)}
        {panel?.panelHeading ? (<h3 className="bb-tal">{panel?.panelHeading}</h3>) : (<span></span>)}
        {panel?.panelHeading ? (<p className="bb-tal">{panel?.panelPara}</p>) : (<span></span>)}
        <ul className="bb-m0 bb-p0">
            {panel?.links?.map((link: any, index: number) => (
                <li key={index} className="bb-med-pan-link">
                    {link.linkThumbUrl ? (<img src={link.linkThumbUrl} className="bb-med-panel-thumb bb-mr-1"/>) : (<span></span>)}
                    <a href={link.linkUrl}>{link.linkText}</a>
                </li>
            ))}
        </ul>
    </Box>
    );
};
