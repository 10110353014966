import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

interface BeRightBackDialogProps {
    open: boolean;
    onCancel: () => void;
    onSetBRB: () => void;
}

export const BeRightBackDialog = ({ open, onCancel, onSetBRB }: BeRightBackDialogProps) => {
    return (
        <Dialog open={open}>
            <DialogTitle className="bbot-dialog-title">
                <Typography>
                    Be Right Back
                </Typography>
            </DialogTitle>
            <DialogContent className="bbot-dialog-content">
                <Typography>
                    You are about to set status as 'Be Right Back', do you wish to continue?
                </Typography>
            </DialogContent>
            <DialogActions className="bbot-dialog-actions">
                <Button onClick={onCancel}>No</Button>
                <Button onClick={onSetBRB}>Yes</Button>
            </DialogActions>
        </Dialog>
    );
};
