import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Link } from "@mui/material";
import './ColourPickerStyle.css';

const ContrastChecker = () => {
    const [bgColor, setBgColor] = useState('');
    const [fgColor, setFgColor] = useState('');
    const [result, setResult] = useState('');
    const [contrastRatio, setContrastRatio] = useState<number | null>(null);
    const isValidHex = (hex: string) => /^#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})$/.test(hex);

    const calculateLuminance = (hex: string) => {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        const a = [r, g, b].map(v => {
            v /= 255;
            return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
        });
        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    };

    const checkContrast = () => {
        if (!isValidHex(bgColor) || !isValidHex(fgColor)) {
            setResult(
                'The color code entered is invalid. Please use a valid hex code ' +
                '(e.g., #FFFFFF for white or #000000 for black).'
            );
            setContrastRatio(null); // Clear any previous ratio
            return;
        }

        const bgLuminance = calculateLuminance(bgColor);
        const fgLuminance = calculateLuminance(fgColor);
        const calculatedContrastRatio = (Math.max(bgLuminance, fgLuminance) + 0.05) / (Math.min(bgLuminance, fgLuminance) + 0.05);
        setContrastRatio(calculatedContrastRatio); // Store the ratio in state

        if (calculatedContrastRatio >= 4.5) {
            setResult(
                `✅ Great news! Your colors have a contrast ratio of ${calculatedContrastRatio.toFixed(2)}, ` +
                `meeting the WCAG AA minimum contrast requirement of 4.5:1.`
            );
        } else {
            setResult(
                `❌ Your colors have a contrast ratio of ${calculatedContrastRatio.toFixed(2)}, ` +
                `which is below the WCAG AA requirement of 4.5:1. Try increasing the brightness ` +
                `of the foreground color or darkening the background color to improve accessibility.`
            );
        }
    };

    return (
        <Box sx={{ }}>
            <Typography variant="h4">Contrast Checker</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {isValidHex(bgColor) && (
                    <Box
                        sx={{
                            width: 42,
                            height: 42,
                            backgroundColor: bgColor,
                            border: '1px solid #000',
                            borderRadius: '4px'
                        }}
                    />
                )}
                <TextField
                    label="Background Hex Colour"
                    value={bgColor}
                    onChange={(e) => setBgColor(e.target.value)}
                    fullWidth
                    margin="normal"
                />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {isValidHex(fgColor) && (
                    <Box
                        sx={{
                            width: 42,
                            height: 42,
                            backgroundColor: fgColor,
                            border: '1px solid #000',
                            borderRadius: '4px'
                        }}
                    />
                )}
                <TextField
                    label="Foreground Hex Colour"
                    value={fgColor}
                    onChange={(e) => setFgColor(e.target.value)}
                    fullWidth
                    margin="normal"
                />
            </Box>
            {contrastRatio !== null && (
                <Box
                    sx={{
                        p: 1.4,
                        mt: 2,
                        border: '1px solid',
                        borderColor: 'black',
                        backgroundColor: '#F5F5F5',
                        borderRadius: 1,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        width: '60%'
                    }}
                >
                    <Typography variant="h4" sx={{ marginBottom: '-0rem!important' }}>
        Contrast Ratio: {contrastRatio.toFixed(2)}
                    </Typography>
                </Box>
            )}
            {result && (
                <Typography variant="h6" sx={{ mt: 2 }}>
                    {result}
                </Typography>
            )}
            <Link href="https://www.w3.org/WAI/WCAG22/Understanding/contrast-minimum.html" target="_blank" sx={{ mt: 2, display: 'block' }}>
                WCAG Guidelines for Contrast
            </Link>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button className='CheckerButton' variant="contained" onClick={checkContrast} sx={{ mt: 3 }}>
                Check Contrast
                </Button>
            </Box>
        </Box>
    );
};

export { ContrastChecker };
