import { useParams, Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import {
    Container,
    Box,
    Button,
    CircularProgress,
    Select,
    MenuItem,
    Tooltip,
    Alert
} from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { SelectChangeEvent } from '@mui/material/Select';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { useState, useEffect, useCallback } from "react";
import { ResultEntityModel } from "../../schemas/eventManagement/resultSchema";
import { SearchField } from "../applications/customWidget/Search/SearchField";
import useDataClient from "../../axios/dataClient";
import {
    getPartner,
    getPartnerUsers,
    sendEmailInvite
} from "../../services/api/PartnerManagementService";
import { PartnerUser, Partner } from "../../schemas/partnerManagement/schema";
import { newPartnerUser } from "../newModuleNames";

export const PartnerUsersList = () => {
    const { get, put } = useDataClient();
    const { partnerId } = useParams();

    const [partner, setPartner] = useState<Partner>();
    const [partnerUsers, setPartnerUsers] = useState<PartnerUser[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [searchString, setSearchString] = useState<string>();
    const [filteredPartnerUsers, setFilteredPartnerUsers] = useState<PartnerUser[]>([]);
    const [selectedRows, setSelectedRows] = useState<PartnerUser[]>([]);

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Box className="bb-med-search bb-ml-2" sx={{ minWidth: 200, marginLeft: '0px!important' }}>
                        <Select
                            id="filter-select"
                            value={listFilter}
                            onChange={handleFilterChange}
                            variant="outlined"
                            fullWidth
                            sx={{ height: 40 }}
                        >
                            <MenuItem value={filterShowAll}>Show All</MenuItem>
                            <MenuItem value={filterShowActiveOnly}>Show Active Users Only</MenuItem>
                        </Select>
                    </Box>
                    <Tooltip
                        title="Search for a User By Email"
                        arrow
                        placement="top"
                        PopperProps={{
                            sx: {
                                '& .MuiTooltip-tooltip': {
                                    backgroundColor: 'black',
                                    color: 'white',
                                    fontSize: '.8rem',
                                    padding: '0.5rem',
                                },
                                '& .MuiTooltip-arrow': {
                                    color: 'black',
                                },
                            },
                        }}>
                        <Box className="bb-med-search" sx={{ paddingLeft: '0px!important', marginLeft: '0px!important' }}>
                            <SearchField
                                searchString={searchString}
                                callSearch={callSearch}
                                timeout={500}
                            />
                        </Box>
                    </Tooltip>
                </Box>
                <Box>
                    <Tooltip
                        title="Search for a user by Email."
                        arrow
                        placement="top"
                        PopperProps={{
                            sx: {
                                '& .MuiTooltip-tooltip': {
                                    backgroundColor: 'black',
                                    color: 'white',
                                    fontSize: '.8rem',
                                    padding: '0.5rem',
                                },
                                '& .MuiTooltip-arrow': {
                                    color: 'black',
                                },
                            },
                        }}>
                        <Button
                            variant="contained"
                            className="bb-ml-2 bb-primary-button"
                            startIcon={<EmailOutlinedIcon />}
                            onClick={handleSendEmail}
                            disabled={isLoading || selectedRows.length === 0}
                        >
                        Send Invitation Email
                        </Button>
                    </Tooltip>
                    <GridToolbarExport />
                </Box>
            </GridToolbarContainer>
        );
    };

    const filterShowAll = "ShowAll";
    const filterShowActiveOnly = "filterShowActiveOnly";
    const [listFilter, setListFilter] = useState(filterShowActiveOnly);

    const callSearch = useCallback((search?: string) => {
        setSearchString(search);
    }, [setSearchString]);

    const handleFilterChange = (event: SelectChangeEvent) => {
        setListFilter(event.target.value as string);
    };

    useEffect(() => {
        if (!partnerUsers) {
            return;
        }

        const searchStringUpper = searchString?.toUpperCase();
        const filtered = partnerUsers.filter((partnerUser) =>
            (!searchStringUpper || (partnerUser?.email && partnerUser?.email.toUpperCase().includes(searchStringUpper)))
            && (listFilter === filterShowAll || partnerUser?.isActive === true));
        setFilteredPartnerUsers(filtered);
    }, [searchString, listFilter, partnerUsers]);

    const fetchData = useCallback(async () => {
        if (partnerId) {
            getPartner<ResultEntityModel<Partner>>(get)(partnerId).then((partnerData) => {
                if (partnerData?.isSuccess) {
                    setPartner(partnerData.entity);
                }
                return;
            });

            getPartnerUsers<PartnerUser[]>(get)(partnerId).then((users) => {
                setPartnerUsers(users);
                setFilteredPartnerUsers(users);
                setLoading(false);
                return;
            });
        }
    }, [get, partnerId]);

    useEffect(() => {
        setSearchString("");
    }, []);

    const refreshList = useCallback(() => {
        setLoading(true);
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [fetchData]);

    const handleSendEmail = async () => {
        const selectedUserIds = selectedRows.map(user => user.id) as string[] ;

        if (selectedUserIds.length === 0) {
            return;
        }
        sendEmailInvite(put)(selectedUserIds).then((partnerData) => {

            const updatedUsers = partnerUsers.map(user =>
                selectedUserIds.includes(user.id as string) ? { ...user, inviteEmailSent: true } : user
            );

            setPartnerUsers(updatedUsers);
            setFilteredPartnerUsers(updatedUsers);
            setSelectedRows([]);
            return partnerData;
        });
    };

    const columns: GridColDef[] = [
        {
            field: 'firstName',
            headerName: 'Name',
            width: 150,
            renderCell: (params) => (
                <Link
                    to={`/partnerManagement/partners/${partnerId}/users/${params.row.id}`}
                >
                    {params.row.firstName} {params.row.lastName}
                </Link>
            )
        },
        { field: 'email', headerName: 'Email', width: 340 },
        { field: 'isActive', headerName: 'Active', width: 100, type: 'boolean' },
        { field: 'inviteEmailSent', headerName: 'Invite Email Sent', width: 100, type: 'boolean' },
        { field: 'actions', headerName: 'View User', flex: 1, renderCell: (params) => (
            <Button
                variant="contained"
                size="small"
                sx={{ padding: '4px 8px', margin: '15px 35px 15px 15px', fontSize: '0.75rem', background: 'transparent', color: '#000000', '&:hover': {
                    background: 'transparent',
                    color: '#000000',
                }, }}
                startIcon={<RemoveRedEyeOutlinedIcon sx={{ color: '#11a681' }} />}
                component={Link}
                to={`/partnerManagement/partners/${partnerId}/users/${params.row.id}`}
            >
                View User
            </Button>
        ) },
    ];

    return (
        <Container>
            <Box sx={{ marginTop: '1.2rem!important', display: 'flex', justifyContent: 'space-between' }}>
                <Box className="bb-title-bar">
                    <h2 className="bb-m0 bb-p0">Manage Users</h2>
                </Box>
                <Box>
                    <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon"
                        to={`/partnerManagement/partners/${partnerId}`}>
                        <Button variant="outlined" size="small" sx={{ padding: '6px 12px', fontSize: '0.95rem', minWidth: 'auto' }}
                            startIcon={<KeyboardArrowLeftIcon/>} disabled={isLoading}>
                            Back to Partner
                        </Button>
                    </Link>
                </Box>
            </Box>
            <Breadcrumbs className="bb-breadcrumb bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
                <Link to="/partnerManagement">Partner Management</Link>
                <Link to="/partnerManagement/partners">Partners</Link>
                <Typography color="text.primary" className="bb-m0 bb-p0">Manage Users</Typography>
            </Breadcrumbs>
            <Alert className="bb-title-info bb-mb-2" severity="info" style={{ width: "100%", marginTop: '20px' }}>Manage your <b>Partner Users</b> here.
            </Alert>
            <Box className="bb-flex bb-ui-box bb-flex-column bb-tac">
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    {partner &&
                    <Box>
                        <h3 className="bb-m0 bb-p0">Partner: {partner.partnerName}</h3>
                    </Box>
                    }
                    <Box>
                        <Button
                            variant="contained"
                            className="bb-ml-auto bb-primary-button"
                            onClick={refreshList}
                            startIcon={<RefreshOutlinedIcon />}
                            disabled={isLoading}
                            sx={{ marginRight: '10px', color: '#000000!important', backgroundColor: '#ffffff!important', border: '1px solid #11a681' }}
                        >
                        Refresh
                        </Button>
                        <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon"
                            to={`/partnerManagement/partners/${partnerId}/users/${newPartnerUser}`}>
                            <Button variant="contained" disabled={isLoading}>
                            New User
                            </Button>
                        </Link>
                    </Box>
                </Box>
                {isLoading
                    ? (<Box className="bb-tac"><CircularProgress /></Box>)
                    : (
                        <Box sx={{ height: 400, width: '100%' }}>
                            <DataGrid
                                rows={filteredPartnerUsers}
                                columns={columns}
                                pagination
                                pageSizeOptions={[5, 10, 25]}
                                checkboxSelection
                                autoHeight
                                isRowSelectable={(params) => !params.row.inviteEmailSent}
                                onRowSelectionModelChange={(newSelection) => {
                                    const selectedIDs = new Set(newSelection);
                                    const selectedRowsData = partnerUsers.filter((row) => selectedIDs.has(row.id as string));
                                    setSelectedRows(selectedRowsData);
                                }}
                                sortingOrder={['asc', 'desc']}
                                slots={{
                                    toolbar: CustomToolbar,
                                }}
                            />
                        </Box>
                    )
                }
            </Box>
        </Container>
    );
};
