import { useCallback, useEffect, useState } from "react";
import { Box, Grid, Container } from "@mui/material";
import { LiveChatDashboardData } from "../../../services/model/livechat/dashboard/liveChatDashboardData";
import { LiveChatDashboardPageData } from "../../../services/model/livechat/dashboard/liveChatDashboardPageData";
import useDataClient from "../../../axios/dataClient";
import { ActiveConversations } from "./ActiveUsers";
import { RefreshBar } from "./RefreshBar";
import { GroupedInteractions } from "./GroupedInteractions";
import { PageConversations } from "./PageConversations";
import { ActiveLiveAgents } from "./ActiveLiveAgents";
import { ActiveChats } from "./ActiveChats";
import { AvailableAgents } from "./AvailableAgents";
import { LockedChats } from "./LockedChats";
export const LiveChatDashboard = () => {
    const { get } = useDataClient();

    const [dashboardData, setDashboardData] = useState<LiveChatDashboardData>({
        conversationCount: 0,
        liveAgentCount: 0,
        activeChatCount: 0,
        groupedInteractionsData: [],
        liveAgentData: [],
        lockedChatData: [],
        lastRefreshDate: new Date().toString()
    });

    const [dashboardPageData, setDashboardPageDataageData] = useState<{ pathName: string; data: LiveChatDashboardPageData }>({
        pathName: "",
        data: {
            pageConversationData: []
        }
    });

    const [isLoadingData, setIsLoadingData] = useState(true);

    const [isPageDataVisible, setIsPageDataVisible] = useState(false);
    const [isLoadingPageData, setIsLoadingPageData] = useState(true);

    const handleRefresh = useCallback(async () => {
        setIsLoadingData(true);
        const data = await get<LiveChatDashboardData>("api/chatdashboard");
        setDashboardData(data);
        setIsLoadingData(false);
    }, [get]);

    const onPageSelected = useCallback(async (botId: string, path: string) => {
        setIsLoadingPageData(true);
        setIsPageDataVisible(true);
        const data = await get<LiveChatDashboardPageData>(`api/chatdashboard/page?botId=${encodeURIComponent(botId)}&path=${encodeURIComponent(path)}`);
        const pathName = new URL(path).pathname;
        setDashboardPageDataageData({ data, pathName });
        setIsLoadingPageData(false);
    }, [get]);

    const removeLockedChat = useCallback((chatId: string) => {
        const newData = dashboardData.lockedChatData.filter(item => item.chatId !== chatId);
        setDashboardData({
            ...dashboardData,
            lockedChatData: newData
        });
    }, [dashboardData]);

    const removeDisconnectedAgent = useCallback((agentId: string) => {
        const newData = dashboardData.liveAgentData.filter(item => item.id !== agentId);
        setDashboardData({
            ...dashboardData,
            liveAgentData: newData
        });}, [dashboardData]);

    useEffect(() => {
        handleRefresh();
    }, [handleRefresh]);

    const lastRefreshDate = new Date(dashboardData.lastRefreshDate);

    return (<>
        <Container>
            <Box className="bb-tac bb-width-100 bb-mb-2 bb-refresh-bar">
                <RefreshBar isLoading={isLoadingData} lastRefreshDate={lastRefreshDate} handleRefresh={handleRefresh} />
            </Box>
            <Grid container spacing={2} className="bb-width-100">
                <Grid item xs={4} sm={4} md={4} >
                    <div className="bb-tac">
                        <ActiveConversations isLoading={isLoadingData} conversationCount={dashboardData.conversationCount} />
                    </div>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <div className="bb-tac">
                        <ActiveLiveAgents isLoading={isLoadingData} liveAgentsCount={dashboardData.liveAgentCount} />
                    </div>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <div className="bb-tac">
                        <ActiveChats isLoading={isLoadingData} chatCount={dashboardData.activeChatCount} />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="bb-tac">
                        <AvailableAgents isLoading={isLoadingData}
                            onAgentDisconnected={removeDisconnectedAgent}
                            liveAgentData={dashboardData.liveAgentData} />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="bb-tac">
                        <GroupedInteractions isLoading={isLoadingData}
                            groupedInteractionsData={dashboardData.groupedInteractionsData}
                            onPageSelected={onPageSelected} />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="bb-tac">
                        <LockedChats  isLoading={isLoadingData} lockedChatsData={dashboardData.lockedChatData} removeLockedChat={removeLockedChat} />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <PageConversations isLoading={isLoadingPageData}
                        isVisible={isPageDataVisible}
                        pageConversationData={dashboardPageData.data.pageConversationData}
                        path={dashboardPageData.pathName} />
                </Grid>
            </Grid>
        </Container>
    </>);
};
