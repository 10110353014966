import {
    Button,
    CircularProgress,
    Grid,
    Container,
    Box,
    Breadcrumbs,
    Typography,
    Alert
} from "@mui/material";

import { Form, Formik } from "formik";

import { useState, useCallback, useEffect } from "react";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import { useParams, Link, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import useDataClient from "../../../axios/dataClient";

import { TextField } from "../../../material/TextField";

import { Booking, BookingSchema } from "../../../schemas/eventManagement/bookingSchema";

import { ResultModel, ResultEntityModel } from "../../../schemas/eventManagement/resultSchema";

import { getBooking, createBooking, updateBooking, deleteBooking } from "../../../services/api/BookingManagementService";

import { UndoButton } from "../../applications/UndoButton";

import { WarningModal } from "../../shared/WarningModal";

import { errors } from "../../ErrorDisplay";

import { EventDetails } from '../schedule/EventDetails';

export const BookingForm = () => {

    const { put, post, get, deleteRequest } = useDataClient();
    const { eventId, scheduleId, bookingId } = useParams();
    const navigate = useNavigate();
    const isDelete = location?.pathname?.endsWith("/delete");

    const [booking, setBooking] = useState<Booking>();
    const [isLoading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [warningModalOpen, setWarningModalOpen] = useState(false);

    const fetchData = useCallback(async () => {
        if (!scheduleId) {
            return;
        }

        if (bookingId) {
            getBooking<ResultEntityModel<Booking>>(get)(scheduleId, bookingId).then((bookingData) => {
                if (bookingData?.isSuccess) {
                    setBooking(bookingData.entity);
                } else if (bookingData?.messages) {
                    errors(bookingData.messages);
                }

                return;
            }).finally(() => {
                setLoading(false);
            });
        }
        else {
            setBooking({
                bookingNumber: "",
                userEmail: undefined
            });
            setLoading(false);
        }
    }, [get, bookingId, scheduleId]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await fetchData();
        })();
    }, [fetchData]);

    const callSave = useCallback(async (bookingData: Booking) => {
        if (!eventId || !scheduleId) {
            return;
        }

        setSubmitting(true);

        const bookingDataToSave: Booking = { ...bookingData, scheduleId };

        if (bookingId) {
            updateBooking<ResultModel>(put)(scheduleId, bookingId, bookingDataToSave).then((result) => {
                if (result.isSuccess) {
                    toast.success("Booking updated");
                } else if (result?.messages) {
                    errors(result.messages);
                }
                return;
            }).finally(() => {
                setSubmitting(false);
            });
        }
        else {
            createBooking<ResultEntityModel<Booking>>(post)(scheduleId, bookingDataToSave).then((result) => {
                if (result.isSuccess) {
                    if (result.entity?.isAddedToWaitingQueue) {
                        toast.success("The event booking capacity is already full. " +
                            "The booking request is placed in a waiting queue and will be confirmed if a space frees up for the event.");
                        navigate(`/eventManagement/events/${eventId}/schedules/${scheduleId}/bookings`);
                    }
                    else {
                        toast.success("Booking created");
                        navigate(`/eventManagement/events/${eventId}/schedules/${scheduleId}/bookings/${result.entity?.rowKey}`);
                    }
                } else if (result?.messages) {
                    errors(result.messages);
                }
                return;
            }).finally(() => {
                setSubmitting(false);
            });
        }
    }, [put, post, eventId, scheduleId, bookingId, navigate]);

    const navigateToDelete = () => {
        navigate(`/eventManagement/events/${eventId}/schedules/${scheduleId}/bookings/${bookingId}/delete`);
    };

    const handleUndo = useCallback(async (): Promise<void> => {
        setSubmitting(true);

        fetchData().then(() => {
            toast.warning("Booking data has been reset");
            return;
        }).finally(() => {
            setSubmitting(false);
        });
    }, [fetchData]);

    const handleDelete = useCallback(async (): Promise<boolean> => {
        if (bookingId && scheduleId) {
            setSubmitting(true);
            if (booking) {
                return deleteBooking<ResultModel>(deleteRequest)(scheduleId, bookingId).then((result) => {
                    if (result?.isSuccess) {
                        setBooking({ ...booking, deleted: true });
                        toast.warning(`Booking has been marked as deleted`);
                        setLoading(true);
                        fetchData();
                        return true;
                    } else if (result?.messages) {
                        errors(result.messages);
                    }
                    return false;
                }).finally(() => {
                    setSubmitting(false);
                });
            }
        }

        return false;
    }, [scheduleId, bookingId, deleteRequest, booking, fetchData]);

    const handleConfirmDelete = useCallback(async (): Promise<void> => {
        await handleDelete();
        setWarningModalOpen(false);
        navigate(`/eventManagement/events/${eventId}/schedules/${scheduleId}/bookings/${bookingId}`);
    }, [navigate, handleDelete, eventId, scheduleId, bookingId]);

    const handleWarningModalClose = () => {
        setWarningModalOpen(false);
        navigate(`/eventManagement/events/${eventId}/schedules/${scheduleId}/bookings/${bookingId}`);
    };

    const handleWarningModalOpen = () => setWarningModalOpen(true);

    useEffect(() => {
        if (isDelete) {
            handleWarningModalOpen();
        }
    }, [isDelete]);

    if (isLoading || !booking) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <>
            <Container>
                <Box sx={{ marginTop: '1.2rem!important', display: 'flex', justifyContent: 'space-between' }}>
                    <Box className="bb-title-bar">
                        <h2 className="bb-m0 bb-p0">Booking:</h2>{eventId && <EventDetails eventId={eventId} />}
                    </Box>
                    <Box>
                        <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon"
                            to={`/eventManagement/events/${eventId}/schedules/${scheduleId}/bookings`}>
                            <Button variant="outlined" size="small" sx={{ padding: '6px 12px', fontSize: '0.95rem', minWidth: 'auto' }}
                                startIcon={<KeyboardArrowLeftIcon/>} disabled={isLoading}>
                            Back to Bookings
                            </Button>
                        </Link>
                    </Box>
                </Box>
                <Breadcrumbs className="bb-breadcrumb bb-mb-3 bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
                    <Link to="/eventManagement">Event Management</Link>
                    <Link to="/eventManagement/events">Events</Link>
                    <Link to={`/eventManagement/events/${eventId}/schedules`}>Schedules</Link>
                    <Link to={`/eventManagement/events/${eventId}/schedules/${scheduleId}/bookings`}>Bookings</Link>
                </Breadcrumbs>
                <Alert className="bb-title-info bb-mb-2 bb-align-items-center bb-flex" severity="info" sx={{ marginTop: '18px!important' }}
                >
                 Welcome to the <b>Bookings Page.</b> Here, you can manage a specific booking for your event.
                </Alert>
                <Box className="bb-flex bb-ui-box bb-flex-column bb-tac">
                    <Box className="bb-page-edit-cont">
                        <Box className="bb-flex bb-flex-column bb-tac" sx={{ minHeight: "60vh" }}>
                            <Box className="page-edit-buttons bb-mb-4" sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                <Box sx={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
                                    <Typography sx={{ fontSize: 18, fontWeight: "bold", mb: 0 }} alignContent="left"
                                        className="bb-mb-0 bb-pb-0">
                                    Booking Editor
                                    </Typography>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                                    {bookingId &&
                                    <>
                                        {!booking.deleted &&
                                            <Grid item xs={1}>
                                                <Button
                                                    onClick={navigateToDelete}
                                                    variant="outlined"
                                                    startIcon={<DeleteOutlineIcon />}
                                                    color="error"
                                                    disabled={submitting}>
                                                    Delete
                                                </Button>
                                            </Grid>
                                        }
                                        <Grid item xs={1}>
                                            <UndoButton onClick={handleUndo} disable={submitting}></UndoButton>
                                        </Grid>
                                    </>
                                    }
                                    <Button variant="contained" startIcon={<SaveOutlinedIcon />} disabled={submitting} type="submit" form="booking_submit">
                                    Save
                                    </Button>
                                </Box>
                            </Box>
                            <Box sx={{ width: "100%" }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="bb-sp-cont">
                                    <Formik<Booking>
                                        initialValues={booking}
                                        onSubmit={callSave}
                                        enableReinitialize={true}
                                        validationSchema={BookingSchema}>
                                        <Form
                                            id={"booking_submit"}
                                            className="bb-pathways-initial-form"
                                        >
                                            <TextField
                                                name="userName"
                                                label="User Name"
                                                variant="outlined"
                                                className="bb-mt-2 bb-mb-4"
                                                required
                                            />
                                            <TextField
                                                name="userEmail"
                                                label="User Email"
                                                variant="outlined"
                                                className="bb-mt-2 bb-mb-4"
                                                required
                                            />
                                            <TextField
                                                name="bookingNumber"
                                                label="Booking Number"
                                                variant="outlined"
                                                className="bb-mt-2 bb-mb-4"
                                                required
                                            />
                                            {bookingId &&
                                                <Box sx={{ textAlign: "left" }}>
                                                    <label>Deleted: {booking.deleted ? "Yes" : "No"}</label>
                                                </Box>
                                            }
                                        </Form>
                                    </Formik>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
            <WarningModal
                open={warningModalOpen}
                description={"Are you sure you want to perform this action?"}
                onConfirm={handleConfirmDelete}
                onCancel={handleWarningModalClose}
            />
        </>);
};
