/* eslint-disable max-len */
import "./css/bb-home.css";
import { Box, Container, Typography, Card, Button } from "@mui/material";
import Grid from "@mui/material/Grid";

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { keyframes } from '@emotion/react';

import { useContext, useEffect, useState } from "react";

import { MemberAppContext } from "../MemberAppContext";

import { useCustomerName } from "../components/customer/useCustomerName";

import banner from '../img/banner.jpg';
import platformassist from '../img/platform-assist.jpg';

import { FavoritesList } from '../components/favorites/FavoritesList';
import { FavoriteProvider } from '../components/favorites/FavoriteContext';

import { VideoHelper } from '../components/shared/VideoHelper';

export const Home = () => {
    const { user } = useContext(MemberAppContext);
    const firstName = user.displayName?.split(" ")[0] ?? "Guest";
    const videoUrl = 'https://media.bbotapp.com/pub/self-service-assist/Self-Service%20-%20Introduction.m4v';
    const [animate, setAnimate] = useState(true);
    const customerName = useCustomerName();

    const colorPulse = keyframes`
    0% {
      background-color: #11a681;
    }
    50% {
      background-color: #0a5c47;
    }
    100% {
      background-color: #11a681;
    }
  `;

    useEffect(() => {
        document.title = 'Beebot AI - Dashboard';
        const timer = setTimeout(() => {
            setAnimate(false);
        }, 12000);

        return () => clearTimeout(timer);
    }, []);

    if (user.isPartnerUser) {
        return (
            <FavoriteProvider>
                <Container sx={{ marginBottom: "3rem" }}>
                    <Box>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 12 }}>
                            <Grid item xs={12} className="animated animatedFadeInUp fadeInUp1 bb-mt-3">
                                <Card className="bb-p-2 bb-mt-3 bb-flex bb-home-banner">
                                    <Box className="bb-flex bb-flex-column bb-justify-content-center bb-p-2 bb-intro-banner-text">
                                        <Typography variant="h2" className="bb-intro-banner-header">Hi <span className="bb-welcome-name">
                                            {firstName}</span>,
                                        </Typography>
                                        <Typography variant="body1" className="bb-mb-2">Welcome to the <strong><span className="bb-welcome-name">{customerName}</span></strong> Partner Portal.</Typography>
                                        <Typography variant="body1">The Partner Portal enables you to manage your Events & Services in one simple place.</Typography>
                                    </Box>
                                    <Box className="bb-flex bb-align-items-center bb-width-100 bb-flex-end">
                                        <img src={banner} alt="Image" className="img-responsive bb-banner-img" />
                                    </Box>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid>
                        <FavoritesList />
                    </Grid>
                    {/*<Box className="bb-dash-history animated animatedFadeInUp fadeInUp2 bb-flex-column">
                    <h2 className="bb-mb-4 bb-mt-3">Your History</h2>
                    <Grid container spacing={{ xs: 2 }}  columns={{ xs: 3 }} className="bb-dash-history-links">
                        {!userRecentActivity.data ? (<Box className="bb-tac"><CircularProgress /></Box>) : (
                            userRecentActivity.data && (userRecentActivity.data ?? []).map((recentActivityItem: any) => {
                                return (
                                    <Grid key={recentActivityItem.url} sx={{ padding: ".2rem" }} className="animated animatedFadeInUp fadeInUp1">
                                        <Link href={recentActivityItem.url}>{recentActivityItem.title}</Link>
                                    </Grid>
                                );
                            })
                        )}
                    </Grid>
                </Box>*/}
                </Container>
            </FavoriteProvider>
        );
    }

    return (
        <FavoriteProvider>
            <Container sx={{ marginBottom: "3rem" }}>
                <Box>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 12 }}>
                        <Grid item xs={12} className="animated animatedFadeInUp fadeInUp1 bb-mt-3">
                            <Card className="bb-p-2 bb-mt-3 bb-flex bb-home-banner">
                                <Box className="bb-flex bb-flex-column bb-justify-content-center bb-p-2 bb-intro-banner-text">
                                    <Typography variant="h2" className="bb-intro-banner-header">Hi <span className="bb-welcome-name">
                                        {firstName}</span>,
                                    </Typography>
                                    <Typography variant="body1" className="bb-mb-2">Welcome to the <strong>Beebot AI Platform</strong>.</Typography>
                                    <Typography variant="body1">Self-Service enables you to manage your <span className="bb-welcome-name">
                                        <strong>{customerName}</strong></span> applications and services, whilst also providing valuable insights into user engagement.</Typography>
                                    <Button sx={{
                                        width: "fit-content",
                                        backgroundColor: '#11a681',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: '#11a681'
                                        },
                                        marginTop: "1rem",
                                        animation: animate ? `${colorPulse} 3s infinite` : 'none',
                                    }}>
                                        <VideoHelper
                                            videoUrl={videoUrl}
                                            triggerElement={
                                                <a
                                                    href="#"
                                                    className="bb-link-white bb-mr-1"
                                                >
                                                Learn about Self Service
                                                    <HelpOutlineIcon sx={{ marginLeft: '0.5rem' }} />
                                                </a>
                                            }
                                        />
                                    </Button>
                                </Box>
                                <Box className="bb-flex bb-align-items-center bb-width-100 bb-flex-end">
                                    <img src={banner} alt="Image" className="img-responsive bb-banner-img" />
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
                <Grid>
                    <FavoritesList />
                </Grid>
                {/*<Box className="bb-dash-history animated animatedFadeInUp fadeInUp2 bb-flex-column">
                    <h2 className="bb-mb-4 bb-mt-3">Your History</h2>
                    <Grid container spacing={{ xs: 2 }}  columns={{ xs: 3 }} className="bb-dash-history-links">
                        {!userRecentActivity.data ? (<Box className="bb-tac"><CircularProgress /></Box>) : (
                            userRecentActivity.data && (userRecentActivity.data ?? []).map((recentActivityItem: any) => {
                                return (
                                    <Grid key={recentActivityItem.url} sx={{ padding: ".2rem" }} className="animated animatedFadeInUp fadeInUp1">
                                        <Link href={recentActivityItem.url}>{recentActivityItem.title}</Link>
                                    </Grid>
                                );
                            })
                        )}
                    </Grid>
                </Box>*/}
                <Box>
                    <Grid container spacing={{ xs: 2 }} columns={{ xs: 12 }}>
                        <Grid item xs={12} className="animated animatedFadeInUp fadeInUp3">
                            <Card className="bb-p-2 bb-mt-4 bb-mb-2 bb-pt-2 bb-flex bb-release-banner">
                                <Box className="bb-flex bb-flex-column bb-justify-content-center bb-p-2 bb-tac bb-width-100">
                                    <img src={platformassist} alt="Image" className="bb-release-notes-img" />
                                    <Typography variant="h2" className="bb-mt-2">Release Notes</Typography>
                                    <Typography variant="body1">Cooming Soon! Stay up to date with our latest platform enhancements by reading our current product release notes.</Typography>
                                    {/*<Button href={`https://product.bbotapp.com/?page=release-notes`}
                                        target="_blank" title="Read More" className="bb-flex bb-mt-2 bb-mb-2" variant="contained" sx={{ width: "fit-content", margin: "auto" }}>Read More</Button> */}
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </FavoriteProvider>
    );
};
