import { createContext, useContext } from "react";

import { DbData } from "../../schemas/pages/schema";

import { Partner } from "../../schemas/partnerManagement/schema";

export interface PartnerUserContextSchemas {
    dbData: DbData;
    partners: Partner[];
}

export const PartnerUserContext = createContext<PartnerUserContextSchemas>(
    {
        dbData: {},
        partners: []
    }
);
export const usePartnerUserContext = () => useContext(PartnerUserContext);
