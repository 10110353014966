import { Question } from "../../schemas/eventManagement/questionSchema";

const getQuestions = <schema>(get: <T>(url: string) => Promise<T>) => async (eventId: string) => {
    const result = await get<schema>(`/api/event/${eventId}/question`);
    return result;
};

const getQuestion = <schema>(get: <T>(url: string) => Promise<T>) => async (eventId: string, questionId: string) => {
    const result = await get<schema>(`/api/event/${eventId}/question/${questionId}`);
    return result;
};

const createQuestion = <schema>(post: <T>(url: string, body: any) => Promise<T>) => async (eventId: string, form: Question) => {
    const result = await post<schema>(`/api/event/${eventId}/question`, form);
    return result;
};

const updateQuestion = <schema>(put: <T>(url: string, body: any) => Promise<T>) => async (eventId: string, questionId: string, form: Question) => {
    const result = await put<schema>(`/api/event/${eventId}/question/${questionId}`, form);
    return result;
};

const deleteQuestion = <schema>(deleteRequest: <T>(url: string, body: any) => Promise<T>) => async (eventId: string, questionId: string) => {
    const result = await deleteRequest<schema>(`/api/event/${eventId}/question/${questionId}`, {});
    return result;
};

export { getQuestions, getQuestion, createQuestion, updateQuestion, deleteQuestion };
