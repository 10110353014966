import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Typography, Grid, IconButton, TextField, Modal, Tabs, Tab } from '@mui/material';
import { ColorPicker } from "react-pick-color";
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import { MemberAppContext } from '../../../../MemberAppContext';

export const ColourPickerPresets = () => {
    const [presets, setPresets] = useState<{ title: string; color: string; userSub: string }[]>([]);
    const [showPicker, setShowPicker] = useState(false);
    const [presetTitle, setPresetTitle] = useState('');
    const [selectedColor, setSelectedColor] = useState('#fff');
    const [tabIndex, setTabIndex] = useState(0);
    const { user } = useContext(MemberAppContext);
    const userSub = user?.sub || 'defaultSub';

    useEffect(() => {
        const savedPresets = JSON.parse(localStorage.getItem('colour-presets') || '[]');
        const userPresets = savedPresets.filter((preset: { userSub: string }) => preset.userSub === userSub);
        setPresets(userPresets);
    }, [userSub]);

    const savePreset = () => {
        const newPreset = { title: presetTitle, color: selectedColor, userSub };
        const allPresets = JSON.parse(localStorage.getItem('colour-presets') || '[]');
        const updatedPresets = [...allPresets, newPreset];
        setPresets(updatedPresets.filter((preset: { userSub: string }) => preset.userSub === userSub));
        localStorage.setItem('colour-presets', JSON.stringify(updatedPresets));
        setShowPicker(false);
    };

    const deletePreset = (index: number) => {
        const allPresets = JSON.parse(localStorage.getItem('colour-presets') || '[]');
        const updatedPresets = allPresets.filter((_: { title: string; color: string; userSub: string }, i: number) => i !== index);
        setPresets(updatedPresets.filter((preset: { userSub: string }) => preset.userSub === userSub));
        localStorage.setItem('colour-presets', JSON.stringify(updatedPresets));
    };

    const handleAddNewClick = () => {
        setPresetTitle('');
        setShowPicker(true);
    };

    return (
        <Box className="bb-flex bb-ui-box bb-flex-column">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h4" className="bb-mb-2" color="text.secondary" gutterBottom>
                    Custom Colours
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddNewClick}
                    startIcon={<ColorLensIcon />}
                >
                    Add Colour
                </Button>
            </Box>
            {presets.length === 0 ? (
                <Typography sx={{ marginTop: '1rem' }}>
                   Add <b>Custom Colours</b> to use in the <b>Colour Picker Tool</b> across the <b>Self-Service Portal</b>.</Typography>
            ) : (
                <Grid container spacing={2} sx={{ marginTop: '1rem' }}>
                    {presets.map((preset, index) => (
                        <Grid item key={index}>
                            <Box
                                sx={{
                                    width: 120,
                                    height: 100,
                                    backgroundColor: preset.color,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'relative',
                                    borderRadius: '5px',
                                    border: 'transparent',
                                    cursor: 'pointer',
                                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)'
                                }}
                            >
                                <IconButton
                                    size="small"
                                    sx={{ position: 'absolute', top: 0, right: 0 }}
                                    onClick={() => deletePreset(index)}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            border: '1.4px solid black',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)'
                                        }}
                                    >
                                        <DeleteIcon sx={{ color: 'white' }} />
                                    </Box>
                                </IconButton>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '0.5rem' }}>
                                <Typography variant="caption" sx={{ color: 'black' }}>
                                    {preset.title}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            )}
            <Modal
                open={showPicker}
                onClose={() => setShowPicker(false)}
                aria-labelledby="color-picker-modal"
                aria-describedby="color-picker-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        border: 'thick transparent',
                        borderRadius: '5px'
                    }}
                >
                    <IconButton
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                        onClick={() => setShowPicker(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Tabs value={tabIndex} onChange={(e, newValue) => setTabIndex(newValue)}>
                        <Tab label="Colour Picker" />
                    </Tabs>
                    {tabIndex === 0 && (
                        <Box>
                            <ColorPicker className='ColorPicker' color={selectedColor} onChange={(color) => setSelectedColor(color.hex)} hideAlpha={true} />
                            <TextField
                                label="Preset Name"
                                value={presetTitle}
                                onChange={(e) => setPresetTitle(e.target.value)}
                                fullWidth
                                sx={{ marginTop: .8 }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={savePreset}
                                sx={{ marginTop: 2 }}
                            >
                                Save Preset
                            </Button>
                        </Box>
                    )}
                </Box>
            </Modal>
        </Box>
    );
};
