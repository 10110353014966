import { DeploymentType } from "../../schemas/deployment";

const postDeployment =
    <Response>(post: <T>(url: string, body: any) => Promise<T>) => async (deploymentType: DeploymentType, deploymentId: string, body: any) => {
        const result = await post<Response>(`/api/FrontendDeployment/trigger/${deploymentId}/${deploymentType}`, body);
        return result;
    };

const resetStaging = <Response>(post: <T>(url: string, body: any) => Promise<T>) =>
    async (deploymentType: DeploymentType, appId: string, deploymentId: string, body: any) => {
        const result = await post<Response>(`/api/FrontendDeployment/resetStaging/${deploymentType}/${deploymentId}?appId=${appId}`, body);
        return result;
    };

const getDeployments = <Response>(get: <T>(url: string) => Promise<T>) => async (deploymentType: DeploymentType) => {
    const result = await get<Response>(`/api/FrontendDeployment/deployments/${deploymentType}`);
    return result;
};

export { postDeployment, getDeployments, resetStaging };
