/* eslint-disable max-lines */

import "./css/bb-live-chat.css";
import EventEmitter from "events";

import { Box, Divider, Paper, Typography } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
// Why do we even upgrade packages https://github.com/remix-run/react-router/issues/8139#issuecomment-977790637
import {
    useNavigate,
    UNSAFE_NavigationContext as NavigationContext,
} from "react-router-dom";

import {  HubConnectionState } from "@microsoft/signalr";

import { MemberAppContext } from "../../MemberAppContext";

import useDataClient from "../../axios/dataClient";

import { error } from "../ErrorDisplay";

import { ChatHubContext } from "./chatHubContext";
import { ChatMessage, ChatWindow, FileMessage } from "./ChatWindow";
import { ChatWindowProps } from "./ChatWindowProps";
import { ChatsListSidebar } from "./ChatsListSidebar";
import { ChatHandoffRequest } from "./chatHandoffRequest";

import { AgentState } from "./agentState";

import { LiveAgentQueueItem } from "./queues";

import { AssignedChatRequest } from "./assignedChatRequest";

import { receiveTransferRequest } from ".";

export const ChatPage = () => {
    const { get } = useDataClient();
    const { user, updateLiveAgentStatus } = useContext(MemberAppContext);
    const {
        closeConnection,
        subscribe,
        registerCallback,
        registerTypingCallback,
        registerTransferredCallback,
        registerOnNewChatCallback,
        registerOnReceiveTransferCallback,
        registerOnAssignedCallback,
        registerChatExistsCallback,
        registerFileScannedCallback,
        connectionState
    } = useContext(ChatHubContext);
    const defaultUnblock = () => undefined;
    const [unblock, setUnblock] = useState<() => void>(() => defaultUnblock);
    const [activeChats, setActiveChats] = useState<ChatWindowProps[]>([]);
    const navigate = useNavigate();
    const { navigator } = useContext(NavigationContext);
    const [activeChat, setActiveChat] = useState<ChatWindowProps | null>(null);
    const [isDisconnecting, setIsDisconnecting] = useState(false);

    const isMobile = useMediaQuery({ query: "(max-width: 760px)" });

    const checkConnctedStatus = useCallback(async () => {
        const connected = await get<AgentState>("/api/chat/isconnected");
        if (updateLiveAgentStatus !== undefined) {
            updateLiveAgentStatus(connected);
        }
    }, [get, updateLiveAgentStatus]);

    useEffect(() => {
        checkConnctedStatus();
    }, [checkConnctedStatus]);

    useEffect(() => {
        if (user.isChatting !== AgentState.DISCONNECTED) {
            const blockFunction = (navigator as any).block(() => {
                window.confirm(
                    "Close connection and existing chats to navigate"
                );
            });
            setUnblock((previousUnblock) => {
                previousUnblock();
                return blockFunction;
            });
        }
    }, [user.isChatting, navigator]);

    // Split out to prevent infinite loops as we call setUnblock
    useEffect(() => {
        if (user.isChatting === AgentState.DISCONNECTED) {
            unblock();
        }
    }, [user.isChatting, unblock]);

    const createMessageCallback = useCallback(
        (messageEmitter: EventEmitter) => {
            return (message: string) => {
                messageEmitter.emit("message", message);
            };
        },
        []);

    const createTypingCallback = useCallback(
        (messageEmitter: EventEmitter) => {
            return () => {
                messageEmitter.emit("typing");
            };
        },
        []);

    const createFileCallback = useCallback(
        (messageEmitter: EventEmitter) => {
            return (chatId: string, fileName: string, success: boolean) => {
                messageEmitter.emit("file", fileName, success);
            };
        },
        []);

    const onChatCompletion = useCallback((chatId: string) => {
        setActiveChats((chats) => {
            return chats.filter((chat) => chat.chatId !== chatId);
        });
    }, []);

    const createChatTransferredCallback = useCallback((chatId: string) => () => {
        onChatCompletion(chatId);
        setActiveChat(null);
    }, [onChatCompletion]);

    const resetUnreadMessages = useCallback(
        (chatId: string) => {
            const currentChatIndex = activeChats.findIndex(n => n.chatId === chatId);

            const newCurrentChatState = { ...activeChats[currentChatIndex], unreadMessages: 0 };

            const newActiveChats = [...activeChats];
            newActiveChats[currentChatIndex] = newCurrentChatState;
            setActiveChats(newActiveChats);
        },
        [activeChats]);

    const setCurrentChat = useCallback(
        (chatId: string) => {
            const filteredChats = activeChats.filter((chat) => chat.chatId === chatId);
            setActiveChat(filteredChats[0]);
            resetUnreadMessages(chatId);
        },
        [activeChats, resetUnreadMessages]);

    const pushConversationMessage = useCallback(
        (message: ChatMessage | FileMessage, chatId: string, increment: boolean) => {
            const currentChatIndex = activeChats.findIndex(n => n.chatId === chatId);

            const newCurrentChatState = {
                ...activeChats[currentChatIndex],
                conversationMessages: [...activeChats[currentChatIndex].conversationMessages, message],
            };

            if (activeChat?.chatId !== chatId && increment) {
                newCurrentChatState.unreadMessages = newCurrentChatState.unreadMessages + 1;
            }

            const newActiveChats = [...activeChats];
            newActiveChats[currentChatIndex] = newCurrentChatState;
            setActiveChats(newActiveChats);
        },
        [activeChat?.chatId, activeChats]);

    const createWindowProps = useCallback((
        botName: string,
        chatId: string,
        userName: string,
        userEmail: string,
        issue: string,
        messages: ChatMessage[],
        files: string[],
        eventEmitter: EventEmitter): ChatWindowProps => {
        return {
            botName,
            chatId,
            userName,
            userEmail,
            conversationMessages: messages,
            newMessage: eventEmitter,
            unreadMessages: messages.length,
            conversationFiles: files,
            onChatCompletion,
            pushConversationMessage: () => undefined,
            setCurrentChat
        };
    }, [onChatCompletion, setCurrentChat]);

    const registerCallbacks = useCallback((eventEmitter: EventEmitter, botName: string, chatId: string) => {
        registerCallback(createMessageCallback(eventEmitter), botName, chatId);
        registerTypingCallback(createTypingCallback(eventEmitter), botName, chatId);
        registerFileScannedCallback(createFileCallback(eventEmitter), chatId);
        registerTransferredCallback(createChatTransferredCallback(chatId), botName, chatId);
    }, [registerCallback,
        createMessageCallback,
        registerTypingCallback,
        createTypingCallback,
        registerTransferredCallback,
        createChatTransferredCallback,
        createFileCallback,
        registerFileScannedCallback]);

    const newChatCallback = useCallback(
        (handoff: ChatHandoffRequest | LiveAgentQueueItem) => {
            const eventEmitter = new EventEmitter();
            const windowProps: ChatWindowProps = createWindowProps(
                handoff.botName,
                handoff.chatId,
                handoff.userName,
                handoff.userEmail,
                handoff.issue,
                [{ message: handoff.issue, sender: "Bot", time: new Date() }],
                [],
                eventEmitter);

            setActiveChats((chats) => [...chats, windowProps]);

            registerCallbacks(eventEmitter, handoff.botName, handoff.chatId);
        },
        [registerCallbacks, createWindowProps]);

    const receiveTransferCallback = useCallback(
        (transfer: receiveTransferRequest) => {
            const eventEmitter = new EventEmitter();
            const windowProps: ChatWindowProps = createWindowProps(
                transfer.botName,
                transfer.chatId,
                transfer.userName,
                transfer.userEmail,
                transfer.issue,
                [...transfer.messageHistory,
                    {
                        sender: transfer.messageHistory[0].sender,
                        message: `Transfer notes: ${transfer.notes}`,
                        time: transfer.messageHistory.at(-1)?.time ?? new Date(),
                        transfer: true
                    }],
                [],
                eventEmitter);

            setActiveChats((chats) => [...chats, windowProps]);

            registerCallbacks(eventEmitter, transfer.botName, transfer.chatId);
        }, [registerCallbacks, createWindowProps]);

    const receiveAssignedCallback = useCallback(
        (transfer: AssignedChatRequest) => {
            const eventEmitter = new EventEmitter();
            const windowProps: ChatWindowProps = createWindowProps(
                transfer.botName,
                transfer.chatId,
                transfer.userName,
                transfer.userEmail,
                transfer.issue,
                [{ message: transfer.issue, sender: "Bot", time: new Date() }],
                [],
                eventEmitter);

            setActiveChats((chats) => [...chats, windowProps]);

            registerCallbacks(eventEmitter, transfer.botName, transfer.chatId);
        }, [registerCallbacks, createWindowProps]);

    const chatExists = useCallback(async (handoff: ChatHandoffRequest) => {
        const eventEmitter = new EventEmitter();
        const messages = await get<ChatMessage[]>(`api/chat/fetchMessages/${handoff.botName}/${handoff.chatId}`);
        const mappedDates = messages.map(message => {return { ...message, time: new Date(message.time) };} );
        const initialDate = messages.length > 0 ? mappedDates[0].time : new Date();
        const initialMessage: ChatMessage = { message: handoff.issue, sender: "Bot", time: initialDate };
        const windowProps: ChatWindowProps = createWindowProps(
            handoff.botName,
            handoff.chatId,
            handoff.userName,
            handoff.userEmail,
            handoff.issue,
            [initialMessage, ...mappedDates],
            [],
            eventEmitter
        );

        setActiveChats(chats => [...chats, windowProps]);

        registerCallbacks(eventEmitter, handoff.botName, handoff.chatId);
    }, [createWindowProps, get, registerCallbacks]);

    const connectAgent = useCallback(async () => {
        if (connectionState !== HubConnectionState.Disconnected || isDisconnecting) {
            return;
        }
        await subscribe();
        registerOnNewChatCallback(newChatCallback);
        registerOnReceiveTransferCallback(receiveTransferCallback);
        registerOnAssignedCallback(receiveAssignedCallback);
        registerChatExistsCallback(chatExists);
    }, [registerOnNewChatCallback, subscribe, newChatCallback, connectionState, isDisconnecting, registerOnAssignedCallback,
        receiveAssignedCallback, receiveTransferCallback, registerOnReceiveTransferCallback, registerChatExistsCallback, chatExists]);

    useEffect(() => {
        if (user.isChatting) {
            connectAgent();
        }
    }, [user.isChatting, connectAgent]);

    const signOut = useCallback(async () => {
        if (activeChats.length > 0) {
            error("You have to close active chats before sign off.");
            return;
        }

        setIsDisconnecting(true);
        await get("api/chat/disconnect");
        await closeConnection();
        setActiveChats([]);
        setActiveChat(null);
        updateLiveAgentStatus(AgentState.DISCONNECTED);
        navigate("/");
    }, [activeChats.length, closeConnection, navigate, get, updateLiveAgentStatus]);

    const isActive = (chatId: string) => {
        if (activeChat?.chatId !== chatId) {
            return { display: "none" };
        }
    };

    return (
        <>
            <Paper sx={{ height: "100%" }} className="live-agent-container">
                <Box className="live-agent-content" display={"flex"} sx={{ height: "100%" }}>
                    {(!isMobile || isMobile && !activeChat) && (
                        <>
                            <ChatsListSidebar
                                signOut={signOut}
                                agentState={user.isChatting}
                                connectionStatus={connectionState}
                                activeChats={activeChats}
                                setCurrentChat={setCurrentChat}
                                chatDequeuedCallback={newChatCallback}
                            />
                            <Divider
                                orientation="vertical"
                                flexItem
                            /></>
                    )}
                    {isMobile && activeChat && (
                        activeChats.map((chat) => (
                            <Box
                                key={chat.chatId}
                                sx={isActive(chat.chatId)}
                                width="100%"
                                height="100%"
                            >
                                <ChatWindow
                                    key={chat.chatId}
                                    {...chat}
                                    pushConversationMessage={pushConversationMessage}
                                    setCurrentChat={setCurrentChat}
                                />
                            </Box>
                        ))
                    )}
                    {!isMobile && (
                        <Box flex={3} display="flex" justifyContent="center" alignItems="center">
                            {activeChat ? (
                                activeChats.map((chat) => (
                                    <Box
                                        key={chat.chatId}
                                        sx={isActive(chat.chatId)}
                                        width="100%"
                                        height="100%"
                                    >
                                        <ChatWindow
                                            key={chat.chatId}
                                            {...chat}
                                            pushConversationMessage={pushConversationMessage}
                                        />
                                    </Box>
                                ))
                            ) : (
                                <Typography paragraph>
                                    Choose chat from the list to begin.
                                </Typography>
                            )}
                        </Box>)
                    }
                </Box>
            </Paper>
        </>
    );
};
