export enum PanelType {
    Img = "bb-img",
    Button = "bb-button",
    StartChat = "bb-start-chat",
    Slider = "bb-slider",
    MediaPanel = "bb-media-panel",
    Accordion = "bb-accordion",
    ButtonStack = "bb-button-stack",
    FadingBanner = "bb-fading-banner",
    CardGrid = "bb-card-grid",
    Html = "bb-html",
    Launcher = "bb-launcher",
    BotLearn = "bb-bot-learn",
    Video = "bb-video",
    IconBox = "bb-icon-box",
    Events = "bb-events",
    Map = "bb-map",
    Social = "bb-social",
    ContactStrip = "bb-contact",
    RichText = "bb-rte",
    ItemList = "bb-item-list"
}

export const panelTypeDisplayNames: { [key in PanelType]: string } = {
    [PanelType.Img]: "Image",
    [PanelType.Button]: "Button",
    [PanelType.StartChat]: "Start Chat",
    [PanelType.Slider]: "Slider",
    [PanelType.MediaPanel]: "Media Panel",
    [PanelType.Accordion]: "Accordion",
    [PanelType.ButtonStack]: "Button Stack",
    [PanelType.FadingBanner]: "Fading Banner",
    [PanelType.CardGrid]: "Card Grid",
    [PanelType.Html]: "HTML",
    [PanelType.Launcher]: "Launcher",
    [PanelType.BotLearn]: "Bot Learn",
    [PanelType.Video]: "Video",
    [PanelType.IconBox]: "Icon Box",
    [PanelType.Events]: "Events",
    [PanelType.Map]: "Map",
    [PanelType.Social]: "Social",
    [PanelType.ContactStrip]: "Contact Strip",
    [PanelType.RichText]: "Rich Text Editor",
    [PanelType.ItemList]: "Item List"
};
