import { Link } from "react-router-dom";
import * as React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
    Container,
    Box,
    Grid,
    Alert,
    Tooltip
} from "@mui/material";

import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';

import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';

import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';

import { useContext } from "react";

import { MemberAppContext } from "../../MemberAppContext";

import { FavoriteProvider } from '../favorites/FavoriteContext';
import { FavoriteButton } from '../favorites/FavoriteButton';

export const PartnerManagement = () => {
    const appContext = useContext(MemberAppContext);
    return (
        <FavoriteProvider>
            <Container>
                <Box className="bb-title-bar" sx={{ marginBottom: '4px' }}>
                    <h2 className="bb-m0 bb-p0">Partner Management</h2>
                    <FavoriteButton page={`partnerManagement/`} displayName={`Partner Management`} displayModule={`Manage Your Partners`} />
                </Box>
                <Alert className="bb-title-info bb-mb-2 bb-align-items-center bb-flex" severity="info" sx={{ marginTop: '0px!important' }}
                >
                            Welcome to the <b>Partner Management Page.</b> Here, you can efficiently organise and manage all of your Partners and their Users.
                </Alert>
                <Grid
                    container
                    spacing={2}
                    alignItems="stretch"
                    alignContent="center">

                    {(appContext.user.isApplicationAdmin || appContext.user.isPartnerManager) &&
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className="animated animatedFadeInUp fadeInUp3">
                        <Tooltip
                            title="The Import Partners section enables you to easily bulk import partners via a simple file upload."
                            arrow
                            placement="top"
                            PopperProps={{
                                sx: {
                                    '& .MuiTooltip-tooltip': {
                                        backgroundColor: 'black',
                                        color: 'white',
                                        fontSize: '.8rem',
                                        padding: '0.5rem',
                                    },
                                    '& .MuiTooltip-arrow': {
                                        color: 'black',
                                    },
                                },
                            }}>
                            <Card className="bb-app-icon">
                                <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon" to={`/partnerManagement/importPartners` }>
                                    <Box className="bb-flex bb-justify-content-center bb-card-header bb-header-icons">
                                        <Diversity3OutlinedIcon></Diversity3OutlinedIcon>
                                    </Box>
                                    <CardContent className="bb-align-items-center bb-flex bb-flex-column bb-module-card">
                                        <Typography gutterBottom variant="h2" fontWeight="bold" component="div">
                                        Import Partners
                                        </Typography>
                                        <Typography variant="body2" className="bb-text-center bb-min-height-3" fontSize={"0.9rem"}>
                                        Import partners via file upload.
                                        </Typography>
                                    </CardContent>
                                </Link>
                            </Card>
                        </Tooltip>
                    </Grid>
                    }

                    {(appContext.user.isApplicationAdmin || appContext.user.isPartnerManager) &&
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className="animated animatedFadeInUp fadeInUp3">
                        <Tooltip
                            title="The Import Partner Users section enables you to easily bulk import partner users via a simple file upload."
                            arrow
                            placement="top"
                            PopperProps={{
                                sx: {
                                    '& .MuiTooltip-tooltip': {
                                        backgroundColor: 'black',
                                        color: 'white',
                                        fontSize: '.8rem',
                                        padding: '0.5rem',
                                    },
                                    '& .MuiTooltip-arrow': {
                                        color: 'black',
                                    },
                                },
                            }}>
                            <Card className="bb-app-icon">
                                <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon" to={`/partnerManagement/import`}>
                                    <Box className="bb-flex bb-justify-content-center bb-card-header bb-header-icons">
                                        <GroupAddOutlinedIcon></GroupAddOutlinedIcon>
                                    </Box>
                                    <CardContent className="bb-align-items-center bb-flex bb-flex-column bb-module-card">
                                        <Typography gutterBottom variant="h2" fontWeight="bold" component="div">
                                        Import Users
                                        </Typography>
                                        <Typography variant="body2" className="bb-text-center bb-min-height-3" fontSize={"0.9rem"}>
                                        Import partner user accounts via file upload.
                                        </Typography>
                                    </CardContent>
                                </Link>
                            </Card>
                        </Tooltip>
                    </Grid>
                    }

                    {(appContext.user.isApplicationAdmin || appContext.user.isPartnerManager) &&
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3} className="animated animatedFadeInUp fadeInUp4">
                        <Tooltip
                            title="The partner management sections enables you to manage all partners & partner users in one simple place."
                            arrow
                            placement="top"
                            PopperProps={{
                                sx: {
                                    '& .MuiTooltip-tooltip': {
                                        backgroundColor: 'black',
                                        color: 'white',
                                        fontSize: '.8rem',
                                        padding: '0.5rem',
                                    },
                                    '& .MuiTooltip-arrow': {
                                        color: 'black',
                                    },
                                },
                            }}>
                            <Card className="bb-app-icon">
                                <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon" to={`/partnerManagement/partners`}>
                                    <Box className="bb-flex bb-justify-content-center bb-card-header bb-header-icons">
                                        <ManageAccountsOutlinedIcon></ManageAccountsOutlinedIcon>
                                    </Box>
                                    <CardContent className="bb-align-items-center bb-flex bb-flex-column bb-module-card">
                                        <Typography gutterBottom variant="h2" fontWeight="bold" component="div">
                                        Manage Partners
                                        </Typography>
                                        <Typography variant="body2" className="bb-text-center bb-min-height-3" fontSize={"0.9rem"}>
                                        List partners and manage partner users.
                                        </Typography>
                                    </CardContent>
                                </Link>
                            </Card>
                        </Tooltip>
                    </Grid>
                    }
                </Grid>
            </Container>
        </FavoriteProvider>
    );
};
