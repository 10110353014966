import React from 'react';
import { Modal, Box, Grid, Typography, IconButton, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

interface TemplateModalProps {
    isOpen: boolean;
    onClose: () => void;
    selectedTemplate: string;
    setSelectedTemplate: (template: string) => void;
}

const TemplateModal: React.FC<TemplateModalProps> = ({ isOpen, onClose, setSelectedTemplate }) => {
    const templates = [
        {
            value: "blank",
            imgSrc: "https://bbotcustomerresource.blob.core.windows.net/public/customer-portal-store/template-blank.png",
            title: "No Template",
            description: "Start fresh with a blank page!"
        },
        {
            value: "textBlocks",
            imgSrc: "https://bbotcustomerresource.blob.core.windows.net/public/customer-portal-store/template-textonly.png",
            title: "Text Only",
            description: "Ideal for text heavy content."
        },
        {
            value: "textAndImage",
            imgSrc: "https://bbotcustomerresource.blob.core.windows.net/public/customer-portal-store/template-text&image.png",
            title: "Text & Images",
            description: "Ideal for engaging articles, blogs & landing pages."
        },
        {
            value: "landingPage",
            imgSrc: "https://bbotcustomerresource.blob.core.windows.net/public/customer-portal-store/template-landingpage.png",
            title: "Landing Page",
            description: "Perfect for designing engaging landing pages that contain various elements of content."
        },
        {
            value: "categories",
            imgSrc: "https://bbotcustomerresource.blob.core.windows.net/public/customer-portal-store/template-categories.png",
            title: "Categories Dashboard",
            description: "Perfect for designing engaging pages that bring together links to multiple categories and sections within your application."
        },
        {
            value: "content",
            imgSrc: "https://bbotcustomerresource.blob.core.windows.net/public/customer-portal-store/template-content.png",
            title: "Content",
            description: "Perfect for designing engaging pages that contain various elements of content."
        },
        {
            value: "nhsGallery",
            imgSrc: "https://bbotcustomerresource.blob.core.windows.net/public/customer-portal-store/template-nhs.png",
            title: "NHS Gallery",
            description: "Implement the full, NHS Video Gallery into your App."
        },
        {
            value: "contactUs",
            imgSrc: "https://bbotcustomerresource.blob.core.windows.net/public/customer-portal-store/template-contactus.png",
            title: "Contact Us",
            description: "Ideal for creating pages that contain contact information."
        },
        {
            value: "policy",
            imgSrc: "https://bbotcustomerresource.blob.core.windows.net/public/customer-portal-store/template-policy.png",
            title: "Policy",
            description: "Ideal for creating policies such as Accessibility Statements, Data Protection Policies, and more."
        }
    ];

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 600,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 1,
                    maxHeight: '80vh',
                    overflowY: 'auto'
                }}
            >
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        backgroundColor: '#11a681',
                        color: 'white',
                        borderRadius: '50%',
                        '&:hover': {
                            backgroundColor: '#0e8c6b',
                        },
                    }}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h4" sx={{ mb: 2 }}>Template Library</Typography>
                <Grid container spacing={2} sx={{ marginTop: '1rem' }}>
                    {templates.map((template) => (
                        <Grid item xs={4} key={template.value}>
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                                onClick={() => {
                                    setSelectedTemplate(template.value);
                                    onClose();
                                }}
                            >
                                <Tooltip
                                    title={template.description}
                                    arrow
                                    placement="top"
                                    PopperProps={{
                                        sx: {
                                            '& .MuiTooltip-tooltip': {
                                                backgroundColor: 'black',
                                                color: 'white',
                                                fontSize: '.8rem',
                                                padding: '0.5rem',
                                            },
                                            '& .MuiTooltip-arrow': {
                                                color: 'black',
                                            },
                                        },
                                    }}
                                >
                                    <img className="templateImg" src={template.imgSrc} alt={template.title} />
                                </Tooltip>
                                <Typography variant="h5" sx={{ marginTop: '1rem' }}>{template.title}</Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Modal>
    );
};

export { TemplateModal };
