import { Link } from "react-router-dom";

import {
    Container,
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    TablePagination,
    TableHead,
    CircularProgress,
    Select,
    MenuItem,
    Typography,
    Breadcrumbs,
    Alert
} from "@mui/material";

import { SelectChangeEvent } from '@mui/material/Select';

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';

import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';

import { useState, useEffect, useCallback } from "react";

import { useParams } from "react-router-dom";

import useDataClient from "../../../axios/dataClient";

import { dateSort } from "../../../services/helper/sort";

import { Booking } from "../../../schemas/eventManagement/bookingSchema";

import { getBookings } from "../../../services/api/BookingManagementService";

import { ResultListModel } from "../../../schemas/eventManagement/resultSchema";

import { errors } from "../../ErrorDisplay";

import { DateDisplay } from "../../shared/DateDisplay";

import { newBooking } from "../../newModuleNames";

import { SearchField } from "../../applications/customWidget/Search/SearchField";

import { EventDetails } from '../schedule/EventDetails';

export const BookingList: React.FC = () => {
    const { get } = useDataClient();
    const { eventId, scheduleId } = useParams();

    const [bookings, setBookings] = useState<Booking[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [isLoading, setLoading] = useState(false);
    const [filteredBookings, setFilteredBookings] = useState<Booking[]>([]);

    const filterShowAll = "ShowAll";
    const filterShowNonDeletedOnly = "filterShowNonDeletedOnly";
    const [listFilter, setListFilter] = useState(filterShowNonDeletedOnly);
    const [searchString, setSearchString] = useState<string>();

    const handleFilterChange = (event: SelectChangeEvent) => {
        setListFilter(event.target.value as string);
    };

    const callSearch = useCallback((search?: string) => {
        setSearchString(search);
    }, [setSearchString]);

    useEffect(() => {
        if (!bookings) {
            return;
        }

        setCurrentPage(0);
        const filtered = bookings.filter((booking) =>
            (listFilter === filterShowAll || (listFilter === filterShowNonDeletedOnly && booking?.deleted !== true)) &&
            (!searchString ||
               booking.userEmail?.toLowerCase().includes(searchString.toLowerCase()) ||
               booking.bookingNumber?.toLowerCase().includes(searchString.toLowerCase())
            )
        );
        setFilteredBookings(filtered);
    }, [listFilter, bookings, searchString]);

    const fetchData = useCallback(async () => {

        if (scheduleId) {
            getBookings<ResultListModel<Booking>>(get)(scheduleId).then((bookingsData) => {
                if (bookingsData?.isSuccess && bookingsData?.data) {
                    setBookings(bookingsData.data);
                } else if (bookingsData?.messages) {
                    errors(bookingsData.messages);
                }

                return;
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [get, scheduleId]);

    const refreshList = useCallback(() => {
        setLoading(true);
        fetchData();
    }, [fetchData]);

    const handleChangePage = (event: any, newPage: any) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0); // Reset to first page
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            await fetchData();
        })();
    }, [fetchData]);

    if (!scheduleId) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <Container>
            <Box sx={{ marginTop: '1.2rem!important', display: 'flex', justifyContent: 'space-between' }}>
                <Box className="bb-title-bar">
                    <h2 className="bb-m0 bb-p0">Bookings:</h2>{eventId && <EventDetails eventId={eventId} />}
                </Box>
                <Box>
                    <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon"
                        to={`/eventManagement/events/${eventId}/schedules/${scheduleId}`}>
                        <Button variant="outlined" size="small" sx={{ padding: '6px 12px', fontSize: '0.95rem', minWidth: 'auto' }}
                            startIcon={<KeyboardArrowLeftIcon/>} disabled={isLoading}>
                            Back to Schedule
                        </Button>
                    </Link>
                </Box>
            </Box>
            <Breadcrumbs className="bb-breadcrumb bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
                <Link to="/eventManagement">Event Management</Link>
                <Link to="/eventManagement/events">Events</Link>
                <Link to={`/eventManagement/events/${eventId}/schedules`}>Schedules</Link>
                <Typography color="text.primary" className="bb-m0 bb-p0">Bookings</Typography>
            </Breadcrumbs>
            <Alert className="bb-title-info bb-mb-2 bb-align-items-center bb-flex" severity="info" sx={{ marginTop: '18px!important' }}
            >
                        Welcome to the <b>Bookings Management Page.</b> Here, you can manage all of the bookings for your event.
            </Alert>
            <Box className="bb-flex bb-ui-box bb-flex-column bb-tac">
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        <Typography variant="h3" className="bb-m0 bb-p0">Bookings Manager</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Button
                            variant="contained"
                            onClick={refreshList}
                            startIcon={<RefreshOutlinedIcon sx={{ color: '#11a681' }}/>}
                            disabled={isLoading}
                            sx={{ marginRight: '10px', color: '#000000!important', backgroundColor: '#ffffff!important', border: '1px solid #11a681' }}
                        >
                        Refresh
                        </Button>
                        <Link
                            className="bb-ml-auto bb-text-decoration-none bb-app-icon"
                            to={`/eventManagement/events/${eventId}/schedules/${scheduleId}/bookings/${newBooking}`}
                        >
                            <Button variant="contained" disabled={isLoading} startIcon={<PersonAddAlt1OutlinedIcon />} sx={{ marginRight: '10px' }}>
                            New Booking
                            </Button>
                        </Link>
                        <Link
                            className="bb-ml-auto bb-text-decoration-none bb-app-icon"
                            to={`/eventManagement/events/${eventId}/schedules/${scheduleId}/bookings/queue`}
                        >
                            <Button variant="contained" disabled={isLoading} startIcon={<GroupsOutlinedIcon />}>
                            View Waiting Queue
                            </Button>
                        </Link>
                    </Box>
                </Box>
                {isLoading
                    ? (<Box className="bb-tac"><CircularProgress /></Box>)
                    : (
                        <TableContainer component={Paper} sx={{ boxShadow: "none", marginTop: "1rem" }} >
                            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '20px', marginTop: '20px' }}>
                                <Box className="bb-med-search" sx={{ minWidth: 200, marginLeft: '0px!important' }}>
                                    <Select
                                        id="filter-select"
                                        value={listFilter}
                                        onChange={handleFilterChange}
                                        variant="outlined"
                                        fullWidth
                                        sx={{ height: 40 }}
                                    >
                                        <MenuItem value={filterShowAll}>Show All</MenuItem>
                                        <MenuItem value={filterShowNonDeletedOnly}>Hide Deleted Bookings</MenuItem>
                                    </Select>
                                </Box>
                                <Box className="bb-med-search" sx={{ paddingLeft: '0px!important', marginLeft: '0px!important' }}>
                                    <SearchField
                                        searchString={searchString}
                                        callSearch={callSearch}
                                        timeout={500}
                                    />
                                </Box>
                            </Box>
                            <Table aria-label="table" sx={{ padding: "0", margin: "0" }} className="bb-page-list-table bb-global-table">
                                <TableHead sx={{ borderRadius: "5px 5px 0 0" }}>
                                    <TableRow >
                                        <TableCell>Booking Number</TableCell>
                                        <TableCell>User Email</TableCell>
                                        <TableCell>Last Update Date</TableCell>
                                        <TableCell>Deleted</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>{
                                    filteredBookings
                                        .sort((a, b) => dateSort(b.timestamp, a.timestamp))
                                        .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                                        .map((booking, index) => {
                                            return (
                                                <TableRow key={`partnerUser_${index}`} sx={{ padding: "0", margin: "0" }}>
                                                    <TableCell sx={{ padding: "0", margin: "0" }}>
                                                        <Box className="bb-ml-2">
                                                            <Link
                                                                className="bb-pages-list bb-align-items-center"
                                                                to={`/eventManagement/events/${eventId}/schedules/${scheduleId}/bookings/${booking.rowKey}`}
                                                            >
                                                                {booking.bookingNumber}
                                                            </Link>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>
                                                        {booking.userEmail}
                                                    </TableCell>
                                                    {booking.timestamp ?
                                                        (<TableCell>
                                                            <DateDisplay value={new Date(booking.timestamp)} dateStyle="short" timeStyle="short" />
                                                        </TableCell>)
                                                        : (<TableCell></TableCell>)
                                                    }
                                                    <TableCell>
                                                        <Box className="bb-ml-2">{booking.deleted ? "Yes" : "No"}</Box>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                }
                                </TableBody>
                            </Table>
                            <TablePagination
                                className="bb-global-table-footer"
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={filteredBookings.length}
                                rowsPerPage={rowsPerPage}
                                page={currentPage}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    )
                }
            </Box>
        </Container>
    );
};
