import * as React from "react";

import { DataGrid, GridRowsProp, GridColDef, GridToolbar, GridRenderCellParams  } from "@mui/x-data-grid";

import { Box, Chip, Typography } from "@mui/material";

import { transform_grid_data } from "./AnalyticsHelpers";

interface Props {
    data: any | undefined;
    config: any;
    title?: string;
    transformed: boolean;
    referenceData: any | undefined;
}

export const AnalyticsConfig_sessiondetail = {
    title: "Session Detail",
    initialState: {
        sorting: {
            sortModel: [{ field: "Clicks", sort: "desc" }],
        },
        pagination: { paginationModel: { pageSize: 25 } }
    },
    columnDef: [
        {
            TimeGenerated: {
                label: "Time"
            }
        },
        {
            activePage: {
                label: "Active Page"
            }
        },
        {
            et: {
                label: "Activity"
            }
        },
        {
            eventValue: {
                label: "Activity Detail"
            }
        },
        {
            timeDiffInSeconds: {
                label: "Time Spent (seconds)"
            }
        }
    ], columns: [
        { field: "TimeGenerated", headerName: "Time", flex: 3, minWidth: 150 },
        { field: "activePage", headerName: "Active Page", flex: 3, minWidth: 150 },
        { field: "et", headerName: "Activity", flex: 3, minWidth: 150 },
        { field: "eventValue", headerName: "Activity Detail", flex: 3, minWidth: 150 },
        { field: "timeDiffInSeconds", headerName: "Time Spent (seconds)", flex: 3, minWidth: 150 },
    ]
};

export const AnalyticsConfig_notificationdetail = {
    title: "Notification Detail",
    initialState: {
        sorting: {
            sortModel: [{ field: "timestamp", sort: "desc" }],
        },
        pagination: { paginationModel: { pageSize: 25 } }
    },
    columnDef: [
        {
            channels: {
                label: "Channels"
            }
        },
        {
            sentSuccessCount: {
                label: "Sent"
            }
        },
        {
            sentOpenedCount: {
                label: "Opened"
            }
        }
    ], columns: [
        { field: "channels", headerName: "Channels", flex: .5, renderCell: (params: GridRenderCellParams<any, any>) => {
            return ( <div> { params.value ? (params.value.map((gateway: any) => <Chip size={"small"} key={gateway} label={gateway} />)) : null} </div> ); }
        },
        { field: "sentSuccessCount", headerName: "Sent", flex: 0.5, },
        { field: "sentOpenedCount", headerName: "Opened", flex: 0.8, },
    ]
};

export const AnalyticsDataGridModal = ({ data, config, title, transformed }: Props) => {

    if (!data || data === "") {
        return null;
    }
    // loading={analyticsData.isLoading} data={analyticsData.data}

    // no need to use existing header def config!!!!!!!!!!!!!!!!!!!!!!!!!!
    let transform_data;
    if (transformed) {
        transform_data = (data  as GridRowsProp);
    } else {
        transform_data = (transform_grid_data(data.data) as GridRowsProp);
    }
    const cols = (config.columns as GridColDef[]);

    return (
        <Box>
            <Box>
                <Typography variant="h3" className="bb-mb-2 bb-tac">{title}</Typography>
            </Box>
            <Box className="bb-analytics-tables" sx={{ width: "100%" }}>
                <DataGrid
                    sx={{
                    }}
                    pageSizeOptions={[25]}
                    rows={transform_data}
                    columns={cols}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            printOptions: { disableToolbarButton: true }
                        },
                    }}
                    initialState={config.initialState}
                    density="compact"
                    disableColumnFilter={false}
                    disableDensitySelector={true}
                    disableColumnSelector={true}
                />
            </Box>
        </Box>
    );
};
