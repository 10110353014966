import {
    Button,
    CircularProgress,
    Grid,
    Container,
    Box,
    Typography,
    Alert
} from "@mui/material";

import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';

import Breadcrumbs from "@mui/material/Breadcrumbs";

import { useParams, Link, useNavigate } from "react-router-dom";

import React, { useState, useEffect, useCallback, useMemo } from "react";

import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import { toast } from "react-toastify";

import { Form } from '@rjsf/mui';

import validatorAjv8 from "@rjsf/validator-ajv8";

import { JSONSchema7 } from "json-schema";

import { UiSchema } from "@rjsf/utils";

import useDataClient from "../../axios/dataClient";

import { getPartner, createPartner, updatePartner } from "../../services/api/PartnerManagementService";

import { Partner } from "../../schemas/partnerManagement/schema";

import { ResultEntityModel } from "../../schemas/eventManagement/resultSchema";

import partnerSchemaModel from "../../schemas/partnerManagement/partnerSchemaData.json";

import { UndoButton } from "../applications/UndoButton";

import { newPartner } from "../newModuleNames";

import { errors } from "../ErrorDisplay";

import { AltMediaLibraryWidget } from "../applications/customWidget/MediaLibrary/AltMediaLibraryWidget";

export const PartnerForm: React.FC = () => {
    const navigate = useNavigate();

    const { partnerId } = useParams();
    const [partner, setPartner] = useState<Partner>();
    const { get, post, put } = useDataClient();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [isLoading, setLoading] = useState(false);

    const customWidgets = useMemo(() => ({
        AltMediaLibraryWidget,
    }), []);

    const uiSchema: UiSchema = {
        'ui:submitButtonOptions': {
            norender: true
        },
        "ui:options": {
            classNames: "custom-class-title1"
        },
        "rowKey": {
            "ui:widget": "hidden"
        },
        "partnerLogo": {
            "ui:widget": "AltMediaLibraryWidget"
        },
    };

    const fetchData = React.useCallback(async () => {
        if (partnerId) {
            getPartner<ResultEntityModel<Partner>>(get)(partnerId).then((partnerData) => {
                if (partnerData?.isSuccess) {
                    setPartner(partnerData.entity);
                } else if (partnerData?.messages) {
                    errors(partnerData.messages);
                }

                return;
            }).finally(() => {
                setLoading(false);
            });
        }
        else {
            setPartner({
                partnerName: "",
                registeredAddress: "",
                contactNumber: "",
                contactEmail: "",
                website: "",
                partnerLogo: "",
                serviceDescription: ""
            });
            setLoading(false);
        }
    }, [get, partnerId]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await fetchData();
        })();
    }, [get, fetchData]);

    const handleSubmit = useCallback(async ({ formData }: any) => {
        setSubmitting(true);
        const partnerData = formData as Partner;

        if (partnerId) {
            updatePartner(put)(partnerId, partnerData).then(() => {
                toast.success("Partner updated");
                setPartner(partnerData);

                return;
            }).finally(() => {
                setSubmitting(false);
            });
        }
        else {
            createPartner<ResultEntityModel<Partner>>(post)(partnerData).then((result) => {
                if (result.isSuccess) {
                    toast.success("Partner created");
                    setPartner(result.entity);
                    navigate(`/partnerManagement/partners/${result?.entity?.rowKey}`);
                } else if (result?.messages) {
                    errors(result.messages);
                }
                return;
            }).finally(() => {
                setSubmitting(false);
            });
        }
    }, [put, post, partnerId, navigate]);

    const handleError = useCallback((e: any): void => {
        console.log("Data changed: ", e.formData);
    }, []);

    const handleUndo = useCallback(async (): Promise<void> => {
        setSubmitting(true);
        fetchData().then(() => {
            toast.warning("Partner data has been reset");
            return;
        }).finally(() => {
            setSubmitting(false);
        });
    }, [fetchData]);

    const navigateToPartnerUsers = () => {
        navigate(`/partnerManagement/partners/${partnerId}/users`);
    };

    if (isLoading || !partner) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <Container>
            <Box sx={{ marginTop: '1.2rem!important', display: 'flex', justifyContent: 'space-between' }}>
                <Box className="bb-title-bar">
                    <h2 className="bb-m0 bb-p0">Partners</h2>
                </Box>
                <Box>
                    <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon"
                        to={`/partnerManagement/partners`}>
                        <Button variant="outlined" size="small" sx={{ padding: '6px 12px', fontSize: '0.95rem', minWidth: 'auto' }}
                            startIcon={<KeyboardArrowLeftIcon/>} disabled={isLoading}>
                            Back to Partners
                        </Button>
                    </Link>
                </Box>
            </Box>
            <Breadcrumbs className="bb-breadcrumb bb-mb-3 bb-flex bb-align-items-center"
                separator="›" aria-label="breadcrumb">
                <Link to="/partnerManagement">Partner Management</Link>
                <Link to="/partnerManagement/partners">Partners</Link>
                <Typography color="text.primary" className="bb-m0 bb-p0 bb-crumb-text">{partner?.partnerName ? partner?.partnerName : newPartner}</Typography>
            </Breadcrumbs>
            <Alert className="bb-title-info bb-mb-2 bb-align-items-center bb-flex" severity="info" sx={{ marginTop: '20px!important' }}
            >
            Welcome to the <b> Partner Manager.</b> Here, you can view and edit your Partner Details.
            </Alert>
            <Box className="bb-flex bb-ui-box bb-flex-column bb-tac">
                <Box className="bb-page-edit-cont">
                    {submitting && <Box className="bb-tac"><CircularProgress /></Box>}
                    <Box className="bb-flex bb-flex-column bb-justify-content-center bb-tac" sx={{ minHeight: "60vh" }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box>
                                <Typography variant="h3" className="bb-m0 bb-p0">Partner Manager</Typography>
                            </Box>
                            <Box className="page-edit-buttons" sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                {partnerId &&
                                <>
                                    <Grid item xs={1}>
                                        <Button
                                            onClick={navigateToPartnerUsers}
                                            variant="outlined"
                                            startIcon={<GroupsOutlinedIcon />}
                                            disabled={submitting}>
                                            Partner Users
                                        </Button>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <UndoButton onClick={handleUndo} disable={submitting}></UndoButton>
                                    </Grid>
                                </>
                                }
                                <Button variant="contained" startIcon={<SaveOutlinedIcon />} disabled={submitting} type="submit" form="partner_submit">
                                Save
                                </Button>
                            </Box>
                        </Box>
                        <Box sx={{ width: "100%" }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="bb-sp-cont">
                                <Form
                                    id={"partner_submit"}
                                    schema={partnerSchemaModel as JSONSchema7}
                                    formData={partner}
                                    validator={validatorAjv8}
                                    onSubmit={handleSubmit}
                                    onError={handleError}
                                    uiSchema={uiSchema}
                                    liveValidate={true}
                                    showErrorList={false}
                                    disabled={submitting}
                                    widgets={customWidgets}
                                    className="bb-pathways-initial-form"
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Container>);
};
