import * as React from "react";

import { CardContent, Grid, Card, Box, CardHeader } from "@mui/material";

import { PageColumn } from "../../../schemas/pages/schema";

import { PageEditPanel } from "./PageEditPanel";

export interface PageEditColumnProps {
    column: PageColumn;
    rowIndex: number;
    columnIndex: number;
    allButtons: JSX.Element;
    editButtonRender: (rowIndex: number, columnIndex: number, panelIndex: number) => JSX.Element;
    deleteButtonRender: (rowIndex: number, columnIndex: number, panelIndex: number) => JSX.Element;
}
export const PageEditColumn: React.FC<PageEditColumnProps> = ({ column, rowIndex, columnIndex, allButtons, editButtonRender, deleteButtonRender }) => {
    const colCount = 'bb-col-count-'+columnIndex;
    const colSpan = column?.colSpan ?? 12;

    return (
        <Grid item xs={colSpan} className={`bb-single-col bb-col-count-${columnIndex}`}>
            <Card variant="outlined" className="bb-pe-col">
                <CardHeader className="bb-pe-col-title bb-pe-titles"
                    titleTypographyProps={{
                        textAlign: "left"
                    }}
                    action={
                        allButtons
                    }
                    title={`Column ${columnIndex + 1}`}
                />
                <CardContent className={`bb-pe-col-content bb-col-span-${colSpan}`}>
                    <Box sx={{ flexGrow: 1 }} className={colCount}>
                        <Grid container direction="column" spacing={1}>
                            {column?.bbPanelTypes?.map((panel, panelIndex) =>
                                (<PageEditPanel
                                    key={`row-${rowIndex}-columns-${columnIndex}-panel-${panelIndex}`}
                                    panelIndex={panelIndex}
                                    editButton={editButtonRender(rowIndex, columnIndex, panelIndex)}
                                    deleteButton={deleteButtonRender(rowIndex, columnIndex, panelIndex)}
                                    panelType={panel.type}
                                    panel={panel}
                                />)
                            )}
                            {/* panel ? (<span></span>) : (<Button variant="contained">Add Content</Button>) */}
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    );
};
