import * as yup from "yup";

export type Question = {
    rowKey?: string;
    eventId?: string;
    text?: string;
    options?: string;
    required?: string;
    description?: string;
    questionType?: string;
    timestamp?: Date;
    deleted?: boolean;
};

export const QuestionSchema: yup.SchemaOf<Question> = yup.object({
    rowKey: yup.string().notRequired(),
    eventId: yup.string().notRequired(),
    text: yup.string().required(),
    options: yup.string().notRequired(),
    description: yup.string().notRequired(),
    required: yup.string().notRequired(),
    questionType: yup.string().required(),
    timestamp: yup.date().notRequired(),
    deleted: yup.boolean().notRequired()
});

