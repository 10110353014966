import React, { useState, useCallback, ReactElement } from 'react';
import { VideoComponent } from '../shared/VideoComponent';

interface VideoHelperProps {
    videoUrl: string;
    triggerElement: ReactElement<any, any>;
}

export const VideoHelper: React.FC<VideoHelperProps> = ({ videoUrl, triggerElement }) => {
    const [isVideoOpen, setIsVideoOpen] = useState(false);

    const handleOpenVideo = useCallback((event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
        event.preventDefault();
        setIsVideoOpen(true);
    }, []);

    const handleCloseVideo = useCallback(() => {
        setIsVideoOpen(false);
    }, []);

    return (
        <>
            {React.cloneElement(triggerElement, { onClick: handleOpenVideo })}
            <VideoComponent
                videoUrl={videoUrl}
                isOpen={isVideoOpen}
                onClose={handleCloseVideo}
            />
        </>
    );
};
