import { DependencyList, useEffect } from "react";

export function useAsync<T>(asyncFn: () => Promise<T>, onSuccess: (data: T) => void, dependencies: DependencyList) {
    useEffect(() => {
        let isActive = true;
        asyncFn().then(data => {
            if (isActive) {
                onSuccess(data);
                return;
            }
            return;
        });

        return () => { isActive = false; };
    // happy to ignore this here because we're just letting people pass whatever they need in
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asyncFn, onSuccess, ...dependencies]);
};
