import {
    Button,
    CircularProgress,
    Container,
    Box,
    Breadcrumbs,
    Typography
} from "@mui/material";

import { Form, Formik } from "formik";

import { useState, useCallback, useEffect } from "react";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import { useParams, Link, useLocation } from "react-router-dom";

import useDataClient from "../../../axios/dataClient";

import { TextField } from "../../../material/TextField";

import { QuestionAnswer, QuestionAnswerSchema } from "../../../schemas/eventManagement/questionAnswerSchema";

import { ResultEntityModel } from "../../../schemas/eventManagement/resultSchema";

import { getQuestionAnswer } from "../../../services/api/QuestionAnswerManagementService";

import { errors } from "../../ErrorDisplay";

interface LocationState {
    questionText?: string;
}

export const QuestionAnswerForm = () => {

    const { get } = useDataClient();
    const { eventId, questionId, questionAnswerId } = useParams();

    const location = useLocation();
    const state = location.state as LocationState;
    const { questionText } = state || {};

    const [questionAnswer, setQuestionAnswer] = useState<QuestionAnswer>(
        {
            answer: "",
            userEmail: undefined,
            userId: undefined,
            questionText: questionText || ""
        }
    );
    const [isLoading, setLoading] = useState(false);

    const fetchData = useCallback(async () => {
        if (!questionId) {
            return;
        }

        if (questionAnswerId) {
            getQuestionAnswer<ResultEntityModel<QuestionAnswer>>(get)(questionId, questionAnswerId).then((questionAnswerData) => {
                if (questionAnswerData?.isSuccess) {
                    setQuestionAnswer({
                        ...questionAnswerData.entity,
                        questionText: questionText || ""
                    });
                } else if (questionAnswerData?.messages) {
                    errors(questionAnswerData.messages);
                }

                return;
            }).finally(() => {
                setLoading(false);
            });
        }
        else {
            setQuestionAnswer({
                answer: "",
                userEmail: undefined,
                userId: undefined,
                questionText: questionText || ""
            });
            setLoading(false);
        }
    }, [get, questionAnswerId, questionId, questionText]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await fetchData();
        })();
    }, [fetchData]);

    const callSave = useCallback(() => {
        // save functionality not enabled for answers from web app
    }, []);

    if (isLoading || !questionAnswer) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <>
            <Container>
                <Box sx={{ marginTop: '1.2rem!important', display: 'flex', justifyContent: 'space-between' }}>
                    <Box className="bb-title-bar">
                        <h2 className="bb-m0 bb-p0">Answers</h2>
                    </Box>
                    <Box>
                        <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon"
                            to={`/eventManagement/events/${eventId}/questions/${questionId}/questionAnswers`}
                            state={{ questionText }}
                        >
                            <Button variant="outlined" size="small" sx={{ padding: '6px 12px', fontSize: '0.95rem', minWidth: 'auto' }}
                                startIcon={<KeyboardArrowLeftIcon/>} disabled={isLoading}>
                            Back to Answers
                            </Button>
                        </Link>
                    </Box>
                </Box>
                <Breadcrumbs className="bb-breadcrumb bb-mb-3 bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
                    <Link to="/eventManagement">Event Management</Link>
                    <Link to="/eventManagement/events">Events</Link>
                    <Link to={`/eventManagement/events/${eventId}/questions`}>Questions</Link>
                    <Link to={`/eventManagement/events/${eventId}/questions/${questionId}/questionAnswers`}>Answers</Link>
                </Breadcrumbs>
                <Box className="bb-flex bb-ui-box bb-flex-column bb-tac">
                    <Box className="bb-page-edit-cont">
                        <Box className="bb-flex-column bb-justify-content-center bb-tac" sx={{ minHeight: "60vh" }}>
                            <Box className="page-edit-buttons bb-mb-4" sx={{ display: "flex", justifyContent: "flex-start" }}>
                                <Typography sx={{ fontSize: 18, fontWeight: "bold", mb: 0 }} alignContent="left"
                                    className="bb-mb-0 bb-pb-0">
                                    Answer Details
                                </Typography>
                            </Box>
                            <Box sx={{ width: "100%" }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="bb-sp-cont">
                                    <Formik<QuestionAnswer>
                                        initialValues={questionAnswer}
                                        onSubmit={callSave}
                                        enableReinitialize={true}
                                        validationSchema={QuestionAnswerSchema}>
                                        <Form
                                            id={"questionAnswer_submit"}
                                            className="bb-pathways-initial-form"
                                        >
                                            <TextField
                                                name="userEmail"
                                                label="User Email"
                                                variant="outlined"
                                                className="bb-mt-2 bb-mb-4"
                                                disabled
                                                required
                                            />
                                            <TextField
                                                name="questionText"
                                                label="Question"
                                                variant="outlined"
                                                className="bb-mt-2 bb-mb-4"
                                                disabled
                                                required
                                                value={questionAnswer.questionText}
                                            />
                                            <TextField
                                                name="answer"
                                                label="Answer"
                                                variant="outlined"
                                                className="bb-mt-2 bb-mb-4"
                                                disabled
                                                required
                                            />
                                            {questionAnswerId &&
                                                <Box sx={{ textAlign: "left" }}>
                                                    <label>Deleted: {questionAnswer.deleted ? "Yes" : "No"}</label>
                                                </Box>
                                            }
                                        </Form>
                                    </Formik>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </>);
};
