import * as React from "react";
import { useState } from "react";
import { Box, Card, CardContent, CardMedia, Typography, IconButton, Tooltip } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export interface BBEventsProps {
    panel: any;
}

export const BBEvents: React.FC<BBEventsProps> = ({ panel }) => {
    const [currentEventIndex, setCurrentEventIndex] = useState(0);
    const events = React.useMemo(() => panel.links || [], [panel.links]);
    const eventCount = events.length;

    const handlePrevClick = () => {
        setCurrentEventIndex((prevIndex) => (prevIndex === 0 ? events.length - 1 : prevIndex - 1));
    };

    const handleNextClick = () => {
        setCurrentEventIndex((prevIndex) => (prevIndex === events.length - 1 ? 0 : prevIndex + 1));
    };

    if (eventCount === 0) {
        return <Box>No Events have been created yet.</Box>;
    }

    const currentEvent = events[currentEventIndex];

    return (
        <Box>
            <Box className="bb-mb-2 bb-event-container">
                <Card className="bb-event-card">
                    <CardMedia
                        component="img"
                        height="140"
                        image={currentEvent.eventImage}
                        alt={currentEvent.eventTitle}
                        className="bb-event-image"
                    />
                    <CardContent className="bb-event-content">
                        <Typography variant="h4" component="div" className="bb-event-title" sx={{ marginTop: '4px' }}>
                            {currentEvent.eventTitle}
                        </Typography>
                        <Typography color="text.secondary" className="bb-event-description" sx={{ marginTop: '8px' }}>
                            {currentEvent.eventDescription}
                        </Typography>
                        <Box className="bb-event-detail" sx={{ marginTop: '10px' }}>
                            <CalendarTodayIcon className="bb-event-icon" />
                            <Typography variant="body2" color="text.secondary">
                                {currentEvent.eventDate}
                            </Typography>
                        </Box>
                        <Box className="bb-event-detail" sx={{ marginTop: '8px' }}>
                            <AttachMoneyIcon className="bb-event-icon" />
                            <Typography variant="body2" color="text.secondary">
                                {currentEvent.eventPrice}
                            </Typography>
                        </Box>
                        <Box className="bb-event-detail" sx={{ marginTop: '8px' }}>
                            <LocationOnIcon className="bb-event-icon" />
                            <Typography variant="body2" color="text.secondary">
                                {currentEvent.eventVenue}
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
                <Box className="dots-arrows-container" sx={{ justifyContent: eventCount > 1 ? 'space-between' : 'center' }}>
                    {eventCount > 1 && (
                        <IconButton onClick={handlePrevClick} className="arrow-button">
                            <ArrowBackIosIcon className="arrow-icon" />
                        </IconButton>
                    )}
                    <Box className="dots-container">
                        {events.map((_: any, index: number) => (
                            <Box
                                key={index}
                                className={`dot ${index === currentEventIndex ? 'active' : ''}`}
                            />
                        ))}
                    </Box>
                    {eventCount > 1 && (
                        <IconButton onClick={handleNextClick} className="arrow-button">
                            <ArrowForwardIosIcon className="arrow-icon" />
                        </IconButton>
                    )}
                </Box>
            </Box>
            <Box sx={{ borderTop: "1px solid #eee" }}>
                <Box className="bb-flex bb-align-items-center bb-justify-content-center bb-mt-2" sx={{ alignItems: "bottom" }}>
                    <h3 className="bb-header-with-icon">Events</h3>
                    <Tooltip
                        title="Events can display across the full width of your page. You can cycle through different events using the arrows."
                        placement="top"
                    >
                        <InfoOutlinedIcon className="bb-header-icon"></InfoOutlinedIcon>
                    </Tooltip>
                </Box>
                <ul className="bb-pe-list bb-justify-content-center">
                    <li className="bb-flex bb-align-items-center">
                        <Tooltip title="Number of event items." placement="top">
                            <InfoOutlinedIcon className="bb-tip-icon"></InfoOutlinedIcon>
                        </Tooltip>
                        <span className="bb-pe-setting">Items</span>: <b>&nbsp;{eventCount}</b>
                    </li>
                </ul>
            </Box>
        </Box>
    );
};

