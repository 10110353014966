import React, { FC, useMemo, useState } from "react";

import { Form } from '@rjsf/mui';

import validatorAjv8 from "@rjsf/validator-ajv8";

import { RJSFSchema, UiSchema } from "@rjsf/utils";

import { Grid, Box, Button, styled, Modal, Typography, useTheme } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

export interface JsonFormModalProps {
    onUpdate: (e: any) => void;
    onCancel: (e: any) => void;
    onError?: (e: any) => void;
    schema: RJSFSchema;
    data: any;
    open: boolean;
    disabled?: boolean;
}

const ModalBox = styled(Box)(() => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    padding: 15,
    p: 4,
    overflow: "auto",
    maxHeight: "60%",
}));

export const JsonFormModal: FC<JsonFormModalProps> = ({ onUpdate, onError, onCancel, schema, data, open, disabled }) => {
    const theme = useTheme();
    const [formData, setFormData] = useState(data);

    const uiSchema: UiSchema = useMemo(() => ({
        "PartitionKey": {
            "ui:readonly": true
        },
        "RowKey": {
            "ui:readonly": true
        },
        "IdentityKeys": {
            "ui:readonly": true
        },
        "RowStatus": {
            "ui:readonly": true
        },
        "ProviderStatus": {
            "ui:readonly": true
        },
        "Timestamp": {
            "ui:readonly": true
        },
        "ui:title": "",
        'ui:submitButtonOptions': {
            norender: true
        }
    }), []);

    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ "& .MuiBox-root": { backgroundColor: theme.palette.background.default } }}
        >
            <ModalBox className={"bb-edit-page-modal"}>
                <Box className="bb-flex bb-p-3 bb-align-items-center bb-edit-page-modal-header">
                    <Typography variant='h2' className="bb-mb-0" sx={{ marginLeft: ".5rem" }}>
                        {schema?.title}
                    </Typography>
                    <Button className="bb-ml-auto" onClick={onCancel} variant="text">
                        <CloseIcon></CloseIcon>
                    </Button>
                </Box>
                <Box className="bb-pe-modal-form-media">
                    <Form
                        id={formData?.title}
                        schema={{ ...schema }}
                        onChange={(e) => setFormData(e.formData)}
                        formData={{ ...formData }}
                        validator={validatorAjv8}
                        onError={onError}
                        onSubmit={onUpdate}
                        uiSchema={uiSchema}
                        className={"bb-pe-modal-form"}
                        disabled={disabled}
                        formContext={{ formData }}
                    >
                        <Box className="bb-pe-modal-footer">
                            <Grid container spacing={1} className="bb-flex bb-fixed">
                                <Grid item>
                                    <Button onClick={onCancel} variant="text">
                                Cancel
                                    </Button>
                                </Grid>
                                <Grid item className="bb-ml-auto">
                                    <Button variant="contained" type="submit">
                                Update
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Form>
                </Box>
            </ModalBox>
        </Modal>
    );
};
