import { DialogContent, DialogTitle, Divider, Grid, Typography } from "@mui/material";

import { BeeBotFileTemplateDialogDetails } from "../../../services/model/beeBotFile/BeeBotFile";

interface props {
    dialog: BeeBotFileTemplateDialogDetails;
}

export const TemplateDialogDetails = ({ dialog }: props) => {
    return (<>
        <DialogTitle>
            <Typography>
                <b>{dialog.name}</b> -  Dialog Details
            </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
            <Grid
                container
                spacing={4}
                alignItems="stretch"
                alignContent="center"
                justifyContent="center">
                <Grid item xs={2} >Language</Grid>
                <Grid item xs={5} >Intents</Grid>
                <Grid item xs={5} >Templates</Grid>
                {dialog.languages.map(language => (<>
                    <Grid item xs={2}>{language.name}</Grid>
                    <Grid item xs={5}>
                        {language.intents.map(intent => (<>
                            <Typography key={intent.name}>{intent.name}</Typography>
                            {intent.utterances.map(ut => (<Typography sx={{ paddingLeft: "1rem" }} key={ut}>{"> " + ut}</Typography>))}
                        </>))}
                    </Grid>
                    <Grid item xs={5}>
                        {language.templates.map(template => (<Typography key={template}>{template}</Typography>))}
                    </Grid>
                </>))}
            </Grid>
        </DialogContent>
    </>);
};
