import * as React from "react";

import { Grid, Box } from "@mui/material";

export interface BBCardGridProps {
    panel: any;
}

export const BBCardGrid: React.FC<BBCardGridProps> = ({ panel }) => {
    console.log(panel);
    return (
        <Box>
            <Grid item xs>
                {panel?.imgUrl ? (<img src={panel?.imgUrl} className="bb-pe-img, bb-width-100" />) : (<span></span>)}
                {panel?.panelHeading ? (<h3 className="bb-tal">{panel?.panelHeading}</h3>) : (<span></span>)}
                {panel?.panelHeading ? (<p className="bb-tal">{panel?.panelPara}</p>) : (<span></span>)}
                <Box className="bb-flex bb-flex-wrap bb-justify-content-center">
                    {panel?.links?.map((link: any, index: number) => (
                        <a key={index} href={link?.linkAction}>
                            <Box className="bb-flex bb-flex-column bb-width-100 bb-pe-card bb-mr-1 bb-ml-1 bb-mt-2">
                                <Box className="bb-pe-card-header">
                                    <img src={link?.linkThumbUrl} className="bb-width-100"/>
                                </Box>
                                <Box className="bb-pe-card-footer">
                                    {link?.linkText}
                                </Box>
                            </Box>
                        </a>
                    ))}
                </Box>
            </Grid>
        </Box>
    );
};
