import {  Card, CardActionArea, CardHeader, Divider } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

interface FolderCardProps {
    name: string;
    parentPath: string;
    isPublic: boolean;
    friendlyName: string;
}

export const FolderCard = ({ name, parentPath, isPublic, friendlyName }: FolderCardProps) =>
{
    const navigate = useNavigate();
    const selectFolder = useCallback(() => {
        const visibility = isPublic ? "public" : "private";
        if (parentPath === "root") {
            navigate(`${visibility}`);
        }
        else if (parentPath === "") {
            navigate(`${visibility}/${name}`);
        }
        else {
            navigate(`${visibility}/${parentPath}/${name}`);
        }
    }, [navigate, name, parentPath, isPublic]);

    return (<Card>
        <CardActionArea onClick={selectFolder} sx={{ flexGrow: 1 }}>
            <CardHeader
                title={friendlyName}
                className="asset-folder-header"
                sx={{ "& .MuiCardHeader-title": { fontSize: "0.8rem!important" } }}
            ></CardHeader>
            <Divider />
        </CardActionArea>
    </Card>);
};
