import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
    Box,
    Typography,
    TextField,
    Button,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Container,
    Alert,
    CircularProgress,
    Collapse,
    Checkbox,
    FormControlLabel,
    Grid,
    Tooltip,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Chip
} from '@mui/material';
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import SendIcon from "@mui/icons-material/Send";
import { ExpandMore } from '@mui/icons-material';
import PeopleIcon from "@mui/icons-material/People";

import { GridRenderCellParams } from '@mui/x-data-grid';

import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

import { ISubscriptionsSummary } from "../../../services/model/notifications/ISubscriptionsSummary";
import { ISendNotificationRequest } from "../../../services/model/notifications/ISendNotificationRequest";
import { IFilter } from "../../../services/model/notifications/IFilter";
import { AppViewModel } from "../../../services/model/apps/AppViewModel";
import { getApp } from "../../../services/api/FrontendService";
import { getSubscriptions, getSubscriptionsCount, send } from "../../../services/api/NotificationService";
import useDataClient from "../../../axios/dataClient";
import { AnalyticsDataGrid } from "../../analytics/AnalyticsDataGrid";
import { AppLinks } from "../AppLinksComponent";
import { Breadcrumb } from "../BreadCrumbComponent";
import { FavoriteProvider } from '../../favorites/FavoriteContext';
import { FavoriteButton } from '../../favorites/FavoriteButton';
import { VideoHelper } from '../../shared/VideoHelper';

type ExpandedState = {
    [key: string]: boolean;
};

type SelectedItem = {
    category: string;
    item: string;
};

const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export const AppNotifications = () => {
    const { appId } = useParams();
    const [appData, setAppData] = useState<AppViewModel | null>(null);
    const { get, post } = useDataClient();
    const [expanded, setExpanded] = useState<ExpandedState>({});
    const [notificationTitle, setNotificationTitle] = useState('');
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationLink, setNotificationLink] = useState('');
    const [notificationsData, setNotificationsData] = useState<ISubscriptionsSummary | null>(null);
    const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);
    const [subscriptionsCount, setSubscriptionsCount] = useState<number>(0);
    const videoUrl = 'https://media.bbotapp.com/pub/self-service-assist/Self-Service%20-%20Notifications%20Manager.m4v';

    const [viaBrowserNotification, setViaBrowserNotification] = useState<boolean>(false);
    const [viaText, setViaText] = useState<boolean>(false);
    const [viaEmail, setViaEmail] = useState<boolean>(false);

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
        dayjs().add(30, 'day').startOf('hour').add(1, 'hour')
    );

    const broadcastGridConfig = {
        title: "",
        initialState: {
            sorting: {
                sortModel: [{ field: "timestamp", sort: "desc" }],
            },
            pagination: { paginationModel: { pageSize: 25 } }
        },
        columnDef: [
            {
                timestamp: {
                    label: "Timestamp"
                }
            },
            {
                user: {
                    label: "Creator"
                }
            },
            {
                title: {
                    label: "Title"
                }
            },
            {
                mmessage: {
                    label: "Message"
                }
            },
            {
                channels: {
                    label: "Channels"
                }
            },
            {
                filters: {
                    label: "Audience Filters"
                }
            }
        ], columns: [
            {
                field: "timestamp",
                headerName: "Timestamp",
                flex: 1.5 ,
                valueFormatter: (param: any) => new Date(param).toISOString().replace(/T/, ' ').replace(/\..+/, '')  },
            { field: "user", headerName: "Creator", flex: 1 },
            { field: "title", headerName: "Title", flex: 1 },
            { field: "message", headerName: "Message", flex: 1 },
            { field: "channels", headerName: "Channels", flex: 2, renderCell: (params: GridRenderCellParams<any, any>) => {
                return ( <div> { params.value ? (params.value.map((gateway: any) => <Chip size={"small"} key={gateway} label={gateway} />)) : null} </div> ); }
            },
            {
                field: "filters",
                headerName: "Audience Filters",
                flex: 2,
                renderCell: (params: GridRenderCellParams<any, any>) => {
                    return ( <div> { params.value ? (
                        params.value.map(
                            (gateway: any) =>
                                gateway.items.map(
                                    (kvp: any ) => <Chip size={"small"} key={gateway.category + '-' + kvp}  label={kvp} />) ) ) : null} </div> ); }
            }
        ]
    };

    useEffect(() => {
        const fetchData = async () => {
            if (appId) {
                const data = await getApp(get)(appId);
                setAppData(data);
            }
        };

        fetchData();
    }, [appId, get]);

    useEffect(() => {
        const fetchNotifications = async () => {
            if (appId) {
                const data = await getSubscriptions(get)(appId);
                setNotificationsData(data);
                setSubscriptionsCount(data.subscriptionsCount);
            }
        };

        fetchNotifications();
    }, [appId, get]);

    useEffect(() => {
        document.title = 'Beebot AI - Notifications - Create';
    },[]);

    const handleCheckboxChange = async (section: string, itemValue: string) => {
        const updatedSelectedItems = selectedItems.some(i => i.item === itemValue && i.category === section)
            ? selectedItems.filter(i => !(i.item === itemValue && i.category === section))
            : [...selectedItems, { category: section, item: itemValue }];

        setSelectedItems(updatedSelectedItems);

        if (appId) {
            const filters = updatedSelectedItems.reduce((acc, { category, item }) => {
                const existingFilter = acc.find(f => f.category === category);
                if (existingFilter) {
                    existingFilter.items.push(item);
                } else {
                    acc.push({ category, items: [item] });
                }
                return acc;
            }, [] as IFilter[]);

            const data = await getSubscriptionsCount(get)(appId, filters);
            setSubscriptionsCount(data.subscriptionsCount);

            setNotificationsData(prevData => ({
                ...prevData,
                subscriptionsCount: data.subscriptionsCount ?? prevData?.subscriptionsCount ?? 0,
                channelSubscribersCount: data.channelSubscribersCount,
                subscriptions: prevData?.subscriptions ?? {},
                pastNotifications: prevData?.pastNotifications ?? []
            }));
        }
    };

    const handleSendNotification = async () => {
        if (appId && notificationTitle && notificationMessage) {
            const channels = [
                viaBrowserNotification && 'push',
                viaText && 'sms',
                viaEmail && 'email'
            ].filter(Boolean) as string[];

            const filters = selectedItems.reduce((acc, { category, item }) => {
                const existingFilter = acc.find(f => f.category === category);
                if (existingFilter) {
                    existingFilter.items.push(item);
                } else {
                    acc.push({ category, items: [item] });
                }
                return acc;
            }, [] as IFilter[]);

            const request: ISendNotificationRequest = {
                title: notificationTitle,
                message: notificationMessage,
                link: notificationLink,
                channels,
                filters,
                expiryTime: selectedDate ? selectedDate.toISOString() : ''
            };

            const result = await send(post)(appId, request);

            setNotificationsData(prevData => {
                if (!prevData) {
                    return {
                        pastNotifications: [result],
                        subscriptionsCount: 0,
                        subscriptions: {},
                        channelSubscribersCount: {}
                    };
                }

                return {
                    ...prevData,
                    pastNotifications: [result, ...prevData.pastNotifications]
                };
            });

            setNotificationTitle('');
            setNotificationMessage('');
            setNotificationLink('');
            setSelectedItems([]);
            setViaBrowserNotification(false);
            setViaText(false);
            setViaEmail(false);

            const updatedSubscriptionsCount = await getSubscriptionsCount(get)(appId, []);
            setSubscriptionsCount(updatedSubscriptionsCount.subscriptionsCount);

            setNotificationsData(prevData => ({
                ...prevData,
                subscriptionsCount: updatedSubscriptionsCount.subscriptionsCount ?? prevData?.subscriptionsCount ?? 0,
                channelSubscribersCount: updatedSubscriptionsCount.channelSubscribersCount,
                subscriptions: prevData?.subscriptions ?? {},
                pastNotifications: prevData?.pastNotifications ?? []
            }));
        }
    };

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleConfirmSend = () => {
        handleSendNotification();
        handleCloseDialog();
    };

    if (!appData) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    const toggleExpand = (section: string) => {
        setExpanded({ [section]: !expanded[section] });
    };

    const audienceSections = notificationsData ? Object.keys(notificationsData.subscriptions).map(capitalizeFirstLetter) : [];

    const isSendDisabled = !viaBrowserNotification && !viaText && !viaEmail || !notificationTitle || !notificationMessage;

    return (
        <FavoriteProvider>
            <Container>
                <Box className="bb-title-bar bb-mt-3">
                    <h2 className="bb-m0 bb-p0">Notifications: {appData.displayName}</h2>
                    <FavoriteButton page={`applications/${appId}/notifications`} displayName={`App: ${appData.displayName}`}
                        displayModule={`Module: Send Notification`} />
                    <AppLinks />
                </Box>
                <Breadcrumb />
                <Alert className="bb-title-info bb-mb-2" severity="info">Send notifications to visitors of your <b>
                    {appData.displayName}</b> application.
                    To learn more about Sending Notifications with Self-Service Assist&nbsp;
                <VideoHelper
                    videoUrl={videoUrl}
                    triggerElement={
                        <a
                            href="#"
                            className="bb-mr-1"
                        >
                            Click Here
                        </a>
                    }
                />
                </Alert>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', gap: 3, width: '100%' }}>
                        <Card elevation={1} sx={{ flex: 2, p: 2 }}>
                            <Typography variant="h3">Your Notification</Typography>
                            <Typography variant="body1" sx={{ mb: 2 }}>
                            Please choose:
                            </Typography>
                            <Grid container spacing={0}>
                                <Grid item xs={6}>
                                    <Box className="bb-notifications-check bb-mr-1">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={viaBrowserNotification}
                                                    onChange={(e) => setViaBrowserNotification(e.target.checked)}
                                                />
                                            }
                                            label="Browser Notification (Web Push)"
                                            labelPlacement="end"
                                            sx={{ width: "100%" }}
                                            className="bb-notifications-label"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box className="bb-notifications-check bb-ml-1">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={viaText}
                                                    onChange={(e) => setViaText(e.target.checked)}
                                                />
                                            }
                                            label="Text (SMS)"
                                            labelPlacement="end"
                                            sx={{ width: "100%" }}
                                            className="bb-notifications-label"
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box className="bb-notification-container">
                                <Tooltip
                                    title="This is the title that will be displayed to the user when the notification is sent."
                                    arrow
                                    placement="top"
                                    PopperProps={{
                                        sx: {
                                            '& .MuiTooltip-tooltip': {
                                                backgroundColor: 'black',
                                                color: 'white',
                                                fontSize: '.8rem',
                                                padding: '0.5rem',
                                            },
                                            '& .MuiTooltip-arrow': {
                                                color: 'black',
                                            },
                                        },
                                    }}>
                                    <TextField
                                        fullWidth
                                        label="Title"
                                        placeholder="Add a descriptive notification title"
                                        variant="outlined"
                                        margin="normal"
                                        size="small"
                                        value={notificationTitle}
                                        onChange={(e) => setNotificationTitle(e.target.value)}
                                        required
                                    />
                                </Tooltip>
                                <Tooltip
                                    title="This is the message that will be displayed to the user when the notification is sent.
                                    Please add any links to external sites in your message not the Application Link below."
                                    arrow
                                    placement="top"
                                    PopperProps={{
                                        sx: {
                                            '& .MuiTooltip-tooltip': {
                                                backgroundColor: 'black',
                                                color: 'white',
                                                fontSize: '.8rem',
                                                padding: '0.5rem',
                                            },
                                            '& .MuiTooltip-arrow': {
                                                color: 'black',
                                            },
                                        },
                                    }}>
                                    <TextField
                                        fullWidth
                                        label="Message"
                                        placeholder="Your Message"
                                        variant="outlined"
                                        margin="normal"
                                        size="small"
                                        multiline
                                        rows={3}
                                        value={notificationMessage}
                                        onChange={(e) => setNotificationMessage(e.target.value)}
                                        required
                                        sx={{ background: "#fff", borderRadius: "10px" }}
                                    />
                                </Tooltip>
                                <Tooltip
                                    title="This link is designed to take the user to a specific page or an action within your application, please ensure
                                    you add a forward slash at the beginning of the page name e.g. /home"
                                    arrow
                                    placement="top"
                                    PopperProps={{
                                        sx: {
                                            '& .MuiTooltip-tooltip': {
                                                backgroundColor: 'black',
                                                color: 'white',
                                                fontSize: '.8rem',
                                                padding: '0.5rem',
                                            },
                                            '& .MuiTooltip-arrow': {
                                                color: 'black',
                                            },
                                        },
                                    }}>
                                    <TextField
                                        fullWidth
                                        label="Application Link"
                                        placeholder="Add a link"
                                        variant="outlined"
                                        margin="normal"
                                        size="small"
                                        value={notificationLink}
                                        onChange={(e) => setNotificationLink(e.target.value)}
                                    />
                                </Tooltip>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        label="Expiry Date and Time"
                                        value={selectedDate}
                                        onChange={(newValue) => setSelectedDate(newValue)}
                                        slotProps={{ textField: { fullWidth: true } }}
                                        format="DD/MM/YYYY HH:mm"
                                        minDateTime={dayjs()}
                                        sx={{ mt: 3 }}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                                <Tooltip title={isSendDisabled ? "Please select a notification channel and fill in the title and message" : ""}>
                                    <span>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleOpenDialog}
                                            disabled={isSendDisabled}
                                            endIcon={<SendIcon />}
                                        >
                                        Send
                                        </Button>
                                    </span>
                                </Tooltip>
                            </Box>
                        </Card>

                        <Card elevation={1} sx={{ flex: 1, p: 2 }}>
                            <Typography variant="h3">Your Audience</Typography>
                            <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }} className="bb-recipients">
                                <PeopleIcon></PeopleIcon> <h4 className="bb-m0 bb-ml-1">Recipients</h4>
                                <p className="bb-ml-auto bb-recipient-count">{subscriptionsCount}</p>
                            </Box>
                            {notificationsData && notificationsData.channelSubscribersCount && (
                                <Box sx={{ mb: 2 }}>
                                    <List>
                                        {Object.entries(notificationsData.channelSubscribersCount).map(([channel, count]) => (
                                            <ListItem key={channel}>
                                                <ListItemText primary={`${channel}: ${count}`} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            )}

                            <List className="bb-notifications-list">
                                {audienceSections.map((section) => (
                                    <React.Fragment key={section}>
                                        <ListItem disablePadding>
                                            <ListItemText primary={section} />
                                            <IconButton onClick={() => toggleExpand(section)}>
                                                <ExpandMore />
                                            </IconButton>
                                        </ListItem>
                                        <Collapse in={expanded[section]} timeout="auto" unmountOnExit>
                                            <List className="bb-notifications-sub-list">
                                                {notificationsData?.subscriptions[section.toLowerCase()].map((item, index) => (
                                                    <ListItem key={index}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={selectedItems.some(i => i.item === item.value &&
                                                                    i.category === section.toLowerCase())}
                                                                    onChange={() => handleCheckboxChange(section.toLowerCase(), item.value)}
                                                                />
                                                            }
                                                            label={`${capitalizeFirstLetter(item.value)} (${item.subscriptions})`}
                                                        />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Collapse>
                                    </React.Fragment>
                                ))}
                            </List>
                        </Card>
                    </Box>
                </Box>
                {notificationsData && notificationsData.pastNotifications && (
                    <Box sx={{ width: '100%', }}>
                    </Box>
                )}
                <Grid container className="bb-ui-box-analytics bb-flex-column animated animatedFadeInUp fadeInUp7">
                    <Grid item xs={12} className="bb-notifications-table">
                        {notificationsData && notificationsData.pastNotifications && (
                            <>
                                <AnalyticsDataGrid
                                    data={notificationsData.pastNotifications}
                                    config={broadcastGridConfig}
                                    title="Notification History"
                                    transformed={true}
                                />
                            </>
                        )}
                    </Grid>
                </Grid>
                <Dialog
                    PaperProps={{ style: { minWidth: "500px" } }}
                    open={isDialogOpen}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="bb-global-modal"
                >
                    <DialogTitle id="alert-dialog-title" className="bb-error-dialog-header bb-flex bb-align-items-center bb-tac">
                        <WarningAmberIcon className="bb-mr-1"></WarningAmberIcon> <h2 className="bb-m0 bb-p0">CONFIRM</h2>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" className="bb-mt-2 bb-tac">
                            <b className="bb-mt-2">Are you sure you want to send this notification?</b>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                        </Button>
                        <Button className="bb-ml-auto" variant="contained" onClick={handleConfirmSend} color="primary" autoFocus>
                        Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </FavoriteProvider>
    );
};
