import * as React from "react";

import { Grid, Box } from "@mui/material";

export interface BBStartChatProps {
    panel: any;
}
export const BBStartChat: React.FC<BBStartChatProps> = ({ panel }) => {
    return (
        <Grid item xs>
            <img src={panel?.imgUrl} className="bb-pe-img bb-width-100" />
            <h3>{panel?.panelHeading}</h3>
            <p>{panel?.panelPara}</p>
            <Box>
                <input type='text' value='How can I help you today?'></input>
                <input type='submit' title='Go' value='go'></input>
            </Box>
        </Grid>
    );
};
