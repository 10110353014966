import { Dialog, DialogContent, DialogActions, Button, Typography, Box } from "@mui/material";

import { LiveChatDashboard } from "./LiveChatDashboard";

interface LiveChatDashboardDialogProps {
    open: boolean;
    onClose: () => void;
}

export const LiveChatDashboardDialog = (props: LiveChatDashboardDialogProps) => {
    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth={true} maxWidth={"xl"}>
            <DialogContent>
                <Box sx={{ margin: '1rem', display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="h2">Live Agent Dashboard</Typography>
                </Box>
                <LiveChatDashboard />
            </DialogContent>
            <DialogActions>
                <Button sx={{ margin: '1rem' }} onClick={props.onClose} color="primary" variant="contained">
                    Close
                </Button>
            </DialogActions>
        </Dialog>);
};
