import * as React from "react";

import { Grid, Box } from "@mui/material";

export interface BBVideoProps {
    panel: any;
}

export const BBVideo: React.FC<BBVideoProps> = ({ panel }) => {
    return (
        <Box>
            <Grid item xs>
                {panel?.imgUrl ? (<img src={panel?.imgUrl} className="bb-pe-img, bb-width-100" />) : (<span></span>)}
                {panel?.panelHeading ? (<h3 className="bb-tal">{panel?.panelHeading}</h3>) : (<span></span>)}
                {panel?.panelHeading ? (<p className="bb-tal">{panel?.panelPara}</p>) : (<span></span>)}
                <video width="320" height="240" controls className="bbVideo">
                    <source src={panel?.videoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </Grid>
        </Box>
    );
};
