import * as yup from "yup";

export const UserSchema = yup.object({
    firstName: yup.string().notRequired(),
    lastName: yup.string().notRequired(),
    email: yup.string().required(),
    rolesString: yup.string().required(),
    maximumChats: yup.number().nullable(true).max(20).min(1).notRequired(),
    displayName: yup.string().nullable().notRequired(),
    teamIds: yup.array()
});

export type UserEditObject = yup.InferType<typeof UserSchema>;
