import { AccordionFieldTemplate } from "../../applications/customWidget/FieldTemplate/AccordionFieldTemplate";
export const clientBrandUiSchema = {
    "ui:ObjectFieldTemplate": AccordionFieldTemplate,
    "primaryColor": {
        "ui:widget": "ColourPicker"
    },
    "secondaryColor": {
        "ui:widget": "ColourPicker"
    },
    "tertiaryColor": {
        "ui:widget": "ColourPicker"
    },
    "contentMaxWidth": {
        "ui:widget": "hidden"
    },
    "radius": {
        "ui:widget": "hidden"
    },
    "containerLogoSize": {
        "ui:style": {
            width: "150px",
        }
    }
};

export const headerUiSchema = {
    "ui:ObjectFieldTemplate": AccordionFieldTemplate,
    "backgroundColor": {
        "ui:widget": "ColourPicker"
    },
    "fontColor": {
        "ui:widget": "ColourPicker"
    },
    "iconsColor": {
        "ui:widget": "ColourPicker"
    }
};

export const mainUiSchema = {
    "ui:ObjectFieldTemplate": AccordionFieldTemplate,
    "backgroundColor": {
        "ui:widget": "ColourPicker"
    },
    "backgroundAltColor": {
        "ui:widget": "ColourPicker"
    },
    "panelBackgroundColor": {
        "ui:widget": "ColourPicker"
    },
    "inputBackgroundColor": {
        "ui:widget": "hidden"
    },
    "botPanelsHeaderFontColor": {
        "ui:widget": "hidden"
    },
    "botPanelsSpanFontColor": {
        "ui:widget": "hidden"
    },
    "panelFontColor": {
        "ui:widget": "hidden"
    },
    "panelListBackgroundColor": {
        "ui:widget": "hidden"
    },
    "panelListBackgroundBorderColor": {
        "ui:widget": "hidden"
    },
    "panelsLancherBorderColor": {
        "ui:widget": "hidden"
    },
    "shadowColor": {
        "ui:widget": "hidden"
    },
    "contentColumns": {
        "ui:widget": "hidden"
    },
    "contentRowColumns": {
        "ui:widget": "hidden"
    },
    "shadowSizes": {
        "ui:widget": "hidden"
    }
};

export const popupsUiSchema = {
    "ui:ObjectFieldTemplate": AccordionFieldTemplate,
    "backgroundColor": {
        "ui:widget": "ColourPicker"
    },
    "fontColor": {
        "ui:widget": "ColourPicker"
    }
};

export const smartPagesUiSchema = {
    "ui:ObjectFieldTemplate": AccordionFieldTemplate,
    "backgroundColor": {
        "ui:widget": "ColourPicker"
    },
    "fontColor": {
        "ui:widget": "hidden"
    },
    "smartPageButtonColor": {
        "ui:widget": "ColourPicker"
    },
    "smartPageButtonTextColor": {
        "ui:widget": "hidden"
    },
    "smartPageButtonIconColor": {
        "ui:widget": "ColourPicker"
    }
};

export const botUiSchema = {
    "ui:ObjectFieldTemplate": AccordionFieldTemplate,
    "backgroundColor": {
        "ui:widget": "ColourPicker"
    },
    "fontColor": {
        "ui:widget": "ColourPicker"
    },
    "adaptivecardButtonColor": {
        "ui:widget": "ColourPicker"
    },
    "adaptivecardFontColor": {
        "ui:widget": "ColourPicker"
    }
};

export const customCssUiSchema = {
    "ui:ObjectFieldTemplate": AccordionFieldTemplate,
    "css": {
        "ui:widget": "textarea",
        "ui:style": {
            fontFamily: "'Courier New', Courier, monospace"
        },
        "ui:options": {
            "rows": 20
        }
    }
};

export const savedHashUiSchema = {
    "ui:widget": "hidden",
    "guid": {
        "ui:widget": "hidden"
    }
};
