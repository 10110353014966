import React, { useEffect, useState, useCallback } from 'react';
import { Typography } from '@mui/material';
import useDataClient from "../../../axios/dataClient";
import { Event } from '../../../schemas/eventManagement/eventSchema';
import { ResultEntityModel } from '../../../schemas/eventManagement/resultSchema';
import { getEvent } from '../../../services/api/EventManagementService';
import { errors } from '../../ErrorDisplay';

interface EventDetailsProps {
    eventId: string;
}

export const EventDetails: React.FC<EventDetailsProps> = ({ eventId }) => {
    const { get } = useDataClient();
    const [event, setEvent] = useState<Event | null>(null);

    const loadEvent = useCallback(async () => {
        if (eventId) {
            const eventData = await getEvent<ResultEntityModel<Event>>(get)(eventId);
            if (eventData?.isSuccess && eventData?.entity) {
                setEvent(eventData.entity);
            } else if (eventData?.messages) {
                errors(eventData.messages);
            }
        }
    }, [eventId, get]);

    useEffect(() => {
        loadEvent();
    }, [loadEvent]);

    return (
        <Typography variant="h3" className="bb-m0 bb-p0" sx={{ marginLeft: '6px!important' }}>
            {event?.eventName}
        </Typography>
    );
};
