import { PartnerUserDetails, Partner } from "../../schemas/partnerManagement/schema";

const getImports = <schema>(get: <T>(url: string) => Promise<T>) => async () => {
    const result = await get<schema>(`/api/partnerManagement/imports`);
    return result;
};

const getPartnerUser = <schema>(get: <T>(url: string) => Promise<T>) => async (userId: string) => {
    const result = await get<schema>(`/api/partnerManagement/partnerUsers/${userId}`);
    return result;
};

const getPartnerUsers = <schema>(get: <T>(url: string) => Promise<T>) => async (partnerId: string) => {
    const result = await get<schema>(`/api/partnerManagement/partner/${partnerId}/users`);
    return result;
};

const getPartnerUsersForCurrentUser = <schema>(get: <T>(url: string) => Promise<T>) => async () => {
    const result = await get<schema>(`/api/partnerManagement/partnerUsers`);
    return result;
};

const updatePartnerUser = (put: <T>(url: string, body: any) => Promise<T>) => async (userId: string, form: PartnerUserDetails) => {
    const result = await put<{ errors: any[] }>(`/api/partnerManagement/partnerUsers/${userId}`, form);
    return result;
};

const createPartnerUser = <schema>(post: <T>(url: string, body: any) => Promise<T>) => async (form: PartnerUserDetails) => {
    const result = await post<schema>(`/api/partnerManagement/partnerUsers`, form);
    return result;
};

const uploadImportUsers = (post: <T>(url: string, body: any) => Promise<T>) => async (form: FormData) => {
    const result = await post<{ errors: any[] }>("/api/partnerManagement/importUsers", form);
    return result;
};

const getImportPartners = <schema>(get: <T>(url: string) => Promise<T>) => async () => {
    const result = await get<schema>(`/api/partnerManagement/importPartners`);
    return result;
};

const getPartner = <schema>(get: <T>(url: string) => Promise<T>) => async (partnerId: string) => {
    const result = await get<schema>(`/api/partnerManagement/partner/${partnerId}`);
    return result;
};

const sendEmailInvite = (put: <T>(url: string, body: any) => Promise<T>) => async (form: string[]) => {
    const result = await put<{ errors: any[] }>(`/api/partnerManagement/emailinvite`, form);
    return result;
};

const getPartners = <schema>(get: <T>(url: string) => Promise<T>) => async () => {
    const result = await get<schema>(`/api/partnerManagement/partner`);
    return result;
};

const updatePartner = (put: <T>(url: string, body: any) => Promise<T>) => async (partnerId: string, form: Partner) => {
    const result = await put<{ errors: any[] }>(`/api/partnerManagement/partner/${partnerId}`, form);
    return result;
};

const createPartner = <schema>(post: <T>(url: string, body: any) => Promise<T>) => async (form: Partner) => {
    const result = await post<schema>(`/api/partnerManagement/partner`, form);
    return result;
};

const uploadImportPartners = (post: <T>(url: string, body: any) => Promise<T>) => async (form: FormData) => {
    const result = await post<{ errors: any[] }>("/api/partnerManagement/importPartners", form);
    return result;
};

export {
    getImports,
    uploadImportUsers,
    getPartnerUsers,
    getPartnerUser,
    updatePartnerUser,
    getImportPartners,
    getPartner,
    getPartners,
    updatePartner,
    uploadImportPartners,
    getPartnerUsersForCurrentUser,
    createPartner,
    createPartnerUser,
    sendEmailInvite
};
