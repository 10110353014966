import React from "react";
import { Button } from "@mui/material";
import { WarningModal } from "../shared/WarningModal";
import UndoIcon from "@mui/icons-material/Undo";

export interface UndoButtonProps {
    onClick: () => void;
    disable: boolean;
}

export const UndoButton: React.FC<UndoButtonProps> = ({ onClick, disable }) => {
    const [open, setOpen] = React.useState(false);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const [confirmAction, setConfirmAction] = React.useState<() => void>(() => () => {});

    const handleOpen = (action: () => void) => {
        setConfirmAction(() => action);

        setOpen(true);
    };
    const handleClose = () => setOpen(false);
    const handleConfirm = () => {
        confirmAction();
        setOpen(false);
    };

    return (
        <>
            <Button onClick={() => handleOpen(onClick)} startIcon={<UndoIcon/>} variant="outlined" disabled={disable}>Undo</Button>
            <WarningModal
                open={open}
                description={"Are you sure you want to undo all changes?"}
                onConfirm={handleConfirm}
                onCancel={handleClose}
            />
        </>
    );
};
