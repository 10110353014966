import { ReactNode, createContext } from "react";

import { useQuery } from "react-query";

import { Configuration } from "./services/model/Configuration";
import useDataClient from "./axios/dataClient";
import { getConfiguration } from "./services/api/CustomerConfigurationService";

export const ConfigurationContext =
    createContext<Configuration>({
        composer: ""
    });

export const ConfigurationContextProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const { get } = useDataClient();

    const { data } = useQuery("ConfigurationContext", () =>
        getConfiguration(get)
    );

    const customerConfiguration = data ?? {
        composer: ""
    };

    return (
        <ConfigurationContext.Provider value={customerConfiguration}>
            {children}
        </ConfigurationContext.Provider>
    );
};
