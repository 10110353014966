import { ITableListModel } from "../model/dataManagement/ITableListModel";
import { ITableEditModel } from "../model/dataManagement/ITableEditModel";
import { ITableDataEditModel } from "../model/dataManagement/ITableEditModel";
import { ITableStructureListModel } from "../model/dataManagement/ITableStructureListModel";
import { IRowKeyPair } from "../model/dataManagement/IRowKeyPair";

const getTables = async (getFunction: <T>(url: string) => Promise<T>) => {
    const result = await getFunction<ITableListModel[]>("/api/data");
    return result;
};

const getTableStructures = async (getFunction: <T>(url: string) => Promise<T>) => {
    const result = await getFunction<ITableStructureListModel[]>("/api/data/structures");
    return result;
};

const getTable = async (getFunction: <T>(url: string) => Promise<T>, tableId: string) => {
    const result = await getFunction<ITableEditModel>(`/api/data/${tableId}`);
    return result;
};

const getDataApprovals = async (getFunction: <T>(url: string) => Promise<T>, tableId: string) => {
    const result = await getFunction<ITableDataEditModel>(`/api/data/${tableId}/dataApprovals`);
    return result;
};

const deleteRows = async (
    deleteFunction: (url: string, data: any) => Promise<void>,
    tableId: string,
    rowKeyPairs: IRowKeyPair[]
) => {
    await deleteFunction(`/api/data/${tableId}/rows/delete`, rowKeyPairs);
};

const saveRow = async (
    putFunction: (url: string, data: any) => Promise<IRowKeyPair>,
    tableId: string,
    rowData: any
): Promise<IRowKeyPair> => {
    const rowKeyPair = await putFunction(`/api/data/${tableId}/rows/save`, rowData);
    return rowKeyPair;
};

const savepPendingRow = async (
    putFunction: (url: string, data: any) => Promise<IRowKeyPair>,
    tableId: string,
    partitionKey: string,
    rowKey: string,
    rowData: any
): Promise<IRowKeyPair> => {
    const rowKeyPair = await putFunction(`/api/data/${tableId}/rows/${partitionKey}/${rowKey}/savePending`, rowData);
    return rowKeyPair;
};

const approveRows = async (
    putFunction: (url: string, data: any) => Promise<IRowKeyPair>,
    tableId: string,
    rowKeyPairs: IRowKeyPair[]
): Promise<IRowKeyPair> => {
    const rowKeyPair = await putFunction(`/api/data/${tableId}/rows/approve`, rowKeyPairs);
    return rowKeyPair;
};

const rejectRows = async (
    putFunction: (url: string, data: any) => Promise<IRowKeyPair>,
    tableId: string,
    rowKeyPairs: IRowKeyPair[]
): Promise<IRowKeyPair> => {
    const rowKeyPair = await putFunction(`/api/data/${tableId}/rows/reject`, rowKeyPairs);
    return rowKeyPair;
};

const cancelRows = async (
    putFunction: (url: string, data: any) => Promise<IRowKeyPair>,
    tableId: string,
    rowKeyPairs: IRowKeyPair[]
): Promise<IRowKeyPair> => {
    const rowKeyPair = await putFunction(`/api/data/${tableId}/rows/cancel`, rowKeyPairs);
    return rowKeyPair;
};

export {
    getTables,
    getTableStructures,
    getTable,
    deleteRows,
    saveRow,
    getDataApprovals,
    approveRows,
    rejectRows,
    cancelRows,
    savepPendingRow
};
