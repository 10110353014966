import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";

import { TextField } from "../../material/TextField";
import useDataClient from "../../axios/dataClient";

interface EditDisplayNameDialogProps {
    displayName: string | undefined;
    open: boolean;
    onCancel: () => void;
    onSuccess: (displayName: string) => void;
}

export const EditDisplayNameDialog = ({ open, onCancel, onSuccess, displayName }: EditDisplayNameDialogProps) => {
    const { post } = useDataClient();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const saveDisplayName = useCallback(async (values: { displayName: string | undefined }) => {
        if (values.displayName === undefined) {
            return;
        }
        setIsSubmitting(true);
        await post("api/userManagement/displayName", values);
        setIsSubmitting(false);
        onSuccess(values.displayName);
    }, [post, onSuccess]);
    return (<Dialog open={open}>
        <DialogTitle className="bbot-dialog-title">Edit Display Name</DialogTitle>
        <Formik initialValues={{ displayName }}

            onSubmit={saveDisplayName}>
            <Form>
                <DialogContent className="bbot-dialog-content">
                    <TextField name="displayName" label="Display Name"></TextField>
                </DialogContent>
                <DialogActions className="bbot-dialog-actions">
                    <Button onClick={onCancel}>Cancel</Button>
                    {isSubmitting ? <CircularProgress /> : <Button type="submit">Submit</Button>}
                </DialogActions>
            </Form>
        </Formik>
    </Dialog>);
};
