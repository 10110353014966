import { Button } from "@mui/material";
import { useCallback, useState } from "react";

import { LiveChatDashboardDialog } from "./LiveChatDashboardDialog";

export const LiveChatDashboardButton = () => {
    const [isLiveChatDashboardDialogOpen, setIsLiveChatDashboardDialogOpen] = useState(false);
    const showLiveChatDialog = useCallback(() => setIsLiveChatDashboardDialogOpen(true), []);
    const handleCloseDashboard = useCallback(() => setIsLiveChatDashboardDialogOpen(false), []);

    return (<>
        <Button variant="outlined"
            onClick={showLiveChatDialog}>
            Dashboard
        </Button>
        <LiveChatDashboardDialog open={isLiveChatDashboardDialogOpen} onClose={handleCloseDashboard} />
    </>);
};
