import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface VersionResponse {
    version: string;
}

export const AutoUpdater: React.FC = () => {
    const location = useLocation();

    const checkVersion = async (): Promise<boolean> => {
        try {
            const currentVersion = localStorage.getItem('appVersion');

            const response = await fetch('/api/version?' + Date.now(), {
                headers: {
                    'Cache-Control': 'no-cache, no-store, must-revalidate',
                    'Pragma': 'no-cache',
                    'Expires': '0'
                }
            });

            if (!response.ok) {
                throw new Error(`AutoUpdater: Version fetch failed with status: ${response.status}`);
            }

            const data: VersionResponse = await response.json();

            if (!currentVersion) {
                localStorage.setItem('appVersion', data.version);
                return true;
            }

            if (data.version !== currentVersion) {
                localStorage.setItem('appVersion', data.version);
                return true;
            }

            return false;

        } catch (error) {
            return false;
        }
    };

    const clearCachesAndReload = async () => {
        try {
            if ('caches' in window) {
                const cacheKeys = await caches.keys();
                await Promise.all(
                    cacheKeys.map(key => caches.delete(key))
                );
            }

            if ('serviceWorker' in navigator) {
                const registrations = await navigator.serviceWorker.getRegistrations();
                await Promise.all(
                    registrations.map(registration => registration.unregister())
                );
            }

            const url = new URL(window.location.href);
            url.searchParams.set('_cb', Date.now().toString());

            try {
                await fetch(url.toString(), {
                    headers: {
                        'Cache-Control': 'no-cache, no-store, must-revalidate',
                        'Pragma': 'no-cache',
                        'Expires': '0',
                    },
                });
            } catch (e) {
                console.log('AutoUpdater: Cache-busting fetch failed (this is okay):', e);
            }

            window.location.replace(url.toString());

            setTimeout(() => {
                window.location.reload();
            }, 1000);

        } catch (error) {
            console.error('AutoUpdater: Cache clear failed:', error);
            window.location.reload();
        }
    };

    useEffect(() => {
        const runVersionCheck = async () => {
            try {
                const shouldRefresh = await checkVersion();

                if (shouldRefresh) {
                    await clearCachesAndReload();
                }
            } catch (error) {
                console.error('AutoUpdater: Version check failed in effect:', error);
            }
        };

        runVersionCheck();
    }, [location]);

    return null;
};
