import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useCallback } from "react";

import { TextField } from "../../material/TextField";

import useDataClient from "../../axios/dataClient";
import { AddBotSchema, AddBotType } from "../../yup-schemas/AddBotSchema";
import { Select } from "../../material/Select";

interface AddBotDialogProps {
    open: boolean;
    templates: { displayName: string; templateKey: string}[];
    onCancel: () => void;
    onSuccess: () => void;
}

export const AddBotDialog = ({ open, templates, onCancel, onSuccess }: AddBotDialogProps) => {
    const { post } = useDataClient();
    const AddBot = useCallback(async (botModel: AddBotType) => {
        await post("api/bot", { ...botModel, liveDate: new Date() });
        onSuccess();
    }, [post, onSuccess]);
    return (<Dialog open={open}>
        <DialogTitle className="bbot-dialog-title">
            <Typography>Add Bot</Typography></DialogTitle>
        <Formik<AddBotType>
            onSubmit={AddBot} initialValues={{ azureBotName: "", displayName: "", templateKey: "" }} validationSchema={AddBotSchema}>
            {props => (
                <Form>
                    <DialogContent className="bbot-dialog-content">
                        <Stack spacing={2}>
                            <TextField name='azureBotName' label='Bot Name' />
                            <TextField name='displayName' label='Display Name' />
                            <Select name="templateKey" label="Template">
                                {templates.map((template) => (
                                    <MenuItem key={template.templateKey} value={template.templateKey}>{template.displayName}</MenuItem>
                                ))}
                            </Select>
                        </Stack>
                    </DialogContent>
                    <DialogActions className="bbot-dialog-actions">
                        <Button onClick={onCancel}>Cancel</Button>
                        {props.isSubmitting ? <CircularProgress /> : <Button type="submit" disabled={!props.isValid}>Submit</Button>}
                    </DialogActions>
                </Form>
            )}
        </Formik>
    </Dialog>);
};
