import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { useCallback, useRef, useState } from "react";

interface CloseChatConfirmDialogProps {
    open: boolean;
    chatName: string;
    onCancel: () => void;
    onAccept: (closeMessage: string) => Promise<void>;
}
export const CloseChatConfirmDialog = ({ open, chatName, onCancel, onAccept }: CloseChatConfirmDialogProps) => {
    const textValue = useRef({ value: "" });
    const [finishing, setFinishing] = useState(false);

    const onFinish = useCallback(async () => {
        setFinishing(true);
        try {
            await onAccept(textValue.current.value);
        }
        finally {
            setFinishing(false);
        }
    }, [onAccept]);

    const onEnter = useCallback((event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            onFinish();
        }
    }, [onFinish]);
    return (<Dialog open={open}>
        <DialogTitle>Close {chatName}</DialogTitle>
        <DialogContent>
            <Typography>Why do you want to finish chatting with {chatName}? This will be sent to the customer.</Typography>
            <TextField fullWidth sx={{ marginTop: "1em" }} inputRef={textValue} onKeyDown={onEnter} />
        </DialogContent>
        <DialogActions>
            <Button onClick={onCancel}>Cancel</Button>
            <Button variant="contained" onClick={onFinish} disabled={finishing}>Finish</Button>
        </DialogActions>
    </Dialog>);
};
