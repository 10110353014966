import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import axios from "axios";

const result = await axios.get<any>(`/api/configuration/aiconnection`);
console.log(result.data);
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: result.data,
        extensions: [reactPlugin],
        enableAutoRouteTracking: true,
    }
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };
