/* eslint-disable max-len */
import { Button, AppBar, Toolbar, Menu, MenuItem, IconButton, Box, useTheme } from "@mui/material";
import { Menu as OpenMenuIcon } from "@mui/icons-material";
import { useCallback, useContext, useMemo, useState } from "react";

import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import BusinessIcon from '@mui/icons-material/Business';
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";
import Edit from "@mui/icons-material/Edit";

import { matchRoutes, useLocation, Link } from "react-router-dom";

import { MemberAppContext } from "../../MemberAppContext";
import { TopBarHeight } from "../../theme";

import { AppRoute } from "../../authorized-routes";

import { routes } from "../../App";

import { SearchBar } from "./Search";
import { EditDisplayNameDialog } from "./EditDisplayName";
import { useCustomerName } from "../customer/useCustomerName";

// Hiding for now - import { TemporaryDrawer } from "./HistoryDrawer";

/* eslint @typescript-eslint/no-var-requires: "off" */ // Need to find a way around it, but hotfixed for live release on friday.
const beeBotTitleLogo = require("../../img/bb-logo-white.png");

export const TopToolbar = () => {
    const theme = useTheme();

    const { msalConfig, user, sidebarOpen, setSidebarOpen, updateDisplayName } = useContext(MemberAppContext);
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const location = useLocation();
    const [displayNameOpen, setDisplayNameOpen] = useState(false);
    const customerName = useCustomerName();

    const logout = useCallback(() => {
        msalConfig.msalClientApp?.logoutRedirect();
    }, [msalConfig]);

    const open = Boolean(anchorEl);
    const handleOpen = useCallback((event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const toggleSidebar = useCallback(() => {
        setSidebarOpen(!sidebarOpen);
    }, [sidebarOpen, setSidebarOpen]);

    const openDisplayName = useCallback(() => {
        setDisplayNameOpen(true);
    }, []);

    const closeDisplayName = useCallback(() => {
        setDisplayNameOpen(false);
    }, []);

    const displayNameSuccess = useCallback((displayName: string) => {
        updateDisplayName(displayName);
        setDisplayNameOpen(false);
    }, [updateDisplayName]);

    const isSearchableMatch = useMemo(() => {
        const currentMatchedRoutes = matchRoutes(routes, location) ?? [];
        return currentMatchedRoutes.some((x) => (x.route as AppRoute)?.searchBar);
    }, [location]);

    return (
        <AppBar
            position="fixed"
            sx={{ zIndex: theme.zIndex.drawer + 1, justifyContent: "center", height: TopBarHeight, bgcolor: "background.paper", }}
        >
            <Toolbar>
                <Box sx={{ justifyContent: "space-between", alignItems: "center", display: "flex", width: "100%" }}>
                    {<IconButton onClick={toggleSidebar} className="bb-mob-menu" sx={{ color: "#111", display: { xs: "block", md: "none", lg: "none", xl: "none" } }}>
                        <OpenMenuIcon />
                    </IconButton>}
                    <Link to="/"><img src={beeBotTitleLogo} height={40} alt="BeeBotAI Logo" /></Link>
                    { isSearchableMatch ? <SearchBar /> : <></>}
                    {/*<TemporaryDrawer></TemporaryDrawer>*/}
                    <Box>
                        <Box className="user-profile" sx={{ display: "inline-flex", marginLeft: 'auto' }}>
                            <Button startIcon={<BusinessIcon />} >
                                <span className="user-name">{customerName}</span>
                            </Button>
                        </Box>
                        <Box className="user-profile" sx={{ display: "inline-flex", marginLeft: 'auto' }}>
                            <Button startIcon={<PersonOutlineOutlinedIcon />} onClick={handleOpen} >
                                <span className="user-name">{user.displayName}</span>
                            </Button>
                        </Box>
                    </Box>
                    <Menu anchorEl={anchorEl}
                        open={open}
                        id="bb-user-menu"
                        onClose={handleClose}>
                        <MenuItem onClick={openDisplayName}>
                            <ListItemIcon>
                                <Edit fontSize="small" />
                            </ListItemIcon>
                            Edit Name
                        </MenuItem>
                        <MenuItem onClick={logout}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            Log Out
                        </MenuItem>
                    </Menu>
                </Box>
            </Toolbar>
            <EditDisplayNameDialog open={displayNameOpen} onCancel={closeDisplayName} onSuccess={displayNameSuccess} displayName={user.displayName} />
        </AppBar>);
};
