export const createJsonObject = (data: any) => {
    const properties: any = {};

    data.columns.forEach((column: any) => {
        const type = column.type || "string";
        let newType = type;
        const isSingleSelect = type === "singleSelect";

        if ((type === "dateTime") || (isSingleSelect)) {
            newType = "string";
        }

        if (isSingleSelect) {
            properties[column.field] = {
                type: newType,
                title: column.headerName,
                enum: column.valueOptions
            };
        } else {
            properties[column.field] = {
                type: newType,
                title: column.headerName,
            };
        }
    });

    return {
        "$schema": "http://json-schema.org/draft-07/schema#",
        "type": "object",
        "title": data.displayName,
        // eslint-disable-next-line object-shorthand
        "properties": properties
    };
};

export const createObjectFromRow = (row: any, columns: any[]) => {
    const rowObject: any = {};
    columns.forEach((column: any) => {
        const value = row[column.field];
        rowObject[column.field] = column.type === 'number' ? Number(value) : value;
    });
    return rowObject;
};
