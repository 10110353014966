import { MouseEvent, useCallback } from "react";

import { Button, Box, Typography } from "@mui/material";
import { DataGrid, GridRenderCellParams, GridColDef } from "@mui/x-data-grid";

import { GroupedInteractionsData } from "../../../services/model/livechat/dashboard/groupedInteractionsData";
import { LoadingCard } from "../../shared/LoadingCard";

interface GroupedInteractionsProps {
    isLoading: boolean;
    groupedInteractionsData: GroupedInteractionsData[];
    onPageSelected: (botName: string, path: string) => Promise<void>;
}

export const GroupedInteractions = (props: GroupedInteractionsProps) => {
    const { isLoading, groupedInteractionsData, onPageSelected } = props;

    const onViewClickFactory = useCallback((params: GridRenderCellParams<any, any, any>) => async (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation(); // don't select this row after clicking

        const path = params.row.path;
        const botId = params.row.botId;

        await onPageSelected(botId, path);
    }, [onPageSelected]);

    const columns: GridColDef[] = [
        /* TODO { field: "path", headerName: "URL", flex: 1, valueFormatter: params => new URL(params.value).pathname }, */
        { field: "path", headerName: "URL", flex: 1, valueFormatter: params => new URL(params).pathname },
        { field: "conversations", headerName: "Active Users", flex: 1 },
        { field: "botName", headerName: "Bot Name", flex: 1 },
        {
            field: "view",
            headerName: "View",
            flex: 1,
            sortable: false,
            renderCell:
            (params: GridRenderCellParams<any, any, any>) =>
                (<Button data-testid="view-button" variant="outlined" onClick={onViewClickFactory(params)}>View</Button>)
        }
    ];

    if (isLoading) {
        return (<LoadingCard title="Live Users By Bot" />);
    }

    return (
        <Box className="bb-mt-2 bb-mb-2 bb-p-2" sx={{ background: "#fff", border: "1px solid #ddd", borderRadius: "7px" }}>
            <Typography variant="h3">Active users by URL</Typography>
            <DataGrid
                autoHeight
                rows={groupedInteractionsData}
                columns={columns}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "conversations", sort: "desc" }]
                    },
                    pagination: {
                        paginationModel: {
                            pageSize: 5
                        }
                    }
                }}
                disableRowSelectionOnClick
                pageSizeOptions={[5, 10, 25, 50]} />
        </Box>
    );
};
