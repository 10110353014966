import {  Card, CardActionArea, CardHeader, Divider } from "@mui/material";
import { useCallback } from "react";

import { FileFolderInfo } from "./FileFolderView";

interface FolderCardProps {
    fileFolderInfo: FileFolderInfo;
    handleFolderSelection: (fullPath?: string) => void;
    friendlyName: string;
}

export const FolderCard = ({ fileFolderInfo, handleFolderSelection, friendlyName }: FolderCardProps) =>
{
    const selectFolder = useCallback(() => {
        handleFolderSelection(fileFolderInfo.fullPath);
    }, [fileFolderInfo.fullPath, handleFolderSelection]);

    return (<Card>
        <CardActionArea onClick={selectFolder} sx={{ flexGrow: 1 }}>
            <CardHeader
                title={friendlyName}
                className="asset-folder-header"
                sx={{ "& .MuiCardHeader-title": { fontSize: "0.8rem!important" } }}
            ></CardHeader>
            <Divider />
        </CardActionArea>
    </Card>);
};
