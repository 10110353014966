import { JSONSchema7 } from "json-schema";

import linkTypeOptionsSchema from './linkTypeOptions.json';
import dbTemplateOptionsSchema from './dbTemplateOptions.json';

interface JSONSchema7Extended extends JSONSchema7 {
    [key: string]: any; // Allow dynamic access with string keys
}

function resolveSchemaReferences(schema: JSONSchema7Extended, definitions: Record<string, JSONSchema7>): JSONSchema7 {
    if (Object.prototype.hasOwnProperty.call(schema, 'customReference') && typeof schema.customReference === 'string') {
        const refPath = schema.customReference.split('/').pop(); // Assumes $ref is a simple reference to definitions

        return refPath ? definitions[refPath] || {} : {};
    }

    Object.keys(schema).forEach(key => {
        if (typeof schema[key] === 'object' && schema[key] !== null) {
            schema[key] = resolveSchemaReferences(schema[key] as JSONSchema7, definitions);
        }
    });

    return schema;
}

export function resolveCommonSchemaDefinitions(schema: JSONSchema7) {
    const definitions = {
        'linkTypeOptions.json': linkTypeOptionsSchema as JSONSchema7,
        'dbTemplateOptions.json': dbTemplateOptionsSchema as JSONSchema7
    };

    return resolveSchemaReferences(schema, definitions);
}
