import { Grid } from "@mui/material";

import { StatCard } from "./StatCard";
import { bigNumber, pcNumber } from "./StatHelpers";

interface Props {
    data: any | undefined;
    loading: boolean;
    app: string | undefined;
}

export const AnalyticsKpiGrid = ({ loading, data, app }: Props) => {

    let values = [0, 0, 0, 0, 0];
    let values_pc = [0, 0, 0, 0, 0];

    if (data && app) {
        if (app === '[ALL]') {
            app='ALL';
        }
        const values2 = data["005-cards-customer-ai-kpi"].data[app] as any;
        if (values2) {
            // eslint-disable-next-line max-len
            values = [values2.AppUsers_Expected.stat, values2.PWAUsers_pc.stat, values2.contentViewUser_pc.stat, values2.notificationsUser_pc.stat, values2.avg_duration.stat];
            values_pc = [
                values2.AppUsers_Expected.stat_pc,
                values2.PWAUsers_pc.stat_pc,
                values2.contentViewUser_pc.stat_pc,
                values2.notificationsUser_pc.stat_pc,
                values2.avg_duration.stat_pc];
        }
    }

    return (
        <Grid container gap="1rem" className="bb-flex bb-width-100 bb-analytics-big-stat">
            <div className="bb-flex-1 animated animatedFadeInUp fadeInUp"><StatCard loading={loading} title="Users (Predicted)"
                stat={
                    bigNumber(values[0])
                }
                stat_pc={
                    pcNumber(values_pc[0],true)
                } />
            </div>

            <div className="bb-flex-1 animated animatedFadeInUp fadeInUp1"><StatCard loading={loading} title="App Users"
                stat={
                    pcNumber(values[1],false)
                }
                stat_pc={
                    pcNumber(values_pc[1],true)
                } />
            </div>
            <div className="bb-flex-1 animated animatedFadeInUp fadeInUp2"><StatCard loading={loading} title="Content Users"
                stat={
                    pcNumber(values[2],false)
                }
                stat_pc={
                    pcNumber(values_pc[2],true)
                } />
            </div>
            <div className="bb-flex-1 animated animatedFadeInUp fadeInUp3"><StatCard loading={loading} title="Notification Users"
                stat={
                    pcNumber(values[3],false)
                }
                stat_pc={
                    pcNumber(values_pc[3],true)
                } />
            </div>
            <div className="bb-flex-1 animated animatedFadeInUp fadeInUp3"><StatCard loading={loading} title="Session Duration(mins)"
                stat={
                    values[4].toFixed(1)
                }
                stat_pc={
                    pcNumber(values_pc[4],true)
                } />
            </div>
        </Grid >
    );
};
