import {
    Button,
    Box,
    Grid,
    styled
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { Form } from '@rjsf/mui';

import React from "react";

import { JSONSchema7 }  from "json-schema";

import validatorAjv8 from "@rjsf/validator-ajv8";

import { UiSchema } from "@rjsf/utils";

import { SmartPage } from "../../../schemas/smartPages/schema";

import smartPagesSlideModel from "../../../schemas/smartPages/smartPagesSlidesSchema.json";

import { AccordionFieldTemplate } from "../../applications/customWidget/FieldTemplate/AccordionFieldTemplate";

export interface AppSmartPageSlideFormProps {
    currentSmartPage: SmartPage;
    onUpdate: (e: any) => void;
    onError: (e: any) => void;
    onCancel: (e: any) => void;
}

export const AppSmartPageSlideForm: React.FC<AppSmartPageSlideFormProps> = ({ currentSmartPage, onUpdate, onError, onCancel }) => {
    const uiSchema: UiSchema = {
        'ui:submitButtonOptions': {
            norender: true
        },
        "bbSlides": {
            "ui:title": "",
            "ui:options": {
                "orderable": false
            },
            "items": {
                "ui:ObjectFieldTemplate": AccordionFieldTemplate,
                "links": {
                    "ui:ObjectFieldTemplate": AccordionFieldTemplate,
                    "ui:options": {
                        "orderable": false
                    },
                    "items": {
                        "ui:ObjectFieldTemplate": AccordionFieldTemplate,
                    },
                },
                "html": {
                    "ui:widget": "textarea",
                    "ui:options": {
                        "rows": 5
                    }
                },
            },
        },
        "ui:title": ""
    };

    const ModalBox = styled(Box)(() => ({
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "50%",
        padding: 15,
        p: 4,
        overflow: "auto",
        maxHeight: "60%",
    }));

    return (
        <ModalBox className="bb-edit-page-modal">
            <>
                <Box className="bb-flex bb-pe-modal-header">
                    <h2>Smart Page: {currentSmartPage.name}</h2>
                    <Button className="bb-ml-auto" onClick={onCancel} variant="text">
                        <CloseIcon></CloseIcon>
                    </Button>
                </Box>
                <Form
                    schema={smartPagesSlideModel as JSONSchema7}
                    formData={currentSmartPage}
                    validator={validatorAjv8}
                    onError={onError}
                    onSubmit={onUpdate}
                    className={"bb-pe-modal-form"}
                    uiSchema={uiSchema}
                >
                    <Box className="bb-pe-modal-footer">
                        <Grid container spacing={1} className="bb-flex bb-fixed">
                            <Grid item>
                                <Button onClick={onCancel} variant="text">
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item className="bb-ml-auto">
                                <Button variant="contained" type="submit">
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Form>
            </>
        </ModalBox>
    );
};
