import { Grid } from "@mui/material";

import { StatCard } from "./StatCard";
import { bigNumber } from "./StatHelpers";

interface Props {
    data: any | undefined;
    loading: boolean;
}

export const AnalyticsAppSummaryGrid = ({ loading, data }: Props) => {

    let values = [0, 0, 0, 0, 0];

    if (data) {
        const values2 = data["005-summary-customer-ai-app"].data as any;
        values = [values2.AppUsers[0], values2.AppSessions[0], values2.PWAUsers[0], values2.PageViews[0],values2.avg_duration[0] ];
    }

    return (
        <Grid container gap="1rem" className="bb-flex bb-width-100 bb-analytics-big-stat">
            <div className="bb-flex-1 animated animatedFadeInUp fadeInUp"><StatCard loading={loading} title="Users"
                stat={
                    bigNumber(values[0])
                } />
            </div>

            <div className="bb-flex-1 animated animatedFadeInUp fadeInUp1"><StatCard loading={loading} title="Sessions"
                stat={
                    bigNumber(values[1])
                } />
            </div>
            <div className="bb-flex-1 animated animatedFadeInUp fadeInUp2"><StatCard loading={loading} title="Installed App Users"
                stat={
                    bigNumber(values[2])
                } />
            </div>
            <div className="bb-flex-1 animated animatedFadeInUp fadeInUp3"><StatCard loading={loading} title="Page Views"
                stat={
                    bigNumber(values[3])
                } />
            </div>
            <div className="bb-flex-1 animated animatedFadeInUp fadeInUp4"><StatCard loading={loading} title="Session Duration (mins)"
                stat={
                    values[4].toFixed(1)
                } />
            </div>
        </Grid >
    );
};
