/* eslint-disable max-lines */
import * as signalR from "@microsoft/signalr";
import { ReactNode, useCallback, useContext, useRef, useState } from "react";

import useDataClient from "../../axios/dataClient";

import { MemberAppContext } from "../../MemberAppContext";

import {
    chatExistsCallback,
    ChatHubContext,
    chatTransferredCallback,
    fileScannedCallback,
    messageCallback,
    messageDequeuedCallback,
    messageQueuedCallback,
    typingCallback
} from "./chatHubContext";
import { ChatMessage } from "./ChatWindow";

import { LiveAgentQueueItem } from "./queues";

import { AgentState } from "./agentState";

import { AssignedChatRequest } from "./assignedChatRequest";

import { ChatHandoffRequest, receiveTransferRequest } from ".";

export const RegisterChatContext = ({ children }: { children: ReactNode }) => {
    const { getToken } = useDataClient();
    const { updateLiveAgentStatus } = useContext(MemberAppContext);
    const [connection] = useState(
        process.env.NODE_ENV === "development"
            ? new signalR.HubConnectionBuilder()
                .configureLogging(signalR.LogLevel.Trace)
                .withUrl("https://localhost:7094/chatHub", {
                    accessTokenFactory: getToken,
                    transport: signalR.HttpTransportType.WebSockets,
                    skipNegotiation: true,
                })
                .withAutomaticReconnect()
                .build()
            : new signalR.HubConnectionBuilder()
                .configureLogging(signalR.LogLevel.Error)
                .withUrl("/chatHub", { accessTokenFactory: getToken })
                .withAutomaticReconnect()
                .build()
    );

    const ref = useRef<{
        onNewChatCallback: (handoff: ChatHandoffRequest) => void;
        onReceiveTransferCallback: (transfer: receiveTransferRequest) => void;
        onRecieveAssignedCallback: (assignedChat: AssignedChatRequest) => void;
        messageCallbacks: { [key: string]: messageCallback };
        typingCallbacks: { [key: string]: typingCallback };
        chatTransferredCallbacks: { [key: string]: chatTransferredCallback };
        messageQueuedCallbacks: messageQueuedCallback[] ;
        messageDequeuedCallbacks: messageDequeuedCallback[];
        chatExistsCallback: chatExistsCallback;
        fileScannedCallback: { [key: string]: fileScannedCallback };
            }>({
                messageCallbacks: {},
                typingCallbacks: {},
                chatTransferredCallbacks: {},
                onNewChatCallback: () => undefined,
                onReceiveTransferCallback: () => undefined,
                onRecieveAssignedCallback: () => undefined,
                messageQueuedCallbacks: [],
                messageDequeuedCallbacks: [],
                chatExistsCallback: async () => undefined,
                fileScannedCallback: {}
            });

    const handoffMessageCallback = useCallback(
        (
            userName: string,
            userEmail: string,
            issue: string,
            botName: string,
            chatId: string
        ) => {
            const handoff = {
                userName,
                userEmail,
                issue,
                botName,
                chatId,
            };

            ref.current.onNewChatCallback(handoff);
        },
        []
    );

    const transferredCallback = useCallback(
        (botName: string, chatId: string) => {
            const callback =
                ref.current.chatTransferredCallbacks[botName + chatId];
            if (callback === undefined) {
                throw Error("Chat ID not registered to this live agent");
            }
            callback();
        },
        []
    );

    const receiveChatTransferCallback = useCallback(
        (
            userName: string,
            userEmail: string,
            issue: string,
            botName: string,
            chatId: string,
            notes: string,
            messageHistory: ChatMessage[]
        ) => {
            const transfer = {
                userName,
                userEmail,
                issue,
                botName,
                chatId,
                notes,
                messageHistory: messageHistory.map((m) => {
                    return {
                        sender: m.sender,
                        message: m.message,
                        time: new Date(m.time),
                    };
                }),
            };

            ref.current.onReceiveTransferCallback(transfer);
        },
        []
    );

    const recieveChatAssignedCallback = useCallback((
        userName: string,
        userEmail: string,
        issue: string,
        botName: string,
        chatId: string,
    ) => {
        const assignedChat = {
            userName,
            userEmail,
            issue,
            botName,
            chatId
        };
        ref.current.onRecieveAssignedCallback(assignedChat);
    }, []);

    const chatExists = useCallback((userName: string, userEmail: string, issue: string, botName: string, chatId: string) => {
        const existingChat = { userName, userEmail, issue, botName, chatId };
        ref.current.chatExistsCallback(existingChat);
    }, []);

    const fileScanned = useCallback((chatId: string, fileName: string, success: boolean) => {
        const callback = ref.current.fileScannedCallback[chatId];
        if (callback === undefined) {
            throw Error("ChatId not registered to this live agent");
        }

        callback(chatId, fileName, success);
    }, []);

    const serverMessageCallback = useCallback(
        (message: string, botName: string, chatId: string) => {
            const callback = ref.current.messageCallbacks[botName + chatId];
            if (callback === undefined) {
                throw Error("Chat ID not registered to this live agent");
            }
            callback(message);
        },
        []
    );

    const serverTypingCallback = useCallback(
        (botName: string, chatId: string) => {
            const callback = ref.current.typingCallbacks[botName + chatId];
            if (callback === undefined) {
                throw Error("Chat ID not registered to this live agent");
            }
            callback();
        },
        []
    );

    const onMessageQueuedCallback = useCallback((item: LiveAgentQueueItem) => {
        ref.current.messageQueuedCallbacks.forEach(callback => callback(item));
    }, []);

    const onMessageDequeuedCallback = useCallback((customerId: string, chatId: string) => {
        ref.current.messageDequeuedCallbacks.forEach(callback => callback(customerId, chatId));
    }, []);

    const statusUpdateCallback = useCallback((status: AgentState) => {
        updateLiveAgentStatus(status);
    }, [updateLiveAgentStatus]);

    connection.on("ChatMessage", serverMessageCallback);

    connection.on("ChatHandoff", handoffMessageCallback);

    connection.on("ChatTransferred", transferredCallback);

    connection.on("ReceiveChatTransfer", receiveChatTransferCallback);

    connection.on("ChatTyping", serverTypingCallback);

    connection.on("MessageQueued", onMessageQueuedCallback);

    connection.on("MessageDequeued", onMessageDequeuedCallback);

    connection.on("StatusUpdate", statusUpdateCallback);

    connection.on("ChatExists", chatExists);

    connection.on("FileScanned", fileScanned);

    connection.on("ChatAssigned", recieveChatAssignedCallback);

    const registerCallback = useCallback(
        (callback: messageCallback, botName: string, chatId: string) => {
            ref.current.messageCallbacks[botName + chatId] = callback;
        },
        []
    );

    const registerTypingCallback = useCallback(
        (callback: typingCallback, botName: string, chatId: string) => {
            ref.current.typingCallbacks[botName + chatId] = callback;
        },
        []
    );

    const registerTransferredCallback = useCallback(
        (
            callback: chatTransferredCallback,
            botName: string,
            chatId: string
        ) => {
            ref.current.chatTransferredCallbacks[botName + chatId] = callback;
        },
        []
    );

    const registerMessageQueuedCallback = useCallback((callback: messageQueuedCallback) => {
        ref.current.messageQueuedCallbacks.push(callback);
    }, []);

    const registerMessageDequeuedCallback = useCallback((callback: messageDequeuedCallback) => {
        ref.current.messageDequeuedCallbacks.push(callback);
    }, []);

    const subscribe = useCallback(async () => {
        const promise = connection.start().catch((err) => {
            alert(err);
            setConnectionState(connection.state);
        });
        setConnectionState(connection.state);
        await promise;
        setConnectionState(connection.state);
    }, [connection]);

    const sendMessage = useCallback(
        async (message: string, botName: string, chatId: string) => {
            await connection.send("Message", message, chatId, botName);
        },
        [connection]
    );

    const sendTypingEvent = useCallback(
        async (botName: string, chatId: string) => {
            await connection.send("Typing", chatId, botName);
        },
        [connection]
    );

    const registerOnNewChatCallback = useCallback(
        (callback: (handoff: ChatHandoffRequest) => void) => {
            ref.current.onNewChatCallback = callback;
        },
        []
    );

    const registerOnReceiveTransferCallback = useCallback(
        (callback: (transfer: receiveTransferRequest) => void) => {
            ref.current.onReceiveTransferCallback = callback;
        },
        []
    );

    const registerOnAssignedCallback = useCallback(
        (callback: (assignedChat: AssignedChatRequest) => void) => {
            ref.current.onRecieveAssignedCallback = callback;
        }, []);

    const registerChatExistsCallback = useCallback(
        (callback: chatExistsCallback) => {
            ref.current.chatExistsCallback = callback;
        },
        []
    );

    const registerFileScannedCallback = useCallback((callback: fileScannedCallback, chatId: string) => {
        ref.current.fileScannedCallback[chatId] = callback;
    }, []);

    const closeConnection = useCallback(async () => {
        await connection.stop();
        setConnectionState(connection.state);
    }, [connection]);

    const [connectionState, setConnectionState] = useState(connection.state);

    connection.onclose(() => {
        setConnectionState(connection.state);
    });
    connection.onreconnected(() => {
        setConnectionState(connection.state);
    });
    connection.onreconnecting(() => {
        setConnectionState(connection.state);
    });

    return (
        <ChatHubContext.Provider
            value={{
                registerCallback,
                registerTypingCallback,
                registerTransferredCallback,
                closeConnection,
                subscribe,
                sendMessage,
                sendTypingEvent,
                registerOnReceiveTransferCallback,
                registerOnAssignedCallback,
                registerOnNewChatCallback,
                registerMessageQueuedCallback,
                registerMessageDequeuedCallback,
                registerChatExistsCallback,
                registerFileScannedCallback,
                connectionState,
            }}
        >
            {children}
        </ChatHubContext.Provider>
    );
};
