import {
    ListItemButton,
    ListItemText,
    Badge,
    Typography,
    Box,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import { DefaultBorderWidth, theme } from "../../theme";

import { ChatMessage } from "./ChatWindow";

export interface ChatPreviewProps {
    chatId: string;
    conversationMessages: ChatMessage[];
    userName: string;
    activeChatId: string | null;
    selectChat: (chatId: string) => void;
    unreadMessages: number;
}

export const ChatPreview = (props: ChatPreviewProps) => {
    const {
        chatId,
        conversationMessages,
        userName,
        activeChatId,
        selectChat,
        unreadMessages,
    } = props;
    const [messagePreview, setMessagePreview] = useState(
        conversationMessages[conversationMessages.length - 1]?.message ?? ""
    );
    const [timeElapsed, setTimeElapsed] = useState<string>("0m");

    useEffect(() => {
        setMessagePreview(
            conversationMessages[conversationMessages.length - 1]?.message ?? ""
        );
    }, [conversationMessages]);

    const calculateTimeElapsed = useCallback(() => {
        if (conversationMessages.length === 0) {
            setTimeElapsed("0m");
            return;
        }
        const initialmsElapsed = Date.now() - conversationMessages[0].time.getTime();
        const initialMinutesElapsed = Math.floor(initialmsElapsed / (1000 * 60));
        setTimeElapsed(initialMinutesElapsed + "m");
    }, [conversationMessages]);

    useEffect(() => {
        calculateTimeElapsed();
        const interval = setInterval(() => {
            calculateTimeElapsed();
        }, 30000);

        return () => clearInterval(interval);
    }, [calculateTimeElapsed]);

    const isChatSelected = useCallback(
        (id: string) => {
            return activeChatId === id;
        },
        [activeChatId]
    );

    const listItemStyle = (id: string) =>
        isChatSelected(id)
            ? { borderWidth: `calc(${DefaultBorderWidth} * 2)`, borderColor: theme.palette.primary.main, borderRightWidth: `calc(${DefaultBorderWidth} * 8)` }
            : {};

    return (
        <ListItemButton
            className="live-chat-preview"
            key={chatId}
            sx={{
                border: `${DefaultBorderWidth} solid ${theme.palette.divider}`,
                mb: theme.spacing(1),
                borderRadius: 1,
                ...listItemStyle(chatId),
            }}
            data-testid={chatId}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => selectChat(chatId)}
            selected={isChatSelected(chatId)}
        >
            <ListItemText
                primary={
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            sx={{
                                wordBreak: "break-word",
                                display: "-webkit-box",
                                overflow: "hidden",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 2,
                            }} variant="h5">
                            {userName}
                        </Typography>
                        <Typography color={theme.palette.grey[600]} mt={theme.spacing(0.5)}>{timeElapsed}</Typography>
                    </Box>
                }
                secondary={
                    <Typography
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography
                            component="span"
                            variant="caption"
                            color="text.primary"
                            sx={{
                                wordBreak: "break-word",
                                display: "-webkit-box",
                                overflow: "hidden",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 2,
                                mr: theme.spacing(1.4)
                            }}
                        >
                            {messagePreview}
                        </Typography>

                        <Badge
                            color="secondary"
                            badgeContent={unreadMessages}
                            data-testid="unread-messages-count"
                        />
                    </Typography>
                }
            />
        </ListItemButton>
    );
};
