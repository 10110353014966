interface CustomerUser {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    roles: CustomerUserRole[];
    teamIds: string[];
    isActive: boolean;
    maximumChats: number | undefined;
    terminationDate: Date | null;
    displayName: string | null;
    type: CustomerUserType;
};

interface CustomerUserRole {
    id: string;
    name: string;
}

interface Team {
    id: string;
    customer: string;
    displayName: string;
}

export enum CustomerUserType {
    Local = 0,
    AzureSSO = 1
}

export type { CustomerUser, CustomerUserRole, Team };
