import { QuestionAnswer } from "../../schemas/eventManagement/questionAnswerSchema";

const getQuestionAnswers = <schema>(get: <T>(url: string) => Promise<T>) => async (questionId: string) => {
    const result = await get<schema>(`/api/question/${questionId}/questionAnswer`);
    return result;
};

const getQuestionAnswer = <schema>(get: <T>(url: string) => Promise<T>) => async (questionId: string, questionAnswerId: string) => {
    const result = await get<schema>(`/api/question/${questionId}/questionAnswer/${questionAnswerId}`);
    return result;
};

const createQuestionAnswer = <schema>(post: <T>(url: string, body: any) => Promise<T>) => async (questionId: string, form: QuestionAnswer) => {
    const result = await post<schema>(`/api/question/${questionId}/questionAnswer`, form);
    return result;
};

const updateQuestionAnswer = <schema>(put: <T>(url: string, body: any) => Promise<T>) =>
    async (questionId: string, questionAnswerId: string, form: QuestionAnswer) => {
        const result = await put<schema>(`/api/question/${questionId}/questionAnswer/${questionAnswerId}`, form);
        return result;
    };

const deleteQuestionAnswer = <schema>(deleteRequest: <T>(url: string, body: any) => Promise<T>) => async (questionId: string, questionAnswerId: string) => {
    const result = await deleteRequest<schema>(`/api/question/${questionId}/questionAnswer/${questionAnswerId}`, {});
    return result;
};

export { getQuestionAnswers, getQuestionAnswer, createQuestionAnswer, updateQuestionAnswer, deleteQuestionAnswer };
