import * as yup from "yup";

export type Booking = {
    rowKey?: string;
    scheduleId?: string;
    userEmail?: string;
    bookingNumber?: string;
    timestamp?: Date;
    deleted?: boolean;
    isAddedToWaitingQueue?: boolean;
};

export interface BookingQueue extends Booking {
    booked?: boolean;
};

export const BookingSchema: yup.SchemaOf<Booking> = yup.object({
    rowKey: yup.string().notRequired(),
    scheduleId: yup.string().notRequired(),
    userEmail: yup.string().email("Invalid email address").required("User Email is required"),
    bookingNumber: yup.string().required(),
    timestamp: yup.date().notRequired(),
    deleted: yup.boolean().notRequired(),
    isAddedToWaitingQueue: yup.boolean().notRequired()
});

