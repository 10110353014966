import { useCallback, useEffect, useState } from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, MenuItem } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { Select, TextField } from "formik-mui";

import useDataClient from "../../axios/dataClient";
import { Operator } from "../../services/model/operator";
import { LiveChatTransfer, liveChatTransferSchema } from "../../yup-schemas/live-chat-transfer-schema";

import { ChatMessage } from "./ChatWindow";

interface TransferDialogProps {
    open: boolean;
    chatId: string;
    botName: string;
    issue: string;
    userEmail: string;
    userName: string;
    onClose: () => void;
    messageHistory: ChatMessage[];
}

const TransferDialog = (props: TransferDialogProps) => {
    const { get, post } = useDataClient();
    const [loading, setLoading] = useState(true);
    const [operatorsList, setOperatorsList] = useState<Operator[]>([]);

    const { open, chatId, onClose, botName, issue, userEmail, userName, messageHistory } = props;

    useEffect(() => {
        const effect = async () => {
            if (open) {
                const operators = await get<Operator[]>("api/chat/operators/active");
                setOperatorsList(operators);
                setLoading(false);
            }
        };

        effect();
    }, [get, open, setOperatorsList]);

    const handleTransfer = useCallback(async (transfer: LiveChatTransfer) => {
        await post<any>("api/chat/transfer", transfer);
        onClose();
    }, [post, onClose]);

    if (loading) {
        return (<Dialog open={open} onClose={onClose}>
            <DialogContent>
                <CircularProgress />
            </DialogContent>
        </Dialog>);
    }

    if (operatorsList.length === 0) {
        return (<Dialog open={open} onClose={onClose} fullWidth={true}>
            <DialogContent>
                No operators available
            </DialogContent>
        </Dialog>);
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth={true}>
            <Formik<LiveChatTransfer>
                initialValues={{ chatId, toOperatorId: "", botName, issue, userEmail, userName, notes: "", messageHistory }}
                onSubmit={handleTransfer}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={liveChatTransferSchema}>
                {formProps => {
                    return (
                        <Form>
                            <DialogContent>
                                <Field component={Select}
                                    id="toOperatorId"
                                    name="toOperatorId"
                                    label="Transfer to"
                                    sx={{ minWidth: 120 }}>
                                    {
                                        operatorsList.map((operator) => (
                                            <MenuItem key={operator.operatorId} value={operator.operatorId}>{operator.operatorName}</MenuItem>))
                                    }
                                </Field>
                                <Field component={TextField} name="notes" label="Notes" placeholder="Transfer notes" fullWidth multiline={true} minRows={2}>
                                </Field>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    type="submit"
                                    disabled={formProps.isSubmitting}>
                                    {!formProps.isSubmitting ? (
                                        "Transfer"
                                    ) : (
                                        <CircularProgress />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>);
                }}
            </Formik>
        </Dialog>);
};

export { TransferDialog };
