import { Box, Card, CardActionArea, CardContent, CardHeader, Collapse, Divider, IconButton, Typography, Button } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useCallback, useContext, useMemo, useState } from "react";

import { DeleteOutline, ForwardOutlined } from "@mui/icons-material";

import { Team } from "../../../services/model/users/customerUser";

import { MemberAppContext } from "../../../MemberAppContext";

import { LiveAgentQueueItem } from "./liveAgentQueueItem";

interface QueueItemProps {
    item: LiveAgentQueueItem;
    teams: Team[];
    selectQueueItem: (item: LiveAgentQueueItem) => void;
    deleteQueueItem: (item: LiveAgentQueueItem) => void;
    forwardQueueItem: (item: LiveAgentQueueItem) => void;
}

export const QueueItem = ({ item, teams, selectQueueItem, deleteQueueItem, forwardQueueItem }: QueueItemProps) => {
    const [summaryExpanded, setSummaryExpanded] = useState(true);
    const { user: { isLiveAgentAdmin } } = useContext(MemberAppContext);
    const lockItem = useCallback(
        () => {
            selectQueueItem(item);
        },
        [item, selectQueueItem],
    );

    const deleteItem = useCallback(() => {
        deleteQueueItem(item);
    }, [item, deleteQueueItem]);

    const showInfo = useCallback(() => {
        setSummaryExpanded(!summaryExpanded);
    }, [summaryExpanded]);

    const assignItem = useCallback(() => {
        forwardQueueItem(item);
    }, [forwardQueueItem, item]);

    const teamName = useMemo(() => {
        const selectedTeam = teams.find(team => item?.team?.startsWith(team.id));
        return selectedTeam?.displayName;
    }, [teams, item]);

    return (<Card className="queue-item">
        <CardHeader
            className="queue-item-card-title"
            title={item.userName}
            action={<Box>
                <IconButton onClick={deleteItem}>
                    <DeleteOutline className="queue-item-info" />
                </IconButton>
                <IconButton onClick={showInfo}>
                    <InfoOutlinedIcon className="queue-item-info" />
                </IconButton>
                {isLiveAgentAdmin &&
                <IconButton onClick={assignItem}>
                    <ForwardOutlined className="queue-item-info" />
                </IconButton>}
            </Box>
            } />
        <CardActionArea onClick={lockItem} sx={{ flexGrow: 1 }}>
            <CardContent className="queue-item-content">
                <Box className="queue-item-text">
                    <Typography>
                        {item.issue}
                    </Typography>
                    <Typography>
                        {teamName}
                    </Typography>
                    <Collapse in={!summaryExpanded}>
                        <Divider />
                        <Box>
                            <Typography>CAI: {item.botName}</Typography>
                            <Typography>Queued: {new Date(item.firstQueued).toLocaleTimeString()}</Typography>
                        </Box>
                    </Collapse>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                    <Button variant="contained" className="button-look">View</Button>
                </Box>
            </CardContent>
        </CardActionArea>
    </Card>);
};
