import React, { useCallback, useState, useEffect, useContext } from 'react';
import { ColorPicker } from "react-pick-color";
import { Button, Tabs, Tab, TextField, Grid, Box, Modal, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { MemberAppContext } from '../../../../MemberAppContext';
import { ContrastChecker } from './ContrastChecker';
import './ColourPickerStyle.css';

interface Preset {
    title: string;
    color: string;
    userSub: string;
}

const ColourPicker = (props: any) => {
    const [showPicker, setShowPicker] = useState(false);
    const [tabIndex, setTabIndex] = useState(0); // Default to "Colour Selection" tab
    const [presetTitle, setPresetTitle] = useState('');
    const [presets, setPresets] = useState<Preset[]>([]);
    const [initialColor, setInitialColor] = useState(props.value);
    const { user } = useContext(MemberAppContext);
    const userSub = user?.sub || 'defaultSub';

    const loadPresets = useCallback(() => {
        const savedPresets = localStorage.getItem('colour-presets');
        if (savedPresets) {
            const parsedPresets = JSON.parse(savedPresets);
            const userPresets = parsedPresets.filter((preset: { userSub: string }) => preset.userSub === userSub);
            setPresets(userPresets);
        }
    }, [userSub]);

    useEffect(() => {
        loadPresets();
    }, [loadPresets]);

    const handleChange = useCallback((color: any) => {
        const newValue = color.hex;
        props.onChange(newValue);
    }, [props]);

    const togglePicker = () => {
        if (showPicker) {
            // Revert to initial color if closing the picker
            props.onChange(initialColor);
        } else {
            // Store the initial color when opening the picker
            setInitialColor(props.value);
            // Load presets from local storage
            loadPresets();
        }
        setShowPicker(!showPicker);
    };

    const getTextColor = (hexColor: string) => {
        const rgb = parseInt(hexColor?.slice(1), 16);
        const r = Math.floor(rgb / (256 * 256)) % 256;
        const g = Math.floor(rgb / 256) % 256;
        const b = rgb % 256;
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;
        return brightness > 186 ? 'black' : 'white';
    };

    const savePreset = () => {
        const newPreset = { title: presetTitle, color: props.value, userSub };
        const allPresets = JSON.parse(localStorage.getItem('colour-presets') || '[]');
        const updatedPresets = [...allPresets, newPreset];
        localStorage.setItem('colour-presets', JSON.stringify(updatedPresets));
        setPresets(updatedPresets.filter((preset: { userSub: string }) => preset.userSub === userSub));
        setTabIndex(1);
    };

    const deletePreset = (index: number) => {
        const allPresets = JSON.parse(localStorage.getItem('colour-presets') || '[]');
        const updatedPresets = allPresets.filter((preset: { userSub: string }, i: number) => !(preset.userSub === userSub && i === index));
        localStorage.setItem('colour-presets', JSON.stringify(updatedPresets));
        setPresets(updatedPresets.filter((preset: { userSub: string }) => preset.userSub === userSub));
    };

    const selectPreset = (color: string) => {
        props.onChange(color);
        setShowPicker(false);
    };

    const selectColor = () => {
        setShowPicker(false);
    };

    const textColor = getTextColor(props.value);

    return (
        <div className='bb-flex bb-align-items-center'>
            <Button
                variant="contained"
                onClick={togglePicker}
                style={{
                    backgroundColor: props.value,
                    color: textColor,
                    boxShadow: "0px 0px 5px #eee",
                    minWidth: "40px",
                    minHeight: "40px",
                    borderRadius: "5px",
                    marginRight: ".5rem"
                }}
            >
            </Button>
            <p className="bb-ml-1 bb-p0 bb-mb-0 bb-mt-0">{props.label}</p>
            <Modal
                open={showPicker}
                onClose={togglePicker}
                aria-labelledby="color-picker-modal"
                aria-describedby="color-picker-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        border: 'thick transparent',
                        borderRadius: '5px'
                    }}
                >
                    <IconButton
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                        onClick={togglePicker}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Tabs
                        className='ColorTabs'
                        value={tabIndex}
                        onChange={(e, newValue) => setTabIndex(newValue)}
                        sx={{ minHeight: '36px' }}
                    >
                        <Tab label="Colour Picker" sx={{ minWidth: '80px', padding: '4px 8px' }} />
                        <Tab label="My Colours" sx={{ minWidth: '80px', padding: '4px 8px' }} />
                        <Tab label="Contrast Checker" sx={{ minWidth: '80px', padding: '4px 8px' }} />
                    </Tabs>
                    {tabIndex === 0 && (
                        <Box sx={{}}>
                            <ColorPicker className='ColorPicker' color={props.value} onChange={handleChange} hideAlpha={true} />
                            <Box className='text&button'>
                                <Typography sx={{ marginBottom: '.8rem!important' }} variant='h4'>Create a Preset:</Typography>
                                <TextField
                                    label="Preset Name"
                                    value={presetTitle}
                                    onChange={(e) => setPresetTitle(e.target.value)}
                                    className='PresetTitle'
                                />
                                <Box className='ButtonBox'>
                                    <Button className='PresetButton' onClick={savePreset}>Save Preset</Button>
                                    <Button className='SelectButton' onClick={selectColor}>Select Colour</Button>
                                </Box>
                            </Box>
                        </Box>
                    )}
                    {tabIndex === 1 && (
                        <Box sx={{ maxHeight: '400px', overflowY: 'auto', margin: '.5rem', padding: '0px 3px' }}>
                            <Typography variant='h4'>Select Your Colour:</Typography>
                            {presets.length === 0 ? (
                                <p>No Presets Created</p>
                            ) : (
                                <Grid container spacing={2}>
                                    {presets.map((preset, index) => (
                                        <Grid item xs={6} key={index}>
                                            <Box
                                                sx={{
                                                    backgroundColor: preset.color,
                                                    width: '100%',
                                                    height: '90px',
                                                    borderRadius: '5px',
                                                    cursor: 'pointer',
                                                    position: 'relative',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    border: '1px solid black',
                                                    transition: 'transform 0.3s ease',
                                                    '&:hover': {
                                                        transform: 'scale(1.03)'
                                                    }
                                                }}
                                                onClick={() => selectPreset(preset.color)}
                                            >
                                                <IconButton
                                                    sx={{ position: 'absolute', top: 0, right: 0 }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        deletePreset(index);
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            border: '1.4px solid black',
                                                            borderRadius: '50%',
                                                            padding: '2px',
                                                            backgroundColor: 'rgba(0, 0, 0, 0.5)'
                                                        }}
                                                    >
                                                        <DeleteIcon sx={{ color: 'white' }} />
                                                    </Box>
                                                </IconButton>
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '0.5rem' }}>
                                                <Typography variant="caption" sx={{ color: 'black', textAlign: 'center' }}>
                                                    {preset.title}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </Box>
                    )}
                    {tabIndex === 2 && (
                        <ContrastChecker /> // Render the ContrastChecker component
                    )}
                </Box>
            </Modal>
        </div>
    );
};

export { ColourPicker };
