import { Configuration } from "../model/Configuration";
import { CustomerConfiguration } from "../model/CustomerConfiguration";

const getCustomerConfiguration = async (
    getFunction: <T>(url: string) => Promise<T>
) => {
    const result = await getFunction<CustomerConfiguration>(
        "/api/customer/configuration/"
    );
    return result;
};

const getConfiguration = async (
    getFunction: <T>(url: string) => Promise<T>
) => {
    const result = await getFunction<Configuration>(
        "/api/configuration"
    );
    return result;
};

export { getCustomerConfiguration, getConfiguration };
