import { useState, useEffect, useCallback } from 'react';
import { WidgetProps } from '@rjsf/utils';
import { MenuItem, Select, InputLabel } from '@mui/material';

import { ITableColumn } from "../../../../services/model/dataManagement/ITableStructureListModel";

import { usePageContext } from "../../pages/pagesContext";

const DbColumnWidget = ({ onChange, id, value, label }: WidgetProps) => {
    const { dbData, selectedTableId } = usePageContext();
    const [columns, setColumns] = useState<ITableColumn[]>();

    useEffect(() => {
        if (!columns || columns.length === 0) {
            return;
        }

        if (columns.length > 1 &&
            !columns.some(column => column.field === value)) {

            // This occurs when the table selection is changed and a selected column value is not available as the columns values have changed.
            // In this case, we set the value to the first value which is "none", otherwise the column dropdown shows blank.
            onChange(columns[0].field);
        }
    }, [selectedTableId, columns, value, onChange]);

    useEffect(() => {
        const selectedTable = selectedTableId === "none" ?
            { columns: [] } :
            dbData?.tables?.find(table => table.id === selectedTableId);

        const selectedTableColumns = selectedTable && selectedTable.columns.length > 0 ?
            selectedTable.columns :
            [{ field: "none", headerName: "None" }] as ITableColumn[];

        setColumns(selectedTableColumns);
    }, [dbData, selectedTableId]);

    const handleChange = useCallback((event: any) => {
        const newValue = event.target.value;
        onChange(newValue);
    }, [onChange]);

    if (!columns) {
        return <></>;
    }

    return (
        <>
            <InputLabel id={`${id}-label`}>{label}</InputLabel>
            <Select
                id={id}
                value={value || columns[0].field}
                labelId={`${id}-label`}
                onChange={handleChange}
                label={label}
                variant="outlined"
                defaultValue={columns[0].field}
            >
                {columns?.map((column) =>
                    <MenuItem key={column.field} value={column.field}>{column.headerName}</MenuItem>)
                }
            </Select>
        </>
    );
};

export { DbColumnWidget };
