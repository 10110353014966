import { Box, Paper, SxProps, Theme, Typography } from "@mui/material";

import { theme } from "../../theme";

import { ChatMessage } from "./ChatWindow";

export const ChatMessageBox = ({ sender, message, time, transfer }: ChatMessage) => {
    const sharedStyles: SxProps<Theme> = {
        margin: theme.spacing(1, 1, 1, 0),
        display: "flex",
        flexDirection: "column",
        maxWidth: "80%"
    };
    const styles: SxProps<Theme> =
        sender === "Admin"
            ? { alignSelf: "end", alignItems: "end" }
            : { alignSelf: "start", alignItems: "start" };

    const textBoxStyles: SxProps<Theme> =
        transfer
            ? { backgroundColor: theme.palette.success.dark, color: theme.palette.success.contrastText }
            : sender === "Admin"
                ? { backgroundColor: theme.palette.text.primary, color: theme.palette.secondary.contrastText }
                : { backgroundColor: theme.palette.secondary.main, color: theme.palette.secondary.contrastText };

    return (
        <Box sx={{ ...sharedStyles, ...styles }}>
            <Paper sx={textBoxStyles}>
                <Typography sx={{
                    wordBreak: "break-word",
                    margin: theme.spacing(1)
                }}>{message}</Typography>
            </Paper>
            <Typography variant="caption" sx={{ mt: theme.spacing(0.5) }}>
                {time.toLocaleTimeString()}
            </Typography>
        </Box>
    );
};
