import { SkipPage } from "../model/skipPage";
import { CustomerUser } from "../model/users/customerUser";

const getCustomerUsers = async (
    getFunction: <T>(url: string) => Promise<T>,
    search?: string, skipToken?: string) => {
    const queries = [
        skipToken && `skipToken=${skipToken}` || undefined,
        search && `search=${encodeURIComponent(search)}` || undefined,
    ].filter(x => x?.length);

    const compiledQuery = queries.length ? "?" + queries.join("&") : "";

    const result = await getFunction<SkipPage<CustomerUser>>("/api/user" + compiledQuery);
    return result;
};

export {
    getCustomerUsers
};
