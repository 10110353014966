import MuiTextField, { TextFieldProps } from "@mui/material/TextField";

import { useField } from "formik";

interface CustomProps {
    name: string;
}

type MuiTextFieldProps = CustomProps & TextFieldProps;

const TextField = (props: MuiTextFieldProps) => {
    const [field, meta] = useField(props.name);

    const error: boolean = meta.touched && !!meta.error;
    const errorMessage: string | undefined = error ? meta.error : undefined;
    const variant = props.variant ? props.variant: "standard";

    return (
        <MuiTextField
            {...props}
            error={meta.touched && !!meta.error}
            name={field.name}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            fullWidth
            helperText={errorMessage}
            variant={variant}
            margin="dense"
        />
    );
};

export { TextField };
