import * as yup from "yup";

export type Schedule = {
    rowKey?: string;
    eventId?: string;
    venueId?: string;
    scheduleName?: string;
    venueName?: string;
    capacity?: number;
    price?: number;
    startDate?: Date;
    endDate?: Date;
    timestamp?: Date;
    deleted?: boolean;
    isRecurring?: boolean;
    repeatEvery?: number;
    daysOfWeek?: string[];
    repeatUntilDate?: Date;
};

export const ScheduleSchema: yup.SchemaOf<Schedule> = yup.object({
    rowKey: yup.string().notRequired(),
    eventId: yup.string().notRequired(),
    venueId: yup.string().required(),
    scheduleName: yup.string().notRequired(),
    venueName: yup.string().notRequired(),
    capacity: yup.number().required(),
    price: yup.number().required(),
    startDate: yup.date().notRequired(),
    endDate: yup.date().notRequired(),
    timestamp: yup.date().notRequired(),
    deleted: yup.boolean().notRequired(),
    isRecurring: yup.boolean().notRequired(),
    repeatEvery: yup.number().notRequired(),
    daysOfWeek: yup.array().notRequired(),
    repeatUntilDate: yup.date().notRequired(),
});

