import { SchemaType } from "../../schemas/schemaTypes";
import { PreviewModel } from "../model/previewContent";
import { removeExtension } from "./FileNameService";

const getAppConfigContent = <schema>(get: <T>(url: string) => Promise<T>) => async (appId: string, schema: SchemaType) => {
    const result = await get<schema>(`/api/frontendconfig/${appId}/${schema}`);
    return result;
};

const getPreviewContent = (get: (url: string) => Promise<PreviewModel>) => async (appId: string) => {
    const result = await get(`/api/frontendconfig/${appId}/preview`);
    return result;
};

const postAppConfigContent = <schema>(post: <T>(url: string, body: any) => Promise<T>) => async (appId: string, schema: SchemaType, body: any) => {
    const result = await post<schema>(`/api/frontendconfig/${appId}/${schema}`, body);
    return result;
};

const storePreviewModelInLocalStorage = (previewModel: PreviewModel,  pageName: string, page: any,  previewType: "page" | "modal", themeCss?: string) => {
    previewModel.configFiles.forEach(file => {
        const configItem = removeExtension(file.name);

        if (configItem === 'client-style' && themeCss) {
            localStorage.setItem(`_edit_appconfig/client-style`, themeCss);
        }
        else {
            localStorage.setItem(`_edit_appconfig/${configItem}`, file.content);
        }

        if (configItem === 'modal' && previewType === 'modal') {
            localStorage.setItem(`_edit_appconfig/modal`, page);
        }
    });

    previewModel.pageFiles.forEach(file => {
        const pageItem = removeExtension(file.name);

        if (pageName === pageItem) {
            localStorage.setItem(`_edit_pages/${pageName}`,  JSON.stringify(page));
        }
        else {
            localStorage.setItem(`_edit_pages/${pageItem}`, file.content);
        }
    });
};

export { getAppConfigContent, postAppConfigContent, getPreviewContent, storePreviewModelInLocalStorage };
