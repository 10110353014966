import "./css/bb-media-gallery.css";
import { Grid, Container, Typography, Box } from "@mui/material";

import { FolderCard, PartnerFolderDirectoryList } from ".";
import { useCallback, useContext, useState } from "react";
import useDataClient from "../../axios/dataClient";
import { useAsync } from "../../useAsync";
import { MemberAppContext } from "../../MemberAppContext";

export const Assets = () => {
    const appContext = useContext(MemberAppContext);
    const { get } = useDataClient();

    const [partnerDirectories, setPartnerDirectories] = useState<PartnerFolderDirectoryList[]>([]);

    const fetchPartnerFolders = useCallback(async () => {
        const value = await get<PartnerFolderDirectoryList[]>("api/asset/partnerFolders");
        return value;
    }, [get]);

    useAsync(fetchPartnerFolders, setPartnerDirectories, []);

    return (<>
        <Container>
            <Box sx={{ display: "flex", margin: "auto", marginBottom: "2rem", justifyContent: "center" }}>
                <Typography variant="h2" component="h2">
                    Media Library
                </Typography>
            </Box>
            <Grid
                container
                justifyContent="center"
                spacing={6}>
                { !appContext.user.isPartnerUser && (
                    <>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <FolderCard name="Public" friendlyName={"Public"} parentPath="root" isPublic={true} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <FolderCard name="Private" friendlyName={"Private"} parentPath="root" isPublic={false} />
                        </Grid>
                    </>)
                }
                {partnerDirectories.map((directory) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={directory.path}>
                        <FolderCard
                            name={directory.path}
                            friendlyName={directory.name}
                            parentPath=""
                            isPublic={true}
                        />
                    </Grid>
                ))}
            </Grid>
        </Container>
    </>);
};
