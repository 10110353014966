/*eslint no-loop-func: "error"*/

import * as React from "react";
import { Box, Typography } from "@mui/material";

export interface BBItemListProps {
    panel: any;
}

export const BBItemList: React.FC<BBItemListProps> = ({ panel }) => {
    const stripHtml = (html: string) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    return (
        <Box className="ItemList">
            {panel?.imgUrl && <img src={panel.imgUrl} alt="Header Image" />}
            {panel?.panelHeading ? (<Typography variant="h3" className="bb-tal">{panel?.panelHeading}</Typography>) : (<span></span>)}
            {panel?.panelPara ? (<Typography variant="body1" className="bb-tal">{panel?.panelPara}</Typography>) : (<span></span>)}
            <Box sx={{ width: '100%' }}>
                {panel?.links && panel.links.length > 0 && (
                    <Box>
                        {panel.links.map((link: any, index: number) => (
                            <Box className="ItemListLinks" key={index}>
                                <Box className="link-name">{link.name}:</Box>
                                <Box className="link-value">{stripHtml(link.value)}</Box>
                            </Box>
                        ))}
                    </Box>
                )}
            </Box>
        </Box>
    );
};
