import React, { useEffect, useRef, useState, useCallback } from 'react';
import ReactPlayer from 'react-player';
import { Modal, Box, Button, Slider, IconButton, Typography, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

interface VideoComponentProps {
  videoUrl: string;
  isOpen: boolean;
  onClose: () => void;
}

const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
};

export const VideoComponent: React.FC<VideoComponentProps> = ({ videoUrl, isOpen, onClose }) => {
    const playerRef = useRef<ReactPlayer | null>(null);
    const [playing, setPlaying] = useState(false);
    const [played, setPlayed] = useState(0);
    const [seeking, setSeeking] = useState(false);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setPlaying(true);
            setLoading(true); // Set loading to true when the modal is opened
            if (playerRef.current) {
                playerRef.current.seekTo(0, 'seconds'); // Seek to the beginning
            }
            setPlayed(0);
            setCurrentTime(0);
        } else {
            setPlaying(false);
        }
    }, [isOpen]);

    useEffect(() => {
        if (isOpen && playerRef.current) {
            const videoElement = playerRef.current.getInternalPlayer();
            if (videoElement instanceof HTMLVideoElement) {
                // Disable download option in video controls
                videoElement.setAttribute('controlsList', 'nodownload');
                // Disable right-click to prevent "Save video as..."
                videoElement.oncontextmenu = (e) => e.preventDefault();
            }
        }
    }, [isOpen]);

    const handleProgress = useCallback((state: { played: number; playedSeconds: number }) => {
        if (!seeking) {
            setPlayed(state.played); // Synchronize `played` only when not seeking
            setCurrentTime(state.playedSeconds); // Update current time
        }
    }, [seeking]);

    const handleDuration = (videoDuration: number) => {
        setDuration(videoDuration); // Set the total duration of the video
        setLoading(false);
    };

    const handleSeekChange = useCallback((event: Event | React.SyntheticEvent<Element, Event>, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            setPlayed(newValue / 100); // Update slider position during seek
            setCurrentTime((newValue / 100) * duration); // Update current time during seek
        }
    }, [duration]);

    const handleSeekMouseDown = useCallback(() => {
        setSeeking(true); // Disable onProgress updates while seeking
    }, []);

    const handleSeekMouseUp = useCallback(
        (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
            if (typeof newValue === 'number') {
                const seekTo = newValue / 100;
                setPlayed(seekTo); // Update played state
                setCurrentTime(seekTo * duration); // Update current time state
                setSeeking(false); // Stop seeking
                if (playerRef.current) {
                    playerRef.current.seekTo(seekTo, 'fraction'); // Correctly seek based on fraction
                }
            }
        },
        [duration]
    );

    const togglePlayPause = useCallback(() => {
        setPlaying((prev) => !prev);
    }, []);

    return (
        <Modal
            open={isOpen}
            onClose={() => {
                setPlaying(false); // Ensure video is paused when modal is closed
                onClose();
            }}
            aria-labelledby="training-video-modal"
            aria-describedby="training-video-modal-description"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    width: '80%',
                    maxWidth: '1000px',
                    bgcolor: 'background.paper',
                    borderRadius: '8px',
                    border: '3px solid #11a681',
                    boxShadow: 24,
                    p: 0,
                    overflow: 'hidden',
                    backgroundColor: '#000',
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <Button
                    onClick={() => {
                        setPlaying(false); // Ensure video is paused when modal is closed
                        onClose();
                    }}
                    sx={{
                        position: 'absolute',
                        top: '15px',
                        right: '15px',
                        color: 'white',
                        backgroundColor: '#11a681',
                        borderRadius: '50%',
                        padding: '8px',
                        minWidth: 'auto',
                        height: '40px',
                        width: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
                        zIndex: 2001,
                        '&:hover': {
                            backgroundColor: '#0e8d6b',
                        },
                    }}
                >
                    <CloseIcon sx={{ fontSize: '24px' }} />
                </Button>
                {loading && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: 'white',
                            zIndex: 2000,
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <CircularProgress color="primary" sx={{ marginBottom: '14px' }} />
                        <Typography sx={{ ml: 2 }}><h2>Video Loading.....</h2></Typography>
                    </Box>
                )}
                <ReactPlayer
                    ref={playerRef}
                    url={videoUrl}
                    playing={playing}
                    controls={false} // Disable default controls
                    width="100%"
                    height="100%"
                    style={{
                        display: 'block',
                        backgroundColor: 'black',
                    }}
                    config={{
                        file: {
                            attributes: {
                                controlsList: 'nodownload',
                                onContextMenu: (e: React.MouseEvent) => e.preventDefault(),
                            },
                        },
                    }}
                    onProgress={handleProgress}
                    onDuration={handleDuration}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: '10px',
                        left: '10px',
                        right: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        zIndex: 2000,
                        backgroundColor: 'rgba(0, 0, 0, 0.85)',
                        padding: "1.2rem",
                        borderRadius: '8px',
                        border: '1px solid #11a681',
                        opacity: isHovered ? 1 : 0,
                        transition: 'opacity 0.3s',
                    }}
                >
                    <IconButton
                        onClick={togglePlayPause}
                        sx={{
                            color: 'white',
                            backgroundColor: '#11a681',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
                            '&:hover': {
                                backgroundColor: '#0e8d6b',
                            },
                        }}
                    >
                        {playing ? <PauseIcon /> : <PlayArrowIcon />}
                    </IconButton>
                    <Box sx={{ backgroundColor: '#11a681', borderRadius: '4px', marginLeft: '14px', display: 'flex', alignItems: 'center',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)' }}>
                        <Typography sx={{ color: 'white', marginBottom: '0px!important', padding: ' 7px 12px' }}>
                            {formatTime(currentTime)} / {formatTime(duration)}
                        </Typography>
                    </Box>
                    <Slider
                        value={played * 100}
                        onChange={(event, newValue) => handleSeekChange(event as Event, newValue)}
                        onMouseDown={handleSeekMouseDown}
                        onChangeCommitted={(event, newValue) => handleSeekMouseUp(event as Event, newValue)}
                        aria-labelledby="continuous-slider"
                        sx={{
                            flex: 1,
                            mx: 2,
                            '& .MuiSlider-rail': {
                                color: 'white',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
                            },
                            '& .MuiSlider-track': {
                                color: '#11a681',
                            },
                            '& .MuiSlider-thumb': {
                                color: '#11a681',
                            },
                        }}
                    />
                </Box>
            </Box>
        </Modal>
    );
};
